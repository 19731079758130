import { GET_LIST_PROMOCODES } from '../actions/typesAction'

const initialState = {
    listPromos: [],
}

const serviceReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        // case PROCESSING:
        //     return initialState;
        // case GET_LIST_OF_TAXES:
        //     return {
        //         ...state,
        //         taxes: payload,
        //     };
        case GET_LIST_PROMOCODES:
            return {
                ...state,
                listPromos: payload
            };
        default:
            return state;
            
    }
}

export default serviceReducer;