import React from 'react';
import DashboardLayout from '../../layout/Layout';
import ServiceSchedule from './ServiceSchedule'

const DashboardServiceSchedule = () => {
  return(
    <DashboardLayout>
      <ServiceSchedule />
    </DashboardLayout>
  );
}

export default DashboardServiceSchedule;