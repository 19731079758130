import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { authAction, resetPassword } from '../../store/actions/authAction';
import { useParams, useNavigate } from "react-router-dom";
import AlertMessage from '../layout/AlertMessage';
import Input from '../share/inputs/Input';
import { setAlert } from '../../store/actions/alertAction';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
/*
import axios from 'axios';
*/
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link to="#" color="inherit" href="https://material-ui.com/">
                Tiggidoo
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(20, 16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        '& img': {
            padding: '3rem'
        }
    },
    errorMessage: {
        color: '#dc3545',
        paddingTop: '5px'
    },
    errors: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
                borderRadius: '4px'
            }
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '11px',
            paddingBottom: '11px',
        }
    },
    displayPassword: {
        marginLeft: '-6rem',
        '& .MuiIconButton-root': {
            padding: '7px'
        }
    },
    backProgressBar: {
        width: '100%',
        padding: '0 20px'
    },
    backProgressBarLayOut1: {
        width: '100%',
        backgroundColor: '#d0eae0',
        borderRadius: '5px'
    },
    backProgressBarLayOut2: {
        textAlign: 'center',
        height: '2.3rem',
        borderRadius: '5px'
    },
    progressBarText: {
        textAlign: 'center',
        marginTop: '-2.1rem',
        fontWeight: 'bold'
    },
}));

const ResetPasswordForm = ({ isLoggedIn, setAlert, resetPassword }) => {

    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [color, setColor] = useState('#f112024f');
    const [percentage, setPercentage] = useState('0%');
    const [messageText, setMessageText] = useState('Faible');
    let [colorTexto, setColorTexto] = useState('#000');
    let perc = 0;

    let { token, email } = useParams();

    token = decodeURIComponent(token);
    email = decodeURIComponent(email);


    const [formData, setFormData] = useState({
        password: '',
        passwordConfirmation: '',
        showPassword: false
    })
    const [validate, setValidate] = useState(false);

    //const isLoggedIn = useSelector(store => store.auth.isLoggedIn);
    const history = useNavigate();

    React.useEffect(() => {

        function loggearse() {
            if (isLoggedIn) {
                history('/dashboard');
            }
        }
        loggearse();
    }, [isLoggedIn, history])

    const { password, passwordConfirmation } = formData;

    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#%&])(?=.{8,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const submitForm = (e) => {
        e.preventDefault();
        let isValidationOk = true;
        if (password.trim().length === 0 || passwordConfirmation.trim().length === 0) {
            isValidationOk = false;
            setValidate(true);
            setAlert("Ecrire un mot de passe et la confirmation", 'error');
        }
        if (password.trim().length < 8) {
            isValidationOk = false;
            setValidate(true);
            setAlert("Le mot de passe doit comporter au moins 8 chiffres", 'error');
        }
        if (!(passwordConfirmation.localeCompare(password) === 0)) {
            isValidationOk = false;
            setValidate(true);
            setAlert("Les mots de passe doivent être identiques", 'error');
        }
        if (isValidationOk) {
            resetPassword(token, email, formData)
        } else {
            return false;
        }
    };

    const handleChange = e => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() });

        if (e.target.name === 'password') {
            let value = e.target.value;

            if (strongRegex.test(value)) {
                setColor('#f11202');
                setMessageText('Forte');
                perc = 100;
            } else if (mediumRegex.test(value)) {
                setColor('#f1120275');
                setMessageText('Moyenne');
                perc = 60 + (value.length < 9 ? (value.length * 3) : 24);
            } else {
                setColor('#f112024f');
                setMessageText('Faible');
                perc = 10 + (value.length < 9 ? (value.length * 3) : 24);
            }
            if (perc > 35) {
                setColorTexto('#fff');
            } else {
                setColorTexto('#000');
            }
            setPercentage(value.length > 0 ? `${perc}%` : '0');
        }


    }
    const handDisplayPassword = e => {
        e.preventDefault();
        setShowPassword(!showPassword);
    }
    const handDisplayPasswordConfirmation = e => {
        e.preventDefault();
        setShowPasswordConfirmation(!showPasswordConfirmation);
    }    
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    return (
        <Container maxWidth="xl">
            { (!isLoggedIn) && (
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />

                    <Grid item xs={false} sm={4} md={6}>
                        <Box className={classes.backImage}>
                            <img src="images/header/illustration-connexion.svg" className={classes.image} alt="" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square className={classes.sgnin} >
                        <AlertMessage />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Changer le mot de passe
                            </Typography>
                            <form className={classes.form}>
                                <Box my={2} display="flex" flexDirection="row" alignItems="center">
                                    <Input
                                        id="password"
                                        placeholder=""
                                        label="Mot de passe *"
                                        size="small"
                                        width={'100%'}
                                        type={showPassword ? 'text' : 'password'}
                                        defaultValue={password}
                                        onBlur={handleChange}
                                        variant="outlined"
                                        error={(password.trim().length === 0 && validate) ? 'Entrer un mot de passe' : ''}
                                    />
                                    <Box className={classes.displayPassword}>
                                        {(password.trim().length > 0) && (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                name="showPassword"
                                                onClick={e => handDisplayPassword(e)}
                                                onMouseDown={e => handleMouseDownPassword(e)}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                                <Box mt={2} mb={3} display="flex" flexDirection="row" alignItems="center">
                                    <Input
                                        id="passwordConfirmation"
                                        placeholder=""
                                        label="Confirmation mot de passe *"
                                        size="small"
                                        type={showPasswordConfirmation ? 'text' : 'password'}
                                        width={'100%'}
                                        defaultValue={passwordConfirmation}
                                        onBlur={handleChange}
                                        variant="outlined"
                                        error={(passwordConfirmation.trim().length === 0 && validate) ? 'Entrez la confirmation' : ''}
                                    />
                                    <Box className={classes.displayPassword}>
                                        {(passwordConfirmation.trim().length > 0) && (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                name="showPasswordConfirmation"
                                                onClick={e => handDisplayPasswordConfirmation(e)}
                                                onMouseDown={e => handleMouseDownPassword(e)}
                                                edge="end"
                                            >
                                                {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                                
                                <Box className={classes.backProgressBar} >
                                    <Box className={classes.backProgressBarLayOut1}>
                                        <Box className={classes.backProgressBarLayOut2} style={{ width: percentage, backgroundColor: color }}></Box>
                                        <Box className={classes.progressBarText} style={{ color: colorTexto }}>{messageText}</Box>
                                    </Box>
                                </Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={e => submitForm(e)}
                                >
                                    Soumettre
                                </Button>

                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Container>
    );

}

const mapStateToProps = state => ({
    //const isLoggedIn = useSelector(store => store.auth.isLoggedIn);
    isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps, { setAlert, authAction, resetPassword })(ResetPasswordForm);
