import React from 'react';
import DashboardLayout from '../../layout/Layout';
import CandidateList from './CandidateList';
import { useParams } from 'react-router-dom';
import Candidate from '../candidate/Candidate';

const DashboardCandidateList = () => {

  const { id, status_id } = useParams()

  return (
    <DashboardLayout>
      {
        id === undefined
          ?
          (<CandidateList />)
          :
          (
            <Candidate
              id={id}
              statusId={status_id}
            />
          )
      }

    </DashboardLayout>
  );
}

export default DashboardCandidateList;