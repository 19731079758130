import { GET_TASKS, GET_TASKS_ACTIVE, PROCESSING } from './typesAction'
import config from '../../config.json'
import axios from 'axios'

export const createTask = (token, description) => async dispatch => {
    try{

        const header = {
            headers: {'Authorization' : `Bearer ${token}`}
        }

        const data = {
            fr: description.fr,
            en: description.en
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/householdList/create`, data, header)
        .then((res) => {
            if(res.status === 200){
                dispatch(getTaskInactive(token))
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getTaskInactive = (token) => async dispatch => {
    try{

        dispatch({
            type: PROCESSING,
        })

        const header = {
            headers: {'Authorization' : `Bearer ${token}`}
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/householdList/inactive`, header)
        .then((res) => {
            console.log('Orbita21: ', res)
            dispatch({
                type: GET_TASKS,
                payload: res.data.inactive
            })
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const getTaskActive = (token) => async dispatch => {
    try{

        const header = {
            headers: {'Authorization' : `Bearer ${token}`}
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/householdList/actives`, header)
        .then((res) => {
            dispatch({
                type: GET_TASKS_ACTIVE,
                payload: res.data.active
            })
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const updateTask = (token, id) => async dispatch => {
    try{

        dispatch({
            type: PROCESSING,
        })

        const header = {
            headers: {'Authorization' : `Bearer ${token}`}
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/householdList/update`, data, header)
        .then((res) => {
            if(res.status === 200){
                dispatch(getTaskInactive(token))
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}

export const removeTask = (token, id) => async dispatch => {
    try{

        dispatch({
            type: PROCESSING,
        })

        const header = {
            headers: {'Authorization' : `Bearer ${token}`}
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/householdList/delete`, data, header)
        .then((res) => {
            if(res.status === 200){
                dispatch(getTaskInactive(token))
            }
        })
        .catch((error) => {
            console.log(error)
        })

    }catch(error){
        console.log(error)
    }
}