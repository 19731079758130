import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, FormControl, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { LogicCandidate } from './LogicCandidate';
import PaperLayout from '../../share/layout/PaperLayout';
import Processing from '../Processing';
import SelectInput from '../../share/inputs/SelectInput';
import CandidateInfo from './CandidateInfo';
import CandidateRef from './CandidateRef';
import Annotations from './Annotations';
import { updateCandidateStatusAction } from '../../../store/actions/candidateAction';
import varConstant from "../../../constant.json"

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { unlockCandidateAction } from '../../../store/actions/candidateAction';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '3.8rem',
    fontWeight: 'bold',
    marginLeft: '1.4rem',
    fontFamily: 'Righteous'
  },
  headerTab: {
    color: '#000',
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  tabStyle: {
    '&>div>div': {
      padding: '16px 0px 0 0px'
    }
  }
}
))

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

let etatCandidate = [
  { id: 1, name: 'NEW', color: '#5087f5', buttonName: 'ACCEPTER' },
  { id: 2, name: 'EN COURS', color: '#f49e3a', buttonName: 'ACCEPTER' },
  { id: 3, name: 'ACCEPTÉ', color: '#3ad640', buttonName: 'ACCEPTER' },
  { id: 4, name: 'REJETÉ', color: '#f05c5c', buttonName: 'REFUSER' }
];
const getDateAt = (createDateAt) => {
  const createdAt = new Date(createDateAt);
  let year = createdAt.getFullYear();
  let month = createdAt.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let day = (createdAt.getDate() < 10) ? "0" + createdAt.getDate() : createdAt.getDate();
  let formCreatedAt = day + "/" + month + "/" + year;
  return formCreatedAt;

}

const Candidate = ({ id, statusId }) => {

  const classes = useStyles()
  const dispatch = useDispatch()
  const store = useStore()
  const history = useNavigate()

  const { VarButton } = varConstant
  const { candidate, dataForm, setDataForm } = LogicCandidate(id, statusId)

  const { value, btnBlocked, servicesList, statusIdCan, errorMsg, errorType } = dataForm

  const candidateUnlocked = e => {
    e.preventDefault();
    const token = store.getState().auth.access_token;
    localStorage.removeItem('candidateId')
    localStorage.setItem('goToCandidateList', true)
    dispatch(unlockCandidateAction(candidate.id, token, '', '', history));

  }

  const handleChange = (e) => {
    let value = e.target.value;
    console.log('Este es el status: ', e.target.name, value)

    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value
    });
  }

  const handleChangeTabs = (event, newValue) => {
    setDataForm({
      ...dataForm,
      value: newValue
    })
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const updateStatus = (e, name) => {
    e.preventDefault();
    const token = store.getState().auth.access_token;

    setDataForm({
      ...dataForm,
      validateMailVisio: false
    })


    let message = '';
    let reference = 0
    if (candidate.reference.length > 0) {
      if (candidate.reference[0].contact_status === 1) {
        reference = 1
      }
    } else {
      reference = 1
    }

    if (statusIdCan === '3') {
      if (candidate.background_check_status === 0
        || candidate.email_interview_status === 0
        || candidate.interview_status === 0
        || reference === 0
      ) {
        message = message + `Pour changer le statut, il est nécessaire d'ajouter toutes les informations`;
      }
    }

    if (message.length === 0) {
      if (statusIdCan > 2) {
        setDataForm({
          ...dataForm,
          btnBlocked: true
        })
        console.log('Paso por aqui: ', btnBlocked)
      }
      dispatch(updateCandidateStatusAction(token, statusIdCan, candidate, history, VarButton.condition2));

    } else {

      setDataForm({
        ...dataForm,
        errorMsg: message,
        errorType: 'error'
      })
      // dispatch(setAlert(message, 'error'));
    }
  }

  const handleCloseMessage = (e) => {
    e.preventDefault()
    setDataForm({
      ...dataForm,
      errorMsg: '',
      errorType: 'success'
    })
  }

  if (candidate === null) {
    return (
      <Processing />
    )
  }

  if (statusIdCan > 1) {
    etatCandidate = etatCandidate.filter(item => item.id > 1);
  }
  let getColor = etatCandidate.filter((item) => item.id === parseInt(statusIdCan));


  return (
    <PaperLayout>
      {
        (errorMsg.length > 0) && (
          <Box style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Box style={{ backgroundColor: errorType === 'error' ? 'red' : '#28cc8b', padding: '20px 10px', margin: '10px 20%', color: '#fff', position: 'relative' }}>
              <Box style={{ position: 'absolute', top: '0', right: '0' }}>
                <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleCloseMessage(e)}>
                  <HighlightOffIcon style={{ color: 'white' }} />
                </IconButton>
              </Box>
              {errorMsg}
            </Box>
          </Box>
        )
      }
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box>
            <Typography component="h1" variant="h1" noWrap className={classes.title}>Candidature</Typography>
          </Box>
        </Grid>
        <Grid item md={7} mb={0}>
          <Box display="flex" flexDirection="column" ml={2}>
            <Box mb={1}><Typography variant="h5">Date de candidature</Typography></Box>
            <span> {getDateAt(candidate.credential.created_at)} </span>
          </Box>
        </Grid>
        <Grid item md={5}>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end" mr={4}>
            {(candidate.status_id <= 3) && (
              <Box mr={2}>
                <Button
                  variant="contained"
                  onClick={(e) => candidateUnlocked(e)}
                  style={{
                    backgroundColor: 'red',
                    color: '#fff',
                    fontWeight: 'bold',
                    textTransform: 'capitalize'
                  }}
                >
                  Quitter
                </Button>
              </Box>
            )}

            <Box>
              <Box mb={1}><Typography variant="h5">État de la candidature</Typography></Box>
              <FormControl disabled={btnBlocked}>
                <SelectInput
                  id="statusIdCan"
                  name="statusIdCan"
                  data={etatCandidate}
                  colorFont={{ color: getColor.length > 0 ? getColor[0].color : '#000', fontWeight: 'bold' }}
                  onChange={(e) => handleChange(e)}
                  defaultValue={statusIdCan}
                  disabled={btnBlocked}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12}>
          <AppBar position="static" className={classes.headerTab}>
            <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
              <Tab label="Candidature" {...a11yProps(0)} />
              <Tab label="Parcours" {...a11yProps(1)} />
              <Tab label="Annotations" {...a11yProps(2)} />
            </Tabs>
            <Box className={classes.tabStyle}>
              <TabPanel value={value} index={0}>
                <CandidateInfo
                  candidate={candidate}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CandidateRef
                  candidate={candidate}
                  servicesList={servicesList}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Annotations
                  id={candidate.id}
                />
              </TabPanel>
            </Box>

            <Box display="flex" justifyContent="space-around">
              {
                ((statusIdCan > 2)) && (

                  <Button
                    variant="contained"
                    onClick={(e) => updateStatus(e, getColor[0].name)}
                    style={{
                      backgroundColor: getColor.length > 0 ? getColor[0].color : '#000',
                      color: '#fff',
                      fontWeight: 'bold',
                      width: '250px'
                    }}
                    disabled={btnBlocked}
                  >
                    {
                      getColor.length > 0 ? getColor[0].buttonName : ''
                    }
                  </Button>

                )
              }
            </Box>
          </AppBar>
        </Grid>


      </Grid>
    </PaperLayout>
  );
}

export default Candidate;