import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
//import CheckBoxCustom from '../../../../../share/inputs/CheckBoxCustom'
import CheckBoxCustom from '../../../../share/inputs/CheckBoxCustom'
import CustomModal from '../../../candidate/CustomModal'

const useStyle = makeStyles((theme) => ({
    boxModal: {
        width: '100%',
        height: '200px',
        marginTop: '-200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blockArea:{
        inset: '0px',
        backgroundColor: 'rgb(182, 237, 253)', //'rgb(224, 248, 255)', 'rgba(170, 211, 223, 0.5)',
        opacity: '.5'
    },
    btn: {
        border: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderRadius: 0,
        '&:focus': { 
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
        '&:hover': { 
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        }
    },
    list: {
        display: 'flex',   
        height: '200px',
        overflow: 'auto',
        zIndex: 1,
        boxShadow: '5px 10px 10px #00000055',
        '& .MuiIconButton-label': { 
            marginTop: '-12px', 
            marginLeft: '0'
        },  
        '@media(max-width: 600px)': {
            flexDirection: 'column',
        }
    },
    listArea:{
        margin: theme.spacing(3, 0) ,
        '& h5':{
            fontSize: '1.8rem'
        },  
        '& .MuiCheckbox-root':{
            padding: '9px 9px 9px 0px',
            marginLeft: '-5px'
        },
        '@media(max-width: 1200px)':{
            maxWidth: '400px',
            margin: '0 auto'
        },  
        '@media(max-width: 600px)': {
            maxWidth: '100%',
            margin: theme.spacing(1)
        }
    }

}))

const compareArrays = (a1, a2) => {
    if(a1.length !== a2.length) {
        return true
    }
    
    for(let index in a2){
        const pos = a1.indexOf(a2[index])
        if(pos === -1){
            return true
        }
    }

    return false
}

const ChangeHouseHoldList = ({ houseHoldList, selectedList, updateHouseHoldListFunction, method }) => {
    const classes = useStyle()  
    
    const [data, setData] = useState({
        listArra: [],
        totalList: [],
        desabledHoldList: true,
        desabledBtnUpdate: true,
        openModal: false
    })

    const { listArra, totalList, desabledHoldList, desabledBtnUpdate, openModal } = data

    useEffect(() => {
        let a = []
        for(let index in selectedList){
            a.push(selectedList[index].id)
        }

        setData({
            listArra: a,
            totalList: a,
            desabledHoldList: true,
            desabledBtnUpdate: false,
            openModal: false
        })
    }, [selectedList])

    /* POPUP START*/
    const closePopup = (e) => {
        e.preventDefault();
        //setOpenModal(false);
        setData({ ...data, openModal: false })
    }

    const runFunctionFromYesPopupButton = (e) => {
        e.preventDefault();
        updateHouseHoldListFunction(listArra, method)
        setData({ ...data, openModal: false })
    }
    /* POPUP END*/

    const handleList = (e) => {
        const value = parseInt(e.target.name.split('_')[1], 10)
        const index = listArra.indexOf(value)

        let btnState = false;
        if(index === -1){
            btnState = compareArrays([...listArra, value], totalList)
            setData({
                ...data, 
                listArra: [...listArra, value],
                desabledBtnUpdate: btnState
            })
        }else{                    
            const list = listArra.filter(row => row !== value)
            btnState = compareArrays(list, totalList)
            setData({
                ...data, 
                listArra: list,
                desabledBtnUpdate: btnState
            })
        }
    }
    
    const changeHoldListState = e => { 
        e.preventDefault() 
        setData({
            ...data, 
            desabledHoldList: !desabledHoldList
        })
    }

    const displayBtnUpdate = e => { 
        e.preventDefault() 
        setData({ ...data, openModal: true })
    }

    return (
        <Box className={classes.listArea}>
            <CustomModal 
                openModal={openModal}
                closePopup={closePopup}
                content="Voulez-vous mettre à jour la liste des tâches ?"
                runFunction={runFunctionFromYesPopupButton}
            />
            <Box mb={1}>
                <Typography variant="h5">LISTE MENAGE DU CLIENT</Typography>
            </Box>
            <Box className={classes.list}>
                <Box width={1} mt={2}>
                    {
                        houseHoldList.map(row => {
                            let ind = listArra.indexOf(row.id)  
                            return(
                                <Box key={row.id}>
                                    <CheckBoxCustom 
                                        name={`activity_${row.id}`}
                                        value={ind !== -1 ? true : false}
                                        handleChange={handleList}
                                        label={row.name}
                                        //disabled={handleList === undefined ? true : false}
                                        disabled={desabledHoldList}
                                    />
                                </Box>        
                            )
                        })
                    }
                </Box>
            </Box>

            <Box hidden = {!desabledHoldList}>
                <Box className={classes.boxModal + ' ' + classes.blockArea}>
                </Box>
                <Box className={classes.boxModal}>
                    <Button 
                        className={classes.btn}
                        variant="contained" 
                        color="secondary"
                        onClick={e => changeHoldListState(e)}
                    >
                        Modifier
                    </Button>
                </Box>
            </Box>

            <Box pt={3} textAlign="center" hidden = {!desabledBtnUpdate}>
                <Button 
                    className={classes.btn}
                    variant="contained" 
                    color="secondary"
                    onClick={e => displayBtnUpdate(e)}
                >
                    Modifier
                </Button>
            </Box>
        </Box>
    )
}

//export default AccommodationList

export default ChangeHouseHoldList