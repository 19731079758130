import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { sendMessage, getAllProChat, GetProInfoChat, GetAllMsgChat, updateMsg, addMsg,renitialiserCount } from '../../../../store/actions/chatAction';
import { connect } from "react-redux";
//import './chatList.css';
import { Box } from '@material-ui/core'
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
class ActiveUsers extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            users:null,  
        };
        this.onTagsChange = this.onTagsChange.bind(this);
        this.usersTable = this.usersTable.bind(this);
    }
   
    usersTable = () => {
        if (this.props.usersChat !== null) {
            let info = this.props.usersChat.pro.map(e =>
      
                `${e.proId}  ${e.firstName}  ${e.lastName} `
            )
            return info
        }
    }
   
    onTagsChange = (event, values) => {
          
        this.setState({
          tags: values
        });

       let user = this.props.usersChat.pro.filter(e => 
            String(e.proId) === String(values).charAt(0)  
            
        )
        user.map (e => 
            this.props.getUserData(e.email, e.chatRoomUid)
        )
    }
    componentDidMount() {
       this.usersTable() 
    }

    render() {
        const classes = this.props.classes
        const usersChat = this.props.usersChat
        const chatRoomUid = this.props.chatRoomUid
       
        if (usersChat === null || chatRoomUid === null) {
            return null
        }
      
        return (
            <div className={classes.Active_Users}>
            
                <div className={classes.Chat_li}>
                    Active Users
                </div>
                <Box>
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        options={ this.usersTable()}
                        onChange={this.onTagsChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                
                                label="Search user"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}/>
            
                        )}
                            
                    />
            
                </Box>       
                <hr />

                {this.props.usersChat.pro.map((elmt) => {
                        return (
                            < button className={classes.Users} onClick={(e) => this.props.getUserData(elmt.email, elmt.chatRoomUid)}>
                                    <Avatar className={classes.avatar} src={elmt.avatar} />
                                    <h6> {elmt.firstName} {elmt.lastName} </h6>
                                    <Box hidden={elmt.count === 0 ? true : false}>
                                        < AnnouncementOutlinedIcon className={classes.IconMessage}></AnnouncementOutlinedIcon>
                                    </Box>    
                            </button>
                        )

                })}
            </div>
        );
    }
}

    const mapDispatchToProps = (dispatch) => {
        return {//inscrire les action
            sendMessage: (reg) => dispatch(sendMessage(reg)),
            getAllProChat: (reg) => dispatch(getAllProChat(reg)),
            GetAllMsgChat: (reg) => dispatch(GetAllMsgChat(reg)),
            GetProInfoChat: (reg) => dispatch(GetProInfoChat(reg)),
            updateMsg: (reg) => dispatch(updateMsg(reg)),  
            addMsg: (reg) => dispatch(addMsg(reg)),
            renitialiserCount: (reg) => dispatch(renitialiserCount(reg))
      
        }
      }
      
    const mapStateToProps = (state) => {
        
        return {// prendre la data
            userAdmin: state.auth.userAdmin,
            token: state.auth.access_token,
            usersChat: state.chat.usersChat,
            proInfo: state.chat.proInfo,
            messagePro: state.chat.messagePro,
            messageEnv: state.chat.messageEnv,
            messageUpdate: state.chat.messageUpdate,
        }
    }
      

export default connect(mapStateToProps,mapDispatchToProps)(ActiveUsers);               