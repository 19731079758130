import React, { useEffect, useState } from "react";
import { Button, makeStyles, Box, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
    getTariff,
    updateTariffPriceAndTime,
    updateBasePrice,
    updateProPersonalization,
} from "../../../store/actions/serviceAction";
import { Typography } from "@material-ui/core";
import BlueBox from "./BlueBox";
import InputLabel from "./InputLabel";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
    tariffArea: {
        "& h5": {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        "& h6": {
            color: "#000",
            fontWeight: "bold",
        },
        "& .MuiInputBase-input": {
            backgroundColor: "#c7c6c6",
        },
        label: {
            fontSize: "1.4rem",
        },
    },
}));

const Tariff = ({ cityId, access_token, tariff }) => {
    const classes = useStyle();

    const dispatch = useDispatch();

    const [displayOptions, setDisplayOptions] = useState("TYPE DU LOGEMENT");
    const [varTime, setVarTime] = useState(null);

    const [formTableUpdate, setFormTableUpdate] = useState({
        city_service_id: cityId,
        id: "",
        table: "",
        price: "",
        time: "",
        chk: null,
        arrayM: [],
        arrayD: [],
    });

    useEffect(() => {
        const minutes = 5;
        let start_date = moment("2000-01-01T00:00:00").unix() * 1000;
        let end_date = moment("2000-01-01T02:35:00").unix() * 1000;
        const varTime = [];
        for (let i = start_date; i < end_date; i += minutes * 60000) {
            varTime.push({
                id: new Date(i).toString().split(" ")[4],
                name: new Date(i).toString().split(" ")[4],
                color: "#000",
            });
        }
        setVarTime(varTime);
    }, []);

    useEffect(() => {
        if (tariff === null) {
            dispatch(getTariff(access_token, cityId));
        }
    }, [access_token, cityId, dispatch, tariff]);

    const handleChange = (e) => {
        e.preventDefault();

        const { arrayM } = formTableUpdate;

        const exp = e.target.name.split("_");
        let type = exp[2];
        let nbreChk = `${exp[0]}_${exp[1]}_Check`;
        if (exp.length === 4) {
            type = exp[3];
            nbreChk = `${exp[0]}_${exp[1]}_${exp[2]}_Check`;
        }

        if (arrayM.indexOf(nbreChk) > -1) {
            if (type === "Price") {
                let { arrayD } = formTableUpdate;
                arrayD[`${e.target.name.slice(0, -5)}Check`].price =
                    e.target.value;
                setFormTableUpdate({
                    ...formTableUpdate,
                    price: e.target.value,
                    arrayD: arrayD,
                });
            } else if (type === "Time") {
                let { arrayD } = formTableUpdate;
                arrayD[`${e.target.name.slice(0, -4)}Check`].time =
                    e.target.value;
                setFormTableUpdate({
                    ...formTableUpdate,
                    time: e.target.value,
                    arrayD: arrayD,
                });
            }
        }
    };

    const handleDblClick = (e, table, price, time) => {
        console.log(table, price, "dblclick");
        const exp = table.split("_");
        let id = "";
        let tablea = "";

        if (exp.length === 3) {
            id = exp[2];
            tablea = `${exp[0]}_${exp[1]}`;
        } else {
            id = exp[1];
            tablea = exp[0];
        }

        let { arrayM, arrayD } = formTableUpdate;
        let arrayDatos = [];

        const index = arrayM.indexOf(e.target.name);
        if (index === -1) {
            arrayM.push(e.target.name);
            arrayD[e.target.name] = {
                id: id,
                table: tablea,
                price: price,
                time: time,
            };
        } else {
            arrayM = arrayM.filter((row) => row !== e.target.name);

            for (let index in arrayD) {
                if (index !== e.target.name) {
                    arrayDatos[index] = arrayD[index];
                }
            }
        }

        document.getElementById(`${table}_Price`).focus();

        setFormTableUpdate({
            ...formTableUpdate,
            id: id,
            table: tablea,
            price: price,
            time: time,
            chk: e.target.name,
            arrayM: arrayM,
            arrayD: arrayD,
        });
    };

    const buildHtml = (data, table, optDisplays, inputSelect) => {
        let html = [];
        let n = 0;
        let key = 0;

        for (let i in data) {
            let label = "";
            if (data[i].number !== undefined) {
                label = data[i].number;
            } else {
                label = data[i].fr;
            }

            if (
                table === "vacuum" ||
                table === "product_standard" ||
                table === "product_ecological"
            ) {
                if (i === "0") {
                    label = "Moins de 600 pieds carrés";
                }
                if (i === "1") {
                    label = "Entre 601 et 750 pieds carrés";
                }
                if (i === "2") {
                    label = "Entre 751 et 1000 pieds carrés";
                }
                if (i === "3") {
                    label = "Entre 1001 et 1250 pieds carrés";
                }
                if (i === "4") {
                    label = "Entre 1251 et 1500 pieds carrés";
                }
                if (i === "5") {
                    label = "Entre 1501 et 1750 pieds carrés";
                }
                if (i === "6") {
                    label = "Entre 1751 et 2000 pieds carrés";
                }
                if (i === "7") {
                    label = "Plus que 2001 pieds carrés";
                }
                if (i === "8") {
                    label = "STUDIO";
                }
            }

            let base_price = 0;

            if (
                formTableUpdate.table === table &&
                data[i].id === parseInt(formTableUpdate.id)
            ) {
                base_price = formTableUpdate.price;
            } else {
                if (data[i].price !== undefined) {
                    base_price = data[i].price;
                } else {
                    base_price = data[i].base_price;
                }
            }

            let time = "00:00:00";
            if (data[i].duration !== undefined) {
                if (
                    formTableUpdate.table === table &&
                    data[i].id === parseInt(formTableUpdate.id)
                ) {
                    time = formTableUpdate.time;
                } else {
                    time = data[i].duration;
                }
            }

            const { arrayD } = formTableUpdate;
            if (arrayD[`${table}_${data[i].id}_Check`] !== undefined) {
                base_price = arrayD[`${table}_${data[i].id}_Check`].price;
                time = arrayD[`${table}_${data[i].id}_Check`].time;
            }

            html.push(
                <InputLabel
                    key={key}
                    id={`${table}_${data[i].id}`}
                    label={label}
                    arrayM={formTableUpdate.arrayM}
                    defaultValuep={base_price}
                    defaultValuet={time}
                    handleChange={handleChange}
                    handleDblClick={handleDblClick}
                    inputSelect={inputSelect}
                    varTime={varTime}
                />
            );

            if (optDisplays !== displayOptions) {
                if (n === 1) {
                    break;
                }
                n++;
            }
            key++;
        }

        if (optDisplays === displayOptions) {
            html.push(
                <Box textAlign="center" my={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => sendInfoToTheApi(e)}
                    >
                        Update
                    </Button>
                </Box>
            );
        }

        return html;
    };

    const sendInfoToTheApi = (e) => {
        e.preventDefault();

        if (formTableUpdate.table === "housing_category") {
            const { arrayD } = formTableUpdate;

            for (let index in arrayD) {
                dispatch(updateBasePrice(access_token, arrayD[index], tariff));
            }
        } else if (
            formTableUpdate.table === "vacuum" ||
            formTableUpdate.table === "product_standard" ||
            formTableUpdate.table === "product_ecological"
        ) {
            const { arrayD } = formTableUpdate;

            for (let index in arrayD) {
                dispatch(
                    updateProPersonalization(
                        access_token,
                        arrayD[index],
                        tariff
                    )
                );
            }
        } else {
            const { arrayD } = formTableUpdate;
            for (let index in arrayD) {
                dispatch(
                    updateTariffPriceAndTime(
                        access_token,
                        arrayD[index],
                        tariff
                    )
                );
            }
        }

        setFormTableUpdate({
            ...formTableUpdate,
            arrayM: [],
        });
    };

    const displayOptionChanges = (e, title) => {
        e.preventDefault();
        setDisplayOptions(title);
    };

    return (
        <Box className={classes.tariffArea}>
            <Grid container>
                <Grid item sm={12}>
                    <Box display="flex" flexDirection="row">
                        <Typography variant="h5" noWrap>
                            {"GRILLES TARIFAIRES"}
                        </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" mt={2}>
                        <Typography
                            variant="h5"
                            noWrap
                            style={{ color: "#000" }}
                        >
                            SPÉCIFICITÉS DU LOGEMENT
                        </Typography>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item md={4}>
                            <BlueBox
                                title="TYPE DU LOGEMENT"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                <Box mt={4} mb={6}>
                                    {tariff !== null &&
                                        buildHtml(
                                            tariff["housing_category"],
                                            "housing_category",
                                            "TYPE DU LOGEMENT",
                                            false
                                        )}
                                </Box>
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="SUPERFICIE"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["housing_size"],
                                        "housing_size",
                                        "SUPERFICIE",
                                        true
                                    )}
                            </BlueBox>
                            <BlueBox
                                title="CAC"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["bedrooms"],
                                        "bedrooms",
                                        "CAC",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="ETAGE"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["floors"],
                                        "floors",
                                        "ETAGE",
                                        true
                                    )}
                            </BlueBox>
                            <BlueBox
                                title="SDB"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["bathrooms"],
                                        "bathrooms",
                                        "SDB",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="SALLE D'EAU"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["washbasins"],
                                        "washbasins",
                                        "SALLE D'EAU",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>
                        <Grid item md={4}>
                            <BlueBox
                                title="CUISINE"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["kitchens"],
                                        "kitchens",
                                        "CUISINE",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="SALON"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["salons"],
                                        "salons",
                                        "SALON",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>
                        <Grid item md={4}>
                            <BlueBox
                                title="SALLE À MANGER"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["dining_rooms"],
                                        "dining_rooms",
                                        "SALLE À MANGER",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={12}>
                            <Box display="flex" flexDirection="row" mt={4}>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    style={{ color: "#000" }}
                                >
                                    OPTION(S)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="LIT"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["beds"],
                                        "beds",
                                        "LIT",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>
                        <Grid item md={4}>
                            <BlueBox
                                title="BAIN ET DOUCHE"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["showers"],
                                        "showers",
                                        "BAIN ET DOUCHE",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="FRIGO"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["fridges"],
                                        "fridges",
                                        "FRIGO",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="FOUR(S)"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["ovens"],
                                        "ovens",
                                        "FOUR(S)",
                                        true
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={12}>
                            <Box display="flex" flexDirection="row" mt={4}>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    style={{ color: "#000" }}
                                >
                                    EXTRA (S)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="VACUUM"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["vacuum"],
                                        "vacuum",
                                        "VACUUM",
                                        false
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="PRODUCT STANDARD"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["product_standard"],
                                        "product_standard",
                                        "PRODUCT STANDARD",
                                        false
                                    )}
                            </BlueBox>
                        </Grid>

                        <Grid item md={4}>
                            <BlueBox
                                title="PRODUIT ÉCOLOGIQUE"
                                displayOptionChanges={displayOptionChanges}
                                displayOptions={displayOptions}
                            >
                                {tariff !== null &&
                                    buildHtml(
                                        tariff["product_ecological"],
                                        "product_ecological",
                                        "PRODUIT ÉCOLOGIQUE",
                                        false
                                    )}
                            </BlueBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Tariff;
