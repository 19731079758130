import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import DashboardLayout  from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import PaperLayout from '../share/layout/PaperLayout';
import { useDispatch, useSelector } from 'react-redux';
import ServicesListTable from './Tables/ServicesListTable';
import { Container } from '@material-ui/core';
import { deleteService, getAllServices } from '../../store/actions/serviceAction';
import Service from './Service/Service';

const useStyle = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 'bold',
        marginRight: '20px',
        fontSize: "1.8rem"
    },
    btnDownloadReport: {
        marginTop: '-20px',
        marginRight: '30px',
        '& span': {
            textTransform: 'none'
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: 'transparent'
        },
        '& .MuiButton-contained': {
            color: theme.palette.primary.main,
            boxShadow: '0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)',
            border: `1px solid ` + theme.palette.primary.main
        }
    },
    addUser: {
        color: theme.palette.primary.main,
        fontSize: "3.5rem"
    },
    gridSize: {
        height: '80%',
        width: '100%'
    }
}));

const ServicesList = () => {
    const classes = useStyle();
    
    const { auth:{access_token}, services } = useSelector(
            state => ({
            auth: state.auth,
            services: state.services.services
        })
    );

    const dispatch = useDispatch();

    const [searchdescriptionfr, setSearchDescriptionfr] = useState('');
    const [searchdescriptionen, setSearchDescriptionen] = useState('');
    const [rows, setRows] = useState([]);
    const [displayRows, setDisplayRows] = useState([]);
    const [data, setData] = useState({id: null, upName: ''})
    //const [flagAdminRemoved, setFlagAdminRemoved] = useState(false);

    const headCells = [
        { id: 'descriptionfr', numeric: false, disablePadding: true, label: 'Description-Fr', searchOption: true },
        { id: 'descriptionen', numeric: false, disablePadding: true, label: 'Description-En', searchOption: true },
    ];

    useEffect(() => {

        if(services === null){
            dispatch( getAllServices(access_token) )
        }else{

            let obj = [];
            let adminObj = {};

            for(let index in services){
                adminObj = {
                    id: services[index].id,
                    descriptionfr: services[index].fr,
                    descriptionen: services[index].en,
                };
                obj.push(adminObj);
            }
            setRows(obj);
            setDisplayRows(obj);

        }
        
    },[dispatch, access_token, services])


    const filter = () => {
        let filterRows = rows.filter((row) => row.descriptionfr.toString().toLowerCase().indexOf(searchdescriptionfr.toString().toLowerCase()) > -1);
        filterRows = rows.filter((row) => row.descriptionen.toString().toLowerCase().indexOf(searchdescriptionen.toString().toLowerCase()) > -1);
        setDisplayRows(filterRows);
    }

    const removeService = (id) => {
        dispatch(deleteService(access_token, id))
    }

    const upService = (e, id, name) => {
        e.preventDefault(e)
        setData({id, upName: name})
        
    }

    const onChangeSearch = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'searchdescriptionfr':
                setSearchDescriptionfr(value);
                break;
            case 'searchdescriptionen':
                setSearchDescriptionen(value);
                break;
            default:
                break;
        }
    }

    return (
        <DashboardLayout>
            <Container maxWidth="md">
                <PaperLayout>
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        {(data.id !== null ? "Mettre à jour le service" : "Créer un service" )} 
                    </Typography>
                    <Box mt={3} mb={3}>
                        <Service data={data} />
                    </Box>

                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        List des services
                    </Typography>
                    <Divider/>
                        <Box className={classes.gridSize}>
                            <ServicesListTable
                                rows={displayRows}
                                headCells={headCells}
                                onChangeSearch={onChangeSearch}
                                filter={filter}
                                removeService={removeService}
                                upService={upService}
                            />
                        </Box>

                </PaperLayout>
            </Container>
        </DashboardLayout>
    );
}

export default ServicesList;