import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getACandidateAction } from "../../../store/actions/candidateAction"
import config from '../../../config.json'

export const LogicCandidate = (id, statusId) => {

  const [dataForm, setDataForm] = useState({
    statusIdCan: statusId,
    value: 0,
    change: false,
    referencesBlocked: false,
    validateCandidate: false,
    validateMailVisio: false,
    btnBlocked: false,
    textPopup: '',
    openModal: false,
    popupRunsFunction: '',
    servicesList: '',
    how_know_us_list: {},
    initialState: {
      referContactStatus1: 0,
      referContactStatus2: 0,
      emailInterviewStatus: null,
      timeInterviewMail: null,
      timeInterview: null
    },
    errorMsg: '',
    errorType: 'success'
  })

  const dispatch = useDispatch()
  const token = useSelector(state => { return state.auth.access_token })
  const candidate = useSelector(state => { return state.candidate.candidate })

  useEffect(() => {
    let ignore = false

    const startServicesAndHnu = async () => {
      const obj = [];
      const res = await fetch(`${config.API_SERVER}/api/howknowus/fr`, { method: 'GET' })

      if (res.status === 200) {
        const r = await res.json();
        const data = r.data
        for (const index in data) {

          obj.push({ id: index, name: data[index] })
        }

      }

      let ser = []
      const resServices = await fetch(`${config.API_SERVER}/api/services/all`, { method: 'GET' })

      if (resServices.status === 200) {
        const data = await resServices.json();
        for (let index in data) {
          if (index !== 'id') {
            ser[data[index].id] = data[index].fr
          }
        }
      }

      setDataForm((dataForm) => {
        return {
          ...dataForm,
          how_know_us_list: obj,
          servicesList: ser
        }
      })
    }

    const getCandidate = () => {
      if (candidate === null) {
        startServicesAndHnu()
          .then(() => {
            dispatch(getACandidateAction(id, token))
          })
      }
    }

    if (!ignore) {
      getCandidate()
    }

    return () => {
      ignore = true
    }

  }, [token, dispatch, candidate, id])

  return {
    candidate,
    dataForm,
    setDataForm
  }
}

