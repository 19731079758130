import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import ActiveUsers from './ActiveUsersPro';
import { withStyles} from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core'
import { connect } from "react-redux";
import { compose } from 'redux';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { sendMessage, getAllProChat, GetProInfoChat, GetAllMsgChat, updateMsg, addMsg, renitialiserCount,addImage } from '../../../../store/actions/chatAction';

const styles = (theme) => ({
 
  chat_window: {
    width: ' 900px',
    height: 'calc(100vh - 120px)',
    borderRadius: '10px',
    top: '50%',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.15)',
    backgroundColor: ' #f8f8f8',
    overflow: 'hidden',
    float: 'right',
  },
  body: {
    backgroundColor: '#edeff2',
    fontFamily: "Calibri, Roboto, sansSerif", 
  },

  Active_Users: {
    position: 'relative',
    float: 'left',
  }, 
  chatU: {
    position: 'relative',
    float: 'left',
    width: '300px',
  },
  Chat_li: {
    color: '#2bcc8c',
    fontSize: '1.6rem',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
   
  },

  inputUsers: {
    border: 0,
    outline: 0,
    paddingLeft: '10px',
    fontSize: '1.4rem',
    "&:focus": { outline: "none", }
  },

  avatar: {
    display: 'flex !important',
    float: 'left',
    position: 'relative',
    "&:focus": { outline: "none", }
  },

  Users: {
    border: 0,
    outline: 0,
    display: 'flex',
    width:'250px',
    padding: '10px',
    
    "& h6": {
    position: 'relative',
    float: 'right',
    paddingTop: '10px',
    paddingLeft: '7px',
    fontWeight: 500,
    fontSize: '1.4rem',
    fontFamily: "Avenir_Regular",
    },
    "&:focus": {
      outline: "none",
      backgroundColor: "#28CC8B",
      borderRadius:"5px"
      
    }
  },
  
  wrapper_chat: {
    display: 'flex',
    marginLeft: '15%',
    position: 'absolute',
    backgroundColor: 'white',
    height: 'auto',
    width: '1000px',
  },
  
  messages: {
    position: 'relative',
    listStyle: 'none',
    padding: '20px, 10px, 0, 10px',
    margin: 0,
    height:  'calc(100vh - 250px)',
    overflow: 'scroll',
    '& li': {
      clear: 'both',
      overflow: 'hidden',
      margin: '20px 10px ',
      transition: 'all, 0.5s, linear',
      opacity: 1,
    }
  },
 
 left: {
    '& #avatar__MessageBox': {
     backgroundcolor: '#b3aeae',
     float: 'left',
     width: '60px',
     height: '60px',
     borderRadius: '50%',
     display: 'flex !important'
    },
   '& #text_wrapper_MessageBox': {
      backgroundColor: '#62bbde',
      marginLeft: '20px',
      color: 'black',
      fontWeight: 'inherit',
      float: 'left',
      height: ' auto',
      display: 'inline-block',
      padding: '10px',
      borderRadius: '6px',
      width: 'fit-content',
      minWidth: '100px',
     position: 'relative',
     '& #image': {
      maxWidth: 300,
      maxHeight:300
       },
      '&::after': {
          right: '100%',
          borderRightColor: '#62bbde'
        },
    }, 
  },

      right: {
        '& #avatar__MessageBox': {
          backgroundColor: ' #eae8e5',
          float: 'right',
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          display: 'flex !important'
        },
        '& #text_wrapper_MessageBox': {
          color: ' #45829b',
          backgroundColor: '#c7eafc',
          marginRight: '10px',
          float: 'right',
          height: ' auto',
          display: 'inline-block',
          marginTop:'10px',
          padding: '10px',
          borderRadius: '6px',
          width: 'fit-content',
          minWidth: '100px',
          position: 'relative',
          '& #image': {
            maxWidth: 300,
            maxHeight:300
             },
          '&::before': {
            left: '100%',
            borderLeftColor: '#c7eafc',
            height: 'auto',
            display: 'inline-block'
          }
        }
    },

        text_wrapper__MessageBox: {
          display: 'inline-block',
          padding: '20px',
          borderRadius: '6px',
          width: 'fit-content',
          minWidth: '100px',
          position: 'relative',
          '&::after': {
            top: '18px',
            border: 'solid, transparent',
            content: " ",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderWidth: '13px',
            marginTop: '0px',
          },
          '&::before': {
            top: '18px',
            border: 'solid, transparent',
            content: " ",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderWidth: '15px',
            marginTop: '-2px'
          }
    },
   
    text_MessageBox: {
      fontSize: '18px',
      fontWeight: '300'
    },
 
    send_message: {
       width: 'calc(100% - 630px);',
       height: '50px',
       display: 'inline-block',
       borderRadius: '50px',
       backgroundColor: '#2880fb',
       border: '2px solid #2880fb',
       color: '#fff',
       cursor: 'pointer',
       transition: 'all 0.2s linear',
       textAlign: 'center',
       float: 'right',
       '&:hover': {
        color: '#2880fb',
        backgroundColor: '#fff'
      }      
   },
   
   textSend: {
    fontSize: '16px',
    fontWeight: '300',
    display: 'inline-block',
    lineHeight: '48px',
  },
   
   bottom_wrapper :{
      position: 'relative',
      width: '100%',
      backgroundColor: '#fff',
      padding: ' 35px 2px',
      '& #message_input_wrapper': {
        display: 'inline-block',
        height: 'auto',
        minHeight: '50px',
        borderRadius: '25px',
        border: ' 1px solid #bcbdc0',
        width: 'calc(100% - 190px)',
        marginRight:'10px',
        position: 'relative',
        padding: ' 0 20px',
        '& #msg_input': {
          border: 'none',
          height: '100%',
          boxSizing: 'border-box',
          width: 'calc(100% - 40px)',
          position: ' absolute',
          outlineWidth: 0,
          color: 'gray',
          fontSize:'1.2rem'
        }, 
      },
      '& #Icon': {
        float: 'left',
        border: 0,
        fontFamily:'none',
        outline: "none",
        backgroundColor:'#fff',
        marginTop: '10px',
        fontSize: '30px',
        "&:focus": {
          outline: "none",   
        }
      }  
  },
  Pro: {
    textAlign: "center",
    fontSize: "18px",
    Color: '#2880fb',
    margin:'20px' 
  },

  IconMessage:{
    marginRight: ' 10px',
  },

  btnPhoto: {
    backgroundColor: '#fff',
    "&:focus": {
      outline: "none",   
    }
  }
})

class SendButton extends Component{
  render() {
    const { classes } = this.props;
    return (
       
        <div className={classes.send_message} onClick={this.props.handleClick}>
          <div className={classes.textSend}>send</div>
      </div>
    );
  }
}

class MessageTextBoxContainer extends Component{
  render() {
    const { classes } = this.props;
    return(
      <div id="message_input_wrapper">
            <input id="msg_input" className={classes.message_input_textBox} placeholder="Type here..." value={this.props.message}
                onChange={this.props.onChange}
                onKeyPress={this.props._handleKeyPress} />
      </div>
    );
  }
}

class UserMessageBox extends Component{
  // constructor(props) {
  //   super(props);
  // }
  render() {
    const classes = this.props.classes
    let classPosition = classes.right
    let first_name = ""
    let LastName =""
    if (this.props.appearance === "left") {
      first_name = this.props.proInfo.firstName;
      LastName = this.props.proInfo.lastName;
      classPosition = classes.left
    }
    else
    {
      first_name = this.props.userAdmin.first_name;
      LastName = this.props.userAdmin.last_name;
    }

    let error = false
    if (this.props.message.includes("://")) {
      error = true
    }
    
    return (
      <li className={`${classPosition}`}> 
      <Avatar id='avatar__MessageBox' > {String(first_name).charAt(0).toUpperCase()}{String(LastName).charAt(0).toUpperCase()}</Avatar>
          <div id ='text_wrapper_MessageBox'>
            <img id='image' src={this.props.message} alt= "" onError={e => e.target.style.display = 'none'} />
            <Box hidden={error} className={classes.text_MessageBox}>
              {this.props.message}
            </Box>
          </div>
      </li>
    );
  }
}

class MessagesContainer extends Component{
  constructor(props) {
    super(props);
    this.state = {"newMessage":null}
    this.createBotMessages = this.createBotMessages.bind(this);
    this.createUserMessages = this.createUserMessages.bind(this);
  }

  scrollToBottom = () => {
    var el = this.refs.scroll;
    el.scrollTop = el.scrollHeight;
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
    if (this.props.proInfo === null) {
      this.props.GetProInfoChat({ token: this.props.token, email: this.props.email })
    } else {
      if (this.props.email !== this.props.proInfo.email && this.props.chatRoomUid !== this.props.proInfo.chatRoomUid) {
        this.props.GetProInfoChat({ token: this.props.token, email: this.props.email })
        
      }
    }
  }

  createUserMessages() {
    console.log(this.props.messagePro,"updateMsg3" )
    if (this.props.messagePro !== null)
    return this.props.messagePro.map((message, index) =>
      <UserMessageBox
        proInfo={this.props.proInfo}
        userAdmin={this.props.userAdmin}
        classes={this.props.classes}
        key={index}
        message={message.message}
        appearance={message.user === this.props.proInfo.email ? "left" : "right"} />
    );
  } 

  createBotMessages() {
    return this.props.messages.map((message, index) =>
      <UserMessageBox
        userAdmin={this.props.userAdmin}
        proInfo={this.props.proInfo}
        classes={this.props.classes}
        key={index}
        message={message["message"]}
        appearance={message["isbotmessage"] ? "left" : "right"} />
    );
  }

  render(){
    const { classes } = this.props;
    return(
      <ul className={classes.messages} ref="scroll">
        {this.createBotMessages()}
        {this.createUserMessages()}
        
      </ul>
    );
  }
}



class ChatApp extends Component {
  constructor(props){
    super(props);
    this.state = {
      "preview": null,
      "messages": [],
      "current_message": "",
      "email": null,
      "chatRoomUid": null,
      "message": null,
      "count": 0,
      "chatRoomUidNV": [],
      'formData':null
    }
    this.handleClick = this.handleClick.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addMessageBox = this.addMessageBox.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.setImages = this.setImages.bind(this); 
  }

  setImages(e)  {
      let img = e.target.files[0]
      this.setState({
        ...this.state,
        preview: img,
       
      });  
  }
  
  getUserData(email, chatRoomUid) {
    console.log('All chat2', email)
    if (this.state.chatRoomUidNV.includes(chatRoomUid)) {
      this.props.usersChat.pro.map(elmt => {
        if (elmt.chatRoomUid === chatRoomUid)
        {
          elmt.count = 0
        }
      })
      this.props.renitialiserCount({ usersChat: this.props.usersChat })
    }
      this.setState({
        ...this.state,
        email: email,
        chatRoomUid: chatRoomUid
      });    
  }

  componentDidUpdate() {
    // var today = new Date();
    // var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // var dateTime = date + ' ' + time;
    if (this.state.preview !== null){
      this.props.addImage(this.state.preview)
    }
    //********** active pusher **** */
    // if (this.props.usersChat.pro !== null) {
    //   let channels = []
    //   let channel = null
    //   Pusher.logToConsole = true;
    //   var pusher = new Pusher('a14aa3c605089a73c817', {
    //     cluster: 'us2'
    //   });
    //   this.props.usersChat.pro.map(elt => {
    //     channels = [...channels, pusher.subscribe(`chat_${elt.chatRoomUid}`)]
    //   })
    //   if (this.props.proInfo === null)
    //     return null
    //   else {
    //     for (channel of channels) {
    //       channel.bind('messageEvent', data => {
          
    //         let newData = {
    //           user: this.props.proInfo.email,
    //           message: data.message,
    //           created_at: dateTime
    //         }
    //         if (data.message !== undefined && data.message !== this.state.message && data.chatRoomUid === this.props.proInfo.chatRoomUid) {
    //           this.props.updateMsg({ message: newData, Chat: this.props.messagePro })
    //           this.setState({
               
    //             message: data.message
    //           })
    //         }
    //         else if (data.chatRoomUid !== this.props.proInfo.chatRoomUid) {
    //           this.setState({
    //             ...this.state,
    //             chatRoomUidNV: [...this.state.chatRoomUidNV, data.chatRoomUid]
    //           })
    //           this.props.usersChat.pro.map(elmt => {
    //             if (elmt.chatRoomUid === data.chatRoomUid) {
    //               elmt.count = elmt.count + 1
    //             }
    //           })
              this.props.addMsg({ usersChat: this.props.usersChat })
            }
  //         })
  //       }
  //     } 
  //   }
  // }

  componentDidMount() {
    this.props.getAllProChat(this.props.token)
  }

  addMessageBox(enter=true){
    let current_message = this.state.current_message;
    if ((current_message && enter) || (this.props.img && enter) ) {
      this.props.sendMessage({ token: this.props.token, email: this.props.proInfo.email, message: current_message, selectedFile: this.props.img}) 
      current_message = ""
    }  
    this.setState({
      current_message: current_message,
      preview: null
    });
  }

  handleClick(){
    this.addMessageBox();
  }

  onChange(e) {
   let current_message= e.target.value 
    this.setState({
      current_message: current_message
    });  
  }

    _handleKeyPress(e) {
    let enter_pressed = false;
    if(e.key === "Enter"){
      enter_pressed = true;
    }
    this.addMessageBox(enter_pressed)
  }
 
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper_chat}>
        <div className={classes.chatU}>
          <ActiveUsers
            usersChat={this.props.usersChat}
            getUserData={this.getUserData}
            classes={classes}
          />
        </div>
        <div className={classes.chat_window}>
          {
            <MessagesContainer
              classes={classes}
              messages={this.state.messages}
              email={this.state.email}
              GetProInfoChat={this.props.GetProInfoChat}
              updateMsg={this.props.updateMsg}
              proInfo={this.props.proInfo}
              userAdmin={this.props.userAdmin}
              chatRoomUid={this.state.chatRoomUid}
              token={this.props.token}
              messagePro={this.props.messagePro}
              messageUpdate={this.props.messageUpdate}
              >
            </MessagesContainer>
          }
          <div className={classes.bottom_wrapper}>
              <Box fontSize={"14px"} hidden={this.state.preview === null}> {this.props.img.name}</Box>
              <Box className={classes.areaPhoto}>
                      <Box className={classes.btnPhoto}>
                          <input id="sampleFile" type="file" name="files" hidden onChange={this.setImages} />
                          <Button  htmlFor="sampleFile" component="label"  type="submit" id='Icon'>
                                <ImageOutlinedIcon  />
                          </Button>          
                      </Box>
              </Box>
              <MessageTextBoxContainer 
                  classes={classes}
                  _handleKeyPress={this._handleKeyPress} 
                  onChange={this.onChange} 
                  message={this.state.current_message}>
                
              </MessageTextBoxContainer>
              <SendButton
                handleClick={this.handleClick}
                classes={classes}
              >

              </SendButton>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {//inscrire les action
    sendMessage: (reg) => dispatch(sendMessage(reg)),
    getAllProChat: (reg) => dispatch(getAllProChat(reg)),
    GetAllMsgChat: (reg) => dispatch(GetAllMsgChat(reg)),
    GetProInfoChat: (reg) => dispatch(GetProInfoChat(reg)),
    updateMsg: (reg) => dispatch(updateMsg(reg)),
    renitialiserCount: (reg) => dispatch(renitialiserCount(reg)),
    addMsg: (reg) => dispatch(addMsg(reg)),
    addImage: (reg) => dispatch(addImage(reg)),
  }
}

const mapStateToProps = (state) => {
  
  return {// prendre la data
    userAdmin : state.auth.userAdmin,
    token: state.auth.access_token,
    usersChat: state.chat.usersChat,
    proInfo: state.chat.proInfo,
    messagePro: state.chat.messagePro,
    messageEnv: state.chat.messageEnv,
    messageUpdate:state.chat.messageUpdate,
    img: state.chat.img, 
  }
}

export default compose
  (
  connect(mapStateToProps, mapDispatchToProps),
    
    withStyles(styles)
  )(ChatApp);
