import React from "react";
import DashboardLayout from "../../../../layout/Layout";
import ShowReservation from "./ShowReservation";

const EndingRequest = () => {
    return (
        <DashboardLayout>
            <ShowReservation />
        </DashboardLayout>
    );
};

export default EndingRequest;
//http://localhost:3000/contact/client/finish/1
//http://localhost:3000/reservation/request/1                  /4                   /false/                4
//                                          id,                 reservationStatusId, updateHouseHoldList, requestStatus
