import { Box} from '@material-ui/core'
import React , { useState } from 'react'
import MapPro from '../../../request/Components/MapPro';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useEffect } from 'react';
const Mapp = ({postalCode}) => {
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)
console.log(postalCode,"address2") 
    
    
    const handleSelectAddress = async postalCode => {
        console.log(postalCode,"address3")
        geocodeByAddress(postalCode)
            .then(results => {
                
                 setLat(results[0].geometry.location.lat())
                 setLng(results[0].geometry.location.lng())
                
                 console.log ("lat", lat , lng)   
            })
            .catch(error => console.error('Error', error));
          
    };
    useEffect(() => {
        console.log("addr", lat)
        handleSelectAddress(postalCode)
        },[handleSelectAddress, Mapp])
        console.log("addr2", lng)
    if (lat === 0 || lng === 0)
    return false
    return (
        <Box >
        <MapPro profs = {[]}  address = {{lat : lat , lng : lng}} pin = {true}/>
        </Box>
    )
}

export default Mapp
