import React from 'react'
import { Box, Typography, makeStyles, Checkbox, FormControlLabel, Input } from '@material-ui/core'
import { Grid } from '@material-ui/core';
import SelectInput from '../../share/inputs/SelectInput';
import { Button } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    testerArea: {
        '& h6': {
            fontSize: '1rem'
        },
        '& .MuiInput-underline:before':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:after':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:focus':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before':{
            border: '0px solid #999898',
        },
        '& .MuiInputBase-input:hover':{
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputBase-input:focus':{
            border: `1px solid ${theme.palette.primary.main}`,
        }, 
        '& input[type=number]::-webkit-inner-spin-button':{
            display: 'none',
            webkitAppearance: 'none',
        },
        '& input[type=number]::-webkit-outer-spin-button':{
            display: 'none',
            webkitAppearance: 'none'
        },
        '& [type=number]': {
            '-moz-appearance': 'textfield'
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& .MuiInputBase-input':{
            padding: theme.spacing(1),
            border: '1px solid #999898',
            borderRadius: '4px'
        }

    },
    total: {
        border: `2px solid ${theme.palette.primary.main}`,
        padding: '5px',
        marginTop: '10px',
        textAlign: 'center',
        fontWeight: 'bold',
        '& h6': {
            marginTop: '-15px',
            padding: '0 5px 0 5px',
            backgroundColor: '#fff',
            width: 'max-content',
            color: '#2880fb !important',
            fontSize: '12px'
        }
    }
}))

const Tester = ({ dataTH, dataHS, dataTester, estimation, handleChangeTester, keyPress, onClick }) => {
    const classes = useStyle()

    const { houseCategoryId, houseSizeId, floors, bedroom, bathroom, washbasin, kitchens, salon, diningRoom, optionBeds, optionBathtub, optionFridge, optionFour, product, ecoProduct } = dataTester;

    let totalPrice = '0.00'
    let totalDuration = '00:00:00'
    if (estimation !== null) {
        totalPrice = estimation.totalPrice
        totalDuration = estimation.totalDuration
    }

    return (
        <Box className={classes.testerArea}>
            <Typography variant="h5" noWrap>{'TESTEUR'}</Typography>

            <Grid container spacing={3}>
                <Grid item md={3}>
                    <Box>
                        <Box>
                            <Typography variant="h6">TYPE DE LOGEMENT</Typography>
                        </Box>
                        <Box>

                            <SelectInput
                                id="houseCategoryId"
                                name="houseCategoryId"
                                data={dataTH}
                                colorFont={{ color: '#000' }}
                                onChange={(e) => handleChangeTester(e)}
                                defaultValue={houseCategoryId}
                                disabled="false"
                            />

                        </Box>
                    </Box>
                </Grid>
                <Grid item md={5}>
                    <Box>
                        <Box>
                            <Typography variant="h6">SUPERFICIE</Typography>
                        </Box>
                        <Box>
                            <SelectInput
                                id="houseSizeId"
                                name="houseSizeId"
                                data={dataHS}
                                colorFont={{ color: '#000' }}
                                onChange={(e) => handleChangeTester(e)}
                                defaultValue={houseSizeId}
                                disabled="false"
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">ETAGE</Typography>
                        </Box>
                        <Box>
{/*                             
                            <TextField
                                id="floors"
                                name="floors"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={parseInt(floors)}
                                autoComplete='off'
                            /> */}

                                <Input
                                    id="floors"
                                    name="floors"
                                    placeholder=""
                                    label=""
                                    //variant="outlined"
                                    size="small"
                                    type="number"
                                    onChange={e => handleChangeTester(e)}
                                    onKeyDown={e => keyPress(e)}
                                    value={parseInt(floors)}
                                 />

                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">CAC</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="bedroom"
                                name="bedroom"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={bedroom}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">S-BAIN</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="bathroom"
                                name="bathroom"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={bathroom}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">SALLE D'EAU</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="washbasin"
                                name="washbasin"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={washbasin}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">CUISINE</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="kitchens"
                                name="kitchens"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={kitchens}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">SALON</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="salon"
                                name="salon"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={salon}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">SALLE MANGER</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="diningRoom"
                                name="diningRoom"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={diningRoom}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box display="flex" flexDirection="row" mt={4} >
                        <Typography variant="h5" noWrap style={{ color: "#000" }}>OPTION(S)</Typography>
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">LITS</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="optionBeds"
                                name="optionBeds"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={optionBeds}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">BAIN ET DOUCHE</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="optionBathtub"
                                name="optionBathtub"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={optionBathtub}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">FRIGO</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="optionFridge"
                                name="optionFridge"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={optionFridge}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box>
                        <Box>
                            <Typography variant="h6">FOUR</Typography>
                        </Box>
                        <Box>
                            <Input
                                id="optionFour"
                                name="optionFour"
                                placeholder=""
                                label=""
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={e => handleChangeTester(e)}
                                onKeyDown={e => keyPress(e)}
                                value={optionFour}
                                autoComplete='off'
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={(e) => handleChangeTester(e)}
                                    name="product"
                                    color="primary" checked={product}
                                />
                            }
                            label="Produits"
                        />
                    </Box>
                </Grid>

                <Grid item md={2}>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={(e) => handleChangeTester(e)}
                                    name="ecoProduct"
                                    color="primary" 
                                    checked={ecoProduct}
                                />
                            }
                            label="Produits Eco"
                        />
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box className={classes.total}>
                        <Box>
                            <Typography variant="h6">TOTAL</Typography>
                        </Box>
                        <Box>{totalPrice}</Box>
                    </Box>
                </Grid>
                <Grid item md={2}>
                    <Box className={classes.total}>
                        <Box>
                            <Typography variant="h6">TIME</Typography>
                        </Box>
                        <Box>{totalDuration}</Box>
                    </Box>
                </Grid>

                <Grid item md={4}>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={e => onClick(e) }>METTRE À JOUR</Button>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}

export default Tester
