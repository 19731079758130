import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import DashboardLayout  from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import PaperLayout from '../share/layout/PaperLayout';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
//import TaxesListTable from './Tables/TaxesListTable';
import { Container } from '@material-ui/core';
import { getListOfTaxes, removeTaxOfList } from '../../store/actions/taxAction';
import Tax from './tax/Tax';
import TaxesListTable from './Tables/TaxesListTable';

const useStyle = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 'bold',
        marginRight: '20px',
        fontSize: "1.8rem"
    },
    btnDownloadReport: {
        marginTop: '-20px',
        marginRight: '30px',
        '& span': {
            textTransform: 'none'
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: 'transparent'
        },
        '& .MuiButton-contained': {
            color: theme.palette.primary.main,
            boxShadow: '0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)',
            border: `1px solid ` + theme.palette.primary.main
        }
    },
    addUser: {
        color: theme.palette.primary.main,
        fontSize: "3.5rem"
    },
    gridSize: {
        height: '80%',
        width: '100%'
    }
}));

const TaxesList = () => {
    const classes = useStyle();
    
    const { auth:{access_token}, taxes } = useSelector(
            state => ({
            auth: state.auth,
            taxes: state.tax.taxes
        })
    );

    const [formData, setFormData] = useState({
        id: '',
        country: '',
        province: '',
        tps: 0,
        tvq: 0
    })

    const dispatch = useDispatch();
    const history = useNavigate()
    const [searchcountry, setSearchCountry] = useState('');
    const [searchprovince, setSearchProvince] = useState('');
    const [rows, setRows] = useState([]);
    const [displayRows, setDisplayRows] = useState([]);
    //const [data, setData] = useState({id: null, upName: ''})
    //const [flagAdminRemoved, setFlagAdminRemoved] = useState(false);

    const headCells = [
        { id: 'country', numeric: false, disablePadding: true, label: 'Country', searchOption: true },
        { id: 'province', numeric: false, disablePadding: true, label: 'Province', searchOption: true },
        { id: 'tps', numeric: false, disablePadding: true, label: 'TPS', searchOption: false },
        { id: 'tvq', numeric: false, disablePadding: true, label: 'TVQ', searchOption: false },
    ];

    useEffect(() => {

        if(taxes === null){
            dispatch( getListOfTaxes(access_token) )
        }else{

            let obj = [];
            let adminObj = {};

            for(let index in taxes){
                adminObj = {
                    id: taxes[index].id,
                    country: taxes[index].country,
                    province: taxes[index].province,
                    tps: taxes[index].TPS,
                    tvq: taxes[index].TVQ
                };
                obj.push(adminObj);
            }
            console.log('Datos de taxes: ', obj)
            setRows(obj);
            setDisplayRows(obj);

        }
        
    },[dispatch, access_token, taxes])

    const removeTax = (id) => {
        console.log('Este es el id: ', id)
        dispatch(removeTaxOfList(access_token, id))
    }

    const updateTax = (e, id, country, province, tps, tvq) => {
        e.preventDefault()
        
        setFormData({
            id: id,
            country: country,
            province: province,
            tps: tps,
            tvq: tvq
        })
    }

    const filter = () => {
        let filterRows = rows.filter((row) => row.country.toString().toLowerCase().indexOf(searchcountry.toString().toLowerCase()) > -1);
        filterRows = filterRows.filter((row) => row.province.toString().toLowerCase().indexOf(searchprovince.toString().toLowerCase()) > -1);
        setDisplayRows(filterRows);
    }

    // const upService = (e, id, name) => {
    //     e.preventDefault(e)
    //     setData({id, upName: name})        
    // }

    const onChangeSearch = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'searchcountry':
                setSearchCountry(value);
                break;
            case 'searchprovince':
                setSearchProvince(value);
                break;
            default:
                break;
        }
    }
    
    return (
        <DashboardLayout>
            <Container maxWidth="md">
                <PaperLayout>
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        {/* {(data.id !== null ? "Mettre à jour le service" : "Créer un service" )}  */}
                    </Typography>
                    <Box mt={3} mb={3}>
                        <Tax 
                            data={rows} 
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </Box>

                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        List des taxes
                    </Typography>
                    <Divider/>
                        <Box className={classes.gridSize}>
                            <TaxesListTable rows={displayRows}
                                headCells={headCells}
                                onChangeSearch={onChangeSearch}
                                filter={filter}
                                removeTax={removeTax}
                                updateTax={updateTax}
                            />
                        </Box>

                </PaperLayout>
            </Container>
        </DashboardLayout>
    );
}

export default TaxesList;