import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useDispatch, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { updateCandidateAvatarRejectsAction, updateCandidatePhotoAction } from "../../../store/actions/candidateAction"

const useStyles = makeStyles((theme) => ({
        img: {
            '& img': {
                width: '16rem',
                height: '17.7rem'
            }
        }
    }
))

function Avatar() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const store = useStore()
    const candidate = store.getState().candidate.candidate;

    const [data, setData] = useState({
        avatarStatus: 0,
        imgChanged: true,
        files: [],
        preview: '',
        previewPhoto: false
    })

    const { avatarStatus, imgChanged, preview, previewPhoto } = data

    const avatarRejects = (e) => {
        e.preventDefault();    
        const token = store.getState().auth.access_token;
        const id = store.getState().candidate.candidate.id;
        setData({
            ...data,
            avatarStatus: 1
        })
        dispatch(updateCandidateAvatarRejectsAction(id, token, 'fr'));
    }

    const savePhoto = (e) => {
        const token = store.getState().auth.access_token;
        const id = store.getState().candidate.candidate.id;
        e.preventDefault();

        dispatch(updateCandidatePhotoAction(id, token, data.files, 'candidate'))
        .then(()=> {
            setData({
                ...data,
                imgChanged: true,
                'avatarStatus': 0
            })
        })
    }

    const setImages = (e) => {
        e.preventDefault();
        setData({
            ...data,
            'files': e.target.files,
            'avatarStatus': 2,
            'preview': URL.createObjectURL(e.target.files[0]),
            'imgChanged': false,
            'previewPhoto': true
        })

    }

    return (
        <Box display="flex" flexDirection="row" mt={2}>
            <Box mr={2} className={classes.img}>
                {(candidate.avatar_status === 0) ?
                    (<img src={previewPhoto ? preview : "/images/default_man.png"} alt="" />)
                    :
                    (<img src={candidate.avatar.url} alt="" />)
                }
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                {
                    avatarStatus === 0
                    ?
                        (
                            <Box ml={1} className={classes.noTextTransform}>
                            {
                                (!previewPhoto)
                                ?
                                    (<Button href="#" onClick={(e) => avatarRejects(e)} color="primary" style={{ color: 'red' }} className={classes.noTextTransform}>Refuser la photo</Button>)
                                :
                                    (<Box color="primary" style={{ color: '#28cc8b' }} className={classes.noTextTransform}>La photo a été modifiée</Box>)
                            }
                            </Box>
                            
                        )
                    :
                        (
                            <Box ml={1} className={classes.noTextTransform}>
                                {(imgChanged) ?
                                    (<Button htmlFor="sampleFile" component="label" variant="contained" type="submit">Choisir un fichier</Button>)
                                    :
                                    (<Button variant="contained" onClick={(e) => savePhoto(e)}>Sauvegarder le fichier</Button>)
                                }
                                <input id="sampleFile" type="file" name="files" style={{ display: 'none' }} multiple onChange={setImages} />
                            </Box>    
                        )
                }
            </Box>
        </Box>
    )
}

export default Avatar