import React, { useEffect } from 'react'
import { Box, Paper } from  '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DashboardLayout from '../../../layout/Layout'
import { makeStyles } from '@material-ui/styles'
import { getAContactPro } from '../../../../store/actions/contactAction'
import TabsForm from './components/TabsForm'

const useStyle = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: "flex",
        justifyContent: 'row',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '30px',
        borderRadius: '25px',
        fontSize: "2.1rem",
        '& label': {
            fontSize: '2rem',
            color: '#7f7f7f',
        },
        '&>div':{
            width: '100%'
        }
    }
}))

const Pro = () => {
    const classes = useStyle()

    let { id } = useParams()
    const dispatch = useDispatch()

    const { access_token, pro } = useSelector(
        state => ({
            access_token: state.auth.access_token,
            pro: state.contact.contact
        })
    )

    useEffect(() => {
        if(pro === null){
            dispatch(getAContactPro(access_token, id))
        }
    }, [dispatch, access_token, id, pro])

    return (
        <DashboardLayout>
            <Paper component="form" className={classes.root} >
            {
                (pro !== null) && (
                    <Box>
                        <TabsForm token={access_token} pro = {pro} />
                    </Box>
                )
            }                
            </Paper>
        </DashboardLayout>
    )
}

export default Pro