import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import DashboardLayout  from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import PaperLayout from '../share/layout/PaperLayout';
import { useNavigate } from 'react-router-dom';

//import { connect } from 'react-redux';
import { getServicesByCity } from '../../store/actions/serviceAction'; //linkTariffAndService
import ServiceTable from './Tables/ServiceTable';

import { useDispatch, useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    marginRight: '20px',
    fontSize: "1.8rem"
  },
  btnDownloadReport: {
    marginTop: '-20px',
    marginRight: '30px',
    '& span': {
      textTransform: 'none'
    },
    '& .MuiButton-containedSecondary': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-contained': {
      color: theme.palette.primary.main,
      boxShadow: '0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)',
      border: `1px solid ` + theme.palette.primary.main
    }
  },
  addUser: {
    color: theme.palette.primary.main,
    fontSize: "3.5rem"
  },
  gridSize: {
    height: '80%',
    width: '100%'
  }
}));

const RateList = () => {
  const classes = useStyle();

  const { auth:{access_token}, services } = useSelector(
    state => ({
      auth: state.auth,
      services: state.services.servicesByCity
    })
  );
  
  const dispatch = useDispatch();
  const history = useNavigate()
 // const { access_create, access_delete, access_update } = userAdmin.authority;
  //const permit = { access_create, access_delete, access_update };

  const [searchcountry, setSearchcountry] = useState('');
  const [searchcity, setSearchcity] = useState('');
  const [searchservice, setSearchservice] = useState('');
  const [rows, setRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);


  const headCells = [
    //{ id: '#', numeric: false, disablePadding: true, label: '', searchOption: false },
    { id: 'country', numeric: false, disablePadding: true, label: 'Country', searchOption: true },
    { id: 'city', numeric: false, disablePadding: true, label: 'City', searchOption: true },
    { id: 'service', numeric: false, disablePadding: true, label: 'Service', searchOption: true },
  ];


  //Ingresa solo la primera vez que carga la pagina
  useEffect(() => {

      const getServices = () => {

        const payArray = [];
        for(let index in services){
            if(index === 0){
                payArray.push(services[index])
            }else{

                let contain = false
                for(let i in payArray){
                    const obj = payArray[i]
                    if(JSON.stringify(obj) === JSON.stringify(services[index])){
                        contain = true
                        break;
                    }
                }

                if (!contain){
                    payArray.push(services[index])
                }

            }
        }

        setRows(payArray);
        setDisplayRows(payArray);
      }

      if (services === null) {
        dispatch(getServicesByCity(access_token));
      } else {
        getServices();
      }

    },[access_token, services, dispatch]
  )

  const filter = () => {
    let filterRows = rows.filter((row) => row.country.toString().toLowerCase().indexOf(searchcountry.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.city.toString().toLowerCase().indexOf(searchcity.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.service_name.toString().toLowerCase().indexOf(searchservice.toString().toLowerCase()) > -1);
    setDisplayRows(filterRows);
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchcountry':
        setSearchcountry(value);
        break;
      case 'searchcity':
        setSearchcity(value);
        break;
      case 'searchservice':
        setSearchservice(value);
        break;        
      default:
        break;
    }

  }
  
  // const linkTarifWithService = (e, id) => {
  //   e.preventDefault()
  //   dispatch(linkTariffAndService(access_token, id, '1wX1JP7W6Qfl-LVXahWm3tNqRVJXxRMPwxdTxKrWhowQ'))
  // }

  return (
    <DashboardLayout>
      {/* {(access_create === 1) && ( */}

{/* 
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
          <PaperLayout >
            <Box display="flex" flexDirection="row" >
              <Typography component="h1" variant="h6" noWrap className={classes.title}>
                CRÉER UN TARIF
              </Typography>
              <Link to="/tariff">
                <AddCircleOutlineIcon className={classes.addUser} />
              </Link>
            </Box>
          </PaperLayout>
        </Box>
 */}


      {/* )} */}


      <PaperLayout>        
        <Divider className={classes.divider} />
        <Box className={classes.gridSize}>
          <ServiceTable
            rows={displayRows}
            headCells={headCells}
            onChangeSearch={onChangeSearch}
            filter={filter}
            //linkTarifWithService={linkTarifWithService}
          />
        </Box>


      </PaperLayout>
    </DashboardLayout>
  );
}

export default RateList;