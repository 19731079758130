import { PROCESSING, CREATE_A_NEW_TAX, GET_LIST_OF_TAXES } from '../actions/typesAction'

const initialState = {
    taxes: null,
    tax: null
}

const serviceReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROCESSING:
            return initialState;
        case GET_LIST_OF_TAXES:
            return {
                ...state,
                taxes: payload,
            };
        case CREATE_A_NEW_TAX:
            return {
                ...state,
                tax: payload
            };
        default:
            return state;
            
    }
}

export default serviceReducer;