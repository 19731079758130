import { GET_TEMPLATES, PROCESSING, GET_EMAILS } from '../actions/typesAction';

const initialState = {
    templates: null,
    emails: null
}

export default function emailTemplateReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case PROCESSING:
            return initialState;
        case GET_TEMPLATES:
            return { 
                ...state, 
                templates: payload 
            };
        case GET_EMAILS:
            return { 
                ...state, 
                emails: payload
            }
        default:
            return initialState;
    }
}