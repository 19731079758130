import { SET_ALERT, REMOVE_ALERT, RESET_ALERT, CHANGE_STATE_ALERT } from '../actions/typesAction';

const initialState = {
    msg: [],
    active: false
};

export default function alertReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ALERT:
            if(state.msg.length === 0){
                return {
                    ...state, 
                    msg: [...state.msg, payload]
                };
            }
            return state;
        case REMOVE_ALERT:
            const msg = state.msg.filter(alert => alert.id !== payload);
            return {
                msg: msg,
                active: true
            }
        case CHANGE_STATE_ALERT:
        case RESET_ALERT:
            return initialState;
        default:
            return state;
    }
}