import React, { useState, useEffect } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import Availability from './Availability'
import ScheduledActivities from './ScheduledActivities'
import { useDispatch } from 'react-redux'
import { updateAvailabilityPro } from '../../../../../../store/actions/contactAction'

const MyAvailabilities = ({ token, availability, activities, proId }) => {
    const [activitiesProgrammed, setActivitiesProgrammed] = useState(null)
    const dispatch = useDispatch()

    useEffect(()=>{

        const a = []
        for(let index in activities){            
            let ap = [activities[index].period]
            if(activities[index].period.length > 1){
                ap = activities[index].period.split(',')
            }
            for(let i in ap){
                a.push(`${activities[index].week_date}-${ap[i]}`)    
            }
        }

        setActivitiesProgrammed(a)
    }, [activities])

    const [enableTime, setEnableTime] = useState({
        Mo: availability['Mo'],
        Tu: availability['Tu'],
        We: availability['We'],
        Th: availability['Th'],
        Fr: availability['Fr'],
        Sa: availability['Sa'],
        Su: availability['Su'],
        am_Mo: (availability['Mo'] === 1 || availability['Mo'] === 3) ? true : false, 
        pm_Mo: (availability['Mo'] === 2 || availability['Mo'] === 3) ? true : false, 
        am_Tu: (availability['Tu'] === 1 || availability['Tu'] === 3) ? true : false, 
        pm_Tu: (availability['Tu'] === 2 || availability['Tu'] === 3) ? true : false, 
        am_We: (availability['We'] === 1 || availability['We'] === 3) ? true : false, 
        pm_We: (availability['We'] === 2 || availability['We'] === 3) ? true : false, 
        am_Th: (availability['Th'] === 1 || availability['Th'] === 3) ? true : false, 
        pm_Th: (availability['Th'] === 2 || availability['Th'] === 3) ? true : false,  
        am_Fr: (availability['Fr'] === 1 || availability['Fr'] === 3) ? true : false, 
        pm_Fr: (availability['Fr'] === 2 || availability['Fr'] === 3) ? true : false, 
        am_Sa: (availability['Sa'] === 1 || availability['Sa'] === 3) ? true : false, 
        pm_Sa: (availability['Sa'] === 2 || availability['Sa'] === 3) ? true : false, 
        am_Su: (availability['Su'] === 1 || availability['Su'] === 3) ? true : false, 
        pm_Su: (availability['Su'] === 2 || availability['Su'] === 3) ? true : false, 
    })

    const hancleChange = (e) => {
        const hour = e.target.name.split('_')[0]
        const day = e.target.name.split('_')[1] 
        let total = null
        if(hour === 'am'){
            if(e.target.checked && enableTime[`pm_${day}`]){
                total = 3
            }else if(e.target.checked && !enableTime[`pm_${day}`]){
                total = 1
            }else if(!e.target.checked && enableTime[`pm_${day}`]){
                total = 2
            }
        }else{
            if(e.target.checked && enableTime[`am_${day}`]){
                total = 3
            }else if(e.target.checked && !enableTime[`am_${day}`]){
                total = 2
            }else if(!e.target.checked && enableTime[`am_${day}`]){
                total = 1
            }
        }
 
        setEnableTime({
            ...enableTime, 
            [e.target.name]: e.target.checked,
            [day]: total
        })

    }

    const updateProAvailability = (e) => {
        e.preventDefault()
        dispatch(updateAvailabilityPro(token, proId, enableTime))
    }
    
    return (
        <Grid container>        
            <Grid item xs={12} md={4} style={{backgroundColor: '#fff'}}>
                <Availability availability={enableTime} hancleChange = { hancleChange } />
            </Grid>
            <Grid item xs={12} md={8} style={{backgroundColor: '#fff'}}>
                {
                    (activitiesProgrammed !== null) && (
                        <ScheduledActivities enableTime={enableTime} activitiesProgrammed={activitiesProgrammed} />
                    )
                }
            </Grid>
            <Grid item md={12}>
                <Box pt={3} textAlign="right">
                    <Button variant="contained" color="primary" onClick={e => updateProAvailability(e) }>MODIFIER LES DISPONIBILITÉS</Button>
                </Box>
            </Grid>
        </Grid>
    )
}
export default MyAvailabilities