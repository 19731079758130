import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ProfessionalsTable from "./Tables/ProfessionalsTable";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { useDispatch, useSelector } from "react-redux";
import { contactPro } from "../../store/actions/contactAction";
import Processing from "./Processing";
import { cleanCandidateAction } from "../../store/actions/candidateAction";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "row",
        flexDirection: "column",
        alignItems: "start",
        padding: "30px",
        borderRadius: "25px",
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    gridSize: {
        height: "80%",
        width: "100%",
    },
    title: {
        fontSize: "3.8rem",
        fontWeight: "bold",
        marginLeft: "1.4rem",
    },
    divider: {
        height: "2px",
        backgroundColor: "black",
        width: "100%",
    },
}));

const ProfessionalsList = () => {
    const { token, userAdmin, proList } = useSelector((state) => ({
        token: state.auth.access_token,
        userAdmin: state.auth.userAdmin,
        proList: state.contact.list,
    }));

    const dispatch = useDispatch();

    const classes = useStyles();
    const [searchuuid, setSearchuuid] = useState("");
    const [searchfirstName, setSearchfirstName] = useState("");
    const [searchservices, setSearchservices] = useState("");
    const [searchemail, setSearchemail] = useState("");
    const [searchcity, setSearchcity] = useState("");
    const [searchtelephone, setSearchtelephone] = useState("");
    const { contacts_delete_pro, contacts_update } = userAdmin.authority;

    const permit = { contacts_delete_pro, contacts_update };

    //It is part of the API no delete.
    const [rows, setRows] = useState([]);
    const [filterrows, setFilterrows] = useState([]);

    const headCells = [
        {
            id: "#",
            numeric: false,
            disablePadding: true,
            label: "",
            searchOption: false,
        },
        {
            id: "uuid",
            numeric: false,
            disablePadding: true,
            label: "uuid",
            searchOption: true,
        },
        {
            id: "firstName",
            numeric: false,
            disablePadding: true,
            label: "Prénom",
            searchOption: true,
        },
        {
            id: "services",
            numeric: false,
            disablePadding: true,
            label: "Services",
            searchOption: true,
        },
        {
            id: "telephone",
            numeric: false,
            disablePadding: true,
            label: "Cellulaire",
            searchOption: true,
        },
        {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            searchOption: true,
        },
        {
            id: "city",
            numeric: false,
            disablePadding: true,
            label: "City",
            searchOption: true,
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(cleanCandidateAction());
        };
    }, [dispatch]);

    useEffect(() => {
        if (proList === null) {
            dispatch(contactPro(token));
        } else {
            setFilterrows(proList);
            setRows(proList);
        }
    }, [token, proList, dispatch]);

    const filter = () => {
        console.log("Telefono: ", searchtelephone);
        let filterRows = rows.filter(
            (row) =>
                row.firstName
                    .toString()
                    .toLowerCase()
                    .indexOf(searchfirstName.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.uuid
                    .toString()
                    .toLowerCase()
                    .indexOf(searchuuid.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.services
                    .toString()
                    .toLowerCase()
                    .indexOf(searchservices.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.email
                    .toString()
                    .toLowerCase()
                    .indexOf(searchemail.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.city
                    .toString()
                    .toLowerCase()
                    .indexOf(searchcity.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.telephone
                    .toString()
                    .toLowerCase()
                    .indexOf(searchtelephone.toString().toLowerCase()) > -1
        );
        setFilterrows(filterRows);
    };

    const onChangeSearch = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case "searchuuid":
                setSearchuuid(value);
                break;
            case "searchfirstName":
                setSearchfirstName(value);
                break;
            case "searchservices":
                setSearchservices(value);
                break;
            case "searchemail":
                setSearchemail(value);
                break;
            case "searchcity":
                setSearchcity(value);
                break;
            case "searchtelephone":
                setSearchtelephone(value);
                break;
            default:
                break;
        }
    };

    return (
        <DashboardLayout>
            {proList === null ? (
                <Processing />
            ) : (
                <Box>
                    <Box mb={4} width="22%">
                        <Paper
                            className={classes.root}
                            style={{ padding: "8px" }}
                        >
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar>
                                        <PermIdentityIcon
                                            style={{
                                                fontSize: "3rem",
                                                color: "#4d4d4d",
                                            }}
                                        />
                                    </Avatar>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        className={classes.title}
                                    >
                                        Pro
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    className={classes.title}
                                >
                                    {filterrows.length}
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Paper component="form" className={classes.root}>
                        <Typography
                            component="h1"
                            variant="h6"
                            noWrap
                            className={classes.title}
                        >
                            Pro
                        </Typography>
                        <Divider className={classes.divider} />
                        <Box className={classes.gridSize}>
                            <ProfessionalsTable
                                rows={filterrows}
                                headCells={headCells}
                                onChangeSearch={onChangeSearch}
                                filter={filter}
                                permit={permit}
                            />
                        </Box>
                    </Paper>
                </Box>
            )}
        </DashboardLayout>
    );
};

export default ProfessionalsList;
