import React, { useState } from 'react'
import { Typography, Box, makeStyles, Button } from '@material-ui/core'
import Input from '../../../share/inputs/Input';
import CheckBoxCustom from '../../../share/inputs/CheckBoxCustom'

const useStyle = makeStyles((theme) => ({
    filterArea: {
        '& h4':{
            fontWeight: 'bold'
        },
        '& .MuiInputBase-root':{
            backgroundColor: '#fff'
        }
    },
    checkArea:{
        '& .MuiTypography-root':{
            fontSize: '18px',
            '@media(max-width: 1366px)':{
                fontSize: '14px',
            }
        }
    }
}))

const Filter = ({formData, handleChange, filter, time, personalization, serviceDay }) => {
    const classes = useStyle()
    const [fieldEnabled, setFieldEnabled] = useState(true)

    return (
        <Box className={classes.filterArea}>
            
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Box>
                            <Box>
                                <Typography variant="h5">NOM</Typography>
                            </Box>
                            <Box mt={1}>
                                <Input
                                    id="name"
                                    placeholder=""
                                    label=""
                                    size="small"
                                    width={'95%'}
                                    defaultValue={formData.name}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error=''
                                    readOnly={fieldEnabled}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Box mt={1}>
                                <Typography variant="h5">COURRIEL</Typography>
                            </Box>
                            <Box mt={1}>
                                <Input
                                    id="email"
                                    placeholder=""
                                    label=""
                                    size="small"
                                    width={'95%'}
                                    defaultValue={formData.email}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error=''
                                    readOnly={fieldEnabled}
                                />
                            </Box>
                            <Box mt={1}>
                                <CheckBoxCustom
                                    name="outOfTheZone"
                                    label="HORS ZONE"
                                    value={formData.outOfTheZone}
                                    handleChange={e=>handleChange(e)}
                                    disabled={fieldEnabled}
                                />                            
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box style={{width: '15%'}}>
                    <Box>
                        <Box>
                            <Typography variant="h5">LOCALISATION</Typography>
                        </Box>
                        <Box mt={1}>
                            <Input
                                id="postalCode"
                                placeholder=""
                                label=""
                                size="small"
                                width={'95%'}
                                defaultValue={formData.postalCode}
                                onBlur={e => handleChange(e)}
                                variant="outlined"
                                error=''
                                readOnly={true}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            {/* className={classes.checkArea} */}
                            <Box mr={1}>                                
                                <Box>
                                    <Typography variant="h5">CRITERES</Typography>
                                </Box>
                                <CheckBoxCustom
                                    name="vacuum"
                                    label="Matériel: Aspirateur, mope …"
                                    value={formData.vacuum}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.vacuum ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="fridge"
                                    label="Nettoyage frigo"
                                    value={formData.fridge}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.fridge ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="oven"
                                    label="Nettoyage four"
                                    value={formData.oven}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.oven ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="productStandard"
                                    label="Produits standards"
                                    value={formData.productStandard}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.product_standard ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="productEcological"
                                    label="Produits écologiques"
                                    value={formData.productEcological}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.product_ecological ? true : fieldEnabled}
                                />

                                <CheckBoxCustom
                                    name="bed"
                                    label="Faire les lits"
                                    value={formData.bed}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.bed ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="dog"
                                    label="Chien"
                                    value={formData.dog}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.dog ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    name="cat"
                                    label="Chats"
                                    value={formData.cat}
                                    handleChange={e=>handleChange(e)}
                                    disabled={personalization.cat ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Typography variant="h5">DISPONIBILITES</Typography>
                    </Box>
                    <Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6"></Typography>
                            <Box display="flex" flexDirection="row">
                                <Typography variant="h6" style={{width: '45px', textAlign:"center"}}>AM</Typography>
                                <Typography variant="h6" style={{width: '60px', textAlign:"center"}}>PM</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Dimanche</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.su_am}
                                    name = {`su_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.su_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.su_pm}
                                    name = {`su_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.su_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Lundi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.mo_am}
                                    name = {`mo_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.mo_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.mo_pm}
                                    name = {`mo_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.mo_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Mardi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.tu_am}
                                    name = {`tu_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.tu_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.tu_pm}
                                    name = {`tu_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.tu_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Mercredi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.we_am}
                                    name = {`we_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.we_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.we_pm}
                                    name = {`we_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.we_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>
                                                    
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Jeudi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.th_am}
                                    name = {`th_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.th_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.th_pm}
                                    name = {`th_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.th_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>
                                                    
                                                    
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Vendredi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.fr_am}
                                    name = {`fr_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.fr_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.fr_pm}
                                    name = {`fr_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.fr_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>
                                                    
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Samedi</Typography>
                            <Box display="flex" flexDirection="row">
                                <CheckBoxCustom
                                    value = {time.sa_am}
                                    name = {`sa_am`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.sa_am ? true : fieldEnabled}
                                />
                                <CheckBoxCustom
                                    value = {time.sa_pm}
                                    name = {`sa_pm`}
                                    handleChange={e => handleChange(e)}
                                    label=""
                                    disabled={serviceDay.sa_pm ? true : fieldEnabled}
                                />
                            </Box>
                        </Box>                      
                    </Box>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-around">
                <Box mt={3} ml={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={e => filter(e)}
                    >
                        RECHERCHER
                    </Button>
                </Box>
                <Box mt={3} ml={1}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => setFieldEnabled(!fieldEnabled)}
                    >
                        Activer les options
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Filter
