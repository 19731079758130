import { Box, Button } from '@material-ui/core'
import Input from '../../share/inputs/Input';
import React, { useEffect, useState } from 'react'

const CreatePromo = ({ promoCodeFun, data }) => {

    const [name, setName] = useState({
        codeOrPercPromo: ''
    })

    useEffect(() => {
        setName(name => {
            return {
                codeOrPercPromo: data.promoName
            }
        })
    }, [data])

    const { codeOrPercPromo } = name

    const [validate, setValidate] = useState(false)

    const promo = (e) => {
        e.preventDefault()
        if (codeOrPercPromo === '') {
            setValidate(true)
        } else {
            promoCodeFun(codeOrPercPromo)
            setValidate(false)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Box display="flex" flexDirection="row" >
            <Input
                id="codeOrPercPromo"
                placeholder=""
                label={data.editPromo ? "Saisissez le nouveau nom du code promotionnel" : "Pourcentage du code promotionnel"}
                size="small"
                width={'80%'}
                defaultValue={codeOrPercPromo}
                onBlur={e => handleChange(e)}
                variant="outlined"
                error={(codeOrPercPromo === '' && validate) ? 'Requerido' : ''}
            />

            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={e => promo(e)}>{data.editPromo ? "MISE À JOUR" : "CREER"}</Button>
            </Box>

        </Box>
    )
}

export default CreatePromo