import React, { useEffect, useState } from 'react'
import { AppBar, Tab, Tabs, Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../layout/Layout'
import Input from '../../share/inputs/Input'
import PaperLayout from '../../share/layout/PaperLayout'
import Tree from './Tree'
import { useParams, useNavigate } from 'react-router-dom';
import { crateAdminAction, updateAdminAction, getAnAdmin, savePhotoAdminAction } from '../../../store/actions/adminAction';
import constant from '../../../constant.json';
import SelectInput from '../../share/inputs/SelectInput';
import InputCustomPhone from '../../share/inputs/InputCustomPhone'

// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';

//import SketchExample from './SketchExample'

const useStyle = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: props.backgroundColor,
        color: theme.color,
    }),
    title: {
        fontSize: '3.8rem',
        fontWeight: 'bold',
        marginLeft: '1.4rem'
    },
    uploadImagen: {
        '& img':{
            width: '9rem'
        },
        '& label':{
            display:'flex',
            justifyContent:'center',
            marginBottom:'7px'
            
        }
    },
    container:{
        width: "100%",
        padding: "1rem",
        display:"flex",
        justifyContent:"center"
    },
    errorMessage: {
        color: '#dc3545',
        paddingTop: '5px'
    },
    colorBoxStyle: {
        width: '5.5rem',
        height: '4rem'
    },
    imgAdmin: {
        maxWidth: '12.5rem',
        height: '12.5rem'
    },
    Bloquear:{
        '& .hue-horizontal':{
            display: 'none'
        }
    },
    headerTab: {
        color: '#000',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& button:focus': {
            outline: '0px auto -webkit-focus-ring-color'
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            fontFamily: 'sans-serif',
            fontSize: '2.1rem',
            color: '#7f7f7f',

        }
    },
    phoneInput: {
        '& .react-tel-input':{
            boxShadow: '-1px 4px 6px 3px #80808047',
            borderRadius: '5px'
        },
        '& .react-tel-input .form-control':{
            height: '41px',
            width: '100%'
        }
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const Admin = () => {

    const { admin: {admin, loading}, auth:{ access_token, userAdmin } } = useSelector(
        state => ({
            auth: state.auth,
            admin: state.admin
        })
    );

    const dispatch = useDispatch();
    const history = useNavigate()
    let { id } = useParams();
    const classes = useStyle();
    //const [formChange, setFormChange] = useState(false);
    const [permissionValidated, setPermissionValidated] = useState(false);
    const [fieldReqValidated, setFieldReqValidated] = useState(false);
    const [changePhoto, setChangePhoto] = useState(false);

    //const [flagGeneratePassword, setFlagGeneratePassword ] = useState(false);
    const [phoneValidation, setPhoneValidation ] = useState(id === undefined ? 'The phone must have a minimum of 10 digits' : '');
    const [emailValidation, setEmailValidation] = useState(id === undefined ? 'Invalide email address' : '')
       
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        telephone:'',
        email: '',
        //password:'',
        position: '',
        files: [],
        preview: 'images/default_man.png',
        color: '#f8e71c',
        timezone: null,
        access:0,
        access_create:0,
        access_update:0,
        access_delete:0,
        reservation:0,
        reservation_create:0,
        reservation_delete:0,
        reservation_billing:0,
        contacts:0,
        contacts_update:0,
        contacts_delete_client:0,
        contacts_delete_pro:0,
        recruitment:0,
        recruitment_unlock:0,
        recruitment_update:0,
        recruitment_delete:0,
        mailbox:0,
        billing:0,
        pages:0,
        analytic:0, //PERMISOS
        agenda: admin !== null ? admin.availability : {},
        availability: {
            mondayStart: admin !== null ? parseInt(admin.availability.Mo.split('-')[0]) : 0,
            mondayEnd: admin !== null ? parseInt(admin.availability.Mo.split('-')[1]) : 0,
            tuesdayStart: admin !== null ? parseInt(admin.availability.Tu.split('-')[0]) : 0,
            tuesdayEnd: admin !== null ? parseInt(admin.availability.Tu.split('-')[1]) : 0,
            wednesdayStart: admin !== null ? parseInt(admin.availability.We.split('-')[0]) : 0,
            wednesdayEnd: admin !== null ? parseInt(admin.availability.We.split('-')[1]) : 0,
            thursdayStart: admin !== null ? parseInt(admin.availability.Th.split('-')[0]) : 0,
            thursdayEnd: admin !== null ? parseInt(admin.availability.Th.split('-')[1]) : 0,
            fridayStart: admin !== null ? parseInt(admin.availability.Fr.split('-')[0]) : 0,
            fridayEnd: admin !== null ? parseInt(admin.availability.Fr.split('-')[1]) : 0,
            saturdayStart: admin !== null ? parseInt(admin.availability.Sa.split('-')[0]) : 0,
            saturdayEnd: admin !== null ? parseInt(admin.availability.Sa.split('-')[1]) : 0,
            sundayStart: admin !== null ? parseInt(admin.availability.Su.split('-')[0]) : 0,
            sundayEnd: admin !== null ? parseInt(admin.availability.Su.split('-')[1]) : 0,
        }
    });

    useEffect(
        () => {
            if(id !== undefined){
                if(admin === null){
                    dispatch(getAnAdmin(access_token, id));
                }else{
                    let preview = null;
                    if(admin.avatar !== null){
                        if(admin.avatar !== undefined){
                            preview = admin.avatar.url;
                        }
                    }
                    
                    setFormData({
                        id:admin.id,
                        firstName: !loading && admin !== null ? admin.first_name : '',
                        lastName: !loading && admin !== null ? admin.last_name : '',
                        telephone: !loading && admin !== null ? admin.telephone : '',
                        email: !loading && admin !== null ? admin.email : '',
                        //password: !loading && admin !== null ? '12345' : '12345k',
                        position: !loading && admin !== null ? admin.position : '',
                        preview: preview,
                        color: '#f8e71c',
                        timezone: !loading && admin !== null ? admin.timezone : '',
                        //color: !loading && admin !== null ? admin.color : '#f8e71c',
                        access: !loading && admin !== null ? admin.authority.access : 0,
                        access_create: !loading && admin !== null ? admin.authority.access_create : 0,
                        access_update: !loading && admin !== null ? admin.authority.access_update : 0,
                        access_delete: !loading && admin !== null ? admin.authority.access_delete : 0,
                        reservation: !loading && admin !== null ? admin.authority.reservation : 0,
                        reservation_create: !loading && admin !== null ? admin.authority.reservation_create : 0,
                        reservation_delete: !loading && admin !== null ? admin.authority.reservation_delete : 0,
                        reservation_billing: !loading && admin !== null ? admin.authority.reservation_billing : 0,
                        contacts: !loading && admin !== null ? admin.authority.contacts : 0,
                        contacts_update: !loading && admin !== null ? admin.authority.contacts_update : 0,
                        contacts_delete_client: !loading && admin !== null ? admin.authority.contacts_delete_client : 0,
                        contacts_delete_pro: !loading && admin !== null ? admin.authority.contacts_delete_pro : 0,
                        recruitment: !loading && admin !== null ? admin.authority.recruitment : 0,
                        recruitment_unlock: !loading && admin !== null ? admin.authority.recruitment_unlock : 0,
                        recruitment_update: !loading && admin !== null ? admin.authority.recruitment_update : 0,
                        recruitment_delete: !loading && admin !== null ? admin.authority.recruitment_delete : 0,
                        mailbox: !loading && admin !== null ? admin.authority.mailbox : 0,
                        billing: !loading && admin !== null ? admin.authority.billing : 0,
                        pages: !loading && admin !== null ? admin.authority.pages : 0,
                        analytic: !loading && admin !== null ? admin.authority.analytic : 0,
                        agenda: admin !== null ? admin.availability : {},
                        availability: {
                            mondayStart: parseInt(admin.availability.Mo.split('-')[0]),
                            mondayEnd: admin !== null ? parseInt(admin.availability.Mo.split('-')[1]) : 0,
                            tuesdayStart: admin !== null ? parseInt(admin.availability.Tu.split('-')[0]) : 0,
                            tuesdayEnd: admin !== null ? parseInt(admin.availability.Tu.split('-')[1]) : 0,
                            wednesdayStart: admin !== null ? parseInt(admin.availability.We.split('-')[0]) : 0,
                            wednesdayEnd: admin !== null ? parseInt(admin.availability.We.split('-')[1]) : 0,
                            thursdayStart: admin !== null ? parseInt(admin.availability.Th.split('-')[0]) : 0,
                            thursdayEnd: admin !== null ? parseInt(admin.availability.Th.split('-')[1]) : 0,
                            fridayStart: admin !== null ? parseInt(admin.availability.Fr.split('-')[0]) : 0,
                            fridayEnd: admin !== null ? parseInt(admin.availability.Fr.split('-')[1]) : 0,
                            saturdayStart: admin !== null ? parseInt(admin.availability.Sa.split('-')[0]) : 0,
                            saturdayEnd: admin !== null ? parseInt(admin.availability.Sa.split('-')[1]) : 0,
                            sundayStart: admin !== null ? parseInt(admin.availability.Su.split('-')[0]) : 0,
                            sundayEnd: admin !== null ? parseInt(admin.availability.Su.split('-')[1]) : 0,
                        }
                    });
                    
                }
            }
        },
        [id, access_token, admin, dispatch, loading]
    )
    
    const emailRegex = RegExp(
        /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i
    );

    const handleChangePhone = e => {
        //console.log('Cambio de plugin: ', e)
        setPhoneValidation(e.length < 10 ? "The phone must have a minimum of 10 digits" : ""); 
        setFormData({ ...formData, 'telephone': e })
    }

    const handleChange = e => {
        switch (e.target.name) {
            case 'email':
                setEmailValidation(emailRegex.test(e.target.value) ? "" : "Invalid email address");                
                break;
            case 'telephone':
                let ex = e.target.value;
                ex = ex.replace('(', '');
                ex = ex.replace(')', '');
                ex = ex.replace('-', '');
                ex = ex.replace(' ', '');
                ex = ex.replace(/\s+/g, '');
                ex = ex.trim();
                console.log('Desproporcion: ', e)
                setPhoneValidation(ex.length < 10 ? "The phone must have a minimum of 10 digits" : "");                
                break;
            default:
                break;
            
        }
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    
    const handleCheckbox = (name, checked) => {
        let valChecked = checked ? 1 : 0;
        setFormData({ ...formData, [name]: valChecked })
    }

//    const handleChangeComplete = (color) => setFormData({...formData, color})

    const setImages = (e) => {
        e.preventDefault();
        formData[e.target.name] = e.target.files;
        formData['preview'] = URL.createObjectURL(e.target.files[0]);
        setChangePhoto(true);
        //setFormChange(formChange ? false : true);
    }

    const formValid = () => {

        setFieldReqValidated(false);
        setPermissionValidated(false);        
        //autorisations check if at least one permission has been chosen
        let autorisations = 0;
        //stringEmpty check if string has some information
        let stringEmpty = false;
        for(let index in formData){
            if(index !== 'files'){
                
                if(typeof formData[index] === 'string'){
                    
                    if((formData[index].length === 0)){
                        stringEmpty = true;
                    }
                }

                if(typeof formData[index] === 'number'){
                    if(formData[index] === 1 ){
                        autorisations++;
                    }
                }

            }
        }
        
        if(autorisations === 0){
            setPermissionValidated(true);  
        }
        
        if(autorisations === 0 || stringEmpty || emailValidation.length > 0 || phoneValidation.length > 0){
            setFieldReqValidated(true);
            return false;
        }

        //if return is true is becuase the fields have been filled out.
        return true;
    }

    const submitUpdateAdmin = e => {
        e.preventDefault();
        if(formValid()){
            dispatch(updateAdminAction(formData, access_token, id, admin));
        }           
    }

    const submitCreateAdmin = e => {
        e.preventDefault();
        if(formValid()){
            dispatch(crateAdminAction(formData, access_token, history));
        }      
    }
    
    const savePhoto = e => {
        e.preventDefault();
        setChangePhoto(false);
        dispatch(savePhotoAdminAction(formData, access_token, null, admin, history));
    }

    const { firstName, lastName, telephone, email, position, timezone, access, access_create, access_update, access_delete, reservation,    
        reservation_create, reservation_delete, reservation_billing, contacts, contacts_update, contacts_delete_client, contacts_delete_pro,recruitment, recruitment_unlock, preview, recruitment_update, recruitment_delete, mailbox, billing, pages, analytic } = formData;
    
    const { mondayStart, mondayEnd, tuesdayStart, tuesdayEnd, wednesdayStart, wednesdayEnd, thursdayStart, thursdayEnd, fridayStart, fridayEnd, saturdayStart, saturdayEnd, sundayStart, sundayEnd } = formData.availability;

    const value_access = { access, access_create, access_update, access_delete, reservation,    
        reservation_create, reservation_delete, reservation_billing, contacts, contacts_update, contacts_delete_client, contacts_delete_pro,recruitment, recruitment_unlock, recruitment_update, recruitment_delete, mailbox, billing, pages, analytic };

    const [value, setValue] = useState(0);


    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <DashboardLayout>
                        
            <PaperLayout>
                <Box>
                    <Typography component="h1" variant="h1" noWrap className={classes.title}>Salarié</Typography>
                </Box>
                <Box mt={4}>

                    <AppBar position="static" className={classes.headerTab}>
                        <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                            <Tab label="Profile" {...a11yProps(0)} />
                            <Tab label="Agenda" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0}>

                        <Container component="main" maxWidth="md">
                            <Grid container spacing={2}>
                                <Grid item sm={12} md={12} container>
                                    <Typography component="h5" variant="h5" noWrap >Information du salarié</Typography>
                                </Grid>

                                <Grid item sm={12} container>
                                    <Grid item sm={12} md={4} container direction="column">
                                        <Box  mr={2}>
                                            <Box className={classes.uploadImagen}>
                                                <label htmlFor="upload-button">
                                                    <Box style={{height: '125px'}}>
                                                        {/* {(admin !== null) &&(  */}
                                                            <img src={preview !== null ? preview : 'images/default_man.png'} id="defaultImg" alt="dummy" className={classes.imgAdmin} />
                                                        {/* )}  */}
                                                    </Box>
                                                </label>
                                            </Box>
                                            <Box>
                                                {
                                                    (!changePhoto) ?
                                                    <Button htmlFor="sampleFile" fullWidth component="label" variant="contained" type="submit">
                                                        TÉLÉCHARGER UNE PHOTO
                                                    </Button>
                                                    :
                                                    <Button variant="contained" fullWidth onClick={(e) => savePhoto(e)}>Sauvegarder le fichier</Button>
                                                }
                                                <input id="sampleFile" type="file" name="files" style={{display: 'none'}} multiple onChange={e => setImages(e)} />
                                            </Box>
                                        </Box>

                                    </Grid>
                                    <Grid item sm={12} md={8} container>
                                        <Grid item sm={12} md={6}  >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="firstName"
                                                    placeholder=""
                                                    label="Prenom"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={firstName}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={firstName.length === 0 && fieldReqValidated ? 'requis' : ''}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3}>
                                                <Input
                                                    id="lastName"
                                                    placeholder=""
                                                    label="Nom"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={lastName}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={lastName.length === 0 && fieldReqValidated ? 'requis' : ''}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item sm={12} md={6}  >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="email"
                                                    placeholder=""
                                                    label="Courriel"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={email}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={(email.length === 0 || emailValidation.length > 0) && fieldReqValidated ? emailValidation : ''}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3} className={classes.phoneInput}>
                                                {/* <Input
                                                    id="telephone"
                                                    placeholder=""
                                                    label="Telephone"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={telephone}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={(telephone.length === 0 || phoneValidation.length > 0) && fieldReqValidated ? phoneValidation : ''}
                                                    mask="telephone"
                                                /> */}

                                                <InputCustomPhone
                                                    name = 'telephone'
                                                    country={'ca'}
                                                    label="Cellulaire"
                                                    value={telephone}              
                                                    disableCountryCode={true}
                                                    disableAreaCodes={true}
                                                    disableDropdown={false}
                                                    onChange={handleChangePhone}
                                                    variant="outlined"
                                                    size="small"
                                                    width= {'100%'}                                                      
                                                    error={ (telephone.length === 0 || phoneValidation.length > 0) && fieldReqValidated ? phoneValidation : '' }
                                                /> 
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="position"
                                                    placeholder=""
                                                    label="Post"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={position}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={position.length === 0 && fieldReqValidated ? 'requis' : ''}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3} mr={2}>
                                                <SelectInput
                                                    id="timezone"
                                                    name="timezone"
                                                    colorFont={{ color: '#000', fontWeight: 'bold' }}
                                                    data={constant.TIME_ZONE}
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={timezone}
                                                    //disabled={btnBlocked}
                                                />
                                            </Box>
                                        </Grid>                                    

                                    </Grid>
                                </Grid>

                                <Grid item sm={12} md={12} container>
                                    <Box>
                                        <Typography component="h5" variant="h5" noWrap >Autorisations</Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} container >
                                    <Box>
                                        {( permissionValidated ) && (
                                            <span className={classes.errorMessage}>Choisissez au moins une option</span>
                                        )}
                                    </Box>
                                    <Box className={classes.container}>
                                        <Tree value_access = { value_access } handleCheckbox={handleCheckbox}/>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} container >
                                    <Box className={classes.container}>
                                        {(id === undefined) && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={e => submitCreateAdmin(e)}
                                            >
                                                Soumetre
                                                    
                                            </Button>
                                        )}
                                        {(id !== undefined && userAdmin.authority.access_update === 1) && (
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={e => submitUpdateAdmin(e)}
                                            >
                                                Update                                            
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                            
                        </Container>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Container component="main" maxWidth="sm">
                            <Grid container spacing={2}>
                                <Grid item sm={12} container>
                                    <Box style={{ width: '400px' }}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Lundi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="mondayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={mondayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="mondayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={mondayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Mardi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="tuesdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={tuesdayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="tuesdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={tuesdayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Mercredi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="wednesdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={wednesdayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="wednesdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={wednesdayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Jeudi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="thursdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={thursdayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="thursdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={thursdayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Vendredi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="fridayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={fridayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="fridayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={fridayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Samedi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="saturdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={saturdayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="saturdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={saturdayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Dimanche</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="sundayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={sundayStart}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}

                                                />
                                                <Input
                                                    id="sundayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={sundayEnd}
                                                    //onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
{/* 
                        {
                            (value === 1 ) && (
                            )
                        }
                         */}
                    </TabPanel>

                </Box>
            </PaperLayout>
        </DashboardLayout>
    )
}

export default Admin;