import React from 'react'
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import DiscreteSlider from '../../../../share/inputs/DiscreteSlider'
import CheckBoxCustom from '../../../../share/inputs/CheckBoxCustom';
import Input from '../../../../share/inputs/Input'
import Mapp from "./Mapp"
const useStyle = makeStyles(() => ({
    housingArea:{
        display: 'flex', 
        justifyContent: 'space-between',
        width: '1400px',
        '@media(max-width: 1850px)':{
            width: '1000px',
        },
        '@media(max-width: 1400px)':{
            width: '870px',
        },
        '@media(max-width: 1200px)':{
            width: '850px',
        }
    },
    subtitle:{
        color: '#7f7f7f',
        fontWeight: 'bold',
        fontSize: '1.6rem'
    }
}))

const Criteria = ({criterion, tax, address}) => {
    const classes = useStyle()

    const handleChangeCheck = e => {

    }
    
    return (
        <Box className={classes.housingArea} >
            <Box display="flex" flexDirection="column">
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography className={classes.subtitle} variant="h6">INFORMATIONS POUR LE VERSEMENT</Typography>
                        <Box mt={1}>
                            <Typography variant="h6">Êtes-vous assujetti à la TPS/TVQ</Typography>
                            <RadioGroup row aria-label="gender" name="authorization_status"
                                value={(tax === 1) ? "1" : "0"} >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <Box>
                        <Typography className={classes.subtitle} variant="h6">LOCALISATION</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Box mr={10}>
                        <Input
                            id="postcode" 
                            label="" 
                            size="small" 
                            type='text'
                            width="50%"
                            onBlur={e=>handleChangeCheck(e)} 
                            defaultValue={address.postcode} 
                            variant="outlined"  
                            readOnly={true}
                            error=""
                        />
                        </Box>

                        <Box display="flex" flexDirection="column">
                            <Box mb={5}>
                                <Typography variant="h6">Dans un rayon autour de</Typography>
                            </Box>
                            <DiscreteSlider 
                                value={criterion.scope}
                                //handleScope={handleScope}
                                startValue={5}
                                finalValue={100}
                                step={5}
                                marks={[{value: 0,label: '',}, {value: 5,label: '5k',}, {value: 100,label: '100Km',}]}
                                onOrOff="on"
                            />
                        </Box>
                    
                    </Box>
                    <Box >
                                <Mapp postalCode = {address.postcode}/>
                        </Box>
                </Box>
                <Box>
                    <Box mb={1}>
                        <Typography className={classes.subtitle} variant="h6">PERSONNALISEZ VOS SERVICES</Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Matériel: Aspirateur, mope …"
                                value={criterion.vacuum ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            /> 
                        </Box>
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Produits standards"
                                value={criterion.product_standard ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            /> 
                        </Box>
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Produits écologiques"
                                value={criterion.product_ecological ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            /> 
                        </Box>
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Nettoyage frigo"
                                value={criterion.fridge ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            /> 
                        </Box>
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Nettoyage four"
                                value={criterion.oven ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            />
                        </Box>
                        <Box width="33%">
                            <CheckBoxCustom
                                name="no"
                                label="Faire lits avec draps propres"
                                value={criterion.bed ? true : false}
                                handleChange={e=>handleChangeCheck(e)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" flexDirection="column">
                <Box mb={3}>
                    <Typography variant="h6">Êtes-vous à l’aise de travailler en présence du client dans le logement ?</Typography>
                    <RadioGroup row aria-label="gender" name="authorization_status"
                        value={(criterion.with_client === 1) ? "1" : "0"} >
                        <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                        <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                    </RadioGroup>
                </Box>
                <Box mb={3}>
                    <Typography variant="h6">Êtes-vous à l'aise de travailler en présence de chien ?</Typography>
                    <RadioGroup row aria-label="gender" name="authorization_status"
                        value={(criterion.dog === 1) ? "1" : "0"} >
                        <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                        <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                    </RadioGroup>
                </Box>

                <Box mb={3}>
                    <Typography variant="h6">Êtes-vous à l’aise de travailler en présence de chats ?</Typography>
                    <RadioGroup row aria-label="gender" name="authorization_status"
                        value={(criterion.cat === 1) ? "1" : "0"} >
                        <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                        <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                    </RadioGroup>
                </Box>

                <Box mb={3}>
                    <Typography variant="h6">Souhaitez-vous travailler en binôme ?</Typography>
                    <RadioGroup row aria-label="gender" name="authorization_status"
                        value={(criterion.work_group === 1) ? "1" : "0"} >
                        <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                        <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                    </RadioGroup>
                </Box>
            </Box>                
        </Box>
    )
}

export default Criteria
