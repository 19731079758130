import { Box } from '@material-ui/core';
import React from 'react';
import DashboardLayout from '../../../layout/Layout';

import ChatListPro from '../Chat/ChatListPro'
function ProMsg() {
    return (
        
        
                
       
        <Box>
            <DashboardLayout>
            <ChatListPro />
            </DashboardLayout> 
        </Box>
      );
}

export default ProMsg;