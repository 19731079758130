import { SAVE_AVATAR } from '../actions/typesAction';

const initState = {
    avatarUrl: null,
};

const avatarReducer = (state = initState, action) => {
    switch (action.type) {
        case SAVE_AVATAR:
            return {
                avatarUrl: action.payload
            }
        default: 
            return state;
    }
}

export default avatarReducer;