import axios from 'axios';
import { setAlert } from './alertAction';
import { CREATE_ADMIN, UPDATE_ADMIN, ADMIN_FAIL, PROCESSING, GET_ALL_ADMIN, GET_AN_ADMIN, REMOVE_ADMIN, CREATE_ACTIVITY, GET_ACTIVITIES, RESET_PASSWORD } from './typesAction';
import { savePhotoUser } from './avatarAction';
import { updateUserAuthAction } from './authAction';
import { logOutTokenExpired } from './authAction';
import config from '../../config.json';

export const crateAdminAction = (formData, token, history) => async dispatch => {

    try {
        dispatch({
            type: PROCESSING
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        //const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
        const admin = {
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "telephone": formData.telephone,
            "email": formData.email,
            "timezone": formData.timezone, //resolvedOptions.timeZone,
            "position": formData.position,
            "color": formData.color,
            "authority": {
                "access": formData.access,
                "access_create": formData.access_create,
                "access_update": formData.access_update,
                "access_delete": formData.access_delete,
                "reservation": formData.reservation,
                "reservation_create": formData.reservation_create,
                "reservation_delete": formData.reservation_delete,
                "reservation_billing": formData.reservation_billing,
                "contacts": formData.contacts,
                "contacts_update": formData.contacts_update,
                "contacts_delete_client": formData.contacts_delete_client,
                "contacts_delete_pro": formData.contacts_delete_pro,
                "recruitment": formData.recruitment,
                "recruitment_unlock": formData.recruitment_unlock,
                "recruitment_update": formData.recruitment_update,
                "recruitment_delete": formData.recruitment_delete,
                "mailbox": formData.mailbox,
                "billing": formData.billing,
                "pages": formData.pages,
                "analytic": formData.analytic
            }
        }
        const content = JSON.stringify(admin);
        const imgs = formData.files;
        const f = new FormData();
        for (let index = 0; index < imgs.length; index++) {
            f.append("avatar", imgs[index]);
        }

        console.log('Entorno: ---:21---: ', content)

        f.append('content', content);
        await axios.post(`${config.API_SERVER}/api/v1/admin/super/create`, f, headers)
        .then((res) => {
            dispatch({
                type: CREATE_ADMIN,
                payload: res.data
            })
        }).then(() => {
            history('/adminlist');
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const removeAdmin = (token, id, history) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const data = {
            id: id
        };
        await axios.post(`${config.API_SERVER}/api/v1/admin/super/remove`, data, headers)
            .then((res) => {
                dispatch({
                    type: REMOVE_ADMIN,
                    payload: { id: id, msg: res.data, status: res.status }
                })
            })
            .then(() => {
                dispatch(setAlert(`L'enregistrement a été supprimé avec succès`, 'success'));
                history('/adminlist');
            })
            .catch((error) => {
                console.log(error);
                // localStorage.clear();
                // dispatch(logOutTokenExpired());
            })

    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const savePhotoAdminAction = (formData, token, userAdmin = null, admin = null, history) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
        const imgs = formData.files;
        const f = new FormData();

        for (let index = 0; index < imgs.length; index++) {
            f.append("avatar", imgs[index]);
        }

        f.append('id', formData.id);

        axios.post(`${config.API_SERVER}/api/v1/admin/profile/avatar`, f, headers)
            .then(res => {
                if(res.status === 200){
                    
                    if (admin !== null) {
                        admin.avatar.id = res.data.avatar.id;
                        admin.avatar.filename = res.data.avatar.filename;
                        admin.avatar.url = res.data.avatar.url;

                        dispatch({
                            type: GET_AN_ADMIN,
                            payload: { admin: admin }
                        })

                    }
                    if (userAdmin !== null) {
                        userAdmin.avatar = res.data.avatar;
                        dispatch(updateUserAuthAction(userAdmin, token));
                        dispatch(savePhotoUser(res.data.avatar.url));
                    }

                    dispatch(setAlert("La photo a été mise à jour", 'success'));
                }

            }).catch((error) => {
                console.log(error);
            })

    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })

    }
}

export const updateAdminAction = (formData, token, id, admin) => async dispatch => {

    try {
        dispatch({
            type: PROCESSING
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        //const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
        formData.id = id;
        const adminArray = {
            "id": parseInt(id),
            "first_name": formData.firstName,
            "last_name": formData.lastName,
            "telephone": formData.telephone,
            "email": formData.email,
            "position": formData.position,
            "color": formData.color,
            "timezone": formData.timezone, //resolvedOptions.timeZone,
            "authority": {
                "access": formData.access,
                "access_create": formData.access_create,
                "access_update": formData.access_update,
                "access_delete": formData.access_delete,
                "reservation": formData.reservation,
                "reservation_create": formData.reservation_create,
                "reservation_delete": formData.reservation_delete,
                "reservation_billing": formData.reservation_billing,
                "contacts": formData.contacts,
                "contacts_update": formData.contacts_update,
                "contacts_delete_client": formData.contacts_delete_client,
                "contacts_delete_pro": formData.contacts_delete_pro,
                "recruitment": formData.recruitment,
                "recruitment_unlock": formData.recruitment_unlock,
                "recruitment_update": formData.recruitment_update,
                "recruitment_delete": formData.recruitment_delete,
                "mailbox": formData.mailbox,
                "billing": formData.billing,
                "pages": formData.pages,
                "analytic": formData.analytic
            }
        }
        const content = JSON.stringify(adminArray);
        const f = new FormData();

        f.append('content', content);

        await axios.post(`${config.API_SERVER}/api/v1/admin/super/update`, f, headers)
        .then((res) => {
            res.data.availability = formData.agenda;
            dispatch({
                type: UPDATE_ADMIN,
                payload: res.data
            });
            //dispatch(savePhotoAdminAction(formData, token, null, admin ));                
        }).then(() => {
            dispatch(setAlert('Données mises à jour avec succès', 'success'));
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const getAllUsers = (token) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        await axios.get(`${config.API_SERVER}/api/v1/admin/super/all`, headers)
        .then((res) => {
            dispatch({
                type: GET_ALL_ADMIN,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const getAnAdmin = (token, id) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        });

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        const data = {
            id: id
        };

        await axios.post(`${config.API_SERVER}/api/v1/admin/super/show`, data, headers)
        .then((res) => {

            dispatch({
                type: GET_AN_ADMIN,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const updateAdminProfileAction = (formData, token, userAdmin) => async dispatch => {
    try {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
        //const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
        const profile = {
            "id": formData.id,
            "first_name": formData.firstName,
            "last_name": formData.lastName,
            "telephone": formData.telephone,
            "position": formData.position,
            "color": formData.color,
            "timezone": formData.timezone, //resolvedOptions.timeZone,
            "availability": {
                "Mo": `${formData.mondayStart.toString().trim().length > 0 ? formData.mondayStart : '0'} - ${formData.mondayEnd.toString().trim().length > 0 ? formData.mondayEnd : '0'}`,
                "Tu": `${formData.tuesdayStart.toString().trim().length > 0 ? formData.tuesdayStart : '0'} - ${formData.tuesdayEnd.toString().trim().length > 0 ? formData.tuesdayEnd : '0'}`,
                "We": `${formData.wednesdayStart.toString().trim().length > 0 ? formData.wednesdayStart : '0'} - ${formData.wednesdayEnd.toString().trim().length > 0 ? formData.wednesdayEnd : '0'}`,
                "Th": `${formData.thursdayStart.toString().trim().length > 0 ? formData.thursdayStart : '0'} - ${formData.thursdayEnd.toString().trim().length > 0 ? formData.thursdayEnd : '0'}`,
                "Fr": `${formData.fridayStart.toString().trim().length > 0 ? formData.fridayStart : '0'} - ${formData.fridayEnd.toString().trim().length > 0 ? formData.fridayEnd : '0'}`,
                "Sa": `${formData.saturdayStart.toString().trim().length > 0 ? formData.saturdayStart : '0'} - ${formData.saturdayEnd.toString().trim().length > 0 ? formData.saturdayEnd : '0'}`,
                "Su": `${formData.sundayStart.toString().trim().length > 0 ? formData.sundayStart : '0'} - ${formData.sundayEnd.toString().trim().length > 0 ? formData.sundayEnd : '0'}`,
            }
        };

        const content = JSON.stringify(profile);
        const data = {
            'content': content
        }

        axios.post(`${config.API_SERVER}/api/v1/admin/profile/update`, data, headers)
        .then(res => {
            dispatch({
                type: UPDATE_ADMIN,
                payload: res.data
            });
            if (res.status === 200) {
                if(userAdmin !== null){
                    userAdmin.availability = profile.availability;
                    userAdmin.timezone = profile.timezone;
                    dispatch(updateUserAuthAction(userAdmin, token));
                    dispatch(setAlert("Les modifications ont été effectuées avec succès", 'success'));
                }
            }
        }).catch((error) => {
            console.log(error);
        })

    } catch (error) {
        dispatch({
            type: ADMIN_FAIL,
            error,
        })

    }
}

export const deleteActivityAction = (token, id) => async dispatch => {
    try{
        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/unavailability/delete`, data, headers)
        .then(res => {
            if (res.status === 200){
                dispatch(setAlert('El registro ha sido eliminado', 'success'));
            }
        })
        .catch(error => {
            console.log(error);
        })

    }catch(error) {
        console.log(error);
    }
}

export const createActivityAction = (token, dataForm, userId) => async dispatch => {

    try {
        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            admin_id: userId,
            recurrence_id: dataForm.frequency,
            start_time: dataForm.startTime,
            end_time: dataForm.endTime,
            description: dataForm.description
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/unavailability/create`, data, headers)
        .then((res) => {
            dispatch({
                type: CREATE_ACTIVITY,
                payload: { activities: res.data, status: res.status }
            })
            if (res.status === 200) {
                dispatch(setAlert(`L'activité a été créée avec succès`, 'success'))
            }
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        console.log("This is the error: ", error);
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const getActivityAction = (token) => async dispatch => {

    try {
        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/unavailability/show`, headers)
        .then((res) => {

            dispatch({
                type: GET_ACTIVITIES,
                payload: { activities: res.data, status: res.status }
            })

        }).catch((error) => {

            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());

        })

    } catch (error) {
        console.log("This is the error: ", error);
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }

}

export const getEmailToResetAdminPassword = (email) => async dispatch => {

    try {
        dispatch({
            type: PROCESSING,
        })

        const data = {
            email: email
        }

        await axios.post(`${config.API_SERVER}/api/verify/admin`, data)
        .then((res) => {
            dispatch({
                    type: RESET_PASSWORD,
                    payload: { status: res.status }
            })
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        console.log("This is the error: ", error);
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const resetAdminPassword = (email) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING,
        })

        const data = {
            email: email
        }

        await axios.post(`${config.API_SERVER}/api/password/admin/email`, data)
        .then((res) => {
            dispatch({
                type: RESET_PASSWORD,
                payload: { status: res.status }
            })
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        })
    } catch (error) {
        console.log("This is the error: ", error);
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}