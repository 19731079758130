import React from 'react'
import { Box, Typography, TextareaAutosize, makeStyles } from '@material-ui/core'
import CheckBoxCustom from '../../../../share/inputs/CheckBoxCustom'

const useStyle = makeStyles((theme) => ({
    margens: {
        padding: theme.spacing(3, 3, 0, 0),
        '& textarea':{
            padding: '10px'
        },
        '& h5':{
            fontSize: '1.8rem'
        },  
    },
    enabledOptionArea:{
        border: '0px',
        background: '#c1c1c1c1',
        width: '100%',
        height: '150px !important',
        boxShadow: '5px 10px 10px #00000055'
    }
}))

const MethodToEnterAtHome = ({ method }) => {
    const classes = useStyle()
    return (
        <Box>
            <Box className={classes.margens}>
                <Box>
                    <Typography variant="h5">ACCES ET INFORMATIONS LOGEMENT</Typography>
                </Box>                
                <Box display="flex" flexWrap="wrap" mt={2}>
                    <CheckBoxCustom
                        name="atHome"
                        label="Je serais sur place"
                        value={ method.enter_method === 1 ? true : false }
                        disabled = {true}
                    />
                    <CheckBoxCustom
                        name="keyBox"
                        label="Une boîte a clés est présente"
                        value={ method.enter_method === 2 ? true : false }
                        disabled = {true}
                    />                                
                </Box>
                <Box>     
                    <Box>
                        <TextareaAutosize 
                            name="enterComment" 
                            aria-label="minimum height" 
                            minRows={3}
                            placeholder=""
                            //onChange={(e) => handleChange(e)}
                            value={method.enter_comment}
                            className={classes.enabledOptionArea}
                            disabled = {true}
                        />
                    </Box>                                   
                </Box>
            </Box>
        </Box>
    )
}

export default MethodToEnterAtHome