import React, { useState } from 'react'
import { AppBar, Box, Button, Container, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../layout/Layout';
import PaperLayout from '../../share/layout/PaperLayout';
import Input from '../../share/inputs/Input';
import { savePhotoAdminAction, updateAdminProfileAction } from '../../../store/actions/adminAction';
//import SketchExample from '../admin/SketchExample'
import PropTypes from 'prop-types';
import constant from '../../../constant.json';
import SelectInput from '../../share/inputs/SelectInput';

const useStyle = makeStyles((theme) => ({
    title: {
        fontSize: '3.8rem',
        fontWeight: 'bold',
        marginLeft: '1.4rem'
    },
    uploadImagen: {
        '& img': {
            width: '9rem'
        },
        '& label': {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '7px'

        }
    },
    imgAdmin: {
        maxWidth: '12.5rem',
        height: '12.5rem'
    },
    container: {
        width: "100%",
        padding: "1rem",
        display: "flex",
        justifyContent: "center"
    },
    Bloquear: {
        '& .hue-horizontal': {
            display: 'none'
        }
    },
    headerTab: {
        color: '#000',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& button:focus': {
            outline: '0px auto -webkit-focus-ring-color'
        },
        '& .MuiTab-root': {
            textTransform: 'none',
            fontFamily: 'sans-serif',
            fontSize: '2.1rem',
            color: '#7f7f7f',

        }
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Profile = () => {
    const classes = useStyle();
    const { auth: { userAdmin, access_token } } = useSelector(
        state => ({
            auth: state.auth
        })
    );

    //Date.toUTCString() muestra hora Longres (Greenwich)

    const dispatch = useDispatch();

    let preview = 'images/default_man.png';
    if (userAdmin.avatar !== null) {
        if (userAdmin.avatar !== undefined) {
            preview = userAdmin.avatar.url;
        }
    }

    const [fieldReqValidated, setFieldReqValidated] = useState(false);
    let [phoneValidation, setPhoneValidation] = useState('');
    const [value, setValue] = useState(0);
    const [changeImg, setChangeImg] = useState(false);

    const [formData, setFormData] = useState({
        id: userAdmin.id,
        firstName: userAdmin.first_name,
        lastName: userAdmin.last_name,
        telephone: userAdmin.telephone,
        email: userAdmin.email,
        position: userAdmin.position,
        timezone: userAdmin.timezone,
        files: [],
        preview: preview,
        color: '#4a90e2',
        mondayStart: parseInt(userAdmin.availability.Mo.split('-')[0]),
        mondayEnd: parseInt(userAdmin.availability.Mo.split('-')[1]),
        tuesdayStart: parseInt(userAdmin.availability.Tu.split('-')[0]),
        tuesdayEnd: parseInt(userAdmin.availability.Tu.split('-')[1]),
        wednesdayStart: parseInt(userAdmin.availability.We.split('-')[0]),
        wednesdayEnd: parseInt(userAdmin.availability.We.split('-')[1]),
        thursdayStart: parseInt(userAdmin.availability.Th.split('-')[0]),
        thursdayEnd: parseInt(userAdmin.availability.Th.split('-')[1]),
        fridayStart: parseInt(userAdmin.availability.Fr.split('-')[0]),
        fridayEnd: parseInt(userAdmin.availability.Fr.split('-')[1]),
        saturdayStart: parseInt(userAdmin.availability.Sa.split('-')[0]),
        saturdayEnd: parseInt(userAdmin.availability.Sa.split('-')[1]),
        sundayStart: parseInt(userAdmin.availability.Su.split('-')[0]),
        sundayEnd: parseInt(userAdmin.availability.Su.split('-')[1]),
    });

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleChangeAgenda = e => {
        let value = 24;
        if (e.target.value >= 0 && e.target.value <= 24) {
            value = e.target.value;
            setFormData({ ...formData, [e.target.name]: value })
        }

    }
    
    const setImages = (e) => {
        e.preventDefault();
        formData[e.target.name] = e.target.files;
        formData['preview'] = URL.createObjectURL(e.target.files[0]);
        setChangeImg(true);
    } 

    const savePhoto = e => {
        e.preventDefault();
        dispatch(savePhotoAdminAction(formData, access_token, userAdmin, null));
        setChangeImg(false);
    }
    
    const updateProfile = e => {
        e.preventDefault();

        let ex = formData.telephone.trim();
        ex = ex.replace('(', '');
        ex = ex.replace(')', '');
        ex = ex.replace('-', '');
        ex = ex.replace(' ', '');
        ex = ex.replace(/\s+/g, '');
        ex = ex.trim();
        setPhoneValidation(ex.length < 10 ? "The phone must have a minimum of 10 digits" : "");

        if (formData.firstName.trim().length === 0 || formData.lastName.trim().length === 0 || formData.position.trim().length === 0 || ex.length < 10) {
            setFieldReqValidated(true);
            //formData.trim().length === 0
        } else {

            setFieldReqValidated(false);
            dispatch(updateAdminProfileAction(formData, access_token, userAdmin));
        }

    }

    const { firstName, lastName, telephone, email, position, timezone, mondayStart, mondayEnd, tuesdayStart, tuesdayEnd, wednesdayStart, wednesdayEnd, thursdayStart, thursdayEnd, fridayStart, fridayEnd, saturdayStart, saturdayEnd, sundayStart, sundayEnd
    } = formData;

    return (
        <DashboardLayout>
            <PaperLayout>
                <Box>
                    <Typography component="h1" variant="h1" noWrap className={classes.title}>Profile</Typography>
                </Box>
                <Box mt={4}>

                    <AppBar position="static" className={classes.headerTab}>
                        <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                            <Tab label="Profile" {...a11yProps(0)} />
                            <Tab label="Agenda" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0}>
                        <Container component="main" maxWidth="sm">
                            <Grid container spacing={2}>
                                <Grid item sm={12} container>
                                    <Grid item sm={12} md={12} >
                                        <Box my={4}>
                                            <Box className={classes.uploadImagen}>
                                                <label htmlFor="upload-button">
                                                    <img src={formData.preview} id="defaultImg" alt="dummy" className={classes.imgAdmin} />
                                                </label>
                                            </Box>
                                            
                                            <Box>
                                                {(!changeImg) && (
                                                    <Button htmlFor="sampleFile" fullWidth component="label" variant="contained" type="submit">
                                                        TÉLÉCHARGER UNE PHOTO
                                                    </Button>
                                                )}
                                                {(changeImg) && (
                                                    <Button fullWidth variant="contained" type="submit" onClick={e => savePhoto(e)}>
                                                        ENREGISTRER LA PHOTO
                                                    </Button>
                                                )}
                                                <input id="sampleFile" type="file" name="files" style={{display: 'none'}} multiple onChange={e => setImages(e)} />
                                            </Box>

                                        </Box>
                                    </Grid>

                                    <Grid item sm={12} md={12} container>
                                        <Grid item sm={12} md={6}  >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="firstName"
                                                    placeholder=""
                                                    label="Prenom"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={firstName}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={firstName.trim().length === 0 && fieldReqValidated ? 'requis' : ''}
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3}>
                                                <Input
                                                    id="lastName"
                                                    placeholder=""
                                                    label="Nom"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={lastName}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={lastName.trim().length === 0 && fieldReqValidated ? 'requis' : ''}
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item sm={12} md={6}  >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="email"
                                                    placeholder=""
                                                    label="Courriel"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={email}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error=''
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3}>
                                                <Input
                                                    id="telephone"
                                                    placeholder=""
                                                    label="Telephone"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={telephone}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={(phoneValidation.length > 0 && fieldReqValidated) ? phoneValidation : ''}
                                                    mask="telephone"
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Grid>





                                        <Grid item sm={12} md={6}  >
                                            <Box pb={3} mr={2}>
                                                <Input
                                                    id="position"
                                                    placeholder=""
                                                    label="Post"
                                                    size="small"
                                                    width={'100%'}
                                                    defaultValue={position}
                                                    onBlur={handleChange}
                                                    variant="outlined"
                                                    error={position.trim().length === 0 && fieldReqValidated ? 'requis' : ''}
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={12} md={6} >
                                            <Box pb={3}>
                                                <SelectInput
                                                    id="timezone"
                                                    name="timezone"
                                                    colorFont={{ color: '#000', fontWeight: 'bold' }}
                                                    data={constant.TIME_ZONE}
                                                    onChange={(e) => handleChange(e)}
                                                    defaultValue={timezone}
                                                    //disabled={btnBlocked}
                                                />
                                            </Box>
                                        </Grid>

                                        

                                        <Grid item sm={12} md={6} >
                                            <Box pb={3}>
                                                
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Container component="main" maxWidth="sm">
                            <Grid container spacing={2}>
                                <Grid item sm={12} container>
                                    <Box style={{ width: '400px' }}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Lundi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="mondayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={mondayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"
                                                    error=''

                                                />
                                                <Input
                                                    id="mondayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={mondayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Mardi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="tuesdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={tuesdayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="tuesdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={tuesdayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Mercredi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="wednesdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={wednesdayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="wednesdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={wednesdayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Jeudi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="thursdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={thursdayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="thursdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={thursdayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Vendredi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="fridayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={fridayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="fridayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={fridayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Samedi</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="saturdayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={saturdayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="saturdayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={saturdayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                            <Box>
                                                <Typography component="h5">Dimanche</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Input
                                                    id="sundayStart"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={sundayStart}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''

                                                />
                                                <Input
                                                    id="sundayEnd"
                                                    placeholder=""
                                                    label=""
                                                    size="small"
                                                    type="number"
                                                    width={'70px'}
                                                    defaultValue={sundayEnd}
                                                    onBlur={handleChangeAgenda}
                                                    variant="outlined"                                                    
                                                    error=''
                                                />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
{/* 
                        {
                            (value === 1 ) && (
                            )
                        }
                         */}
                    </TabPanel>
                    <Box className={classes.container}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={e => updateProfile(e)}
                        >
                            Modifier
                        </Button>
                    </Box>

                </Box>
            </PaperLayout>
        </DashboardLayout>

    )
}

export default Profile;
