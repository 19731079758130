import { makeStyles, Typography } from "@material-ui/core";
import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getDateFormatDayMotnYear } from "../../../share/librery/librery";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyle = makeStyles((theme) => ({
    tittles: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        //padding: theme.spacing(5,0, 4, 0),
        "& h5": {
            color: "#737379",
        },
        "& h4": {
            fontWeight: "bold",
        },
    },
    statusStyle: {
        fontSize: "1.4rem",
        color: "white",
        width: "16.4rem",
        height: "3.6rem",
        fontWeight: "bold",
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        //backgroundColor: '#5087f5'
    },
    btnNew: {
        backgroundColor: theme.palette.primary.main,
    },
    btnWaitting: {
        backgroundColor: "#f49e3b",
    },
    nonAffected: {
        backgroundColor: "#983ef2",
    },
    btnAccepted: {
        backgroundColor: "#28cc8b",
    },
    btnAnnuler: {
        backgroundColor: "#FF0A00",
    },
    btnTerminer: {
        backgroundColor: "#FFD966",
    },
    btnNonAssigner: {
        backgroundColor: "#D4A6BC",
    },
    btnLitige: {
        backgroundColor: "#676666",
    },
    iconButtonStyle: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        border: `2px solid ${theme.palette.primary.main}`,
        width: "45px",
        height: "45px",
        "&:hover": {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
            transition: "ease-in 0.3s",
        },
    },
}));

const ReqHeader = ({
    uuid,
    service,
    dates,
    optionDate,
    duration,
    reservationStatusId,
    formule,
    requestStatus,
    isFinish,
}) => {
    const classes = useStyle();
    let d = duration.split(":");

    d = d.length > 1 ? `${d[0]}H${d[1]}` : "0H00";

    const history = useNavigate();

    let messageState = "NOUVELLE";
    let btnStyle = classes.statusStyle + " " + classes.btnNew;

    // if (reservationStatusId === "1") {
    //     btnStyle = btnStyle + " " + classes.btnWaitting;
    //     messageBtn = "EN ATTENTE";
    // } else if (reservationStatusId === "2") {
    //     btnStyle = btnStyle + " " + classes.btnWaitting;
    //     messageBtn = "ACCEPTÉ PAR LE PRO";
    // } else if (reservationStatusId === "4") {
    //     btnStyle = btnStyle + " " + classes.btnAccepted;
    //     messageBtn = "ACCEPTÉ";
    // }
    if (requestStatus === "0") {
        messageState = "NOUVELLE";
        btnStyle = btnStyle + " " + classes.btnNew;
    } else if (requestStatus === "1") {
        messageState = "EN ATTENTE";
        btnStyle = btnStyle + " " + classes.btnWaitting;
    } else if (requestStatus === "2") {
        messageState = "ACCEPTÉ PAR LE PRO";
        btnStyle = btnStyle + " " + classes.btnWaitting;
    } else if (requestStatus === "4") {
        messageState = "ACCEPTÉ";
        btnStyle = btnStyle + " " + classes.btnAccepted;
    } else if (requestStatus === "3") {
        messageState = "TERMINÉ";
        btnStyle = btnStyle + " " + classes.btnTerminer;
    } else if (requestStatus === "5") {
        messageState = "NON ASSIGNÉE";
        btnStyle = btnStyle + " " + classes.btnNonAssigner;
    } else if (requestStatus === "6") {
        messageState = "LITIGE";
        btnStyle = btnStyle + " " + classes.btnLitige;
    } else if (requestStatus === "7") {
        messageState = "NON AFFECTÉE";
        btnStyle = btnStyle + " " + classes.nonAffected;
    } else if (requestStatus === "8") {
        messageState = "Annulé";
        btnStyle = btnStyle + " " + classes.btnAnnuler;
    }

    const getBakcRequestList = (e) => {
        e.preventDefault();
        history("/reservation/requests");
    };

    return (
        <Box>
            {!isFinish && (
                <Box mb={2} mt={-4}>
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={(e) => getBakcRequestList(e)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Box>
            )}

            <Box className={classes.tittles}>
                <Box>
                    <Box>
                        <Typography variant="h5">ID REQUÊTE</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4">{uuid}</Typography>
                    </Box>
                </Box>

                <Box>
                    <Box>
                        <Typography variant="h5">SERVICE</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4">
                            {service.toUpperCase() === "AUCUN"
                                ? "Ménage"
                                : service.toUpperCase()}
                        </Typography>
                    </Box>
                </Box>

                <Box>
                    <Box>
                        <Typography variant="h5">DATE</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4">
                            {getDateFormatDayMotnYear(
                                `${dates[0].week_date} 00:00:00`
                            )}
                        </Typography>
                        {dates.length === 2 && (
                            <Typography variant="h4">
                                {getDateFormatDayMotnYear(
                                    `${dates[1].week_date} 00:00:00`
                                )}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box>
                    <Box>
                        <Typography variant="h5">TEMPS ESTIMÉ</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4">{d}</Typography>
                    </Box>
                </Box>
                {/* 
                <Box>
                    <Box>
                        <Typography variant="h5">FORMULE</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4">{formule}</Typography>
                    </Box>
                </Box>
                 */}
                {!isFinish && (
                    <Box>
                        <Box className={btnStyle}>
                            <label>{messageState}</label>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ReqHeader;
