import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Container, FormControlLabel } from '@material-ui/core';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authAction } from '../../store/actions/authAction';
import { useNavigate } from "react-router-dom";
import AlertMessage from '../layout/AlertMessage';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VerificationInput from "react-verification-input";
import config from '../../config.json'
/*
import axios from 'axios';
*/
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link to="#" color="inherit" href="https://material-ui.com/">
                Tiggidoo
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(20, 16),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        '& img': {
            padding: '3rem'
        }
    },
    errorMessage: {
        color: '#dc3545',
        paddingTop: '5px'
    },
    errors: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
                borderRadius: '4px'
            }
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '11px',
            paddingBottom: '11px',
        }
    },
    displayPassword: {
        marginLeft: '-4rem',
        marginTop: '5px',
        '& .MuiIconButton-root': {
            padding: '7px'
        }
    },
    vefifyCode: {
        '& .vi__wrapper':{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            '& input': {
                borderBottom: '2px solid transparent !important'
            }
        },
        '& .vi__character': { 
            borderRadius: '8px',
            border: `1px solid ${theme.palette.primary.main}`,
            fontSize: '24px' 
        },
        '& .vi__character--selected--default': {  
            outline: `2px solid ${theme.palette.primary.main} !important`,
            color: theme.palette.primary.main
        } 
    },
    error: { 
        '& h6': { 
            color: '#dc3545', 
            fontWeight: 'bold'
        }
    }
}));

const SignIn = (props) => {

    const classes = useStyles();
    const [data, setData] = useState({
        email: '',
        password: '',
        remember: false,
        validate: 0,
        showPassword: false,
        sms: '',
        step: 1
    })

    const {email,password, remember, showPassword, validate, step, sms} = data;

    const history = useNavigate();

    const { isLoggedIn, authAction } = props;

    React.useEffect(() => {

        function loggearse() {
            if (isLoggedIn) {
                history('/dashboard');
            }
        }
        loggearse();
    }, [isLoggedIn, history])

    const sendSmsText = async () => {
        const res = await fetch(`${config.API_SERVER}/api/twilio/admin/send`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email }),
        });

        if(res.status === 200){
            setData({
                ...data,
                step: 2
            })
        }
    };

    const handleLogin = (newValue) => {
        if(step === 1){
            sendSmsText()
        }else{
            if (email === "" || password === "") {
                setData({
                    ...data,
                    validate: 1
                })
                return;
            }
            
            authAction(email, password, remember, 0, newValue);
        }

    }

    const submitForm = (e) => {
        e.preventDefault();
        handleLogin()
     };

    const handleSmsChange = (newValue) => {
        
        //setSms(newValue);
        setData({
            ...data,
            sms: newValue
        })
        if (newValue.length === 6) handleLogin(newValue);
    };

    const handDisplayPassword = e => {
        e.preventDefault();
        setData({
            ...data,
            showPassword: !showPassword
        })
    }
    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleRememberMe = e => {
        
        setData({
            ...data,
            remember: e.target.checked
        })
    }

    const handleChange = e => {
        e.preventDefault()
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Container maxWidth="xl">
            <Grid container component="main" className={classes.root}>
                <CssBaseline />

                <Grid item xs={false} sm={4} md={6}>
                    <Box className={classes.backImage}>
                        <img src="images/header/illustration-connexion.svg" className={classes.image} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square className={classes.sgnin} >
                    <AlertMessage />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form}>
                            <TextField
                                className={(email.length === 0 && validate === 1) ? classes.errors : ""}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                //size="small"
                                onChange={(e) => handleChange(e)}
                            />
                            {(email.length === 0 && validate === 1) && (
                                <span className={classes.errorMessage}>Enter your Email</span>
                            )}
                            {
                                (step === 2) && (

                                    <Box>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <TextField
                                                className={(password.length === 0 && validate === 1) ? classes.errors : ""}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                autoComplete="current-password"
                                                value={password}
                                                //size="small"
                                                onChange={(e) => handleChange(e)}
                                            />

                                            <Box className={classes.displayPassword}>
                                                {(password.trim().length > 0) && (
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        name="showPassword"
                                                        onClick={e => handDisplayPassword(e)}
                                                        onMouseDown={e => handleMouseDownPassword(e)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box my={4}>
                                            <Box mb={2}>
                                                <Typography style={{textAlign:"center"}}>Entrez le code SMS</Typography>
                                            </Box>
                                            <Box className={classes.vefifyCode}>
                                                <VerificationInput 
                                                    value={sms}
                                                    length={6}
                                                    autoFocus={true}
                                                    onChange={handleSmsChange}
                                                    placeholder=""
                                                />
                                            </Box>
                                           
                                            {/* { 
                                                (errorMsg) && ( 
                                                    <Box className={classes.error} mt={2}> 
                                                        <Typography variant="h6">{t(`ProForm.validations.${errorMsg}`)}</Typography>
                                                    </Box>                    
                                                )
                                            } */}

                                        </Box>
                                    </Box>
                                )
                            }
                            {(password.length === 0 && validate === 1) && (
                                <span className={classes.errorMessage}>Enter your password</span>
                            )}
                            {validate === 3 && (
                                <span className={classes.errorMessage}>Username And/Or Password Incorrect</span>
                            )}

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={submitForm}
                            >
                                {
                                    step === 1 ? "Continue" : "Sign In"
                                }
                                
                            </Button>

                            <Grid container>
                                <Grid item md>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Link to="/reset-password-admin-form">
                                            Forgot password?
                                        </Link>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" />}
                                            label="Remember me"
                                            onClick={e => handleRememberMe(e)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );

}

SignIn.propTypes = {
    authAction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    //const isLoggedIn = useSelector(store => store.auth.isLoggedIn);
    isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps, { authAction })(SignIn);
