import React, { useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/Layout";
import { makeStyles } from "@material-ui/styles";
import { getAContactClient } from "../../../../store/actions/contactAction";
import TabsForm from "./components/TabsForm";

const useStyle = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "row",
        flexDirection: "column",
        alignItems: "start",
        padding: "30px",
        borderRadius: "25px",
        fontSize: "2.1rem",
        "& label": {
            fontSize: "2rem",
            color: "#7f7f7f",
        },
    },
}));

const Client = () => {
    const classes = useStyle();

    let { id } = useParams();
    const dispatch = useDispatch();

    const { access_token, client, active } = useSelector((state) => ({
        access_token: state.auth.access_token,
        client: state.contact.contact,
        active: state.alert.active,
    }));

    useEffect(() => {
        if (client === null) {
            dispatch(getAContactClient(access_token, id));
        }
    }, [dispatch, access_token, id, client]);

    return (
        <DashboardLayout>
            <Paper component="form" className={classes.root}>
                {client !== null && (
                    <Box>
                        <TabsForm token={access_token} client={client} />
                    </Box>
                )}
            </Paper>
        </DashboardLayout>
    );
};

export default Client;
