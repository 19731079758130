import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import DashboardLayout from '../../layout/Layout';
import { Box } from '@material-ui/core';
import email_template from '../../../email_template.json';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { createTemplate } from '../../../store/actions/emailTemplateAction';
import Input from '../../share/inputs/Input';
import SelectInput from '../../share/inputs/SelectInput';
import email_tamplate from '../../../email_template.json';
import { useNavigate } from 'react-router';

const CreateMailBox = () => {

    const { auth:{ access_token } } = useSelector(
        state => ({
            auth: state.auth
        })
    );

    const dispatch = useDispatch();
    const history = useNavigate();

    const emailEditorRef = useRef(null);
    const [dataTem, setDataTem] = useState();
    const [validateName, setValidateName] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        templateId: 0
    })

    const { name, subject, templateId } = formData;

    useEffect(() => {
        const arrayTem = [];
        for(let index in email_tamplate){
            
            arrayTem.push({
                id: index,
                name: email_tamplate[index].description
            })
        }
        setDataTem(arrayTem);

    }, [])

    const handleChange = (e) => {
        if(e.target.name === 'name'){
            if(e.target.value.length === 0){
                setValidateName(true)
            }else{
                setValidateName(false)
            }          
        }
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const exportHtml = (e) => {
        e.preventDefault()
        
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            const jsondata = JSON.stringify(data.design);
            saveTemplate(html, jsondata)
        });
    };    

    const saveTemplate = (html, jsondata) => {
        
        if(name.length > 0 && subject.length > 0){
            const blob = new File([html], name +'.html', {type : 'text/html;charset=utf-8'});
            dispatch(createTemplate(access_token, formData, blob, jsondata, history));
        }else{
            setValidateName(true)
        }
    }

    const loadTemplate = (e) => {
        e.preventDefault()
        setFormData({...formData, name: email_template[templateId].description})
        emailEditorRef.current.editor.loadDesign(email_template[templateId].content);
    }

    return(
        <DashboardLayout>
            <Box display="flex">
                <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" mb={3}>
                    <Box  width="28%">
                        <SelectInput
                            id="templateId"
                            name="templateId"
                            data={dataTem}
                            onChange={(e) => handleChange(e)}
                            defaultValue={templateId}
                        />      
                    </Box>
                    <Box mr={2} width="28%">
                        <Input
                            id="name"
                            placeholder=""
                            label="Name"
                            size="small"
                            width={'100%'}
                            defaultValue={name}
                            onBlur={e => handleChange(e)}
                            readOnly={false}
                            variant="outlined"
                            error={(name.length === 0 && validateName)  ? 'Requerido' : ''}
                        />
                    </Box>
                    <Box width="43%">
                        <Input
                            id="subject"
                            placeholder=""
                            label="subject"
                            size="small"
                            width={'100%'}
                            defaultValue={subject}
                            onBlur={e => handleChange(e)}
                            readOnly={false}
                            variant="outlined"
                            error={(subject.length === 0 && validateName)  ? 'Requerido' : ''}
                        />
                    </Box>                  
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-around">
                <Box mb={2}>
                    <Button variant="contained" color="primary" onClick={e => loadTemplate(e)}>Télécharger le template</Button>
                </Box>
                <Box mb={2}>
                    <Button variant="contained" color="secondary" onClick={e => exportHtml(e)}>Garder le template</Button>
                </Box>
            </Box>
            <Box>
                <EmailEditor
                    ref={emailEditorRef}                        
                    minHeight={'625px'}
                />
            </Box>
        </DashboardLayout>
  );
}

export default CreateMailBox;