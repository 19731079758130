import { combineReducers } from 'redux';
import authReducer from './authReducer';
import candidateReducer from './candidateReducer';
import alertReducer from './alertReducer';
import adminReducer from './adminReducer';
import avatarReducer from './avatarReducer';
import planningvisioReducer from './planningvisioReducer';
import emailTemplateReducer from './emailTemplateReducer';
import serviceReducer from './serviceReducer';
import reservationReducer from './reservationReducer';
import taskReducer from './taskReducer';
import taxReducer from './taxReducer';
import contactReducer from './contactReducer';
import chatReducer from './chatReducer';
import promoCodeReducer from './promoCodeReducer';

// import serviceConciergeReducer from './serviceConciergeReducer';
// import getRequests from './reservationConciergeReducer'

const rootReducer = combineReducers({
    alert: alertReducer,
    admin: adminReducer,
    auth: authReducer,
    candidate: candidateReducer,
    avatar: avatarReducer,
    planningAdmin: planningvisioReducer,
    templates: emailTemplateReducer,
    services: serviceReducer,
    reservations: reservationReducer,
    tasks: taskReducer,
    tax: taxReducer,
    contact: contactReducer,
    chat : chatReducer,
    promo: promoCodeReducer
    // serviceConcierge: serviceConciergeReducer,
    // reservationConcierge : getRequests
    

})

export default rootReducer;