import {  GET_ALL_PRO_CHAT, GET_ALL_MSG_CHAT, GET_PRO_INFO_CHAT, SEND_MESSAGE, UPDATE_MSG, ADD_MSG, COUNT_A_ZERO, ADD_IMG } from '../actions/typesAction';


const initialState = {
    
    usersChat: null,
    proInfo: null,
    messagePro: null,
    messageUpdate: null,
    img: {name:""},
   
    
}

const chatReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ALL_PRO_CHAT:
            return {
                ...state,
                usersChat: payload,
                messagePro : null
            }
        case GET_PRO_INFO_CHAT:
            return {
                ...state,
                proInfo : payload
            }
        case  GET_ALL_MSG_CHAT:
            return {
                ...state,
                    proInfo: payload.proInfo,
                    messagePro : payload.msg
            }
            case SEND_MESSAGE:
                return {
                    ...state,
                        messageEnv: payload
                       
            }
        case UPDATE_MSG:
            return {
                ...state,
                messagePro: payload
                
            }
        case COUNT_A_ZERO:
                return {
                    ...state,
                usersChat : payload
                    
            }
            case ADD_MSG:
            return {
                ...state,
                usersChat : payload
            }
        case ADD_IMG:
            return {
                ...state,
                img: payload,
                
            }
        
        default:
            return state;
    }
}

export default chatReducer;