import React, { useState, useEffect } from "react";
import {
    Avatar,
    Box,
    Checkbox,
    Fab,
    FormControlLabel,
    makeStyles,
    Typography,    
    Button,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import {
    datetoLocaleString,
    functionAddMinutesToADate,
} from "../../../share/librery/librery";
import config from "../../../../config.json";
import moment from "moment";
import { useDispatch, useStore } from "react-redux";
import Modal from '@material-ui/core/Modal';
import { setAlert } from "../../../../store/actions/alertAction";
import axios from "axios";
import ProComments from "./ProComments";

const useStyle = makeStyles((theme) => ({
    blueColor: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    evaluationArea: {
        display: "flex",
        width: "100%",
        "&>div": {
            width: "50%",
            padding: "10px 20px 0px 20px",
        },
        "@media(max-width: 960px)": {
            flexDirection: "column",
            "&>div": {
                width: "100%",
            },
        },
        "@media(max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    label: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#000",
    },
    proList: {
        padding: theme.spacing(0, 4, 4, 4),
        "@media(max-width: 600px)": {
            padding: theme.spacing(4, 1),
        },
    },
    proArea: {
        display: "flex",
    },
    avatarStyle: {
        width: "90px",
        height: "90px",
        margin: "14px -40px auto auto",
        border: "6px solid rgb(224, 248, 255)",
        "@media(max-width: 600px)": {
            margin: "-10px -55px auto auto",
            width: "80px",
            height: "80px",
        },
    },
    comments: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& h6": {
            color: "#000",
            margin: theme.spacing(2, 0),
        },
        "@media(max-width: 960px)": {
            margin: theme.spacing(2, 0),
        },
    },
    descriptionPro: {
        display: "flex",
        flexDirection: "row",
        //backgroundColor:'#F7F7F7',
        "&>div": {
            backgroundColor: "#F7F7F7",
            padding: "10px 20px 0px 20px",
            "@media(max-width: 960px)": {
                marginBottom: "20px",
            },
        },
        "@media(max-width: 960px)": {
            flexDirection: "column",
            padding: "0",
        },
    },
    elements: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "@media(max-width: 960px)": {
            width: "100%",
        },
        "& .MuiFormControlLabel-root": {
            marginBottom: "-12px",
            "@media(max-width: 960px)": {
                marginBottom: "0",
            },
        },
    },
    circleStyle: {
        width: "30px",
        height: "30px",
        minHeight: "30px",
        fontSize: "16px",
        color: "#fff",
    },
    promoStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
    },
    promoStyleNoTaxes: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "50%",
    },
    boxTariff: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& h4": {
            //color: theme.palette.primary.main,
        },
        "& h6": {
            color: "#000",
        },
        "& h5": {
            fontWeight: "normal",
        },
    },

    // style={{ width: "80px" }}
    // display="flex"
    // flexDirection="column"
    // alignItems="center"
    // p={2}

    iconLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(2),
        "& img": {
            maxWidth: "65%",
        },
    },
    buble: {
        backgroundColor: "#000",
        borderRadius: "10px",
        width: "20px",
        height: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "-12px",
        marginRight: "-25px",
        zIndex: 99,
    },
    modalPaper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const ProWithPrice = ({
    row,
    total,
    classInfo,
    fontColorClass,
    reservationStatusId,
    circleColor,
    reservationId
}) => {
    const classes = useStyle();
    const store = useStore()

    const dispatch = useDispatch()
    //const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({
        openModal: false,
        msgModal: `Voulez-vous supprimer l'enregistrement?`,
        apiType: '',
        startBtn: false,
        finishBtn: false,
        start_at: null,
        finished_at: null
    });
    const { openModal, msgModal, apiType, startBtn, finishBtn, start_at, finished_at } = data

    useEffect(() => {
        if(reservationStatusId === '4'){
            const request = store.getState().reservations.request.profile_reservation;
            if(request.length > 0){
                
                setData(data => {
                    return {
                        ...data,
                        start_at: request[0].pro_select_time[0].start_at,
                        finished_at: request[0].pro_select_time[0].finished_at
                    }
                })
            }
        }
    },[store, reservationStatusId])

    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }
    
    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();
    
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = useState(getModalStyle);

    const handleClose = (e) => {
        e.preventDefault()
        setData({
            ...data,
            openModal: false
        })
    }

    const startApi = async () => {
        try {
            const token = store.getState().auth.access_token;

            const dateMoment = moment(new Date());
            const startAt = dateMoment.format("YYYY-MM-DD HH:mm");

            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };

            const dataApi = {
                prId: row.prId,
                start_at: startAt,
            }
            
            await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/startWork`, dataApi, headers)
            .then((res) => {
                
                if(res.data === 'success'){
                    setData({
                        openModal: false,
                        apiType: '',
                        startBtn: true,
                        start_at: startAt,
                    })
                    dispatch(setAlert("L'activité a commencé", 'success'))    
                }

            })
            .catch(error => {
                setData({
                    openModal: false,
                    apiType: ''
                })
                dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
            })
            
        } catch (error) {
            setData({
                openModal: false,
                apiType: ''
            })
            dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
        }

        
    }

    const finishApi = async () => {
        try {
            const token = store.getState().auth.access_token;
            const proSelected = store.getState().reservations.request.pendingPros.filter((row) => row.reservation_status_id === 4)

            const prId = proSelected[0].prId
            const proId = proSelected[0].proId
            const clientId = store.getState().reservations.request.client.id
    
            const dateMoment = moment(new Date());
            const finishAt = dateMoment.format("YYYY-MM-DD HH:mm");
            
            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };
    
            const f = new FormData()
            f.append("id", prId);
            f.append("clientId", clientId);
            f.append("litigate", true);
            f.append("proId", proId);
            f.append("lag", 'fr');
    
            await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/finishWork`, f, headers)
            .then(res => {
                setData({
                    ...data,
                    openModal: false,
                    apiType: '',
                    startBtn: true,
                    start_at: finishAt,
                    finishBtn: true,
                    finished_at: finishAt
                })
                dispatch(setAlert("L'activité est terminée", 'success'))  
            })
            .catch(error => {
                setData({
                    openModal: false,
                    apiType: ''
                })
                dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
            })
    
        } catch (error) {
            setData({
                openModal: false,
                apiType: ''
            })
            dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
        }

    }

    const offerCancelByAdmin = async () => {
        try{
            const token = store.getState().auth.access_token;

            const headers = {
                headers: {'Authorization': `Bearer ${token}`}
            }
    
            const data = {
                "reservationId": reservationId
            }
            
            await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/removeRequest`, data, headers)
            .then((res) => {
                dispatch(setAlert("La réservation a été annulée", 'error'))
                setData({
                    openModal: false,
                    apiType: ''
                })
                
            })
            .catch((error) => {
                console.log('Error: ', error.response.data.error)
                setData({
                    openModal: false,
                    apiType: ''
                })
                dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
            })        
    
        }catch(error){
            setData({
                openModal: false,
                apiType: ''
            })
            dispatch(setAlert("Il y a eu une erreur dans l'application", 'error'))
        }
    }

    const apiRun = (e) => {
        e.preventDefault();

        if(apiType === "Start"){
            startApi()
        }else if(apiType === "Finish"){
            finishApi()
        }else if(apiType === "Cancel") {
            offerCancelByAdmin()
        }
    }

    const startActivity = (e) => {
        e.preventDefault()
        setData({
            openModal: true,
            msgModal: 'Voulez-vous commencer la réservation ?',
            apiType: 'Start'
        })
    }

    const finishActivity = (e) => {
        e.preventDefault()
        setData({
            openModal: true,
            msgModal: 'Voulez-vous terminer la réservation ?',
            apiType: 'Finish'
        })
    }

    const cancelActivity = (e) => {
        e.preventDefault()
        setData({
            openModal: true,
            msgModal: 'Voulez-vous annuler la réservation ?',
            apiType: 'Cancel'
        })
    }

    const body = (
        <div style={modalStyle} className={classes.modalPaper}>
          <h2 style={{ fontSize: "22px", marginBottom: '30px' }} >{msgModal}</h2>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.deleteBtn}
              onClick={e => apiRun(e)}
            >
              Oui
            </Button>
    
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.deleteBtn}
              onClick={e => handleClose(e)}
            >
              Non
            </Button>
          </Box>
        </div>
    );

    return (
        <Box mb={2}>
                <Modal
                        open={openModal}
                        onClose={e => handleClose(e)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            <Box key={row.proId} className={classes.proList}>
                <Box className={classes.proArea}>
                    <Avatar
                        className={classes.avatarStyle}
                        //alt={`${pro.firstName} ${pro.lastName}`}
                        src={row.avatar}
                    />

                    <Box style={{ width: "100%", height: "100%" }}>
                        <Box className={classInfo}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Box className={classes.proInfoEvaluation} ml={3}>
                                        <Box>
                                            <Typography
                                                className={fontColorClass}
                                                variant="h5"
                                            >{`${row.firstName} ${row.lastName}`}</Typography>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <Typography variant="h5">
                                                    {row.statistics.stars === null ? 0 : row.statistics.stars}
                                                </Typography>
                                            </Box>
                                            <Box mr={1}>
                                                <StarIcon
                                                    style={{
                                                        color:
                                                            reservationStatusId ===
                                                            "1"
                                                                ? "#2880fb"
                                                                : "#32cc8c",
                                                    }}
                                                />
                                            </Box>

                                            <Box>
                                                <Typography variant="h6">
                                                    Evaluation moyenne
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            ml={3}
                                        >
                                            <Box mr={1}>
                                                <Typography
                                                    className={fontColorClass}
                                                    variant="h5"
                                                >
                                                    {row.statistics.achieves}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h6">
                                                    Prestation(s) réalisée(s)
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Box mr={1}>
                                                <Typography
                                                    className={fontColorClass}
                                                    variant="h5"
                                                >
                                                    {row.comments}
                                                </Typography>
                                            </Box>
                                            <ProComments
                                                statusId = { reservationStatusId }
                                                display={
                                                    1
                                                }
                                                pro={
                                                    {
                                                        comment: row.comment,
                                                        statistics: row.statistics
                                                    }
                                                }
                                            />
                                        </Box>

                                        <Box className={classes.comments}>
                                            <Box
                                                display="flex"
                                                className={
                                                    reservationStatusId === "1"
                                                        ? classes.marksColorBlue
                                                        : classes.marksColorGreen
                                                }
                                            >
                                                <div>"</div>
                                                <p>
                                                    {row.self_description}
                                                    <span>"</span>
                                                </p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                    >
                                        <Box className={classes.iconLabel}>
                                            <Box className={classes.buble}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {row.statistics.punctual === null ? 0 : row.statistics.punctual}
                                                </Typography>
                                            </Box>
                                            <img
                                                id="image"
                                                alt=""
                                                src="/images/hourglass.png"
                                            />
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "1.4rem",
                                                }}
                                            >
                                                Ponctuel
                                            </Typography>
                                        </Box>
                                        <Box className={classes.iconLabel}>
                                            <Box className={classes.buble}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {row.statistics.professional === null ? 0 : row.statistics.professional}
                                                </Typography>
                                            </Box>
                                            <img
                                                id="image"
                                                alt=""
                                                src="/images/Ok.png"
                                            />
                                            <Typography variant="h6" style={{fontSize: "1.4rem"}}>Professionnel</Typography>
                                        </Box>
                                        <Box className={classes.iconLabel}>
                                            <Box className={classes.buble}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {row.statistics.sociable === null ? 0 : row.statistics.sociable}
                                                </Typography>
                                            </Box>
                                            <img
                                                id="image"
                                                alt=""
                                                src="/images/sociable.png"
                                            />
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "1.4rem",
                                                }}
                                            >
                                                Sociable
                                            </Typography>
                                        </Box>
                                        <Box className={classes.iconLabel}>
                                            <Box className={classes.buble}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {row.statistics.super_clean === null ? 0 : row.statistics.super_clean}
                                                </Typography>
                                            </Box>
                                            <img
                                                id="image"
                                                alt=""
                                                src="/images/sponge.png"
                                            />
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "1.4rem",
                                                }}
                                            >
                                                Clean
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={1}>


                            <Box
                                    className={[classes.descriptionPro, { width: '100%' }]}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                >
                                    <Box className={classes.elements}>
                                        <Box>
                                            <Box display="flex" alignItems="center" mb={2}>
                                                <Box mr={1} className={circleColor}>
                                                    <Fab
                                                        className={classes.circleStyle}
                                                        size="small"
                                                        aria-label="add"
                                                    >
                                                        1
                                                    </Fab>

                                                </Box>
                                                <Typography variant="h5">Proposition de service</Typography>
                                            </Box>
                                            <Box mb={4}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography
                                                        variant="h6"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Date de la prestation
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        className={fontColorClass}
                                                    >
                                                        {datetoLocaleString(
                                                            `${row.week_date}`,
                                                            'fr'
                                                        )}

                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography
                                                        variant="h6"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Heure de début
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        className={fontColorClass}
                                                    >
                                                        {row.pro_start_time
                                                            .slice(0, -3)
                                                            .replace(":", "h")}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography
                                                        variant="h6"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Heure de fin
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        className={fontColorClass}
                                                    >
                                                        {functionAddMinutesToADate(
                                                            `${row.week_date}T${row.pro_start_time}`,
                                                            row.pro_duration
                                                        )
                                                            .slice(0, -3)
                                                            .replace(":", "h")}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box
                                                mt={2}
                                                className={
                                                    reservationStatusId === "1"
                                                        ? classes.chechBoxStyle
                                                        : classes.chechBoxStyle +
                                                        " " +
                                                        classes.chechBoxBack
                                                }
                                            >
                                                <Box>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="workAlone"
                                                                color="primary"
                                                                checked={true}
                                                            />
                                                        }
                                                        label={
                                                            row.is_group === 1
                                                                ?
                                                                "Nous travaillons en duo"
                                                                :
                                                                "Je travaille seul"
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className={classes.elements}>
                                        <Box>
                                            <Box>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={2}
                                                >
                                                    <Box mr={1} className={circleColor}>
                                                        <Fab
                                                            className={classes.circleStyle}
                                                            size="small"
                                                            aria-label="add"
                                                        >
                                                            2
                                                        </Fab>
                                                    </Box>
                                                    <Typography variant="h5">
                                                        Tarif du todoo
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box
                                                className={
                                                    !row.proTax
                                                        ? classes.promoStyleNoTaxes
                                                        : classes.promoStyle
                                                }
                                            >
                                                <Box
                                                    className={classes.boxTariff}
                                                >
                                                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                                        Prix de la prestation
                                                    </Typography>
                                                    <Typography variant="h6" className={fontColorClass}>
                                                        {row.total_price} $
                                                    </Typography>
                                                </Box>

                                                {(total.tps > 0 || total.tvq > 0) && (
                                                    <>
                                                        <Box>
                                                            <Box>
                                                                <Typography variant="h5">
                                                                    Taxes
                                                                </Typography>
                                                            </Box>
                                                            {total.tps > 0 && (
                                                                <Box className={classes.boxTariff}>
                                                                    <Typography variant="h6">
                                                                        TPS (
                                                                        {config.TPS}%)
                                                                    </Typography>
                                                                    <Typography variant="h6">{`${row.pro_work_price !== null
                                                                        ? total.tps
                                                                        : 0
                                                                        } $`}</Typography>
                                                                </Box>
                                                            )}
                                                            {total.tvq > 0 && (
                                                                <Box className={classes.boxTariff}>
                                                                    <Typography variant="h6">
                                                                        TVQ (
                                                                        {config.TVQ}%)
                                                                    </Typography>
                                                                    <Typography variant="h6">{`${row.pro_work_price !== null
                                                                        ? total.tvq
                                                                        : 0
                                                                        } $`}</Typography>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </>
                                                )}

                                                <Box className={classes.boxTariff} mt={2} mb={3}>
                                                    <Typography className={fontColorClass} variant="h4">
                                                        TOTAL
                                                    </Typography>
                                                    <Typography className={fontColorClass} variant="h4">
                                                        {`${total.total} $`}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>

                            </Box>
                            {(reservationStatusId === '4') && (
                                <Box mt={3} display="flex" flexDirection="row">
                                    <Box mr={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{width: '130px'}}
                                            disabled={startBtn || start_at}
                                            onClick={e => startActivity(e)}
                                        >
                                            Commencer
                                        </Button>
                                    </Box>
                                    <Box mr={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            style={{width: '130px', color: '#fff'}}
                                            disabled={finishBtn || finished_at}
                                            onClick={e => finishActivity(e)}
                                        >
                                            FINIR
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{width: '130px', backgroundColor: 'white', color: 'red'}}
                                            disabled={finishBtn || finished_at}
                                            onClick={e => cancelActivity(e)}
                                        >
                                            ANNULLER
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ProWithPrice;
