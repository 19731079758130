
import React from 'react'
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
//import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
//import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux'
import { logOutAction } from '../../store/actions/authAction';
import AdminMenu from './AdminMenu';
import AlertMessage from './AlertMessage';

const drawerWidth = 0;
//const elWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    padding: '0 4rem', // keep right padding when drawer closed       0 2.5rem
    height: '10.6rem',
    '@media (max-width:1366px)': {
      height: '9rem',
    },

  },
  appBar: {
    position: 'fixed',
    top: '0',
    zIndex: theme.zIndex.drawer + 5,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: '#cfe3fe',
    backgroundColor: '#fff',
    boxShadow: '0px 9px 12px -11px rgba(0,0,0,0.6)'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    color: "#707070",
    fontWeight: 'bold',
    marginLeft: '20rem',
    fontSize: "2rem",
    '@media (max-width:1366px)': {
      fontSize: "1.8rem",
    },
  },
  userNotification: {
    display: 'flex',
    marginRight: '0'

  },
  userNotificationMargin: {
    marginRight: '200px',
    '@media (max-width:1400px)': {
      marginRight: '10px'
    }

  },
  userDescription: {
    marginLeft: '0.5rem',
  },
  user: {
    display: "flex",
    padding: '1.2rem',
    alignItems: 'center',
    '& h4': {
      fontSize: '1.8rem',
      color: "#707070",
      fontWeight: 'bold',
      display: 'block',
      '@media (max-width:1366px)': {
        fontSize: "1.7rem",
      },
    },
    '& h5': {
      color: "#707070",
      fontWeight: 'bold',
      display: 'block',
      marginTop: '-5px',
      marginLeft: '-4px'
    }
  },
  logo: {
    width: '21.5rem',
    '&.path ': {
      fill: 'red'
    },
    '@media (max-width:1500px)': {
      width: '16.5rem',
    },
    '@media (max-width:1366px)': {
      width: '14.5rem',
    }
  }
}));

const Header = (props) => {
  const { firstName, lastName, position, urlAvatar, logOutAction, auth: { access_token } } = props; //lastName
  const [open] = React.useState(true);
  const classes = useStyles();
  //const dispatch = useDispatch()
  const history = useNavigate();

  const logOut = (e) => {
    e.preventDefault();
    logOutAction(access_token);
    localStorage.clear();
    history('/');
  }

  return (
    <div>
      <AppBar position="static" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <AlertMessage />
        <Toolbar className={classes.toolbar}>
          <img className={classes.logo} src="images/header/logo-blanc.svg" alt="" />

          <Typography component="h1" variant="h6" noWrap className={classes.title}>
            
          </Typography>
          <Box className={classes.userNotification}>
            <Box className={classes.user}>
              <Avatar alt={`${firstName} ${lastName}`} src={urlAvatar} />
              <Box className={classes.userDescription}>
                <Typography component="h4">
                  {`${firstName} ${lastName}`}
                </Typography>
                <Typography component="h5">
                  {position}
                </Typography>
              </Box>
              <Box ml={1}>
                <AdminMenu logOut={logOut} />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logOutAction })(Header);