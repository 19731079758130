import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LeadClientTable from './Tables/LeadClientTable';
import { getDateFormat } from '../share/librery/librery';
import axios from 'axios';

/*
let rows = [
    { id: 1, firstName: 'Snow', lastName: 'Jon', email: 'snow@hotmail.com'},
    { id: 2, firstName: 'Cersei', lastName: 'Lannister', email: 'lannister21@gmail.com'},
    { id: 3, firstName: 'Jaime', lastName: 'Lannister', email: 'jaime@mail.ocm'},
    { id: 4, firstName: 'Stark', lastName: 'Arya', email: 'arya@gmail.com'},
    { id: 5, firstName: 'Targaryen', lastName: 'Daenerys', email: 'daene@hotmaila.com' },
    { id: 6, firstName: 'Melisandre', lastName: 'Melis', email: 'melis@yahoo.es' },
    { id: 7, firstName: 'Clifford', lastName: 'Ferrara', email: 'ferrara@hotmail.com'},
    { id: 8, firstName: 'Frances', lastName: 'Rossini', email: 'rossini@hotmaila.com'},
    { id: 9, firstName: 'Roxie', lastName: 'Harvey', email: 'harvey@sierra.com'},
    { id: 10, firstName: 'Jonier', lastName: 'Muriullo', email: 'jonierm@gmail.com' },
    { id: 11, firstName: 'Damien', lastName: 'Silvain', email: 'damien@gmail.com'},
    { id: 12, firstName: 'Carlos', lastName: 'Salamanca', email: 'salamancaa@gmail.com'},                         
    { id: 13, firstName: 'Diana', lastName: 'Nino', email: 'dianani@latam.com'},
    { id: 14, firstName: 'Henry', lastName: 'Nielsen', email: 'henry@mcnano.com'},
    { id: 15, firstName: 'Robinson', lastName: 'Cabezas', email: 'robinson@mcnano.com'},
    { id: 17, firstName: 'Luis Fernando', lastName: 'Mejia', email: 'lmejia@odonto.com'},
    { id: 18, firstName: 'Melquiades', lastName: 'Nino', email: 'mequi@mgmail.com'},
    { id: 19, firstName: 'Sara', lastName: 'Hollmes', email: 'sara@mgmail.com'},
    { id: 20, firstName: 'Sebastian', lastName: 'Camacho', email: 'smejia@mgmail.com'},
    { id: 21, firstName: 'Lara', lastName: 'Mejia', email: 'lara.m@hotmail.com' },
    { id: 22, firstName: 'Lara', lastName: 'Gallo', email: 'lara.m@hotmail.com' },
    { id: 23, firstName: 'Melisandre', lastName: 'Melis', email: 'melis@yahoo.es'},

];
*/
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: "flex",
    justifyContent: 'row',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '30px',
    borderRadius: '25px'
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridSize: {
    height: '80%',
    width: '100%'
  },
  title: {
    fontSize: '3.8rem',
    fontWeight: 'bold',
    marginLeft: '1.4rem'
  },
  divider: {
    height: '2px',
    backgroundColor: 'black',
    width: '100%'
  }
}))

const LeadClient = () => {
  const classes = useStyles();
  const [searchfirstname, setSearchfirstname] = useState('');
  const [searchemail, setSearchemail] = useState('');
  //const [searchtelephone, setSearchtelephone] = useState('');

  //It is part of the API no delete.
  const [rows, setRows] = useState([]);

  const headCells = [
    { id: 'firstname', numeric: false, disablePadding: true, label: 'First Name', searchOption: true },
    { id: 'lag', numeric: false, disablePadding: true, label: 'Langage', searchOption: false },
    { id: 'email', numeric: false, disablePadding: true, label: 'Mail', searchOption: true },
    { id: 'created_at', numeric: false, disablePadding: true, label: 'Created', searchOption: true },
    //{ id: 'telephone', numeric: false, disablePadding: true, label: 'Phone Number', searchOption: true },
  ];

  const token = process.env.REACT_APP_LANDING_PAGE_API_KEY;

  useEffect(() => {
    const headers = {
      headers: { 'Authorization': `Bearer ${token}` }
    }      
    
    axios.get(`https://api.tiggidoo.com/api/clients`, headers).then((res) => {
      setRows(res.data);
    }).catch((error) => {
      console.error(error)
    })
  }, [token])

  const deleteClient = (id) => {

    const data = {
      id: id
    }
    const headers = {
      headers: { 'Authorization': `Bearer ${token}` }
    }
    axios.post('https://api.tiggidoo.com/api/remove', data, headers)
      .then((res) => {

        let removeItem = rows.filter(item => item.id !== id)
        setRows(removeItem);

      }).catch((error) => {
        console.error(error)
      })
  }


  const createData = (id, firstname, lag, mail, created_at) => {
    return { id, firstname, lag, mail, created_at};
  }


  const filter = () => {

    let filterRows = rows.filter((row) =>
      row.firstName.toString().toLowerCase().indexOf(searchfirstname.toString().toLowerCase()) > -1
    );

    filterRows = filterRows.filter((row) =>
      row.email.toString().toLowerCase().indexOf(searchemail.toString().toLowerCase()) > -1
    );
    /*
      filterRows = filterRows.filter((row) =>
            row.telephone.toString().toLowerCase().indexOf(searchtelephone.toString().toLowerCase()) > -1
      );
    */
    return filterRows.map((r) =>
      createData(r.id, r.firstName, r.lag, r.email, getDateFormat(r.created_at))
    )
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchfirstname':
        setSearchfirstname(value);
        break;
      case 'searchemail':
        setSearchemail(value);
        break;
      /*
          case 'searchtelephone':
              setSearchtelephone(value);      
            break;
      */
      default:
        break;
    }
  }

  return (
    <DashboardLayout>
      <Paper component="form" className={classes.root} >
        <Typography component="h1" variant="h6" noWrap className={classes.title}>
          Lead Client
            </Typography>
        <Divider className={classes.divider} />
        <Box className={classes.gridSize}>
          <LeadClientTable
            rows={filter()}
            headCells={headCells}
            onChangeSearch={onChangeSearch}
            deleteClient={deleteClient}
          />
        </Box>
      </Paper>
    </DashboardLayout>
  )
}

export default LeadClient;