import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyle = makeStyles((theme) => ({
    shadow: {
        boxShadow: '-1px 4px 6px 3px #80808047',
    },
    inputWidth: {
        //marginRight: '15px',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px'
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '11px',
            paddingBottom: '11px',
        },
        '& .MuiFilledInput-underline.Mui-error:after':{
            border: '0 solid #f44336'  
        },
        '& .MuiFormLabel-root.Mui-error':{
            color: '#7f7f7f'
        }
    },
    errors: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#dc3545',
                borderRadius: '4px'
            }
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #dc3545',
        },
        '& label': {
            color: '#dc3545',
        }
    },
    errorMessage: {
        color: '#dc3545',
        paddingTop: '5px',
        fontSize: '1.6rem',
    }

}))


const InputCustomPhone = ({ name, label, country, value, disableCountryCode, disableAreaCodes , disableDropdown, onChange, variant, size, width, error, readOnly }) => {
    const classes = useStyle();

    const handleChange = e => {
        onChange(e);
    }

    let customizedClass = classes.inputWidth ;
    if (variant === 'outlined') {
        customizedClass = customizedClass + ' ' + classes.shadow;
    }

    if (error.length > 0) {
        customizedClass = customizedClass + ' ' + classes.errors;
    }

    let readOnlyPro = false;
    if (readOnly !== undefined) {
        if (readOnly) {
            readOnlyPro = true;
        }
    }

    return (
        <Box display="flex" flexDirection="column" mr={1} style={{ width: width }}>
            <PhoneInput
                name = {name}
                specialLabel={label}
                onlyCountries={ ['ca'] }
                placeholder='1(123) 456-7890'
                value={value }         
                disableCountryCode={disableCountryCode}
                disableDropdown={disableDropdown}
                onChange={ handleChange }
                disabled={readOnlyPro}
                // InputProps={{
                //     readOnly: readOnlyPro,
                // }}
                // masks={{ca: '.(...) ... .. ..'}}
                className={customizedClass}
            />
            {(error.length > 0) && (
                <span className={classes.errorMessage}>{error}</span>
            )}
        </Box>
    )
}



export default InputCustomPhone
