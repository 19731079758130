import axios from 'axios';
//import { setAlert } from './alertAction';
import { GET_CANDIDATES_VISIO, PROCESSING, ADMIN_FAIL, INTERVIEW_DATE_EXISTS } from './typesAction';
import { logOutTokenExpired } from './authAction';
import config from '../../config.json';

export const cleanPlanningVisio = () => async dispatch => {
    dispatch({
        type: PROCESSING,
    })
}

export const interviewDateExists = () => async dispatch => {
    dispatch({
        type: INTERVIEW_DATE_EXISTS,
    })
}

export const getCandidatesPlanningVisio = (token, adminId, apiId) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING,
        })
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        let apiNom;
        if(apiId === 0){
            apiNom = `${config.API_SERVER}/api/v1/admin/calendar/super/${adminId}`
        }else{
            apiNom = `${config.API_SERVER}/api/v1/admin/calendar/show`
        }

        await axios.get(apiNom, headers)
        .then(res => {
            if (res.status === 200) {
                dispatch({
                    type: GET_CANDIDATES_VISIO,
                    payload: { candidates: res.data }
                })
            }
        }).catch((error) => {
            console.log(error);
            // localStorage.clear();
            // dispatch(logOutTokenExpired());
        });

    } catch (error) {
        console.log(error)
        dispatch({
            type: ADMIN_FAIL,
        })
    }
}

export const getAdminAgenda = (email) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING,
        });

        await axios.get(`${config.API_SERVER}/api/getAdminCalendar/${email}`)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_CANDIDATES_VISIO,
                        payload: { candidates: res.data }
                    })
                }
            }).catch((error) => {
                console.log(error)
                dispatch({
                    type: ADMIN_FAIL,
                })
            });
    } catch (error) {
        console.log(error)
        dispatch({
            type: ADMIN_FAIL,
        })
    }
}