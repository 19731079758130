import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from './TableComponents/TablePaginationActions';
import { Link } from 'react-router-dom';
import { getTelephoneFormat } from '../../share/librery/Regex'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells, onChangeSearch, filter } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const filterRows = (e) => {
    e.preventDefault();
    filter();
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box mb={2}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </Box>

          </TableCell>
        ))}
      </TableRow>

      <TableRow style={{ backgroundColor: '#e0dfdf' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}

          >
            {headCell.searchOption ? (
              <TextField
                placeholder="Recherche"
                name={`search${headCell.id}`}
                size="small"
                //InputProps={{ startAdornment: <SearchIcon /> }}
                variant="outlined"
                onBlur={e => onChangeSearch(e)}
              />
            ) : null}
          </TableCell>
        ))}
        <TableCell>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={e => filterRows(e)}
          >
            RECHERCHER
          </Button>
        </TableCell>
      </TableRow>


    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onChangeSearch: PropTypes.func.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Nutrition
          </Typography>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  
  root: {
    width: '100%',
    '& .MuiTableCell-root': {
      borderBottom: 0,
      fontSize: '2.3rem',
      color: '#7f7f7f',
      padding: '1.5rem',
      '@media (max-width:1500px)': { 
          fontSize: "1.8rem",
        },
      '@media (max-width:1366px)': { 
          fontSize: "1.6rem",
          padding: '10px 8px',
        }
    },
    '& .MuiTableHead-root': {
      marginBottom: '2rem'
    },
    '& input': {
      backgroundColor: '#fff',
      // border: '1px solid rgba(0,0,0, 1)',
      borderRadius: '4px',
    },
    '& input:hover': {
      // border: '1px solid transparent',
    },
    '& .MuiInputBase-root': {

    }
  },
  paper: {
    width: '100%',
    '& .MuiAvatar-root':{
      width: '35px',
      height: '35px',
    }
    //boxShadow: '0px 2px 1px -1px rgba(255,255,255,0), 0px 1px 1px 0px rgba(255,255,255,0), 0px 2px 1px 0px rgba(0,0,0,0.2)'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchOptionth: {
    paddingLeft: '0'
  },
  modalPaper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusStyle:{    
    fontSize:"1.4rem", 
    color:"white", 
    width: '10.4rem', 
    height: '3.6rem', 
    fontWeight: 'bold', 
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", 
    borderRadius:"4px",
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    backgroundColor:'#5087f5'
  },
  "@keyframes blinker": {
    "50%": {
      opacity: 0
    }
  },
  btnLocked:{    
    fontSize:"1.4rem", 
    color:"white", 
    width: '10.4rem', 
    height: '3.6rem', 
    fontWeight: 'bold', 
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)", 
    borderRadius:"4px",
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    backgroundColor:'#28cc8b',
    textAlign:'center',
    //animation: '$blinker 1s linear infinite',
    
  },
  statusColorPending:{
    backgroundColor:'#f49e3a', 
  },
  statusColorValidate:{
    backgroundColor:'#3ad640', 
  },
  statusColorRefused:{
    backgroundColor:'#f05c5c', 
  },
  
}));

export default function ClientTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //The data came from the API
  const { rows, headCells, onChangeSearch, filter, permit } = props;
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  

  const emptyRows = ((rows.length <= rowsPerPage) ? rows.length : rowsPerPage) - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              onChangeSearch={onChangeSearch}
              filter={filter}
            />
            <TableBody>
              {
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${row.id}`;

                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.uuid}
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.firstName}
                        </TableCell>
                        <TableCell align="left" padding="none">
                          {row.lastName}
                        </TableCell>
                        <TableCell align="left" padding="none">
                          {  getTelephoneFormat(row.telephone) }
                        </TableCell>
                        <TableCell align="left" padding="none">
                          {row.city}
                        </TableCell>
                        <TableCell align="left" padding="none">
                          {
                            row.active === 0
                            ?
                              (<Box>Active</Box>)
                            :
                              row.active === null
                              ?
                                (<Box>Inactive</Box>)
                              :
                                (<Box>{`Inactive ${row.active}J`}</Box>)
                          }
                        </TableCell>

                        <TableCell align="left" padding="none">
                          <Box>
                              <Box display="flex" justifyContent={permit.contacts_delete_pro === 1 ? "space-between" : "center"} alignItems="center">
                                <Link to={`/contact/client/${row.id}`} >
                                  <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #2880fb' }}>
                                    <ArrowForwardOutlinedIcon style={{ color: '#2880fb' }} />
                                  </Avatar>
                                </Link>
                              </Box>
                          </Box>                          
                        </TableCell>
                      </TableRow>
                    );
                  }
                  )
              }
              {
                emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )
              }

            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

    </div>
  );
}