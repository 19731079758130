import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import Input from '../../../../share/inputs/Input'
import { getTelephoneFormat } from '../../../../share/librery/Regex'

import { updateTelephoneClient } from '../../../../../store/actions/contactAction';
import { useDispatch } from 'react-redux';


const InfoClient = ({ info, token }) => {   

    const [telephone, setTelephone] = useState(info.telephone)
    const dispatch = useDispatch()

    const updateTelephone = e => {
        e.preventDefault()
        console.log(token, info.id, telephone)
        dispatch(updateTelephoneClient(token, info.id, telephone))

    }

    const handleChange = e => {
        e.preventDefault()
        setTelephone(e.target.value)
    }

    return (
        <Box>
            <Box display="flex" flexDirection="column">
                <Box mb={4}> 
                    <Input
                        id="lastName"
                        placeholder=""
                        label="Nom Client"
                        size="small"
                        defaultValue={info.last_name}
                        //onBlur={e => handleChange(e)}
                        width="100%"
                        error=""
                        readOnly={true}
                    />
                </Box>
                <Box mb={4}> 
                    <Input
                        id="firstName"
                        placeholder=""
                        label="Prénom Client"
                        size="small"
                        defaultValue={info.first_name}
                        //onBlur={e => handleChange(e)}
                        width="100%"
                        error=""
                        readOnly={true}

                    />
                </Box>
                <Box mb={4}> 
                    <Input
                        id="email"
                        placeholder=""
                        label="Courriel Client"
                        size="small"
                        defaultValue={info.email}
                        //onBlur={e => handleChange(e)}
                        width="100%"
                        error=""
                        readOnly={true}

                    />
                </Box>
                <Box mb={4}> 
                    <Box display="flex">
                        <Input
                            id="telephone"
                            placeholder=""
                            label="Clellulaire Client"
                            size="small"
                            defaultValue={getTelephoneFormat(telephone)}
                            onBlur={e => handleChange(e)}
                            width="50%"
                            error=""
                            readOnly={false}
                        />
                        <Button variant="contained" color="primary" onClick={ e => updateTelephone(e) }>UPDATE TELEPHONE</Button>
                    </Box>    
                </Box>
                <Box mb={4}> 
                    <Input
                        id="city"
                        placeholder=""
                        label="Ville"
                        size="small"
                        defaultValue={info.address.city}
                        //onBlur={e => handleChange(e)}
                        width="100%"
                        error=""
                        readOnly={true}
                    />
                </Box>
            </Box>            
        </Box>
    )
}

export default InfoClient
