import React from 'react';
import { Avatar, Box, makeStyles, Typography, Link } from '@material-ui/core';
//import classes from '*.module.css';

const useStyle = makeStyles((theme) => ({
    adminArea:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#cfe3fe', 
        padding: '16px 0', 
        marginRight: '48px'
    },
    linkStyle : {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h5':{
            fontSize: '1.3rem',
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        }
    },
    selected: {
        border: '2px solid #32cc8c'
    }
}))

const CardPro = ($props) => {
    const classes = useStyle();
    const { id, firstName, lastName, urlImg, getAdminId, selected } = {...$props}

    let defaultClass = classes.adminArea;
    if(selected){
        defaultClass = defaultClass + ' ' + classes.selected;
    }

    const runAdminId = (e, idmin) => {
        e.preventDefault()
        getAdminId(idmin);
    }

    return(
        <Box className={defaultClass}>
            <Link to="#" onClick={e=>runAdminId(e, id)} className={ classes.linkStyle } >
                <Box mb={2}>
                    <Avatar>
                        <img src={urlImg} alt="" />
                    </Avatar>
                </Box>
                <Box>
                    <Typography component="h5">
                        {firstName} {lastName}
                    </Typography>
                </Box>          
            </Link>
        </Box>
    );
}

export default CardPro;