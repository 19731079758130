import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsAccepterPro from "./RequestsAccepterPro";

const DashboardRequestAccepterPro = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsAccepterPro />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestAccepterPro;
