import axios from 'axios';
import config from '../../config.json';
import { setAlert } from './alertAction';
import { PROCESSING, CREATE_A_NEW_TAX, GET_LIST_OF_TAXES } from './typesAction';


export const createTax = (token, formData) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            country: formData.country,
            province: formData.province,
            TPS: formData.tps,
            TVQ: formData.tvq
        }
        console.log('Date: --- ', data)

        await axios.post(`${config.API_SERVER}/api/v1/admin/tax/create`, data, headers)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_A_NEW_TAX,
                        payload: res.data
                    })
                    dispatch(getListOfTaxes(token))

                    dispatch(setAlert('La tax a été créé avec succès', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}


export const getListOfTaxes = (token) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/tax/all`, headers)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_LIST_OF_TAXES,
                        payload: res.data
                    })
                    //dispatch(setAlert('Le service a été créé avec succès', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}

export const removeTaxOfList = (token, id) => async dispatch => {
    try {
        
        const data = {
            taxId: id
        }
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/tax/remove`, data,  headers)
        .then(res => {
            if (res.status === 200) {
                console.log('Eliminacion: ', res)
                dispatch(getListOfTaxes(token))
                dispatch(setAlert('La taxe a été supprimée', 'success'))
            }
        })
        .catch(error => {
            console.log(error)
        })

    } catch (error) {
        console.log(error)
    }
}

export const updateTaxOfList = (token, formData) => async dispatch => {
    try {
        
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            taxId: formData.id,
            country: formData.country,
            province: formData.province,
            TPS: formData.tps,
            TVQ: formData.tvq
        }
        console.log('Levantaron al policia: ', data)
        await axios.post(`${config.API_SERVER}/api/v1/admin/tax/update`, data,  headers)
        .then(res => {
            if (res.status === 200) {
                console.log('Resultado: ', res)
                dispatch(getListOfTaxes(token))
                dispatch(setAlert('La taxe a été mise à jour', 'success'))
            }
        })
        .catch(error => {
            console.log(error)
        })

    } catch (error) {
        console.log(error)
    }
}
