import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import CalendarForm from './CalendarForm';
import BookingConfirmation from './BookingConfirmation';
import { Box } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from '../../layout/AlertMessage';
import { updateCandidateVisioDateProgrammedAction } from '../../../store/actions/candidateAction';
import { getAdminAgenda } from '../../../store/actions/planningvisioAction';
import { setAlert, resetAlert } from '../../../store/actions/alertAction';

import { getDate, get24HrsFromAMPM, getAMPMFrom24Hrs, getTimeToBlockCalendar, convertTimeFromMontrealToCityX } from '../../share/librery/librery';
import Processing from '../Processing';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link to="#" color="inherit" href="https://www.tiggidoo.com/">
        {new Date().getFullYear()} Tiggidoo
      </Link>{' '}
      {' © All rights reserved'}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Choisissez un rendez-vous', 'Confirmation'];

function getStepContent(step, timeNotAvailable, daysBlocked, selectedDate, onChangeDate, selectedTime, setSelectedTime, validateTime, setValidateTime, dayAgenda, diff_hours) {

  switch (step) {
    case 0:
      return <CalendarForm
        timeNotAvailable={timeNotAvailable}
        selectedDate={selectedDate}
        onChangeDate={onChangeDate}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        validateTime={validateTime}
        setValidateTime={setValidateTime}
        dayAgenda={dayAgenda}
        daysBlocked={daysBlocked}
        diff_hours={diff_hours}

      />;
    case 1:
      return (
        <BookingConfirmation
          selectedDate={selectedDate}
          selectedTime={selectedTime}
        />
      )
    default:
      throw new Error('Unknown step');
  }
}

const BookingInterview = () => {

  const { alertMsg, adminAgenda, diff_hours, interviewCandidateExists } = useSelector(
    state => ({
      alertMsg: state.alert,
      adminAgenda: state.planningAdmin.adminCandidates,
      diff_hours: parseInt(state.planningAdmin.adminCandidates.diff_hours, 10),
      interviewCandidateExists: state.planningAdmin.interviewCandidateExists
    })
  )
  const dispatch = useDispatch();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(moment().add('days', 1));
  let [selectedTime, setSelectedTime] = useState(null);
  const [validateTime, setValidateTime] = useState(false);
  const [daysBlocked, setDaysBlocked] = useState([]);
  const [agenda, setAgenda] = useState(null);
  const [timeNotAvailable, setTimeNotAvailable] = React.useState([]);
  let dayAgenda = null;

  let history = useNavigate();

  let { email } = useParams();
  email = decodeURIComponent(email);

  useEffect(() => {
    if (adminAgenda.length === 0) {
      dispatch(getAdminAgenda(email));
    } else {

      const candidates = adminAgenda.candidates;
      if (candidates.length > 0) {

        const getTimeNotAvailable = getTimeToBlockCalendar(adminAgenda.unavailability);
        let TimeNotAvailable = [];
        if (getTimeNotAvailable[1] !== undefined) {
          TimeNotAvailable = getTimeNotAvailable[1];
        }

        /** Check if there are some candidate with interview and block the time */
        for (let index in candidates) {

          if (candidates[index].interviewTime !== null) {

            const timeHour12 = candidates[index].interviewTime.replace(" ", 'T');
            const timeArray = timeHour12.split('T');
            const timeString12hr = getAMPMFrom24Hrs(timeHour12);
            if (TimeNotAvailable[timeArray[0]] !== undefined) {
              TimeNotAvailable[timeArray[0]].push(timeString12hr);
            } else {
              TimeNotAvailable[timeArray[0]] = [timeString12hr];
            }
          }

        }
        getTimeNotAvailable[1] = TimeNotAvailable;

        const getNumberDay = (nameDay) => {
          switch (nameDay) {
            case 'Mo':
              return 1;
            case 'Tu':
              return 2;
            case 'We':
              return 3;
            case 'Th':
              return 4;
            case 'Fr':
              return 5;
            case 'Sa':
              return 6;
            case 'Su':
              return 0;
            default:
              break;
          }
        }

        const agenda = adminAgenda.availability;
        //dayBlocked take the days when the admin is not working for example: Saturday and Saunday "start hour = 0 and end hour = 0"
        const daysBlocked = [];
        for (let index in agenda) {
          if (index !== 'id') {
            if (parseInt(agenda[index].split('-')[0]) === 0 && parseInt(agenda[index].split('-')[1]) === 0) {
              daysBlocked.push(getNumberDay(index));
            }
          }
        }

        setDaysBlocked(daysBlocked);
        setTimeNotAvailable(getTimeNotAvailable);
        setAgenda(adminAgenda.availability)

      }

    }
  }, [email, adminAgenda, dispatch])

  const validateDay = (day) => {
    const numDay = new Date(day).getDay();
    if (daysBlocked.indexOf(numDay) < 0) {
      if (alertMsg.length > 0) {
        dispatch(resetAlert());
      }
    } else {
      if (alertMsg.length === 0) {
        setSelectedTime(null);
        dispatch(setAlert("Le jour sélectionné n'est pas programmé", "error"));
      }
    }
  }
  /**
   * 
   * @param {Object} day is chosen by the user in the calendar. The information is given by Moment React Plugin
   */
  const onChangeDate = (day) => {

    let numWeeks = 4;
    let now = new Date();
    now.setDate(now.getDate() + numWeeks * 7); //SEMANAS
    validateDay(day);
    setSelectedDate(day);

  }

  const handleNext = () => {

    if (activeStep === 0) {
      if (selectedTime === null) {
        setValidateTime(true);
        return false;
      }
    }

    if (activeStep === 1) {
      const dateVisio = getDate(selectedDate) + ' ' + get24HrsFromAMPM(selectedTime);
      dispatch(updateCandidateVisioDateProgrammedAction(email, dateVisio));

    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const changeDate = (e) => {
    e.preventDefault();
    history(`/reschedule/${email}`);
  }

  if (agenda !== null) {
    dayAgenda = convertTimeFromMontrealToCityX(selectedDate, agenda);
  }

  return (
    <React.Fragment>
      <AlertMessage />
      <CssBaseline />
      {
        (agenda === null && dayAgenda === null)
          ?
          (<Processing />)
          :
          (
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <Box display="flex" justifyContent="center" mb={5} mt={1}>
                  <img src="images/logo_tiggidoo.svg" alt="" width="250" />
                </Box>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <React.Fragment>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                        Merci,
                      </Typography>

                      {
                        (interviewCandidateExists)
                          ?
                          (
                            <Box>
                              <Typography variant="subtitle1">Vous avez déjà une date assignée pour votre entretien. Cliquez sur le bouton pour modifier la date de votre entretien</Typography>
                              <Box mt={2}>
                                <Button variant="contained" color="secondary" onClick={e => changeDate(e)}>Modifier</Button>
                              </Box>
                            </Box>

                          )
                          :
                          (
                            <Typography variant="subtitle1">Une confirmation du rendez-vous a été envoyée par courriel.</Typography>
                          )
                      }


                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {getStepContent(activeStep, timeNotAvailable, daysBlocked, selectedDate, onChangeDate, selectedTime, setSelectedTime, validateTime, setValidateTime, dayAgenda, diff_hours)}
                      <div className={classes.buttons}>
                        {activeStep !== 0 && (
                          <Button onClick={handleBack} className={classes.button}>
                            Retour
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? 'Réservez votre entretien' : 'Suivant'}
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Paper>
              <Copyright />
            </main>
          )
      }
    </React.Fragment>
  );
}

export default BookingInterview;