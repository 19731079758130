import React, { useEffect, useState } from 'react'
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import PaperUser from '../../../share/layout/PaperUser'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { calculateTpsTvqAndTotal } from '../../../share/librery/librery'

import ProWithPrice from './ProWithPrice'
import ProNoProcie from './ProNoProcie'

const useStyle = makeStyles((theme) => ({

    blueColor: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    evaluationArea: {
        display: "flex",
        width: "100%",
        "&>div": {
            width: "50%",
            padding: "10px 20px 0px 20px",
        },
        "@media(max-width: 960px)": {
            flexDirection: "column",
            "&>div": {
                width: "100%",
            },
        },
        "@media(max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    gridEvaluationStyle: {
        width: "100%",
        padding: "0 8px",
        "@media(max-width: 600px)": {
            flexWrap: "unset",
            flexDirection: "column",
        },
        "& h5": {
            lineHeight: 1,
            //color: theme.palette.primary.main,
            fontSize: "16px",
        },
        "& h6": {
            fontSize: "14px",
        },
        "& svg": {
            marginTop: "-5px",
            fontSize: "1.7rem",
            //color: theme.palette.primary.main
        },
    },
    nameAndEvaluation: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    areaPro: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        marginBottom: theme.spacing(3)
    },
    areaProEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        marginBottom: theme.spacing(3)
    },
    iconStyle: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '17px',
        color: theme.palette.primary.main
    },
    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000'
    },
    infoPro: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        marginTop: '-80px',
        '& h4': {
            fontWeight: 'bold',
        }
    },
    orangeColor: {
        color: '#fc933a',
    },
    greenColor: {
        color: theme.palette.secondary.main
    },
    labelActivityDate: {
        color: '#808389'
    },
    btnPro: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '13px',
        '@media(max-width: 1366px)': {
            fontSize: '11px',
        }
    },
    btnDelete: {
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: '#f2060f',
        fontSize: '13px',
        '@media(max-width: 1366px)': {
            fontSize: '11px',
        }
    },
    divTextArea: {
        width: '65%',
        '@media(max-width: 1366px)': {
            width: '63%'
        }
    },
    textArea: {
        width: '100%',
        border: '1px solid #000',
        padding: '10px'
    },
    infoPrix: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#e4e4e4',
        borderRadius: '15px',
        //marginRight: '30px',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2, 3)
    },
    proList: {
        padding: theme.spacing(0, 4, 4, 4),
        "@media(max-width: 600px)": {
            padding: theme.spacing(4, 1),
        },
    },
    info: {
        padding: theme.spacing(3),
        boxShadow: "5px 6px 10px #00000025",
        borderRadius: "23px",
        "@media(max-width: 600px)": {
            //padding: theme.spacing(2,0,0,0),
            boxShadow: "3px 6px 10px #00000075",
        },
    },
    proArea: {
        display: "flex",
    },
    border: {
        border: `3px solid ${theme.palette.primary.main}`,
    },
    borderGreen: {
        border: `3px solid ${theme.palette.secondary.main}`,
    },
    borderOrange: {
        border: `3px solid #fc933a`,
    },
    avatarStyle: {
        width: "90px",
        height: "90px",
        margin: "14px -40px auto auto",
        border: "6px solid #fff",
        "@media(max-width: 600px)": {
            margin: "-10px -55px auto auto",
            width: "80px",
            height: "80px",
        },
    },
    comments: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& h6": {
            color: "#000",
            margin: theme.spacing(2, 0),
        },
        "@media(max-width: 960px)": {
            margin: theme.spacing(2, 0),
        },
    },
    descriptionPro: {
        display: "flex",
        flexDirection: "row",
        //backgroundColor:'#F7F7F7',
        "&>div": {
            backgroundColor: "#F7F7F7",
            padding: "10px 20px 0px 20px",
            "@media(max-width: 960px)": {
                marginBottom: "20px",
            },
        },
        "@media(max-width: 960px)": {
            flexDirection: "column",
            padding: "0",
        },
    },
    elements: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "@media(max-width: 960px)": {
            width: "100%",
        },
        "& .MuiFormControlLabel-root": {
            marginBottom: "-12px",
            "@media(max-width: 960px)": {
                marginBottom: "0",
            },
        },
    },
    circleStyle: {
        width: "30px",
        height: "30px",
        minHeight: "30px",
        fontSize: "16px",
        color: "#fff",
    },
    totalStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "50%",
    },
    promoStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
    },
    promoStyleNoTaxes: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "50%",
    },
    boxTariff: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& h4": {
            //color: theme.palette.primary.main,
        },
        "& h6": {
            color: "#000",
        },
        "& h5": {
            fontWeight: "normal",
        },
    },
    circleColorOrange: {
        '& .MuiButtonBase-root': {
            backgroundColor: '#fc933a'
        }
    },
    circleColorGreen: {
        '& .MuiButtonBase-root': {
            backgroundColor: theme.palette.secondary.main
        }
    }
}))

const PendingPro = ({ pendingPros, base_price, reservationStatusId, sendSelectPro, removeProByAdmin, reservationId }) => {
    const classes = useStyle()
    const [open, setOpen] = useState(false)
    const [displayPro, setDisplayPro] = useState([])
    const [pros, setPros] = useState([])
    const [comment, setComment] = useState()

    useEffect(() => {
        let array = pendingPros
        if (reservationStatusId === '4') {
            array = pendingPros.filter(row => row.reservation_status_id === 4)
            setDisplayPro([array[0].proId])
        }
        setPros(array)

    }, [pendingPros, reservationStatusId])

    const displayReservation = (e, proId) => {
        e.preventDefault()
        const listPro = displayPro
        listPro.push(proId)

        setDisplayPro(listPro)
        setOpen(!open)
    }

    const hideReservation = (e, proId) => {
        e.preventDefault()
        const listPro = displayPro
        const data = listPro.filter(row => row !== proId)

        setDisplayPro(data)
        setOpen(!open)
    }

    const sendSelectProFun = (e, prId) => {
        e.preventDefault()
        sendSelectPro(prId, comment);
    }

    const removePro = (e, prId) => {
        e.preventDefault()
        removeProByAdmin(prId)
    }

    let classInfo = classes.info;
    if (displayPro.length > 0) {
        classInfo = classInfo + " " + classes.border;
    }

    let fontColorClass = classes.orangeColor;
    let circleColor = classes.circleColorOrange
    if (reservationStatusId === "4") {
        fontColorClass = classes.greenColor;
        classInfo = classInfo + " " + classes.borderGreen;
        circleColor = classes.circleColorGreen
    }

    if (reservationStatusId === "2") {
        classInfo = classInfo + " " + classes.borderOrange;
    }
    
    return (
        <Box>
            {
                pros.map((row, index) => {

                    const total = calculateTpsTvqAndTotal(
                        parseFloat(row.pro_work_price, 10),
                        row.pro_vacuum_price === null || row.pro_vacuum_price === undefined
                            ? 0
                            : parseInt(row.pro_vacuum_price, 10),
                        row.pro_product_ecological_price === null ||
                            row.pro_product_ecological_price === undefined
                            ? 0
                            : parseInt(row.pro_product_ecological_price, 10),
                        row.pro_product_standard_price === null ||
                            row.pro_product_standard_price === undefined
                            ? 0
                            : parseInt(row.pro_product_standard_price, 10),
                        1
                    );

                    const price = row.reservation_status_id === 1 ? base_price : row.pro_work_price
                    //&& (reservationStatusId === '2' || reservationStatusId === '4')

                    let displayRowInfo = true
                    if (reservationStatusId === '4') {
                        displayRowInfo = false
                    }

                    if (reservationStatusId === '2') {
                        if (displayPro.length > 0) {
                            if (displayPro.indexOf(row.proId) >= 0) {
                                displayRowInfo = false
                            }
                        }
                    }

                    return (
                        <PaperUser key={index} backgroundColor={row.reservation_status_id === 1 ? '#FFF' : '#e0f8ff'}>
                            <Box className={(displayRowInfo) ? classes.areaPro : classes.areaProEnd}>

                                {
                                    displayRowInfo
                                        ?
                                        <Box display="flex" alignItems="center">
                                            <Box mr={3} >
                                                <Avatar alt="Profile Picture" src={row.avatar} style={{ width: '60px', height: '60px' }} />
                                            </Box>
                                            <Box >
                                                <Typography variant="h5">{`${row.firstName}-${row.lastName}`}</Typography>
                                            </Box>
                                        </Box>
                                        :
                                        null
                                }
                                <Box display="flex" alignItems="center">

                                    {
                                        (displayPro.indexOf(row.proId) < 0) && (
                                            <Box mr={3}>
                                                <Box>
                                                    <Typography variant="h5">TARIF DE L'OFFRE</Typography>
                                                </Box>
                                                <Box textAlign="right">
                                                    <Typography variant="h5">{`${(row.reservation_status_id === 1) ? '0' : price} $`}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }

                                    {
                                        reservationStatusId !== '4'
                                            ?
                                                (row.reservation_status_id > 1)
                                                    ?
                                                    (displayPro.indexOf(row.proId) >= 0)
                                                        ?
                                                        (<KeyboardArrowUpIcon className={classes.iconStyle} onClick={e => hideReservation(e, row.proId)} />)
                                                        :
                                                        (<KeyboardArrowDownIcon className={classes.iconStyle} onClick={e => displayReservation(e, row.proId)} />)

                                                    :
                                                    null
                                            : null
                                    }
                                </Box>

                            </Box>
                            {
                                (displayPro.indexOf(row.proId) >= 0)
                                    ?
                                    (reservationStatusId === '2' || reservationStatusId === '4')
                                        ?
                                        (
                                            <Box>
                                                <ProWithPrice
                                                    row={row}
                                                    total={total}
                                                    classInfo={classInfo}
                                                    fontColorClass={fontColorClass}
                                                    reservationStatusId={reservationStatusId}
                                                    circleColor={circleColor}
                                                    reservationId={reservationId}
                                                />
                                            </Box>

                                        )
                                        :
                                        (
                                            <ProNoProcie
                                                row={row}
                                                total={total}
                                                reservationStatusId={reservationStatusId}
                                                setComment={setComment}
                                                sendSelectProFun={sendSelectProFun}
                                                removePro={removePro}
                                            />
                                        )
                                    :
                                    null
                            }
                        </PaperUser>
                    )
                })
            }
        </Box>
    )
}

export default PendingPro