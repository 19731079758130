import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useSelector } from "react-redux";
import config from "../../../../config.json";
import axios from "axios";

const columns = [
    { field: "id", headerName: "id", width: 150 },
    { field: "Code_Postal", headerName: "Code Postal", width: 150 },

    {
        field: "Ville",
        headerName: "Ville",
        width: 150,
        editable: true,
    },
    {
        field: "Province",
        headerName: "Province",
        width: 150,
        editable: true,
    },
    {
        field: "Pays",
        headerName: "Pays",
        width: 150,
        editable: true,
    },
];
const rows = [
    {
        id: 1,
        Code_Postal: "H1A",
        Ville: "Pointe-aux-Trembles",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 2,
        Code_Postal: "H2A",
        Ville: "Saint-Michel Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 3,
        Code_Postal: "H3A",
        Ville: "Centre-ville de Montréal Nord (Université McGill)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 4,
        Code_Postal: "H4A",
        Ville: "Notre-Dame-de-Grâce, Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 5,
        Code_Postal: "H5A",
        Ville: "Place Bonaventure",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 6,
        Code_Postal: "H7A",
        Ville: "Duvernay-Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 7,
        Code_Postal: "H9A",
        Ville: "Dollard-Des-Ormeaux Nord-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 8,
        Code_Postal: "H1B",
        Ville: "Montréal-Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 9,
        Code_Postal: "H2B",
        Ville: "Ahuntsic Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 10,
        Code_Postal: "H3B",
        Ville: "Centre-ville de Montréal Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 11,
        Code_Postal: "H4B",
        Ville: "Notre-Dame-de-Grâce Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 12,
        Code_Postal: "H5B",
        Ville: "Complexe Desjardins",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 13,
        Code_Postal: "H6B",
        Ville: "Ahuntsic Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 14,
        Code_Postal: "H7B",
        Ville: "Saint-François",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 15,
        Code_Postal: "H9B",
        Ville: "Dollard-Des-Ormeaux Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 16,
        Code_Postal: "H1C",
        Ville: "Rivière-des-Prairies Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 17,
        Code_Postal: "H2C",
        Ville: " Ahuntsic Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 18,
        Code_Postal: "H3C",
        Ville: "Griffintown1 (Incluant Île Notre-Dame & Île Sainte-Hélène)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 19,
        Code_Postal: "H4C",
        Ville: "Saint-Henri",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 21,
        Code_Postal: "H7C",
        Ville: " Saint-Vincent-de-Paul",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 22,
        Code_Postal: "H9C",
        Ville: " LÎle-Bizard Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 23,
        Code_Postal: "H1E",
        Ville: " Rivière-des-Prairies Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 24,
        Code_Postal: "H2E",
        Ville: "Villeray Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 25,
        Code_Postal: "H3E",
        Ville: "Île des Sœurs",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 26,
        Code_Postal: "H4E",
        Ville: "Ville Émard",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 27,
        Code_Postal: "H7E",
        Ville: "Duvernay",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 28,
        Code_Postal: "H9E",
        Ville: "LÎle-Bizard Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 29,
        Code_Postal: "H1G",
        Ville: "Montréal-Nord Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 30,
        Code_Postal: "H2G",
        Ville: "Petite-Patrie Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 31,
        Code_Postal: "H3G",
        Ville: "Centre-ville de Montréal Sud-est (Université Concordia)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 32,
        Code_Postal: "H4G",
        Ville: "Verdun Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 33,
        Code_Postal: "H7G",
        Ville: "Pont-Viau",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 34,
        Code_Postal: "H9G",
        Ville: " Dollard-Des-Ormeaux Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 35,
        Code_Postal: "H0H",
        Ville: "Réservé (Père Noël)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 36,
        Code_Postal: "H1H",
        Ville: "Montréal-Nord Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 37,
        Code_Postal: "H2H",
        Ville: "Plateau Mont-Royal Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 38,
        Code_Postal: "H3H",
        Ville: "Centre-ville de Montréal Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 39,
        Code_Postal: "H4H",
        Ville: "Verdun Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 40,
        Code_Postal: "H7H",
        Ville: "Auteuil Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 41,
        Code_Postal: "H9H",
        Ville: "Sainte-Geneviève Pierrefonds Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 42,
        Code_Postal: "H1J",
        Ville: "Anjou Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 43,
        Code_Postal: "H2J",
        Ville: "Plateau Mont-Royal Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 44,
        Code_Postal: "H3J",
        Ville: "Petite-Bourgogne",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 45,
        Code_Postal: "H4J",
        Ville: "Cartierville Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 46,
        Code_Postal: "H7J",
        Ville: "Auteuil Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 47,
        Code_Postal: "H9J",
        Ville: "Kirkland Pierrefonds Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 48,
        Code_Postal: "H1K",
        Ville: "Anjou Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 49,
        Code_Postal: "H2K",
        Ville: "Centre-Sud Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 50,
        Code_Postal: "H3K",
        Ville: "Pointe Saint-Charles",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 51,
        Code_Postal: "H4K",
        Ville: "Cartierville Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 52,
        Code_Postal: "H7K",
        Ville: "Auteuil Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 53,
        Code_Postal: "H9K",
        Ville: "Senneville",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 54,
        Code_Postal: "H1L",
        Ville: "Mercier-Est Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 55,
        Code_Postal: "H2L",
        Ville: "Centre-Sud Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 56,
        Code_Postal: "H3L",
        Ville: "Ahuntsic Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 57,
        Code_Postal: "H4L",
        Ville: "Saint-Laurent Intérieur Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 58,
        Code_Postal: "H7L",
        Ville: "Sainte-Rose",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 59,
        Code_Postal: "H0M",
        Ville: "Région dAkwesasne (Akwesasne)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 60,
        Code_Postal: "H1M",
        Ville: "Mercier-Ouest Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 61,
        Code_Postal: "H2M",
        Ville: "Ahuntsic Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 62,
        Code_Postal: "H3M",
        Ville: "Cartierville Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 63,
        Code_Postal: "H4M",
        Ville: "Saint-Laurent Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 64,
        Code_Postal: "H7M",
        Ville: "Vimont",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 65,
        Code_Postal: "H9M",
        Ville: "",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 66,
        Code_Postal: "H1N",
        Ville: "Mercier-Ouest Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 67,
        Code_Postal: "H2N",
        Ville: "Ahuntsic Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 68,
        Code_Postal: "H3N",
        Ville: "Parc-Extension",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 69,
        Code_Postal: "H4N",
        Ville: "Saint-Laurent Extérieur Nord-est Intérieur Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 70,
        Code_Postal: "H7N",
        Ville: "Laval-des-Rapides",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 71,
        Code_Postal: "H8N",
        Ville: "LaSalle Nord-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 72,
        Code_Postal: "H1P",
        Ville: "Saint-Léonard Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 73,
        Code_Postal: "H2P",
        Ville: "Villeray Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 74,
        Code_Postal: "H3P",
        Ville: "Mont-Royal Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 75,
        Code_Postal: "H4P",
        Ville: " Mont-Royal Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 76,
        Code_Postal: "H7P",
        Ville: "Fabreville",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 77,
        Code_Postal: "H8P",
        Ville: "LaSalle Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 78,
        Code_Postal: "H9P",
        Ville: "Dorval Extérieur",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 79,
        Code_Postal: "H1R",
        Ville: "Saint-Léonard Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 80,
        Code_Postal: "H2R",
        Ville: "Villeray Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 81,
        Code_Postal: "H3R",
        Ville: " Mont-Royal Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 82,
        Code_Postal: "H4R",
        Ville: " Saint-Laurent Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 83,
        Code_Postal: "H7R",
        Ville: " Laval-sur-le-Lac",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 84,
        Code_Postal: "H8R",
        Ville: "Ville Saint-Pierre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 85,
        Code_Postal: "H9R",
        Ville: "Pointe-Claire",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 86,
        Code_Postal: "H1S",
        Ville: "Saint-Léonard Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 87,
        Code_Postal: "H2S",
        Ville: "Petite-Patrie Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 88,
        Code_Postal: "H3S",
        Ville: "Côte-des-Neiges Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 89,
        Code_Postal: "H4S",
        Ville: " Saint-Laurent Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 90,
        Code_Postal: "H7S",
        Ville: " Chomedey Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 91,
        Code_Postal: "H8S",
        Ville: "Lachine Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 92,
        Code_Postal: "H9S",
        Ville: "LÎle-Dorval Dorval Sud Pointe-Claire Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 93,
        Code_Postal: "H1T",
        Ville: "Rosemont Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 94,
        Code_Postal: "H2T",
        Ville: " Plateau Mont-Royal Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 95,
        Code_Postal: "H3T",
        Ville: "Côte-des-Neiges Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 96,
        Code_Postal: "H4T",
        Ville: "Saint-Laurent Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 97,
        Code_Postal: "H7T",
        Ville: " Chomedey Nord-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 98,
        Code_Postal: "H8T",
        Ville: "Lachine Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 99,
        Code_Postal: "H9T",
        Ville: "Dorval Extérieur",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 100,
        Code_Postal: "H1V",
        Ville: "Maisonneuve",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 101,
        Code_Postal: "H2V",
        Ville: " Outremont",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 102,
        Code_Postal: "H3V",
        Ville: "Côte-des-Neiges Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 103,
        Code_Postal: "H4V",
        Ville: "Côte-Saint-Luc Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 104,
        Code_Postal: "H7V",
        Ville: " Chomedey Est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 105,
        Code_Postal: "H1W",
        Ville: "Hochelaga",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 106,
        Code_Postal: "H2W",
        Ville: " Plateau Mont-Royal Sud Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 107,
        Code_Postal: "H3W",
        Ville: " Côte-des-Neiges Sud-ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 108,
        Code_Postal: "H4W",
        Ville: " Côte-Saint-Luc Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 109,
        Code_Postal: "H7W",
        Ville: "  Chomedey Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 110,
        Code_Postal: "H9W",
        Ville: "Beaconsfield",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 111,
        Code_Postal: "H1X",
        Ville: "Rosemont Centre",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 112,
        Code_Postal: "H2X",
        Ville: "Plateau Mont-Royal Sud-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 113,
        Code_Postal: "H3X",
        Ville: "Hampstead",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 114,
        Code_Postal: "H4X",
        Ville: "Montréal-Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 115,
        Code_Postal: "H7X",
        Ville: "Sainte-Dorothée",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 116,
        Code_Postal: "H9X",
        Ville: "Sainte-Anne-de-Bellevue Baie-dUrfé Senneville",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 117,
        Code_Postal: "H1Y",
        Ville: "Rosemont  Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 118,
        Code_Postal: "H2Y",
        Ville: "Vieux-Montréal",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 119,
        Code_Postal: "H3Y",
        Ville: "Westmount Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 120,
        Code_Postal: "H4Y",
        Ville: "Dorval Centre (YUL)",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 121,
        Code_Postal: "H7Y",
        Ville: "Îles-Laval",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 122,
        Code_Postal: "H8Y",
        Ville: "Roxboro",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 123,
        Code_Postal: "H1Z",
        Ville: "Saint-Michel Ouest",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 124,
        Code_Postal: "H2Z",
        Ville: "Centre-ville de Montréal Nord-est",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 125,
        Code_Postal: "H3Z",
        Ville: "Westmount Sud",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 126,
        Code_Postal: "H4Z",
        Ville: "Tour de la Bourse",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 127,
        Code_Postal: "H8Z",
        Ville: "Pierrefonds Nord",
        Province: "Québec",
        Pays: "Canada",
    },
    {
        id: 128,
        Code_Postal: "J5A",
        Ville: "Saint-Constant",
        Province: "Québec",
        Pays: "Canada",
    },
];

export default function PostalCodeC() {
    const [selectionModel, setSelectionModel] = useState(null);

    const { token } = useSelector((state) => ({
        token: state.auth.access_token,
    }));

    useEffect(() => {
        try {
            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };
            if (selectionModel === null) {
                axios
                    .get(
                        `${config.API_SERVER}/api/v1/admin/postcode/all`,
                        headers
                    )
                    .then((res) => {
                        const createArrayPostCode = []
                        res.data.forEach((e) => {

                            const getPostCode = rows.filter(row => row.Code_Postal === e.postcode)
                            createArrayPostCode.push(getPostCode[0].id)

                            // setSelectionModel((selectionModel) => [
                            //     ...selectionModel,
                            //     rows
                            //         .filter((r) => r.Code_Postal === e.postcode)
                            //         .map((r) => r.id),
                            // ]);
                        });
                        setSelectionModel(createArrayPostCode)
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        } catch (error) {
            console.log(error);
        }
    }, [selectionModel, token]);

    if(selectionModel === null){
        return false
    }
    const getClickedCode = async (row, checked, PostalCode, token) => {
        
        //const hasCode = selectionModel.filter(r => r[0] === row.id)
        const hasCode = selectionModel.indexOf(row.id)
        
        if (hasCode === -1) {
            try {
                const headers = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                const data = {
                    postcode: row.Code_Postal,
                };
                axios
                    .post(
                        `${config.API_SERVER}/api/v1/admin/postcode/create`,
                        data,
                        headers
                    )
                    .then((res) => {
                        setSelectionModel([
                            ...selectionModel,
                            row.id
                        ])
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (error) {
                console.log(error);
            }
        } else {
            
            try {
                const headers = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                
                axios
                    .get(
                        `${config.API_SERVER}/api/v1/admin/postcode/all`,
                        headers
                    )
                    .then((res) => {
                        
                        let tab = res.data.filter((e) => {
                            if (e["postcode"].includes(row.Code_Postal)) {
                                return e;
                            }
                        });

                        tab.forEach((e) => {
                            handleDelete(e["id"], row.id);
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };
    const handleDelete = async (id, postCodeId) => {
        try {
            const headers = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const data = {
                id: id,
            };

            axios
                .post(
                    `${config.API_SERVER}/api/v1/admin/postcode/delete`,
                    data,
                    headers
                )
                .then((res) => {
                    const getPostCode = selectionModel.filter(row => row !== postCodeId)
                    setSelectionModel(getPostCode)
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DataGrid
            key={rows.id}
            rows={rows}
            columns={columns}
            pageSize={10}
            onCellClick={(e) =>
                getClickedCode(e.row, e.value, e.row.Code_Postal, token)
            }
            selectionModel={selectionModel}
            checkboxSelection
            //disableSelectionOnClick
        />
    );
}
