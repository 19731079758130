import React, { useState } from 'react'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import Filter from './Filter'
import Response from './Response'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterPro, sendReservation } from '../../../../store/actions/reservationAction'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyle = makeStyles((theme) => ({
    filterAndReponse: {
        backgroundColor: '#c4e4fa'
    },
    responseArea: {
        '& .leaflet-container': {
            width: '30vw', //'35vw',
            height: '25vh' //'51vh'
        }
    },
    iconStyle: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '17px',
        color: theme.palette.primary.main
    },
    filterArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#c4e4fa',
        '& h4': {
            fontWeight: 'bold'
        }
    }
}))

const funTime = time => {

    const a = {
        mo_am: false,
        mo_pm: false,
        tu_am: false,
        tu_pm: false,
        we_am: false,
        we_pm: false,
        th_am: false,
        th_pm: false,
        fr_am: false,
        fr_pm: false,
        sa_am: false,
        sa_pm: false,
        su_am: false,
        su_pm: false
    }

    for (let index in time) {
        if (time[index].period === 1) {
            a[`${time[index].week_day.toLowerCase()}_am`] = true
        } else if (time[index].period === 2) {
            a[`${time[index].week_day.toLowerCase()}_pm`] = true
        } else {
            a[`${time[index].week_day.toLowerCase()}_am`] = true
            a[`${time[index].week_day.toLowerCase()}_pm`] = true
        }
    }

    return a

}

const FilterAndReponse = ({ reservationId, address, personalization, reservationStatusId, duration, startDate, optionDate }) => {
    const classes = useStyle()

    const { auth: { access_token }, client, time } = useSelector(
        state => ({
            auth: state.auth,
            client: state.reservations.request.client,
            time: state.reservations.request.housework.time
        })
    )

    const dispatch = useDispatch()
    const [open, setOpen] = useState(reservationStatusId === 'null' ? false : true);
    const [proChosen, setProChosen] = useState([])
    const [arrayTokensNot, setArrayTokensNot] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        postalCode: address.postcode,
        vacuum: personalization.vacuum === 0 ? false : true,
        fridge: personalization.fridge === 0 ? false : true,
        oven: personalization.oven === 0 ? false : true,
        productStandard: personalization.product_standard === 0 ? false : true,
        productEcological: personalization.product_ecological === 0 ? false : true,
        bed: personalization.bed === 0 ? false : true,
        dog: personalization.dog === 0 ? false : true,
        cat: personalization.cat === 0 ? false : true,
        outOfTheZone: false,
        timeClient: funTime(time),
        serviceDay: funTime(time)
    })

    const handleChosenPro = (e, push_token, new_request) => {

        if (e.target.name !== undefined) {
            const gIndex = proChosen.indexOf(parseInt(e.target.name))
            if (gIndex === -1) {
                setProChosen([...proChosen, parseInt(e.target.name)])
            } else {
                let array = []
                array = proChosen.filter(item => item !== parseInt(e.target.name))
                setProChosen(array)
            }
            if (new_request !== '1') {
                if (push_token !== '') {
                    const indexToken = arrayTokensNot.indexOf(push_token)
                    if (indexToken === -1) {
                        setArrayTokensNot([...arrayTokensNot, push_token])
                    } else {
                        let arrayToken = []
                        arrayToken = arrayTokensNot.filter(item => item !== push_token)
                        setArrayTokensNot(arrayToken)
                    }
                }
            }
        }

    }

    const handleChange = (e) => {
        if (e.target.type === 'checkbox') {

            let arr = e.target.name
            arr = arr.split('_')

            if (arr.length === 1) {
                setFormData({ ...formData, [e.target.name]: e.target.checked })
            } else {
                const { timeClient } = formData
                timeClient[e.target.name] = !timeClient[e.target.name]
                setFormData({ ...formData, 'timeClient': timeClient })
            }
        } else {
            e.preventDefault()
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const filter = (e) => {
        e.preventDefault()
        dispatch(getFilterPro(access_token, formData, reservationId, duration, time))
    }

    // async function sendPushNotification(expoPushToken) {
    //     try {
    //         const message = {
    //             to: `ExponentPushToken[${expoPushToken}]`,
    //             sound: 'default',
    //             title: 'Original Title',
    //             body: 'And here is the body!'
    //         };

    //         const content = JSON.stringify(message)
    //         const respuesta = await fetch('https://exp.host/--/api/v2/push/send', {
    //             method: 'POST',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Accept-encoding': 'gzip, deflate',
    //                 'Content-Type': 'application/json',
    //             },
    //             body: content,
    //         });
    //     } catch (error) {
    //         console.log('Error: ', error)
    //     }
        
    // }

    const sendRequest = (e) => {
        e.preventDefault()
        dispatch(sendReservation(access_token, proChosen, reservationId)) //, arrayTokensNot
            //.then((res => {
                // console.log('Esta es la respuesta: ', res)

                // const tokenNots = {
                //     "to": "ExponentPushToken[" + arrayTokensNot + "]",
                //     "sound": "default",
                //     "body": "Vous avez reçu une nouvelle demande de ménage dans votre espace pro. Détails…",
                //     "title": "Une nouvelle demande"
                // }
                // const content = JSON.stringify(tokenNots)
                // console.log('Plata: ', content)
                // await fetch('https://exp.host/--/api/v2/push/send', {
                //     method: 'POST',
                //     headers: {
                //         Accept: 'application/json',
                //         'Accept-encoding': 'gzip, deflate',
                //         'Content-Type': 'application/json',
                //     },
                //     body: content,
                // });

                // console.log('Res', res)
                // if(res === 200){
                //     sendPushNotification(arrayTokensNot)
                // }
            //}))
    }

    const hideOption = (e) => {
        e.preventDefault()
        setOpen(!open)
    }
    
    return (
        <Box mb={5}>
            <Box className={classes.filterArea} px={5} py={2}>
                <Typography variant="h4" color="primary">TODOO</Typography>
                {
                    (open)
                        ?
                        (<KeyboardArrowDownIcon className={classes.iconStyle} onClick={e => hideOption(e)} />)
                        :
                        (<KeyboardArrowUpIcon className={classes.iconStyle} onClick={e => hideOption(e)} />)
                }
            </Box>
            <Box hidden={open} >
                <Box>
                    <Box className={classes.filterAndReponse} px={5} pb={2}>
                        <Filter
                            formData={formData}
                            handleChange={handleChange}
                            filter={filter}
                            time={formData.timeClient}
                            serviceDay={formData.serviceDay}
                            personalization={personalization}
                        />
                    </Box>

                    <Box className={classes.responseArea} pl={5} pt={2} mt={3}>
                        <Response
                            postCode={address.postcode}
                            client={client}
                            proChosen={proChosen}
                            handleChosenPro={handleChosenPro}
                            access_token={access_token}
                            reservationId={reservationId}
                            startDate={startDate}
                        />
                    </Box>

                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" p={5}>
                    <Button variant="contained" color="secondary" style={{ backgroundColor: '#c92545', color: 'white' }}>SUPPRIMER LA REQUÊTE</Button>
                    <Button variant="contained" color="secondary" style={{ color: 'white' }} onClick={e => sendRequest(e)}>ASSIGNER LA REQUÊTE</Button>
                    <Button variant="contained" color="primary">CONTACTER CLIENT</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default FilterAndReponse