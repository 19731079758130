import React from 'react'
import { Avatar, Box, Checkbox, Fab, FormControlLabel, makeStyles, Typography, TextareaAutosize, Button } from '@material-ui/core'
import { formateDate } from '../../../share/librery/librery'

const useStyle = makeStyles((theme) => ({
    label:{
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000'
    },
    infoPro:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        marginTop: '-80px',
        '& h4':{
            fontWeight: 'bold',
        }
    },
    orangeColor:{
        color: '#fc933a'
    },
    greenColor:{
        color: theme.palette.secondary.main
    },
    labelActivityDate:{
        color:'#808389'
    },
    btnPro:{
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '13px',
        '@media(max-width: 1366px)':{
            fontSize: '11px',
        }
    },
    btnDelete:{
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: '#f2060f',
        fontSize: '13px',
        '@media(max-width: 1366px)':{
            fontSize: '11px',
        }
    },
    divTextArea: {
        width: '65%',
        '@media(max-width: 1366px)':{
            width: '63%'
        }
    },
    textArea: {
        width: '100%', 
        border: '1px solid #000', 
        padding: '10px'
    },
    infoPrix: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%', 
        backgroundColor: '#e4e4e4', 
        borderRadius: '15px', 
        //marginRight: '30px',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2,3)
    }
}))

const ProNoProcie = ({row, total, reservationStatusId, setComment, sendSelectProFun, removePro}) => {
    const classes = useStyle()

    return (
        <Box className={classes.infoPro}>    
            <Box display="flex" flexDirection="column" p={1} width="65%" pt={6}>
                <Box 
                    display="flex" 
                    flexDirection="row" 
                    mt={(reservationStatusId === '4') ? 2 : 0} 
                    mb={(reservationStatusId === '4') ? 2 : 0} 
                >
                    <Box mr={4}>
                        <Typography variant="h6">Disponibilite(s) choisie(e)</Typography>
                        <Box display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                            <Box mr={2}>
                                <Typography className={classes.labelActivityDate} variant="h5">DATE DEBUT</Typography>
                                <Box mt={1}>
                                    <Typography variant="h4" style={{color: '#000'}}>{formateDate(`${row.week_date} 00:00:00`)}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography className={classes.labelActivityDate} variant="h5">HEURE DEBUT</Typography>
                                <Box mt={1}>
                                    <Typography 
                                        className={(reservationStatusId === '4') ? classes.greenColor : classes.orangeColor} 
                                        variant="h4"
                                    >
                                        {row.pro_start_time !== null ? row.pro_start_time.replace(':', 'H').slice(0,-3) : ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>                                         
                    <Box>
                        <Typography variant="h6">Temps de prestation</Typography>
                        <Box display="flex" flexDirection="row" justifyContent="space-around" mt={2}>
                            <Box>
                                <Typography className={classes.labelActivityDate}  variant="h5">ESTIMATION TODOO</Typography>
                                <Box mt={1}>
                                    <Typography  className={(reservationStatusId === '4') ? classes.greenColor : classes.orangeColor}  variant="h4">{row.pro_duration !== null ? row.pro_duration.replace(':', 'H').slice(0,-3) : ''}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>                                            
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
                    <Box className={classes.divTextArea} display="flex" mr={2}>
                        <TextareaAutosize 
                            name="comment" 
                            className={classes.textArea} 
                            aria-label="minimum height" 
                            rowsMin={2} placeholder="Minimum 3 rows" 
                            onChange={e => setComment(e.target.value)}
                            defaultValue={row.pro_comment}
                            disabled={(reservationStatusId === '4') ? true : false}
                        />
                    </Box>
                    {(reservationStatusId === '2') && (
                        <Box display="flex" flexDirection="column" justifyContent="space-around">
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                className={classes.btnPro}
                                onClick={e => sendSelectProFun(e, row.prId)}
                            >
                                SELECTIONNER LE PRO
                            </Button>
                            <Button 
                                variant="contained" 
                                className={classes.btnDelete}
                                onClick={e => removePro(e, row.prId)}
                            >
                                SUPRIMER L'OFFRE
                            </Button>
                        </Box>                                        
                    )}
                        
                </Box>
            </Box>
            <Box width="35%">
                <Box className={classes.infoPrix}>
                    <Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
                            <Box mr={4}>
                                <Typography className={classes.label} variant="h5">Tarif de base</Typography>
                            </Box>
                            <Box>
                                <Typography className={(reservationStatusId === '4') ? classes.greenColor : classes.orangeColor}  variant="h5">{`${row.pro_work_price} $`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography  className={classes.label}variant="h5">Extra(s) demande(s)</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">Aspirateur et mope</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.label} variant="h6">{`+${row.pro_vacuum_price} $`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">Produits écologiques</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.label} variant="h6">{`+${row.pro_product_ecological_price} $`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
                            <Box>
                                <Typography variant="h6">Produits standards</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.label} variant="h6">{`+${row.pro_product_standard_price} $`}</Typography>
                            </Box>
                        </Box>


                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography className={classes.label} variant="h5">Taxes</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography variant="h6">{`Taxes TPS(${total.ptps}%)`}</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.label} variant="h6">{`+${total.tps} $`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
                            <Box>
                                <Typography variant="h6">{`Taxes TVQ(${total.ptvq}%)`}</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.label} variant="h6">{`+${total.tvq} $`}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box>
                                <Typography className={classes.label} variant="h5">TARIF DE L'OFFRE</Typography>
                            </Box>
                            <Box>
                                <Typography className={(reservationStatusId === '4') ? classes.greenColor : classes.orangeColor}  variant="h4">{`${total.total} $`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            {(reservationStatusId === '4') && (
                <Box display="flex" flexDirection="column" justifyContent="space-around">
                    <Button variant="contained" color="secondary" disabled className={classes.btnPro}>
                        COMMENCER LA PRESTATION
                    </Button>
                </Box>                                        
            )}
            </Box>
        </Box>

    )
}

export default ProNoProcie