import React from "react";
import { Link } from "react-router-dom";
//import SelectInput from '../../share/inputs/SelectInput'
import { Avatar, Box, Button, Divider, Modal, Typography } from "@material-ui/core";
// import { useTheme } from '@mui/material/styles';

import PaperLayout from "../../share/layout/PaperLayout";
// import CustomModal from "../candidate/CustomModal";
import Table from "../../share/Table/Table";
import { LogicCandidateList } from "./LogicCandidateList";
import Processing from "../Processing";
//import { TableBar } from "@mui/icons-material";


//import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// import LockIcon from '@mui/icons-material/Lock';
//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//import LockOpenIcon from '@mui/icons-material/LockOpen';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import LockIcon from '@material-ui/icons/Lock';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  gridSize: {
    height: "650px",
    width: "100%"
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    marginRight: "20px",
    fontSize: "1.8rem",
    marginBottom: "20px",
  },
  modalPaper: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnYes: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#024fbb'
    }
  },
  btnNo: {
    backgroundColor: 'red',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d80404'
    }
  }
}));

const btnStatus = ({ row }) => {
  const localStyle = {
    statusStyle: {
      fontSize: "1.3rem",
      color: "white",
      width: '9.4rem',
      height: '3.6rem',
      fontWeight: 'bold',
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      borderRadius: "4px",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#5087f5',
      textAlign: 'center'
    },
    statusColorPending: {
      backgroundColor: '#f49e3a',
    },
    statusColorValidate: {
      backgroundColor: '#3ad640',
    },
    statusColorRefused: {
      backgroundColor: '#f05c5c',
    }
  }

  let messageBtn = "NOUVEAU";
  let backgroundStatusColor = localStyle.statusStyle
  if (row.status_id === 2) {
    messageBtn = "EN COURS";
    backgroundStatusColor = [localStyle.statusStyle, localStyle.statusColorPending]
  } else if (row.status_id === 3) {
    messageBtn = "ACCEPTÉ";
    backgroundStatusColor = [localStyle.statusStyle, localStyle.statusColorValidate]
  } else if (row.status_id === 4) {
    messageBtn = "REJETÉ";
    backgroundStatusColor = [localStyle.statusStyle, localStyle.statusColorRefused]
  }

  return (
    <Box className={backgroundStatusColor}>{messageBtn}</Box>
  )
}

const displayReservation = (props, permit, deleteRegister, unlockCandidate) => {
  const { row } = props
  //console.log('Nos va bien: 23', row)
  return (
    <Box>

      <Box display={(row.status_id >= 3) ? "flex" : "none"} justifyContent="space-between" alignItems="center">
        <Link to={`/recruitment/candidatelist/${row.id}/${row.status_id}`} >
          <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #2880fb' }}>
            <ArrowForwardOutlinedIcon style={{ color: '#2880fb' }} />
          </Avatar>
        </Link>
      </Box>

      <Box width="100%" display={(row.status_id <= 2) ? "display" : "none"}>
        {(!row.lock_status) ? (
          // <Box display="flex" justifyContent={permit.recruitment_delete === 1 ? "space-arround" : "center"} alignItems="center" width="100%">
          <Box width="115px" display="flex" flexDirection="row" justifyContent="space-around">
            <Box >
              <Link to={`/recruitment/candidatelist/${row.id}/${row.status_id}`} >
                <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #2880fb', height: '3.5rem', width: '3.5rem' }}>
                  <ArrowForwardOutlinedIcon style={{ color: '#2880fb' }} />
                </Avatar>
              </Link>
            </Box>
            <Box>
              {(permit.recruitment_delete === 1) && (
                <Link to="#" onClick={(e) => deleteRegister(e, row.id, row.firstName, row.lastName)} >
                  <Avatar style={{ backgroundColor: 'white' }}>
                    <HighlightOffIcon style={{ color: 'red', fontSize: '4.3rem', marginTop: '-4px' }} />
                  </Avatar>
                </Link>
              )}
            </Box>
          </Box>
        ) : (

          <Box width="110px" display="flex" justifyContent={permit.recruitment_unlock === 1 ? "space-around" : "center"} alignItems="center">
            <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #28cc8b', height: '3.5rem', width: '3.5rem' }}>
              <LockIcon style={{ color: '#28cc8b' }} />
            </Avatar>
            {(permit.recruitment_unlock === 1) && (
              <Avatar style={{ backgroundColor: 'transparent', border: '2px solid #28cc8b', height: '3.5rem', width: '3.5rem' }}>
                <Link to="#" onClick={(e) => unlockCandidate(e, row.id, row.firstName, row.lastName)} >
                  <LockOpenIcon style={{ color: '#28cc8b' }} />
                </Link>
              </Avatar>
            )}

          </Box>

        )}
      </Box>
    </Box>
  )
}



const CandidateList = () => {
  const classes = useStyle();
  const getLogicCandidate = LogicCandidateList()
  const { rows, permit, unlockCandidate, deleteCandidate } = getLogicCandidate

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);
  const [id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteName, setDeleteName] = React.useState('');

  if (rows === null) {
    return (
      <Processing />
    )
  }

  const deleteRegister = (e, id, firstName, lastName) => {
    e.preventDefault();
    console.log('Eliminar: ', id, firstName, lastName)
    setId(id);
    setDeleteName(firstName + ' ' + lastName);
    setOpen(true);
  };

  const headCells = [
    { field: 'firstName', headerName: 'Prénom', width: '130' },
    { field: 'lastName', headerName: 'Nome', width: '130' },
    { field: 'timezone', headerName: 'Timezone', width: '150' },
    { field: 'city', headerName: 'Ville', width: '180' },
    { field: 'created_at', headerName: 'Date création', width: '161' },
    { field: 'time_interview', headerName: 'Date visio', width: '161' },
    { field: 'statut_id', headerName: 'Status', width: '120', renderCell: btnStatus },
    {
      field: 'Action', headerName: '', width: '115', renderCell: (row) => displayReservation(row, permit, deleteRegister, unlockCandidate)
    }
  ]


  const apiRun = (e) => {
    e.preventDefault();
    deleteCandidate(id);
    setId('');
    handleClose();
  }
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Box style={modalStyle} className={classes.modalPaper}>
      <h2 style={{ fontSize: "22px", marginBottom: '30px' }} >Voulez-vous supprimer l'enregistrement? {deleteName}</h2>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          // className={classes.btnYes}
          onClick={e => apiRun(e)}
        >
          Oui
        </Button>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          // className={classes.btnNo}
          onClick={handleClose}
        >
          Non
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <PaperLayout>
        <Typography
          component="h1"
          variant="h6"
          noWrap
          className={classes.title}
        >CANDIDATURE</Typography>
        <Divider />
        <Box className={classes.gridSize}>
          <Table
            rows={rows}
            headCells={headCells}
          />
        </Box>
      </PaperLayout>


    </Box>
  )
}

export default CandidateList