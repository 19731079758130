import { SEND_MESSAGE, GET_ALL_PRO_CHAT, GET_ALL_MSG_CHAT,UPDATE_MSG, COUNT_A_ZERO, ADD_MSG, ADD_IMG } from "./typesAction";
import axios from "axios";
import config from "../../config.json";


export const sendMessage = (ar) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${ar.token}` }
        }
        const f = new FormData();
        f.append('to', ar.email)
        if (ar.message !== '') {
            f.append('message', ar.message)
        }
        else {
            f.append('message', ar.selectedFile )
        }
        
        await axios.post(`${config.API_SERVER}/api/v1/admin/chat/send`,f , headers  )
            .then(res => {
            if (res.status === 200) {
                dispatch({
                    payload:res.data,
                    type: SEND_MESSAGE,
                });
                dispatch(GetProInfoChat(ar))
             }
           
        }).catch((error) => {
            console.log('This is the error: ', error)
        });

    } catch (error) {
        console.log(error);
    }

}
export const GetProInfoChat = (ar) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${ar.token}` }
        }
        const data = {
            email : ar.email
        } 
        await axios.post(`${config.API_SERVER}/api/v1/admin/chat/user`, data, headers)
        .then(res => {            
                
             if (res.status === 200) {
                dispatch(GetAllMsgChat({token: ar.token, proInfo: res.data}))
             }
        })
        .catch((error) => {
            console.log('This is the error: ', error)
        });

    } catch (error) {
        console.log(error);
    }
}
export const GetAllMsgChat = (ar) => async dispatch => {
    try { 
        const headers = {
            headers: { 'Authorization': `Bearer ${ar.token}` }
        }
        
        await axios.get(`${config.API_SERVER}/api/v1/admin/chat/room/${ar.proInfo.chatRoomUid}`, headers)
        .then(res => {
             if (res.status === 200) {
                dispatch({
                    payload: { proInfo: ar.proInfo, msg: res.data },
                    type: GET_ALL_MSG_CHAT,
                });
             }
        }).catch((error) => {
            console.log('This is the error: ', error)
        });
    } catch (error) {
        console.log(error);
    }

}
export const getAllProChat = (token) => async dispatch => {
    try {


        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
      
      
        await axios.get(`${config.API_SERVER}/api/v1/admin/chat/users`, headers)
            .then(res => {
            
            if (res.status === 200) {
                 res.data.client.map(elmt => 
                    elmt.count = 0
                 )
                 res.data.pro.map(elmt => 
                    elmt.count = 0
                    )
                 dispatch({
                        payload: res.data,
                        type: GET_ALL_PRO_CHAT,
                    });
                 
            }
        }).catch((error) => {
            console.log('This is the error: ', error)
        });
    } catch (error) {
        console.log(error);
    }

}
export const updateMsg = (ar) => async dispatch => {
    try { 
        let chat1 = [...ar.Chat, ar.message]
        dispatch({
            payload: chat1,
            type: UPDATE_MSG,
        });
    } catch (error) {
        console.log(error);
    }
}

export const addMsg = (ar) => async dispatch => {
    try {

        dispatch({
            payload: ar.usersChat,         
            type: ADD_MSG,
        });
    } catch (error) {
        console.log(error);
    }
}

export const renitialiserCount = (ar) => async dispatch => {
    try {
        dispatch({
            payload: ar.usersChat,         
            type: COUNT_A_ZERO,
        });
    } catch (error) {
        console.log(error);
    }

}

export const addImage = ( selectedFile) => async dispatch => {
    
    try {
        dispatch({
            payload: selectedFile,         
            type: ADD_IMG,
        });
                       
    } catch (error) {
        console.log(error);
    }
        
}

