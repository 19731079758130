import { SAVE_AVATAR } from './typesAction';

export const  savePhotoUser = avatarUrl => async dispatch => {
    try {
        dispatch({
            type: SAVE_AVATAR,
            payload: avatarUrl
        });
    } catch (error) {
        
    }
}