import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Avatar, Box, Button, Grid, TextareaAutosize } from '@material-ui/core';
import AdjustIcon from '@material-ui/icons/Adjust';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
//import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PolicyIcon from '@material-ui/icons/Policy';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { getFullDate } from '../../share/librery/librery';


import { useDispatch, useSelector, useStore } from 'react-redux';
import { addMessage } from '../../../store/actions/candidateAction';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  paperMessates: {
    padding: '6px',
    backgroundColor: theme.palette.primary.main
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline: {
    height: '54vh',
    overflowY: 'scroll',
    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: '0'
    },
    '& .MuiTimelineDot-defaultGrey': {
      backgroundColor: '#2880fb',
    }
  },
  timelineAcepted: {
    '&.MuiTimelineDot-defaultGrey': {
      backgroundColor: '#28cc8b',
    }
  },
  pending: {
    '&.MuiTimelineDot-defaultGrey': {
      backgroundColor: '#f5a43a',
    }
  },
  rejected: {
    '&.MuiTimelineDot-defaultGrey': {
      backgroundColor: '#f05c5c',
    }
  },
  avatarRejected: {
    '&.MuiTimelineDot-defaultGrey': {
      backgroundColor: '#fff',
      border: '3px solid #2880fb'
    }
  },
  textMessage: {
    color: '#fff'
  },
  boxMessages: {
    height: '42vh',
    overflowY: 'scroll',
    marginBottom: '1.5rem'
  },
  errorMessage: {
    color: '#dc3545',
    paddingTop: '5px'
  },
  userName: {
    fontStyle: 'italic',
    color: '#f5a43a',
    fontWeight: 'bold'
  }
}));

const Annotations = ({ id }) => {

  const classes = useStyles();
  const store = useStore()

  const annotations = useSelector(state => { return state.candidate.notations })
  const messages = useSelector(state => { return state.candidate.messages })

  const dispatch = useDispatch()

  const [content, setContent] = useState('');
  const [textAdded, setTextAdded] = useState(false);

  const addMessageCandidature = e => {
    e.preventDefault();
    const token = store.getState().auth.access_token;

    if (content.trim().length > 0) {
      dispatch(addMessage(token, id, content, messages))
        .then(() => {
          setTextAdded(false);
          setContent('');
        })
    } else {
      setTextAdded(true);
    }
  }
  
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <Box className={classes.timeline}>
          <Timeline align="left">
            {

              (annotations !== undefined) && (
                annotations.map((row) => {

                  const fecha = getFullDate(row.created_at);
                  const getIcon = (type, info) => {
                    let index = -1;
                    switch (type) {
                      case 'status':
                        index = info.search("validé la candidature");
                        if (index > -1) {
                          return (<TimelineDot className={classes.timelineAcepted}><CheckCircleOutlineIcon /></TimelineDot>)
                        }
                        index = index = info.search("refusé la candidature");
                        if (index > -1) {
                          return (<TimelineDot className={classes.rejected}><ThumbDownIcon /></TimelineDot>)
                        } else {
                          return (<TimelineDot className={classes.pending}><AdjustIcon /></TimelineDot>)
                        }
                      case 'avatar':
                        index = info.search("refusé la photo");
                        if (index > -1) {
                          return (<TimelineDot className={classes.avatarRejected}><PhotoCameraIcon style={{ color: "black" }} /></TimelineDot>)
                        } else {
                          return (<TimelineDot><PhotoCameraIcon /></TimelineDot>)
                        }
                      case 'interview_status':
                      case 'interview_time':
                        return (<TimelineDot><VideocamIcon /></TimelineDot>)
                      case 'email':
                        return (<TimelineDot><EmailIcon /></TimelineDot>)
                      case 'verify':
                        return (<TimelineDot><PolicyIcon /></TimelineDot>)
                      case 'modify':
                        return (<TimelineDot><EditIcon /></TimelineDot>)
                      case 'reference':
                        return (<TimelineDot><SupervisedUserCircleIcon /></TimelineDot>)
                      default:
                        return (<TimelineDot><FastfoodIcon /></TimelineDot>);
                    }
                  }
                  return (
                    <TimelineItem key={row.id}>
                      <TimelineSeparator>
                        {getIcon(row.category, row.info)}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography>{row.info}</Typography>
                        </Paper>
                        <Box ml={2} mt={1}>
                          <Typography variant="body2" color="textSecondary">{fecha}</Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  )
                }
                )
              )
            }
          </Timeline>
        </Box>
      </Grid>

      <Grid item md={6}>
        <Box className={classes.boxMessages}>
          {
            (messages !== undefined) && (
              messages.map((row) => {
                if (row !== undefined) {
                  let urlAvatar = '';
                  if (row.avatar !== null) {
                    urlAvatar = row.avatar;
                  }

                  const fecha = getFullDate(row.created_at);

                  return (
                    <Box key={`No${row.id}`} display="flex" flexDirection="row" ml={3} my={1}>
                      <Box mr={2}>
                        <Avatar alt={`${row.user_name}`} src={urlAvatar} />
                      </Box>
                      <Box>
                        <Paper elevation={3} className={classes.paperMessates}>
                          <Box display="flex" flexDirection="row">
                            <Box mr={1}>
                              <Typography className={classes.userName}>{`${row.user_name}...`}</Typography>
                            </Box>
                            <Box>
                              <Typography className={classes.textMessage}>{row.content}</Typography>
                            </Box>
                          </Box>
                        </Paper>
                        <Box ml={2} mt={1}>
                          <Typography variant="body2" color="textSecondary">{fecha}</Typography>
                        </Box>
                      </Box>
                    </Box>

                  )
                }
                return true;
              }
              )
            )
          }
        </Box>
        <Box>
          <Box>
            <TextareaAutosize name="content" onChange={e => setContent(e.target.value)} aria-label="minimum height" rowsMin={2} placeholder="Entrez vos commentaires ici" value={content} style={{ width: '100%', border: '1px solid black' }} />

            <Box>
              {(textAdded) && (
                <span className={classes.errorMessage}>Entrez un commentaire</span>
              )}
            </Box>
          </Box>
          <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={e => addMessageCandidature(e)}>
              Envoyer
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Annotations
