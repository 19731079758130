import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LeadProfessionalTable from './Tables/LeadProfessionalTable';
import { getDateFormat } from '../share/librery/librery';
import axios from 'axios';

/*
let rows = [
    { id: 1, firstName: 'Snow', lastName: 'Jon', email: 'snow@hotmail.com', telephone:'8521473214'},
    { id: 2, firstName: 'Cersei', lastName: 'Lannister', email: 'lannister21@gmail.com', telephone:'1478526541'},
    { id: 3, firstName: 'Jaime', lastName: 'Lannister', email: 'jaime@mail.ocm', telephone:'3216363658'},
    { id: 4, firstName: 'Stark', lastName: 'Arya', email: 'arya@gmail.com', telephone:'0597522584'},
    { id: 5, firstName: 'Targaryen', lastName: 'Daenerys', email: 'daene@hotmaila.com', telephone:'5147157081' },
    { id: 6, firstName: 'Melisandre', lastName: 'Melis', email: 'melis@yahoo.es', telephone:'3105236547' },
    { id: 7, firstName: 'Clifford', lastName: 'Ferrara', email: 'ferrara@hotmail.com', telephone:'3152135823'},
    { id: 8, firstName: 'Frances', lastName: 'Rossini', email: 'rossini@hotmaila.com', telephone:'8523697412'},
    { id: 9, firstName: 'Roxie', lastName: 'Harvey', email: 'harvey@sierra.com', telephone:'5216987412'},
    { id: 10, firstName: 'Jonier', lastName: 'Muriullo', email: 'jonierm@gmail.com', telephone:'1112223654' },
    { id: 11, firstName: 'Damien', lastName: 'Silvain', email: 'damien@gmail.com', telephone:'324223654' },
    { id: 12, firstName: 'Carlos', lastName: 'Salamanca', email: 'salamancaa@gmail.com', telephone:'354523654' },
    { id: 13, firstName: 'Diana', lastName: 'Nino', email: 'dianani@latam.com', telephone:'358523654' },
    { id: 14, firstName: 'Henry', lastName: 'Nielsen', email: 'henry@mcnano.com', telephone:'569823654' },
    { id: 15, firstName: 'Robinson', lastName: 'Cabezas', email: 'robinson@mcnano.com', telephone:'569823785' },
    { id: 16, firstName: 'Carlos Cesar', lastName: 'Mejia', email: 'cmejia@mcnano.com', telephone:'564125785' },
    { id: 17, firstName: 'Luis Fernando', lastName: 'Mejia', email: 'lmejia@odonto.com', telephone:'564526785' },
    { id: 18, firstName: 'Melquiades', lastName: 'Nino', email: 'mequi@mgmail.com', telephone:'452635987' },
    { id: 19, firstName: 'Sara', lastName: 'Hollmes', email: 'sara@mgmail.com', telephone:'4527859852' },
    { id: 20, firstName: 'Sebastian', lastName: 'Camacho', email: 'smejia@mgmail.com', telephone:'6985417532' },
    { id: 21, firstName: 'Lara', lastName: 'Mejia', email: 'lara.m@hotmail.com', telephone:'8563215241' },
    { id: 22, firstName: 'Lara', lastName: 'Gallo', email: 'lara.m@hotmail.com', telephone:'8563215241' },
    { id: 23, firstName: 'Melisandre', lastName: 'Melis', email: 'melis@yahoo.es', telephone:'3105236547' },

];
*/

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: "flex",
    justifyContent: 'row',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '30px',
    borderRadius: '25px'
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridSize: {
    height: '80%',
    width: '100%'
  },
  title: {
    fontSize: '3.8rem',
    fontWeight: 'bold',
    marginLeft: '1.4rem'
  },
  divider: {
    height: '2px',
    backgroundColor: 'black',
    width: '100%'
  }
}))

const LeadProfessional = () => {
  const classes = useStyles();
  const [searchfirstname, setSearchfirstname] = useState('');
  const [searchlastname, setSearchlastname] = useState('');
  const [searchemail, setSearchemail] = useState('');
  const [searchtelephone, setSearchtelephone] = useState('');

  //It is part of the API no delete.
  const [rows, setRows] = useState([]);

  const headCells = [
    { id: 'firstname', numeric: false, disablePadding: true, label: 'First Name', searchOption: true },
    { id: 'lastname', numeric: false, disablePadding: true, label: 'Last Name', searchOption: true },
    { id: 'lag', numeric: false, disablePadding: true, label: 'Langage', searchOption: false },
    { id: 'email', numeric: false, disablePadding: true, label: 'Mail', searchOption: true },
    { id: 'telephone', numeric: false, disablePadding: true, label: 'Phone Number', searchOption: true },
    { id: 'created_at', numeric: false, disablePadding: true, label: 'Created', searchOption: true },
  ];

  const token = process.env.REACT_APP_LANDING_PAGE_API_KEY;

  useEffect(() => {

    const getProApi = () => {
      const headers = {
        headers: { 'Authorization': `Bearer ${token}` }
      }      
      
      axios.get(`https://api.tiggidoo.com/api/cleaners`, headers)
      .then((res) => {
        let dataArray = res.data;
        dataArray = dataArray.filter(item => item.id > 1)
        setRows(dataArray);
      }).catch((error) => {
        console.error(error)
      })
    }

    getProApi();

  }, [token])

  const deleteProfessional = (id) => {
    
    const data = {
      id: id
    }

    const headers = {
      headers: { 'Authorization': `Bearer ${token}` }
    }
    
    axios.post('https://api.tiggidoo.com/api/remove', data, headers)
    .then((res) => {
      let removeItem = rows.filter(item => item.id !== id)
      setRows(removeItem);

    }).catch((error) => {
      console.error(error)
    })

  }
 
  const createData = (id, firstname, lastname, lag, mail, telephone, created_at) => {
    return { id, firstname, lastname, lag, mail, telephone, created_at };
  }

  const filter = () => {

    let filterRows = rows.filter((row) =>
      row.firstName.toString().toLowerCase().indexOf(searchfirstname.toString().toLowerCase()) > -1
    );

    filterRows = filterRows.filter((row) =>
      row.lastName.toString().toLowerCase().indexOf(searchlastname.toString().toLowerCase()) > -1
    );

    filterRows = filterRows.filter((row) =>
      row.email.toString().toLowerCase().indexOf(searchemail.toString().toLowerCase()) > -1
    );

    filterRows = filterRows.filter((row) =>
      row.telephone.toString().toLowerCase().indexOf(searchtelephone.toString().toLowerCase()) > -1
    );

    return filterRows.map((r) =>
      createData(r.id, r.firstName, r.lastName, r.lag, r.email, r.telephone, getDateFormat(r.created_at))
    )
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchfirstname':
        setSearchfirstname(value);
        break;
      case 'searchlastname':
        setSearchlastname(value);
        break;
      case 'searchemail':
        setSearchemail(value);
        break;
      case 'searchtelephone':
        setSearchtelephone(value);
        break;
      default:
        break;
    }
  }

  return (
    <DashboardLayout>
      <Paper component="form" className={classes.root} >
        <Typography component="h1" variant="h6" noWrap className={classes.title}>
          Lead Pro
            </Typography>
        <Divider className={classes.divider} />
        <Box className={classes.gridSize}>
          <LeadProfessionalTable
            rows={filter()}
            headCells={headCells}
            onChangeSearch={onChangeSearch}
            deleteProfessional={deleteProfessional}
          />
        </Box>
      </Paper>
    </DashboardLayout>
  )

  /*
  0px 0px 0px 0px rgba(0,0,0,0), 
  0px 0px 0px 0px rgba(0,0,0,0), 
  0px 1px 3px 0px rgba(0,0,0,0.12)
  
    const [search, setSearch] = useState('');
    const searchColumns = ["firstName", "lastName", "email"];
    const filter = () => {
  
      const filterRows = rows.filter((row) =>
        searchColumns.some(
          (column) =>
            row[column].toString().toLowerCase().indexOf(search.toString().toLowerCase()) > -1
        )
      );
        
      return filterRows.map((r) => 
          createData(r.firstName, r.lastName, r.email, r.telephone)
      )  
    }
  */
}

export default LeadProfessional;