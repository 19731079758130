import { Box, Typography } from '@material-ui/core'
import React from 'react'
import Input from '../../share/inputs/Input'

const TypeEmailForm = ({ email, handleChange, message }) => {
    return (
        <div>
            <Box>
                <Box m={5}>
                    <Typography variant="h5" gutterBottom>Réinitialiser le mot de passe</Typography>
                </Box>
                <Box m={5}>
                    <Input
                        id="email"
                        placeholder="Type an email"
                        label="Email"
                        size="small"
                        defaultValue={email}
                        onBlur={e => handleChange(e)}
                        variant="outlined"
                        width="100%"
                        error={ message !== '' ? message : '' }
                    />
                </Box>
            </Box>
        </div>
    )
}

export default TypeEmailForm;
