import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import constant from '../../../constant.json';
import moment from 'moment';
import { datetoLocaleString } from '../../share/librery/librery';

const useStyle = makeStyles((theme) => ({
    textStyle: {
        fontSize: '18px',
    },
    textColor:{
        color: '#000000a3'
    }
}))

const BookingConfirmation = ({ selectedDate, selectedTime }) => {
    const classes = useStyle();
    const dayName = constant.DAYSOFTHEWEEK.split(',');
    const monthName = constant.MONTHS.split(',');
    const date = new Date(selectedDate);

    const dateToFrench = datetoLocaleString(`${moment(date).format('YYYY-MM-DD')} 00:00:00`, 'fr')
    //console.log('Esta es mi fecha: ', `${date.getFullYear()}-${date.getMonth()}`)

    return (
        <Box className={classes.textStyle}>
            <Box mb={3}>
                Entrevue,                
            </Box>
            <Box mb={2} className={classes.textColor}>
                {/* {`${dayName[date.getDay()]}, ${date.getDate()} ${monthName[date.getMonth()]} ${date.getFullYear()} ${selectedTime}`} */}
                {`${dateToFrench} ${selectedTime}`}
            </Box>
        </Box>
    )
}

export default BookingConfirmation