import { CREATE_ADMIN, UPDATE_ADMIN, ADMIN_FAIL, PROCESSING, GET_ALL_ADMIN, GET_AN_ADMIN, GENERATE_PASSWORD, REMOVE_ADMIN, SAVE_PHOTO_ADMIN, CREATE_ACTIVITY, GET_ACTIVITIES, RESET_PASSWORD } from '../actions/typesAction';

const initialState = {
    admin: null,
    users: null,
    loading: false,
    password_generated: null,
    adminRemoved: null,
    status: 0,
    activities: []
}

const adminReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PROCESSING:
            return initialState;
        case RESET_PASSWORD:
            return {
                admin: null,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: []
            };
        case CREATE_ADMIN:
            return {
                admin: payload.admin,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: []
            };
        case CREATE_ACTIVITY:
            return {
                admin: null,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: payload.activities
            };
        case GET_ACTIVITIES:
            return {
                admin: null,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: payload.activities
            };
        case UPDATE_ADMIN:
            return {
                admin: payload,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: []
            };
        case REMOVE_ADMIN:
            return {
                admin: null,
                users: initialState.users,
                loading: false,
                password_generated: null,
                adminRemoved: payload,
                status: payload.status,
                activities: []
            };
        case GET_ALL_ADMIN:
            return {
                admin: null,
                users: payload.users,
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: []
            };
        case GET_AN_ADMIN:
            return {
                admin: payload.admin,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: payload.status,
                activities: []
            };
        case ADMIN_FAIL:
            return state;
        case GENERATE_PASSWORD:
            return {
                admin: null,
                users: [],
                loading: false,
                password_generated: payload,
                adminRemoved: null,
                status: 200,
                activities: []
            };
        case SAVE_PHOTO_ADMIN:
            return {
                admin: payload.admin,
                users: [],
                loading: false,
                password_generated: null,
                adminRemoved: null,
                status: 200,
                activities: []
            }
        default:
            return state;
    }
}

export default adminReducer;