import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';

import { DataGrid } from "@material-ui/data-grid";

export default function Table({ rows, headCells }) {
  return (
    <div style={{ height: rows.length > 0 ? '100%' : '250px', width: '100%' }}>
      <DataGrid
        style={{ marginTop: '5px' }}
        rows={rows}
        columns={headCells}
      // initialState={{
      //   pagination: {
      //     paginationModel: { page: 0, pageSize: 10 },
      //   },
      // }}
      // pageSizeOptions={[10, 20, 50]}
      />
    </div>
  );
}