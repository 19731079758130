import { Box, Button, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react'

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}



const useStyles = makeStyles((theme) => ({
  //const theme = useTheme()

  modalPaper: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnYes: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#024fbb'
    }
  },
  btnNo: {
    backgroundColor: 'red',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d80404'
    }
  }
}
))

const Popup = ({ open, handleClose, handleApi, title }) => {
  const classes = useStyles()

  const [modalStyle] = useState(getModalStyle);

  const handleModal = (e) => {
    e.preventDefault()
    handleClose()
  }

  const apiRun = (e) => {
    e.preventDefault()
    handleApi()
  }

  const body = (
    <Box style={modalStyle} className={classes.modalPaper}>
      <h2 style={{ fontSize: "22px", marginBottom: '30px' }} >{title}</h2>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.btnYes}
          onClick={e => apiRun(e)}
        >
          Oui
        </Button>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.btnNo}
          onClick={e => handleModal(e)}
        >
          Non
        </Button>
      </Box>
    </Box>
  );


  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  )
}

export default Popup