import { Box } from '@material-ui/core';
import React from 'react';
import DashboardLayout from '../../../layout/Layout';
import ChatListClient from '../Chat/ChatListClient';

function ClientMsg() {
    return (
        
        
                
       
        <Box>
            <DashboardLayout>
            <ChatListClient />
            </DashboardLayout> 
        </Box>
      );
}

export default ClientMsg;