import React from 'react';
import TextField from "@material-ui/core/TextField";
import { MaskTelephone } from './mask/TextMaskCustom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


export default function Input(props) {

    const useStyles = makeStyles((theme) => ({
        shadow: {
            boxShadow: '-1px 4px 6px 3px #80808047',
        },
        inputWidth: {
            //marginRight: '15px',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
                borderRadius: '4px'
            },
            '& .MuiOutlinedInput-inputMarginDense': {
                paddingTop: '11px',
                paddingBottom: '11px',
            }
        },
        errors: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#dc3545',
                    borderRadius: '4px'
                }
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #dc3545',
            },
            '& label': {
                color: '#dc3545',
            }
        },
        errorMessage: {
            color: '#dc3545',
            paddingTop: '5px',
            fontSize: '1.6rem',
        },
        dateMargin: {
            '& input': {
                paddingTop: '10.5px',
                paddingBottom: '10.5px'
            }
        },
        inputReadOnly: {
            '& .MuiInputBase-input':{
                backgroundColor: '#d9d7d7'
            }
        }
    }));

    const classes = useStyles();
    const { id, placeholder, label, size, onBlur, defaultValue, error, type, width, variant, readOnly, mask, inputRef } = props;
    let customizedClass = classes.inputWidth;// = error.length === 0 ? classes.inputWidth : classes.errors;

    if (variant === 'outlined') {
        customizedClass = customizedClass + ' ' + classes.shadow;
    }

    if (error.length > 0) {
        customizedClass = customizedClass + ' ' + classes.errors;
    }

    if (type === 'date') {
        customizedClass = customizedClass + ' ' + classes.dateMargin;
    }

    let defaultValueExists = '';
    if (defaultValue !== undefined || defaultValue !== null) {
        defaultValueExists = defaultValue;
    }

    let readOnlyPro = false;
    if (readOnly !== undefined) {
        if (readOnly) {
            readOnlyPro = true;
            customizedClass = customizedClass + ' ' + classes.inputReadOnly;
        }
    }

    let maskExists = '';
    if (mask !== undefined) {
        maskExists = mask;
    }

    switch (maskExists) {
        case 'telephone':
            return (
                <Box display="flex" flexDirection="column" mr={1} style={{ width: width }}>
                    <TextField
                        id={id}
                        name={id}
                        placeholder={placeholder}
                        label={label}
                        variant={variant}
                        size={size}
                        type={type}
                        onChange={e => onBlur(e)}
                        value={defaultValueExists}
                        className={customizedClass}
                        InputProps={{
                            readOnly: false,
                            inputComponent: MaskTelephone
                        }}
                        inputRef = {inputRef !== undefined ? inputRef : null}
                    />
                    {(error.length > 0) && (
                        <span className={classes.errorMessage}>{error}</span>
                    )}
                </Box>
            );
        default:

            return (
                <Box display="flex" flexDirection="column" mr={1} style={{ width: width }}>
                    <TextField
                        id={id}
                        name={id}
                        placeholder={placeholder}
                        label={label}
                        variant={variant}
                        size={size}
                        type={type}
                        onChange={e => onBlur(e)}
                        value={defaultValueExists}
                        className={customizedClass}
                        autoComplete='off'
                        InputProps={{
                            readOnly: readOnlyPro
                        }}
                        inputRef = {inputRef !== undefined ? inputRef : null}
                    />
                    {(error.length > 0) && (
                        <span className={classes.errorMessage}>{error}</span>
                    )}
                </Box>
            );
    }
}