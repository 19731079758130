import axios from 'axios';
import { setAlert } from './alertAction';
import { savePhotoUser } from './avatarAction';
import config from '../../config.json';
import { updateAdminProfileAction } from './adminAction';

export const authAction = (email, password, remember, actionComeFrom, sms) => async dispatch => {

    //actionComeFrom = 0. It means the user has used login form
    //actionComeFrom = 1  It means the user has reseted the password or it is the first log in
    try {

        dispatch({
            type: "LOGIN_PROCESSING"
        });

        const f = new FormData();
        f.append('email', email);
        f.append('password', password);
        f.append('sms', sms);
        if(remember){
            f.append('remember', remember);
        }

        await axios.post(`${config.API_SERVER}/api/login/admin`, f)
        .then(res => {
            if(res.data.admin.avatar === null){
                
                res.data.admin.avatar = {
                    id: null,
                    filename: '',
                    url: 'images/default_man.png'
                }

            }

            dispatch({
                type: "LOGIN_SUCCESS",
                payload: res.data
            });

            localStorage.setItem('userLoggedIn', JSON.stringify({
                type: "LOGIN_SUCCESS",
                payload: res.data
            }))

            if (actionComeFrom === 1) {
                localStorage.setItem('actionComeFrom', true);

                const profile = {
                    "id": res.data.admin.id,
                    "firstName": res.data.admin.first_name,
                    "lastName": res.data.admin.last_name,
                    "telephone": res.data.admin.telephone,
                    "position": res.data.admin.position,
                    "color": res.data.admin.color,
                    "mondayStart": '0',
                    "mondayEnd": '24',
                    "tuesdayStart": '0',
                    "tuesdayEnd": '24',
                    "wednesdayStart": '0',
                    "wednesdayEnd": '24',
                    "thursdayStart": '0',
                    "thursdayEnd": '24',
                    "fridayStart": '0',
                    "fridayEnd": '24',
                    "saturdayStart": '0',
                    "saturdayEnd": '24',
                    "sundayStart": '0',
                    "sundayEnd": '24',
                };            
                dispatch(updateAdminProfileAction(profile, res.data.access_token, null))
            }

            if (res.data.admin.avatar !== null) {
                dispatch(savePhotoUser(res.data.admin.avatar.url));
            }

        }).catch((error) => {
            console.log(error)
            dispatch(setAlert(`Le nom d'utilisateur ou le mot de passe est mal saisi`, 'error'));
        });
    } catch (error) {
        console.log(error)
        dispatch(setAlert(`Le nom d'utilisateur ou le mot de passe est mal saisi`, 'error'));
    }
}

export const logOutTokenExpired = () => async dispatch => {
    console.log('La session a expire 21..')
    dispatch({
        type: 'LOG_OUT',
    });
}

export const getUserLoggedInAction = () => (dispatch) => {

    if (localStorage.getItem('userLoggedIn')) {
        const userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));
        dispatch({
            type: userLoggedIn.type,
            payload: userLoggedIn.payload
        });
    }
}

export const logOutAction = () => (dispatch) => {
    if (localStorage.getItem('userLoggedIn')) {
        dispatch({
            type: 'LOG_OUT',
        });
    }
}

export const updateUserAuthAction = (userAdmin, token) => (dispatch) => {
    if (localStorage.getItem('userLoggedIn')) {
        
        dispatch({
            type: 'UPDATE_USER_AUTH',
            payload: { userAdmin: userAdmin, access_token: token }
        });
                
        localStorage.setItem('userLoggedIn', JSON.stringify({
            type: "LOGIN_SUCCESS",
            payload: { admin: userAdmin, access_token: token }
        }))

    }
}
//http://localhost:3000/reset-password-form/ceb1d308c83adc286ace513eb3badf81c1696033b54e0ce73b906f33aaea62ca/cursosjonier%40gmail.com
export const resetPassword = (token, email, formData) => async dispatch => {
    try {
        const data = {
            token: token,
            email: email,
            password: formData.password,
            password_confirmation: formData.passwordConfirmation
        }
        await axios.post(`${config.API_SERVER}/api/password/admin/reset`, data)
            .then(res => {

                if (res.status === 200) {
                    dispatch(authAction(email, formData.password, true, 1));
                }
            }).catch((error) => {
                console.log(error)
                dispatch(setAlert(`Le Token a été utilisé / expiré`, 'error'));
            });
    } catch (error) {
        console.log(error)
        dispatch(setAlert(`Un problème est survenu avec le serveur`, 'error'));
    }
}