import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Divider, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ClientTable from "./Tables/ClientTable";
import Processing from "./Processing";
import { cleanCandidateAction } from "../../store/actions/candidateAction";
import { contactClient } from "../../store/actions/contactAction";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import config from "../../config.json";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "row",
        flexDirection: "column",
        alignItems: "start",
        padding: "30px",
        borderRadius: "25px",
        "& h5": {
            marginLeft: "0",
            color: "#000",
        },
        "& h6": {
            fontSize: "3rem",
            textTransform: "uppercase",
            color: "#4D4D4D",
        },
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    gridSize: {
        height: "80%",
        width: "100%",
    },
    title: {
        fontSize: "3.8rem",
        fontWeight: "bold",
        marginLeft: "1.4rem",
    },
    divider: {
        height: "2px",
        backgroundColor: "black",
        width: "100%",
    },
}));

const ClientList = () => {
    const { token, userAdmin, candidates, processing } = useSelector(
        (state) => ({
            token: state.auth.access_token,
            userAdmin: state.auth.userAdmin,
            candidates: state.contact.list,
            processing: state.contact.processing,
        })
    );

    const dispatch = useDispatch();

    const classes = useStyles();
    const [searchfirstName, setSearchfirstName] = useState("");
    const [searchlastName, setSearchlastName] = useState("");
    const [searchactive, setSearchactive] = useState("");
    const [searchcity, setSearchcity] = useState("");
    const [searchtelephone, setSearchtelephone] = useState("");
    const { contacts_delete_pro, contacts_update } = userAdmin.authority;

    const permit = { contacts_delete_pro, contacts_update };

    //It is part of the API no delete.
    const [rows, setRows] = useState([]);
    const [filterrows, setFilterrows] = useState([]);

    const headCells = [
        //{ id: '#', numeric: false, disablePadding: true, label: '', searchOption: false },
        {
            id: "id",
            numeric: false,
            disablePadding: true,
            label: "Id",
            searchOption: false,
        },
        {
            id: "firstName",
            numeric: false,
            disablePadding: true,
            label: "Prénom",
            searchOption: true,
        },
        {
            id: "lastName",
            numeric: false,
            disablePadding: true,
            label: "Nom",
            searchOption: true,
        },
        {
            id: "telephone",
            numeric: false,
            disablePadding: true,
            label: "Cellulaire",
            searchOption: true,
        },
        {
            id: "city",
            numeric: false,
            disablePadding: true,
            label: "City",
            searchOption: true,
        },
        {
            id: "active",
            numeric: false,
            disablePadding: true,
            label: "Activé",
            searchOption: true,
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(cleanCandidateAction());
        };
    }, [dispatch]);

    useEffect(() => {
        const initialFilter = (fils) => {
            let filterRows = fils.map((row) => {
                row.firstName = row.first_name;
                row.lastName = row.last_name;
                row.active = row.active === null ? 0 : row.active;
                return row;
            });

            setFilterrows(filterRows);
            setRows(filterRows);
        };

        if (candidates === null) {
            dispatch(contactClient(token));
        } else {
            const result = candidates;
            initialFilter(result);
        }
    }, [token, candidates, dispatch]);

    const deleteCandidate = (id) => {
        const headers = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const data = {
            id: id,
        };
        axios
            .post(`${config.API_SERVER}/api/v1/candidate/remove`, data, headers)
            .then((res) => {
                let removeItem = rows.filter((item) => item.id !== id);
                setFilterrows(removeItem);
                setRows(removeItem);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const filter = () => {
        let filterRows = rows.filter(
            (row) =>
                row.firstName
                    .toString()
                    .toLowerCase()
                    .indexOf(searchfirstName.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.lastName
                    .toString()
                    .toLowerCase()
                    .indexOf(searchlastName.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.active
                    .toString()
                    .toLowerCase()
                    .indexOf(searchactive.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.city
                    .toString()
                    .toLowerCase()
                    .indexOf(searchcity.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.telephone
                    .toString()
                    .toLowerCase()
                    .indexOf(searchtelephone.toString().toLowerCase()) > -1
        );

        setFilterrows(filterRows);
    };

    const onChangeSearch = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case "searchfirstName":
                setSearchfirstName(value);
                break;
            case "searchlastName":
                setSearchlastName(value);
                break;
            case "searchactive":
                setSearchactive(value);
                break;
            case "searchcity":
                setSearchcity(value);
                break;
            case "searchtelephone":
                setSearchtelephone(value);
                break;
            default:
                break;
        }
    };
    /* Shouete vous confirme la supresion*/

    return (
        <DashboardLayout>
            {processing ? (
                <Processing />
            ) : (
                <Box>
                    <Box mb={4} width="22%">
                        <Paper
                            className={classes.root}
                            style={{ padding: "8px" }}
                        >
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar>
                                        <PermIdentityIcon
                                            style={{
                                                fontSize: "3rem",
                                                color: "#4d4d4d",
                                            }}
                                        />
                                    </Avatar>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        className={classes.title}
                                    >
                                        Clients
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    className={classes.title}
                                >
                                    {filterrows.length}
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Paper component="form" className={classes.root}>
                        <Typography
                            component="h1"
                            variant="h6"
                            noWrap
                            className={classes.title}
                        >
                            Client
                        </Typography>
                        <Divider className={classes.divider} />
                        <Box className={classes.gridSize}>
                            <ClientTable
                                rows={filterrows}
                                headCells={headCells}
                                onChangeSearch={onChangeSearch}
                                filter={filter}
                                deleteCandidate={deleteCandidate}
                                permit={permit}
                            />
                        </Box>
                    </Paper>
                </Box>
            )}
        </DashboardLayout>
    );
};

export default ClientList;
