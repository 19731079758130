import { Box, Button } from '@material-ui/core'
import Input from '../../share/inputs/Input';
import React, { useState } from 'react'

const Task = ({ saveTask }) => {

    const [name, setName] = useState({
        fr: '',
        en: ''
    })
    const { fr, en } = name

    const [validate, setValidate] = useState(false)

    const task = (e) => {
        e.preventDefault()
        if(fr === '' || en === ''){
            setValidate(true)
        }else{
            saveTask(name)
            setValidate(false)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-around" width="1">
            <Input
                id="fr"
                placeholder=""
                label="Français"
                size="small"
                width={'100%'}
                defaultValue={fr}
                onBlur={e => handleChange(e)}
                variant="outlined"
                error={(fr === '' && validate)  ? 'Requerido' : ''}
            />
            <Input
                id="en"
                placeholder=""
                label="Anglais"
                size="small"
                width={'100%'}
                defaultValue={en}
                onBlur={e => handleChange(e)}
                variant="outlined"
                error={(en === '' && validate)  ? 'Requerido' : ''}
            />
 
            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={e => task(e)}>CREER</Button>
            </Box>

        </Box>
    )
}

export default Task