import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsEnAttente from "./RequestsEnAttente";

const DashboardRequestEnAttente = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsEnAttente />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestEnAttente;
