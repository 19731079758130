import { GET_REQUEST, GET_A_REQUEST, GET_FILTER_PRO, PROCESSING, GET_RESERVATIONS_BY_PRO } from '../actions/typesAction'

const initialState = {
    requests: null,
    request: null,
    prosFilter: null,
    reservations: null
}

export default function getRequests(state=initialState, action){
    const { type, payload } = action;
    switch (type) {
        case PROCESSING:
            return initialState
        case GET_REQUEST:
            return{
                ...state,
                requests: payload,
                request: null
            }
        case GET_A_REQUEST:
            return {
                ...state,
                requests: null,
                request: payload
            }
        case GET_FILTER_PRO: 
            return {
                ...state,
                prosFilter: payload
            }
        case GET_RESERVATIONS_BY_PRO:
            return {
                ...state,
                reservations: payload
            }
        default:
            return state;
    }
}