import React from 'react'
import { Box } from '@material-ui/core'

const EmailConfirmationForm = ({email}) => {
    return (
        <Box mb={6} mt={8} style={{fontSize: '19px'}}>
            {`Un courriel a été envoyé à`} <strong>{`: ${email}`}</strong>
        </Box>
    )
}

export default EmailConfirmationForm
