import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
//Matarial-UI
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./assets/css/material-ui/configThemes";
import SignIn from "./components/auth/SignIn";
import HomePage from "./components/views/HomePage";
import DashboardServiceSchedule from "./components/views/planning/Index";
import ClientList from "./components/views/ClientList";
import Client from "./components/views/contact/client/Index";
import ClientMsg from "./components/views/message/clients/Client";
import ProMsg from "./components/views/message/pro/Pro";
// import Pro from "./components/views/contact/pro/Index"
import Pro from "./components/views/contact/professional/Index";
import ProfessionalsList from "./components/views/ProfessionalsList";
import LeadClient from "./components/views/LeadClient";
import LeadProfessional from "./components/views/LeadProfessional";
//import Candidate from "./components/views/candidate/Candidate";
import Planningvisio from "./components/views/Planningvisio";
import Admin from "./components/views/admin/Admin";
import Archive from "./components/views/Archive";
import AdminList from "./components/views/AdminList";
import { PrivateRoute } from "./components/routing/PrivateRoute";
import Profile from "./components/views/profile/Profile";
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import BookingInterview from "./components/views/candidate/BookingInterview";
import Reschedule from "./components/views/candidate/Reschedule";
import UnavailabilityForm from "./components/views/admin/UnavailabilityForm";
import ResetPasswordAdminForm from "./components/views/admin/ResetPasswordAdminForm";
import CreateMailBox from "./components/views/mailbox/CreateMailBox";
import UpdateMailBox from "./components/views/mailbox/UpdateMailBox";
import MailBoxList from "./components/views/MailBoxList";
import SendMail from "./components/views/mailbox/SendMail";
import ServicesList from "./components/views/ServicesList";
//import Service from "./components/views/Service/Service";
import RateList from "./components/views/RateList";
import TariffTester from "./components/views/tariff/TariffTester";

import Request from "./components/views/request/Request";
import TaskList from "./components/views/TaskList";
import DashboardRequestList from "./components/views/request-list/Index";
import DashboardRequestListTerminer from "./components/views/request-Terminer/Index";
import DashboardRequestListAffecter from "./components/views/request-NonAffecter/Index";
import DashboardRequestListAssigner from "./components/views/request-NonAssigner/Index";
import DashboardRequestListLitige from "./components/views/request-Litige/Index";
import DashboardRequestListAnnuler from "./components/views/request-Annuler/Index";
import DashboardRequestListAccepter from "./components/views/request-Accepter/Index";
import DashboardRequestListAccepterPro from "./components/views/request-AccepterPro/Index";
import DashboardRequestListEnAttente from "./components/views/request-EnAttente/Index";
import DashboardRequestListNouvelle from "./components/views/request-Nouvelle/Index";
import DashboardCandidateList from "./components/views/candidate-list/Index";
import TaxesList from "./components/views/TaxesList";

import IndexPoste from "./components/views/concierge/ConciergePostal/Index";
import EndingRequest from "./components/views/contact/client/request/EndingRequest";
import PromoCode from "./components/views/PromoCode";
const App = () => {
    console.log("Date updated: Oct - 16 - 2024 - 13:01");
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>

                    <Route
                        exact
                        path="/concierge/PostalCode"
                        // element={<IndexPoste/>}
                        element={<PrivateRoute Component={<IndexPoste />} />}
                    />
                    <Route
                        exact
                        path="/housework"
                        //element= {<TaskList/>}
                        element={<PrivateRoute Component={<TaskList />} />}
                    />
                    <Route
                        exact
                        path="/promocode"
                        //element= {<PromoCode />}
                        element={<PrivateRoute Component={<PromoCode />} />}
                    />
                    <Route
                        exact
                        path="/taxes"
                        // element= {<TaxesList/>} 
                        element={<PrivateRoute Component={<TaxesList />} />}
                    />
                    <Route
                        exact
                        path="/services"
                        // element= {<ServicesList/>}
                        element={<PrivateRoute Component={<ServicesList />} />}
                    />
                    <Route
                        exact
                        path="/reservation/request/:id/:reservationStatusId/:updateHouseHoldList/:requestStatus"
                        // element= {<Request/>}
                        element={<PrivateRoute Component={<Request />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests"
                        // element= {<DashboardRequestList/>}
                        element={<PrivateRoute Component={<DashboardRequestList />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-complete"
                        // element= {<DashboardRequestListTerminer/>}
                        element={<PrivateRoute Component={<DashboardRequestListTerminer />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-NotAffected"
                        // element= {<DashboardRequestListAffecter/>}
                        element={<PrivateRoute Component={<DashboardRequestListAffecter />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-NotAssigned"
                        // element= {<DashboardRequestListAssigner/>}
                        element={<PrivateRoute Component={<DashboardRequestListAssigner />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Litigate"
                        // element= {<DashboardRequestListLitige/>}
                        element={<PrivateRoute Component={<DashboardRequestListLitige />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Canceled"
                        // element= {<DashboardRequestListAnnuler/>}
                        element={<PrivateRoute Component={<DashboardRequestListAnnuler />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Accepted"
                        // element= {<DashboardRequestListAccepter/>}
                        element={<PrivateRoute Component={<DashboardRequestListAccepter />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-AcceptPro"
                        // element= {<DashboardRequestListAccepterPro/>}
                        element={<PrivateRoute Component={<DashboardRequestListAccepterPro />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-Pending"
                        // element= {<DashboardRequestListEnAttente/>}
                        element={<PrivateRoute Component={<DashboardRequestListEnAttente />} />}
                    />
                    <Route
                        exact
                        path="/reservation/requests-New"
                        // element= {<DashboardRequestListNouvelle/>}
                        element={<PrivateRoute Component={<DashboardRequestListNouvelle />} />}
                    />
                    <Route
                        exact
                        path="/reservation/services"
                        // element= {<DashboardServiceSchedule/>}
                        element={<PrivateRoute Component={<DashboardServiceSchedule />} />}
                    />
                    <Route
                        exact
                        path="/contact/client"
                        // element= {<ClientList/>}
                        element={<PrivateRoute Component={<ClientList />} />}
                    />
                    <Route
                        exact
                        path="/contact/client/:id"
                        // element= {<Client/>}
                        element={<PrivateRoute Component={<Client />} />}
                    />
                    <Route
                        exact
                        path="/contact/client/finish/:id"
                        // element= {<EndingRequest/>}
                        element={<PrivateRoute Component={<EndingRequest />} />}
                    />
                    <Route
                        exact
                        path="/contact/pro/:id"
                        // element= {<Pro/>}
                        element={<PrivateRoute Component={<Pro />} />}
                    />
                    <Route
                        exact
                        path="/contact/professional"
                        // element= {<ProfessionalsList/>}
                        element={<PrivateRoute Component={<ProfessionalsList />} />}
                    />
                    <Route
                        exact
                        path="/landingpage/client"
                        // element= {<LeadClient/>}
                        element={<PrivateRoute Component={<LeadClient />} />}
                    />
                    <Route
                        exact
                        path="/landingpage/professional"
                        // element= {<LeadProfessional/>}
                        element={<PrivateRoute Component={<LeadProfessional />} />}
                    />

                    <Route
                        exact
                        path="/recruitment/candidatelist/:id/:status_id"
                        // element= {<Candidate/>}
                        element={<PrivateRoute Component={<DashboardCandidateList />} />}
                    />
                    <Route
                        exact
                        path="/recruitment/candidatelist"
                        // element= {<DashboardCandidateList/>}
                        element={<PrivateRoute Component={<DashboardCandidateList />} />}
                    />
                    <Route
                        exact
                        path="/recruitment/planningvisio"
                        // element= {<Planningvisio/>}
                        element={<PrivateRoute Component={<Planningvisio />} />}
                    />
                    <Route
                        exact
                        path="/recruitment/archive"
                        // element= {<Archive/>}
                        element={<PrivateRoute Component={<Archive />} />}
                    />

                    <Route
                        exact
                        path="/rete_list"
                        // element= {<RateList/>}
                        element={<PrivateRoute Component={<RateList />} />}
                    />
                    <Route
                        exact
                        path="/tester"
                        // element= {<TariffTester/>}
                        element={<PrivateRoute Component={<TariffTester />} />}
                    />
                    <Route
                        exact
                        path="/tariff/:cityId"
                        // element= {<TariffTester/>}
                        element={<PrivateRoute Component={<TariffTester />} />}
                    />
                    <Route
                        exact
                        path="/message/client"
                        // element= {<ClientMsg/>}
                        element={<PrivateRoute Component={<ClientMsg />} />}
                    />
                    <Route
                        exact
                        path="/message/pro"
                        // element= {<ProMsg/>}
                        element={<PrivateRoute Component={<ProMsg />} />}
                    />
                    <Route
                        exact
                        path="/dashboard"
                        // element= {<HomePage/>}
                        element={<PrivateRoute Component={<HomePage />} />}
                    />
                    <Route
                        exact
                        path="/adminlist"
                        // element= {<AdminList/>}
                        element={<PrivateRoute Component={<AdminList />} />}
                    />
                    <Route
                        exact
                        path="/admin"
                        // element= {<Admin/>}
                        element={<PrivateRoute Component={<Admin />} />}
                    />
                    <Route
                        exact
                        path="/admin/:id"
                        // element= {<Admin/>} 
                        element={<PrivateRoute Component={<Admin />} />}
                    />
                    <Route
                        exact
                        path="/profile"
                        // element= {<Profile/>} 
                        element={<PrivateRoute Component={<Profile />} />}
                    />
                    <Route
                        exact
                        path="/activity"
                        // element= {<UnavailabilityForm/>}
                        element={<PrivateRoute Component={<UnavailabilityForm />} />}
                    />
                    <Route
                        exact
                        path="/list_mailbox"
                        // element= {<MailBoxList/>}
                        element={<PrivateRoute Component={<MailBoxList />} />}
                    />
                    <Route
                        exact
                        path="/compose_email"
                        // element= {<SendMail/>}
                        element={<PrivateRoute Component={<SendMail />} />}
                    />
                    <Route
                        exact
                        path="/create-mailbox/:id"
                        // element= {<CreateMailBox/>}
                        element={<PrivateRoute Component={<CreateMailBox />} />}
                    />
                    <Route
                        exact
                        path="/update-mailbox/:id/:description"
                        // element= {<UpdateMailBox/>}
                        element={<PrivateRoute Component={<UpdateMailBox />} />}
                    />
                    <Route
                        exact
                        path="/reset-password-form/:token/:email"
                        element={<ResetPasswordForm />}
                    />
                    <Route
                        exact
                        path="/reschedule/:email"
                        element={<Reschedule />}
                    />
                    <Route
                        exact
                        path="/calendar-form/:email"
                        element={<BookingInterview />}
                    />
                    <Route
                        exact
                        path="/reset-password-admin-form"
                        element={<ResetPasswordAdminForm />}
                    />

                    <Route exact path="/login" element={<SignIn />} />
                    <Route exact path="/" element={<SignIn />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
