import { GET_SERVICES, CREATE_A_NEW_SERVICE, PROCESSING, GET_SERVICES_BY_CITY, GET_TARIFF_BY_CITY, GET_ESTIMATION } from '../actions/typesAction'
const initialState = {
    services: null,
    service: null,
    servicesByCity: null,
    tariff: null,
    estimation: null
}

const serviceReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case PROCESSING:
            return initialState;
        case GET_SERVICES:
            return {
                ...state,
                services: payload,
            };
        case CREATE_A_NEW_SERVICE:
            return {
                ...state,
                service: payload
            };
        case GET_SERVICES_BY_CITY:
            return {
                ...state,
                servicesByCity: payload
            }
        case GET_TARIFF_BY_CITY:
            return {
                ...state,
                tariff: payload
            }
        case GET_ESTIMATION:
            return {
                ...state,
                estimation: payload
            }
        default:
            return state;
            
    }
}

export default serviceReducer;