import { GET_CANDIDATES_VISIO, PROCESSING, ADMIN_FAIL, INTERVIEW_DATE_EXISTS } from '../actions/typesAction';

const initialState = {
    adminCandidates: [],
    processing: true,
    interviewCandidateExists: false
};

export default function getCandidates(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADMIN_FAIL:
        case PROCESSING: {
            return initialState;
        }
        case GET_CANDIDATES_VISIO:
            return {
                adminCandidates: payload.candidates,
                processing: false,
                interviewCandidateExists: false
            };
        case INTERVIEW_DATE_EXISTS:
            return {
                ...state,
                interviewCandidateExists: true
            }
        default:
            return state;
    }
}