import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Box, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function AdminMenu({logOut}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <KeyboardArrowDownIcon style={{ fontSize: 22 }} color="primary"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {() => (
              <Paper style={{margin: '15px 25px', padding:'15px 60px 45px 40px'}}>
                <Box >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem>
                        <Link to='/profile'>
                          <Box display="flex" flexDirection="row" alignItems="baseline">
                            <Box mr={3}>
                              <AccountBoxIcon style={{fontSize: 25, color: "#7f7f7f"}} />
                            </Box> 
                            <Typography component="h5">Profile</Typography>  
                          </Box>
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={e => logOut(e)}>
                        <Box display="flex" flexDirection="row" alignItems="baseline">
                          <Box mr={3}>
                            <ExitToAppIcon style={{fontSize: 25, color: "#7f7f7f"}} />
                          </Box>
                          <Typography component="h5">Logout</Typography>
                        </Box>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Box>
              </Paper>
          )}
        </Popper>
      </div>
    </div>
  );
}