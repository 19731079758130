import { GET_TASKS, GET_TASKS_ACTIVE, PROCESSING } from '../actions/typesAction'

const initialState = {
    inactive: null,
    active: null
}

const taskReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {

        case PROCESSING: 
            return initialState
        case GET_TASKS:
            return {
                ...state,
                inactive: payload
            }
        case GET_TASKS_ACTIVE:
            return {
                ...state,
                active: payload
            }
        default:
            return initialState

    }

}

export default taskReducer;