import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Box, Button } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { cancelVideo } from '../../../store/actions/candidateAction';
import { connect, useDispatch } from 'react-redux';
import { cleanPlanningVisio } from '../../../store/actions/planningvisioAction';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        '& h4': {
            fontWeight: 'bold',
            color: '#4d4d4d'
        }
    },
}));

const BookingInterview = ({ appointmentStatus, cancelVideo }) => {
    const classes = useStyles();
    let history = useNavigate();
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(cleanPlanningVisio());
    }, [dispatch])

    const { email } = useParams();

    const changeDate = (e) => {
        e.preventDefault();
        localStorage.setItem('reschedule', true);
        history(`/calendar-form/${email}`);
    }

    const cancelAppointment = (e) => {
        e.preventDefault();
        cancelVideo(decodeURIComponent(email));
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Box display="flex" justifyContent="center" mb={5} mt={1}>
                        <img src="images/logo_tiggidoo.svg" alt="" width="250" />
                    </Box>

                    <React.Fragment>
                        <React.Fragment>
                            {
                                appointmentStatus.status === null ?
                                    (
                                        <React.Fragment>
                                            <Box mb={5}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box textAlign="center">Annuler ou modifier la date de la visioconférence</Box>
                                                </Typography>
                                            </Box>
                                            <Box mb={10}>
                                                <Box>
                                                    <Typography variant="subtitle1">Candidate: {decodeURIComponent(email)}</Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent="space-around" mb={10}>
                                                <Button variant="contained" color="primary" onClick={e => cancelAppointment(e)}>Annuler</Button>
                                                <Button variant="contained" color="secondary" onClick={e => changeDate(e)}>Modifier</Button>
                                            </Box>
                                        </React.Fragment>
                                    )
                                    :
                                    (
                                        <React.Fragment>
                                            <Box mb={5}>
                                                <Typography variant="h4" gutterBottom>
                                                    <Box textAlign="center">La date de la vidéoconférence a été annulée</Box>
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    )
                            }
                        </React.Fragment>

                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}

const mapToStateToProps = state => ({
    appointmentStatus: state.candidate
})

export default connect(mapToStateToProps, { cancelVideo })(BookingInterview);
