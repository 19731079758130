import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../share/inputs/Input';
import { createService, updateService } from '../../../store/actions/serviceAction';

const Service = ({data:{id, upName}}) => {

    const { auth:{ access_token } } = useSelector(
        state => ({
            auth: state.auth
        })
    );

    const dispatch = useDispatch();

    const [validateName, setValidateName] = useState(false);

    const [formData, setFormData] = useState({
        id:id,
        nameFr: upName,
        nameEn:  upName

    })

    const { nameFr, nameEn } = formData;

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const saveService = (e) => {
        e.preventDefault()
        if(nameFr.length === 0){
            setValidateName(true)
        }else{
            dispatch(createService(access_token, nameEn, nameFr))
            setValidateName(false)
        }
    }

    const upService = (e) => {
        e.preventDefault();
        dispatch(updateService(access_token, id, nameEn, nameFr))
    }

    return(
        <Box display="flex" flexDirection="row">
            <Box mr={5}>
                <Box mb={3}>
                    <Input
                        id="nameEn"
                        placeholder=""
                        label="Anglais"
                        size="small"
                        width={'100%'}
                        defaultValue={nameEn}
                        onBlur={e => handleChange(e)}
                        variant="outlined"
                        error={(nameEn.length === 0 && validateName)  ? 'Requerido' : ''}
                    />
                </Box>  
                <Box>
                    <Input
                        id="nameFr"
                        placeholder=""
                        label="Français"
                        size="small"
                        width={'100%'}
                        defaultValue={nameFr}
                        onBlur={e => handleChange(e)}
                        variant="outlined"
                        error={(nameFr.length === 0 && validateName)  ? 'Requerido' : ''}
                    />
                </Box>                         
            </Box>
            <Box mb={2}>
                {
                    (id !== null) ? (
                        <Button variant="contained" color="secondary" onClick={e => upService(e)}>Mettre à jour le service</Button>
                    )
                    :
                    (
                        <Button variant="contained" color="primary" onClick={e => saveService(e)}>Enregistrer le service</Button>
                    )
                }
            </Box>
        </Box>
  );
}

export default Service;