import React, { useState, useEffect } from 'react'
import { DateTimePicker } from '@material-ui/pickers';
import { useForm, Controller as Controlador } from 'react-hook-form';
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import Input from '../../share/inputs/Input'
import SelectInput from '../../share/inputs/SelectInput';

import { getDateAndTime } from '../../share/librery/librery';
import ActivitiesTable from '../Tables/ActivitiesTable';
import constant from '../../../constant.json';

const useStyle = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: props.backgroundColor,
        color: theme.color,
    }),
    title: {
        fontSize: '3.8rem',
        fontWeight: 'bold',
        marginLeft: '1.4rem'
    },
}))

export const UnavailabilityForm = ({ auth: { access_token, userAdmin }, activities, createActivityAction, deleteActivityAction, setAlert, dateStart, dateEnd, dispatch }) => {

    const classes = useStyle();
    const frequencyData = [{ id: '1', name: 'No' }, { id: '2', name: 'Quotidien' }, { id: '3', name: 'Ebdomadaire' }, { id: '4', name: 'Mensuel' }];

    const [description, setDescription] = useState('');
    const [frequency, setFrequency] = useState(0);
    const [startDate, setStartDate] = useState(new Date(dateStart));
    const [endDate, setEndDate] = useState(new Date(dateEnd));
    const [rows, setRows] = useState([]);
    const { register, control, handleSubmit } = useForm();

    useEffect(() => {
        if (activities !== undefined) {
            if (activities.length > 0) {
                const frequency = constant.ACTIVITYFREQUENCY.split(',');
                const arrayActivities = activities;

                let obj = [];
                let activitiesObj = {};

                for (let index in arrayActivities) {
                    activitiesObj = {
                        id: arrayActivities[index].id,
                        description: arrayActivities[index].description,
                        recurrence_id: frequency[arrayActivities[index].recurrence_id - 1],
                        start_time: arrayActivities[index].start_time,
                        end_time: arrayActivities[index].end_time
                    };
                    obj.push(activitiesObj);
                }
                setRows(obj);
            }else{
                setRows([]);
            }
        }
    }, [activities])

    const submitData = (data) => {
        const start = data.startTime.getTime();
        const end = data.endTime.getTime();

        data.startTime = getDateAndTime(data.startTime);
        data.endTime = getDateAndTime(data.endTime);
        data.description = data.description.trim();
        if (data.description.trim().length > 0) {
            if (end > start) {
                dispatch(createActivityAction(access_token, data, userAdmin.id));
            } else {
                setAlert('La première date doit être inférieure à la seconde', 'error');
            }
        } else {
            setAlert('Entrez une description', 'error');
        }

    }

    const deleteActivity = (id) => {
        dispatch(deleteActivityAction(access_token, id));
    }

    return (
        <Box>
            <Box>
                <Typography component="h1" variant="h1" noWrap className={classes.title}>Activité du journal</Typography>
            </Box>
            <Box mt={4}>
                <form onSubmit={handleSubmit(submitData)}>
                    <Container component="main" maxWidth="md">
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={2} container>
                                <Input
                                    id="description"
                                    placeholder=""
                                    label="Description"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={description}
                                    onBlur={e => setDescription(e.target.value)}
                                    variant="outlined"
                                    error=''
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item md={2} container>
                                <SelectInput
                                    id="frequency"
                                    name="frequency"
                                    data={frequencyData}
                                    onChange={e => setFrequency(e.target.value)}
                                    defaultValue={frequency}
                                    error=''
                                    inputRef={register}
                                />
                            </Grid>
                            <Grid item md={3} container>
                                {/* <TextField name="description" label="Description" inputRef={register} /> */}
                                <Controlador defaultValue={startDate} name='startTime' control={control}
                                    as={
                                        <DateTimePicker
                                            value={startDate}
                                            onChange={setStartDate}
                                            autoOk
                                            inputVariant="outlined"
                                            variant="inline"
                                            size="small"
                                            label="Start Date"
                                            openTo="year"
                                            disablePast
                                            format="yyyy-MM-dd HH:mm"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item md={3} container>
                                <Controlador defaultValue={endDate} name='endTime' control={control}
                                    as={
                                        <DateTimePicker
                                            value={startDate}
                                            onChange={setEndDate}
                                            autoOk
                                            inputVariant="outlined"
                                            variant="inline"
                                            size="small"
                                            label="End Date"
                                            openTo="year"
                                            disablePast
                                            format="yyyy-MM-dd HH:mm"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item md={2} container>
                                <Button variant="contained" color="primary" type="submit">Submit</Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </Box>

            <Box mt={4}>
                <ActivitiesTable
                    rows={rows}
                    deleteActivity={deleteActivity}
                />
            </Box>

        </Box>
    )
}

export default UnavailabilityForm;