import React, { useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getARequest } from "../../../../../store/actions/reservationAction";
import PaperLayout from "../../../../share/layout/PaperLayout";
import ReqHeader from "../../../request/Components/ReqHeader";

const ShowReservation = () => {
    const { token, request } = useSelector((state) => ({
        token: state.auth.access_token,
        request: state.reservations.request,
    }));

    const refReq = useRef(true);
    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        if (refReq.current) {
            refReq.current = false;
            dispatch(getARequest(token, id, "false"));
        }
    }, [id, token, dispatch]);

    if (request === null) {
        return false;
    }

    console.log("Este es el valor refReq: ", request);

    return (
        <PaperLayout>
            <ReqHeader
                uuid={request.uuid}
                service={request.service.fr}
                dates={request.housework.time}
                optionDate={request.housework.option_date}
                duration={request.total_duration}
                reservationStatusId="4"
                formule={request.housework.frequency.fr}
                requestStatus="4"
                isFinish={true}
            />
            <Box>This page is in construction</Box>
        </PaperLayout>
    );
};

export default ShowReservation;
