import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import DashboardLayout  from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import PaperLayout from '../share/layout/PaperLayout';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

//import { connect } from 'react-redux';
//import { getAllUsers, removeAdmin } from '../../store/actions/adminAction';
import MailBoxListTable from './Tables/MailBoxListTable';

//import { useDispatch, useSelector } from 'react-redux';

import { deleteTemplates, getTemplates } from '../../store/actions/emailTemplateAction';
import { Container } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    marginRight: '20px',
    fontSize: "1.8rem"
  },
  btnDownloadReport: {
    marginTop: '-20px',
    marginRight: '30px',
    '& span': {
      textTransform: 'none'
    },
    '& .MuiButton-containedSecondary': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-contained': {
      color: theme.palette.primary.main,
      boxShadow: '0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)',
      border: `1px solid ` + theme.palette.primary.main
    }
  },
  addUser: {
    color: theme.palette.primary.main,
    fontSize: "3.5rem"
  },
  gridSize: {
    height: '80%',
    width: '100%'
  }
}));

const MailBoxList = () => {
  const classes = useStyle();

  const { auth:{access_token}, templates } = useSelector(
     state => ({
      auth: state.auth,
      templates: state.templates.templates
    })
  );
  
  const dispatch = useDispatch();
  const history = useNavigate()
  
  const [searchdescription, setSearchDescription] = useState('');
  const [rows, setRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);
  //const [flagAdminRemoved, setFlagAdminRemoved] = useState(false);

  const headCells = [
    { id: 'description', numeric: false, disablePadding: true, label: 'Description', searchOption: true },
  ];

  useEffect(() => {

    if(templates === null){
      dispatch(getTemplates(access_token))
    }else{

      let obj = [];
      let adminObj = {};

      for(let index in templates){
          adminObj = {
              id: templates[index].id,
              description: templates[index].name,
          };
          obj.push(adminObj);
      }
      setRows(obj);
      setDisplayRows(obj);
      
    }
  },[dispatch, access_token, templates])
    
  
  const filter = () => {
    let filterRows = rows.filter((row) => row.description.toString().toLowerCase().indexOf(searchdescription.toString().toLowerCase()) > -1);
    setDisplayRows(filterRows);
  }

  const deleteTemplate = (id) => {
    dispatch(deleteTemplates(access_token, id))
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchdescription':
        setSearchDescription(value);
        break;
      default:
        break;
    }

  }

  return (
    <DashboardLayout>
      <Container maxWidth="md">
      {/* {(access_create === 1) && ( */}
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
          
          <PaperLayout >
            <Box display="flex" flexDirection="row" >
              <Typography component="h1" variant="h6" noWrap className={classes.title}>
                Créer un modèle
              </Typography>
              <Link to="/create-mailbox/0">
                <AddCircleOutlineIcon className={classes.addUser} />
              </Link>
            </Box>
          </PaperLayout>
        </Box>
      {/* )} */}


      
        <PaperLayout>
          <Typography component="h1" variant="h6" noWrap className={classes.title}>
            LISTE DES MODÈLES
          </Typography>
          <Divider className={classes.divider} />
          <Box className={classes.gridSize}>
            <MailBoxListTable
              rows={displayRows}
              headCells={headCells}
              onChangeSearch={onChangeSearch}
              filter={filter}
              deleteTemplate={deleteTemplate}
            />
          </Box>

        </PaperLayout>
      </Container>
    </DashboardLayout>
  );
}

export default MailBoxList;