import React from 'react'
import { Input, Box, Checkbox, makeStyles, Typography } from '@material-ui/core'
import SelectInput from '../../share/inputs/SelectInput';

const useStyle = makeStyles((theme) => ({
    label: {
        fontSize: '1.2rem',
    },
    inputStyleBackgrund: {
        '& .MuiInputBase-input': {
            background: '#fff !important'            
        }
    },
    inputStyle: {
        '& .MuiInputBase-input': {
            padding: '8.5px 8px',
            fontSize: '12px',
            border: '1px solid #999898',
            borderRadius: '4px'
            //background: '#fff'            
        },
        '& .MuiInput-underline:before':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:after':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:focus':{
            border: '0px solid #999898',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before':{
            border: '0px solid #999898',
        },
        '& .MuiInputBase-input:hover':{
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiInputBase-input:focus':{
            border: `1px solid ${theme.palette.primary.main}`,
        },    
        '& input[type=number]::-webkit-inner-spin-button':{
            display: 'none',
            webkitAppearance: 'none',
        },
        '& input[type=number]::-webkit-outer-spin-button':{
            display: 'none',
            webkitAppearance: 'none'
        },
        '& [type=number]': {
            '-moz-appearance': 'textfield'
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& .MuiCheckbox-root':{
            padding: '0'
        }
/*        
        ,   
        '& .MuiInputBase-input':{
            //backgroundColor: '#fff'
        } 
*/        
    }
}))

const InputLabel = ({ id, label, arrayM , defaultValuep, defaultValuet, handleChange, handleDblClick, inputSelect, varTime }) => {
    const classes = useStyle()
    let inputStyleBD = classes.inputStyle
    
    if(arrayM.indexOf(`${id}_Check`) > -1){
        inputStyleBD = classes.inputStyle + ' ' + classes.inputStyleBackgrund
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Box style={{ marginRight: '5px', width: '98px' }}>
                <Typography className={classes.label} variant="h6">{label}</Typography>
            </Box>

            <Box className={inputStyleBD} style={{ maxWidth: (inputSelect) ? '165px' : '87px' } }>
                <Box display="flex">
                    <Box style={{ marginRight: '5px', width: '120px' }}>
                        <Box display="flex" flexDirection="column" mr={1} style={{ width: '100%' }}>
                            <Input
                                id={`${id}_Price`}
                                name={`${id}_Price`}
                                placeholder=""
                                label=""
                                size="small"
                                type="number"
                                onChange={e => handleChange(e)}
                                currencySymbol="$"
                                value={defaultValuep}
                                autoComplete='off'
                                readOnlyPro={true}                               
                            />
                        </Box>
                    </Box>

                    {(inputSelect) && (
                        <Box style={{ width: '200px' }}>
                            <Box display="flex" flexDirection="column" mr={1} style={{ width: '100%' }}> 
                                <SelectInput
                                    id={`${id}_Time`}
                                    name={`${id}_Time`}
                                    data={varTime}
                                    colorFont={{ color: '#000' }}
                                    onChange={(e) => handleChange(e)}
                                    defaultValue={defaultValuet}
                                />

                            </Box>
                        </Box>
                    )}
 
                    <Box style={{ width: '50px' }}>
                        <Checkbox 
                            id={`${id}_Check`}
                            name={`${id}_Check`}
                            color="primary" 
                            checked={(arrayM.indexOf(`${id}_Check`) > -1) ? true : false}  
                            onClick={e => handleDblClick(e, id, defaultValuep, defaultValuet)}
                        />
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default InputLabel