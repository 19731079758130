import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slider, Box } from '@material-ui/core';
//import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '@media(max-width: 600px)':{
      width: '100%',
    },

    '& .MuiSlider-rail':{
      height: '4px'
    },
    '& .MuiSlider-track':{
      height: '4px'
    },
    '& .MuiSlider-mark':{
      height: '4px'
    },
    '& .MuiSlider-thumb':{
      width: '17px',
      height: '17px',
      marginTop: '-7px'
    },
    '& .MuiSlider-valueLabel':{
      left: 'calc(-50% + 1px)',
      top: '-30px',
      '&>span':{
        width: '32px',
        height: '32px'
      }
    }
    
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function DiscreteSlider({value, handleScope, startValue, finalValue, step, marks, onOrOff}) {
  const classes = useStyles();
  
  return (
      <Box className={classes.root}>
        <Slider
          defaultValue={startValue}
          value={value} 
          onChange={handleScope}
          //aria-labelledby="discrete-slider-custom"
          step={step}
          marks={marks}
          valueLabelDisplay={onOrOff}
          //ValueLabelComponent={ValueLabelComponent}
          ThumbComponent="span"
          min={0}
          max={finalValue}
          aria-labelledby="range-slider"
        />
      </Box>
  );
}