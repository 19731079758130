import React from 'react'
import { Box, makeStyles, Button } from '@material-ui/core'
import Input from '../../share/inputs/Input'

const useStyle = makeStyles((theme) => ({
  filterArea:{
    padding: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const Filter = ({ handleChange, handleChangeDate, formData, filter }) => {
    const classes = useStyle()

    return (
        <Box className={classes.filterArea}>
            <Box pr={3}>
              <Input
                id="service"
                placeholder=""
                label="Service"
                size="small"
                width={'100%'}
                defaultValue={formData.service}
                onBlur={ e => handleChange(e) }
                variant="outlined"
                error=""
              />
            </Box>
            <Box pr={3}>
              <Input
                id="reservation"
                placeholder=""
                label="No Reservation"
                size="small"
                width={'100%'}
                defaultValue={formData.reservation}
                onBlur={ e => handleChange(e) }
                variant="outlined"
                error=""
              />
            </Box>
            <Box pr={3}>
              <Input
                id="pro"
                placeholder=""
                label="ID PRO"
                size="small"
                width={'100%'}
                defaultValue={formData.pro}
                onBlur={ e => handleChange(e) }
                variant="outlined"
                error=""
              />
            </Box>
            <Box pr={3}>
                <Input
                  id="date"
                  placeholder=""
                  label="Date"
                  size="small"
                  width={'100%'}
                  type="date"
                  defaultValue={formData.date}
                  onBlur={ e => handleChange(e) }
                  variant="outlined"
                  error=""
                />
            </Box>
            <Box pr={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={e => filter(e)}
              >
                RECHERCHER
              </Button>
            </Box>

       </Box>
    )
}

export default Filter