import React from 'react';
import { Box } from '@material-ui/core'
import DashboardLayout from '../../layout/Layout';
import RequestsListAccepter from './RequestsLitige';


const DashboardRequestLitige = () => {
  return(
    <DashboardLayout>
      <Box>
        <RequestsListAccepter />
      </Box>
    </DashboardLayout>
  );
}

export default DashboardRequestLitige;