import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PaperLayout from "../../share/layout/PaperLayout";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useNavigate } from "react-router-dom";
import {
    formateDate,
    compareDatesWithTodayDate
} from "../../share/librery/librery"; //getDate
import CustomModal from "../candidate/CustomModal";
//import { connect } from 'react-redux';
import { removeAdmin } from "../../../store/actions/adminAction";
import {
    getRequests,
    cleanReservation,
    sendRequestToAllPro,
} from "../../../store/actions/reservationAction";
import RequestTable from "../Tables/RequestTable";

import { useDispatch, useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
    btnDownloadReport: {
        "& span": {
            textTransform: "none",
        },
        "& .MuiButton-containedSecondary": {
            backgroundColor: "transparent",
        },
        "& .MuiButton-contained": {
            color: theme.palette.primary.main,
            boxShadow:
                "0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)",
            border: `1px solid ` + theme.palette.primary.main,
        },
    },
    addUser: {
        color: theme.palette.primary.main,
        fontSize: "3.5rem",
    },
    gridSize: {
        height: "80%",
        width: "100%",
    },
}));

const RequestsNonAffecter = () => {
    const classes = useStyle();

    const {
        auth: { access_token, userAdmin },
        admins: { adminRemoved },
        requests,
    } = useSelector((state) => ({
        auth: state.auth,
        admins: state.admin,
        requests: state.reservations.requests,
    }));
    const dispatch = useDispatch();
    const history = useNavigate()

    const { access_create, access_delete, access_update } = userAdmin.authority;
    const permit = { access_create, access_delete, access_update };

    const [searchuuid, setSearchuuid] = useState("");
    const [searchfirstName, setSearchfirstName] = useState("");
    const [searchcreationDate, setSearchcreationDate] = useState("");
    const [searchserviceDate, setSearchserviceDate] = useState("");
    const [searchstatus, setSearchstatus] = useState(-1);
    const [searchcity, setSearchcity] = useState("");
    const [searchpostcode, setSearchpostcode] = useState("");
    const [searchservice, setSearchservice] = useState("");
    const [rows, setRows] = useState([]);
    const [displayRows, setDisplayRows] = useState([]);
    const [flagAdminRemoved, setFlagAdminRemoved] = useState(false);

    const headCells = [
        {
            id: "uuid",
            numeric: false,
            disablePadding: true,
            label: "Id Requête",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "firstName",
            numeric: false,
            disablePadding: true,
            label: "Nom Client",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "lastName",
            numeric: false,
            disablePadding: true,
            label: "Service",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "postcode",
            numeric: false,
            disablePadding: true,
            label: "Code Postal",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "city",
            numeric: false,
            disablePadding: true,
            label: "Ville",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "creationDate",
            numeric: false,
            disablePadding: true,
            label: "Date création",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "serviceDate",
            numeric: false,
            disablePadding: true,
            label: "Date service",
            searchOption: true,
            type: "TextField",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            searchOption: true,
            type: "SelectInput",
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(cleanReservation());
        };
    }, [dispatch]);

    useEffect(() => {
        const getDataFromRequest = (d) => {
            let da = "";
            for (let i in d) {
                da = formateDate(`${d[i]} 00:00:00`) + ", " + da;
            }
            return da;
        };

        const displayRequests = () => {
            let obj = [];
            let adminObj = {};

            for (let index in requests) {
                let expired = false;
                const weekDate = requests[index].weekDate.split(",");
                let date = getDataFromRequest(weekDate);
                date = date.substring(0, date.length - 2);

                if (requests[index].isConfirmed > 0) {
                    if (requests[index].selectedDate !== null) {
                        date = formateDate(
                            `${requests[index].selectedDate} 00:00:00`
                        );
                    }
                } else {
                    expired = compareDatesWithTodayDate(weekDate);
                    console.log("Esta ha expirado: ", expired);
                }
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, "0");
                var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                var yyyy = today.getFullYear();
                today = yyyy + "-" + mm + "-" + dd;

                let requestStatus = 7;

                let validPro = requests[index].validPros;
                if(typeof validPro === 'string'){
                  validPro = parseInt(requests[index].validPros, 10);
                }
    
                if (
                    expired &&
                    requests[index].hasPros > 0 &&
                    validPro > 0 &&
                    requests[index].isConfirmed === 0
                ) {
                    adminObj = {
                        id: requests[index].id,
                        uuid: requests[index].uuid,
                        firstName: requests[index].first_name,
                        postcode: requests[index].postcode,
                        service: requests[index].service,
                        city: requests[index].city, //index === '1' ? requests[index].city : 'Montreal',
                        creationDate: formateDate(requests[index].created_at),
                        serviceDate: date,
                        expired: expired,
                        hasPros: requests[index].hasPros,
                        validPros: validPro,
                        isConfirmed: requests[index].isConfirmed,
                        requestStatus: requestStatus,
                    };
                    obj.push(adminObj);
                }
            }

            setRows(obj);
            setDisplayRows(obj);
        };

        if (requests === null) {
            dispatch(getRequests(access_token));
        } else {
            displayRequests();
        }
    }, [access_token, requests, dispatch]);

    const deleteAdmin = (id) => {
        setFlagAdminRemoved(true);
        dispatch(removeAdmin(access_token, id, history));
    };

    if (flagAdminRemoved) {
        if (adminRemoved !== null) {
            if (adminRemoved.msg === "success") {
                let filterRows = rows.filter(
                    (row) => row.id !== parseInt(adminRemoved.id)
                );
                setRows(filterRows);
                setDisplayRows(filterRows);
                setFlagAdminRemoved(false);
            }
        }
    }

    const filter = () => {
        let filterRows = rows.filter(
            (row) =>
                row.firstName
                    .toString()
                    .toLowerCase()
                    .indexOf(searchfirstName.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.postcode
                    .toString()
                    .toLowerCase()
                    .indexOf(searchpostcode.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.city
                    .toString()
                    .toLowerCase()
                    .indexOf(searchcity.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.uuid
                    .toString()
                    .toLowerCase()
                    .indexOf(searchuuid.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.creationDate
                    .toString()
                    .toLowerCase()
                    .indexOf(searchcreationDate.toString().toLowerCase()) > -1
        );
        filterRows = filterRows.filter(
            (row) =>
                row.serviceDate
                    .toString()
                    .toLowerCase()
                    .indexOf(searchserviceDate.toString().toLowerCase()) > -1
        );

        filterRows = filterRows.filter(
            (row) =>
                parseInt(row.requestStatus, 10) ===
                    parseInt(searchstatus, 10) ||
                parseInt(searchstatus, 10) === -1
        );

        filterRows = filterRows.filter(
            (row) =>
                row.service
                    .toString()
                    .toLowerCase()
                    .indexOf(searchservice.toString().toLowerCase()) > -1
        );
        //Searchposition
        setDisplayRows(filterRows);
    };

    const onChangeSearch = (e) => {
        const value = e.target.value;
        switch (e.target.name) {
            case "searchfirstName":
                setSearchfirstName(value);
                break;
            case "searchpostcode":
                setSearchpostcode(value);
                break;
            case "searchcity":
                setSearchcity(value);
                break;
            case "searchuuid":
                setSearchuuid(value);
                break;
            case "searchcreationDate":
                setSearchcreationDate(value);
                break;
            case "searchserviceDate":
                setSearchserviceDate(value);
                break;
            case "searchservice":
                setSearchservice(value);
                break;
            case "searchstatus":
                setSearchstatus(value);
                break;
            default:
                break;
        }
    };

    /** Popup features */

    const [openModal, setOpenModal] = useState(false);

    const closePopup = (e) => {
        e.preventDefault();
        setOpenModal(false);
    };

    const runFunctionFromYesPopupButton = (e) => {
        e.preventDefault();
        dispatch(sendRequestToAllPro(access_token));
        setOpenModal(false);
    };

    const handleChangeCheck = (e) => {
        e.preventDefault();
        setOpenModal(true);
    };

    return (
        <Box>
            <CustomModal
                openModal={openModal}
                closePopup={closePopup}
                content="Voulez-vous continuer la transaction ?"
                //runFunction={scheduledVideo ? addVideoDateProgrammed : updateBgStatus}
                runFunction={runFunctionFromYesPopupButton}
            />

            <PaperLayout>
                <Box
                    mb={3}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center">
                        <Box pr={10}>
                            <Typography variant="h5">REQUÊTES</Typography>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => handleChangeCheck(e)}
                            >
                                Assigner Auto
                            </Button>
                        </Box>
                    </Box>

                    <Box className={classes.btnDownloadReport}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<ArrowDownwardIcon />}
                        >
                            Télécharger rapport
                        </Button>
                    </Box>
                </Box>

                <Divider className={classes.divider} />
                <Box className={classes.gridSize}>
                    <RequestTable
                        rows={displayRows}
                        headCells={headCells}
                        onChangeSearch={onChangeSearch}
                        filter={filter}
                        deleteAdmin={deleteAdmin}
                        permit={permit}
                        searchstatus={searchstatus}
                    />
                </Box>
            </PaperLayout>
        </Box>
    );
};

export default RequestsNonAffecter;
