import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import DashboardLayout  from '../layout/Layout';
import { makeStyles } from '@material-ui/core/styles';
import PaperLayout from '../share/layout/PaperLayout';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Link, useNavigate } from 'react-router-dom';

//import { connect } from 'react-redux';
import { getAllUsers, removeAdmin } from '../../store/actions/adminAction';
import AdminTable from './Tables/AdminTable';

import { useDispatch, useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    marginRight: '20px',
    fontSize: "1.8rem"
  },
  btnDownloadReport: {
    marginTop: '-20px',
    marginRight: '30px',
    '& span': {
      textTransform: 'none'
    },
    '& .MuiButton-containedSecondary': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-contained': {
      color: theme.palette.primary.main,
      boxShadow: '0px 3px 1px -2px rgba(40,128,251,0.2), 0px 2px 2px 0px rgba(40,128,251,0.14), 0px 1px 5px 0px rgba(40,128,251,0.12)',
      border: `1px solid ` + theme.palette.primary.main
    }
  },
  addUser: {
    color: theme.palette.primary.main,
    fontSize: "3.5rem"
  },
  gridSize: {
    height: '80%',
    width: '100%'
  }
}));

const AdminList = () => {
  const classes = useStyle();

  const { auth:{access_token, userAdmin}, admins: { users, adminRemoved } } = useSelector(
    state => ({
      auth: state.auth,
      admins: state.admin
    })
  );
  const history = useNavigate()

  const dispatch = useDispatch();

  const { access_create, access_delete, access_update } = userAdmin.authority;
  const permit = { access_create, access_delete, access_update };

  const [searchfirstName, setSearchfirstName] = useState('');
  const [searchlastName, setSearchlastName] = useState('');
  const [searchposition, setSearchposition] = useState('');
  const [searchtimezone, setSearchtimezone] = useState('');
  const [searchemail, setSearchemail] = useState('');
  const [rows, setRows] = useState([]);
  const [displayRows, setDisplayRows] = useState([]);
  const [flagAdminRemoved, setFlagAdminRemoved] = useState(false);

  const headCells = [
    { id: '#', numeric: false, disablePadding: true, label: '', searchOption: false },
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Prénom', searchOption: true },
    { id: 'lastName', numeric: false, disablePadding: true, label: 'Nom', searchOption: true },
    { id: 'position', numeric: false, disablePadding: true, label: 'Post', searchOption: true },
    { id: 'timezone', numeric: false, disablePadding: true, label: 'Timezone', searchOption: true },
    { id: 'email', numeric: false, disablePadding: true, label: 'Courriel', searchOption: true },
  ];


  //Ingresa solo la primera vez que carga la pagina
  useEffect(() => {

      const getInfo = () => {
        let obj = [];
        let adminObj = {};

        for (let index in users) {
          if (users[index].credential !== null) {
            adminObj = {
              id: users[index].id,
              firstName: users[index].first_name,
              lastName: users[index].last_name,
              email: users[index].email,
              position: users[index].position,
              timezone: users[index].timezone,
//              avatar_id: users[index].avatar_id,
              deleted_at: users[index].deleted_at,
              //urlAvatar: users[index].credential.avatar.url
            };
            if (users[index].avatar!== null) {
              adminObj.urlAvatar = users[index].avatar.url;
            } else {
              adminObj.urlAvatar = 'images/default_man.png';
            }
            obj.push(adminObj);
          }
        }

        setRows(obj);
        setDisplayRows(obj);

      }

      if (users === null) {
        dispatch(getAllUsers(access_token));
      } else {
        getInfo();
      }

    },[access_token, users, dispatch]
  )

  const deleteAdmin = (id) => {
    setFlagAdminRemoved(true);
    dispatch(removeAdmin(access_token, id, history))
  }

  if (flagAdminRemoved) {
    if (adminRemoved !== null) {

      if (adminRemoved.msg === "success") {
        let filterRows = rows.filter(row => row.id !== parseInt(adminRemoved.id));
        setRows(filterRows)
        setDisplayRows(filterRows);
        setFlagAdminRemoved(false);
      }

    }
  }

  const filter = () => {
    let filterRows = rows.filter((row) => row.firstName.toString().toLowerCase().indexOf(searchfirstName.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.lastName.toString().toLowerCase().indexOf(searchlastName.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.position.toString().toLowerCase().indexOf(searchposition.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.email.toString().toLowerCase().indexOf(searchemail.toString().toLowerCase()) > -1);
    filterRows = filterRows.filter((row) => row.timezone.toString().toLowerCase().indexOf(searchtimezone.toString().toLowerCase()) > -1);
    setDisplayRows(filterRows);
  }

  const onChangeSearch = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'searchfirstName':
        setSearchfirstName(value);
        break;
      case 'searchlastName':
        setSearchlastName(value);
        break;
      case 'searchposition':
        setSearchposition(value);
        break;
      case 'searchemail':
        setSearchemail(value);
        break;
      case 'searchtimezone':
        setSearchtimezone(value);
        break;        
      default:
        break;
    }

  }
  
  return (
    <DashboardLayout>
      {(access_create === 1) && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
          <Box className={classes.btnDownloadReport}>
            <Button variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<ArrowDownwardIcon />}
            >
              Télécharger rapport
            </Button>
          </Box>
          <PaperLayout >
            <Box display="flex" flexDirection="row" >
              <Typography component="h1" variant="h6" noWrap className={classes.title}>
                CRÉER UN SALARIÉ
              </Typography>
              <Link to="/admin">
                <AddCircleOutlineIcon className={classes.addUser} />
              </Link>
            </Box>
          </PaperLayout>
        </Box>
      )}


      <PaperLayout>
        <Typography component="h1" variant="h6" noWrap className={classes.title}>
          LISTE DES ADMINISTRATEURS
        </Typography>
        <Divider className={classes.divider} />
        <Box className={classes.gridSize}>
          <AdminTable
            rows={displayRows}
            headCells={headCells}
            onChangeSearch={onChangeSearch}
            filter={filter}
            deleteAdmin={deleteAdmin}
            permit={permit}
          />
        </Box>
      </PaperLayout>
    </DashboardLayout>
  );
}

export default AdminList;