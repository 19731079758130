import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import EmailConfirmationForm from './EmailConfirmationForm';
import TypeEmailForm from './TypeEmailForm';
import { connect } from 'react-redux';

import { getEmailToResetAdminPassword, resetAdminPassword } from '../../../store/actions/adminAction';
import { Navigate } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link to="#" color="inherit" href="https://www.tiggidoo.com/">
                {new Date().getFullYear()} Tiggidoo
            </Link>
            {' '}
            {' © All rights reserved'}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const steps = [`Entrez l'email`, 'Confirmation'];

function getStepContent(step, email, handleChange, message) {
    switch (step) {
        case 0:
            return <TypeEmailForm
                email={email} handleChange={handleChange} message={message}
            />;
        case 1:
            return <EmailConfirmationForm email={email}/>;
        default:
            throw new Error('Unknown step');
    }
}

const ResetPasswordAdminForm = ({ admin: { status }, getEmailToResetAdminPassword, resetAdminPassword }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [email, setEmail] = useState();
    const [emailSent, setEmailSent] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = e => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleNext = () => {
        if (activeStep === 0) {
            getEmailToResetAdminPassword(email);
            setEmailSent(true);
        }
    };

    if(emailSent){
        if(status === 200){
            setActiveStep(activeStep + 1);
            setMessage('');
            setEmailSent(false);
            resetAdminPassword(email);
        }else{
            if(status === 404){
                setMessage(`Le courrier n'existe pas`);
                setEmailSent(false);
            }
        }        
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
/*
    const checkIfEmailExists = e => {
        e.preventDefault()
        getEmailToResetAdminPassword(email);
    }
*/
    return (
        <React.Fragment>
            <Container component="main" maxWidth="md">
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Box display="flex" justifyContent="center" mb={5} mt={1}>
                        <img src="images/logo_tiggidoo.svg" alt="" width="250" />
                    </Box>

                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <Navigate to="/" />
                        ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep, email, handleChange, message)}
                                    <div className={classes.buttons}>
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Retour
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Login' : 'Suivant'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                </Paper>
                <Copyright />
            </main>
            </Container>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    admin: state.admin
})

export default connect(mapStateToProps, { getEmailToResetAdminPassword, resetAdminPassword })(ResetPasswordAdminForm);
