import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Box } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyle = makeStyles((theme) => ({
  areaOption: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 1, 2, 1),
    border: `2px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(4)
  },
  title: {
    '& h6': {
      color: '#2880fb !important',
      marginTop: '-37px',
      backgroundColor: '#fff',
      width: 'max-content',
      padding: theme.spacing(0, 1)
    }
  },
  iconDisplayInfo: {
    marginBottom: '-34px',
    backgroundColor: '#fff',
    width: 'max-content',
    textAlign: 'center'
  },
  iconStyle: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '17px',
    color: theme.palette.primary.main
  }

}))

const BlueBox = ({ children, title, displayOptionChanges, displayOptions }) => {
  const classes = useStyle()
  return (

    <Box className={classes.areaOption}>
      <Box className={classes.title}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      { children}
      <Box display="flex" justifyContent="center">
        <Box className={classes.iconDisplayInfo}>
          {
            (title === displayOptions)
              ?
              (<KeyboardArrowUpIcon className={classes.iconStyle} fontSize='large' onClick={e => displayOptionChanges(e, 'TYPE')} />)
              :
              (<KeyboardArrowDownIcon className={classes.iconStyle} fontSize='large' onClick={e => displayOptionChanges(e, title)} />)
          }
        </Box>
      </Box>
    </Box>

  )
}

export default BlueBox
