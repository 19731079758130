import React, { useState, useEffect } from 'react'
import { Box, Typography, AppBar, Tabs, Tab, makeStyles } from '@material-ui/core'
import DashboardLayout from '../../layout/Layout';
import PaperLayout from '../../share/layout/PaperLayout';
import Tariff from './Tariff';
import { useParams } from 'react-router';
import Tester from './Tester';
import { useDispatch, useSelector } from 'react-redux'
import { getEstimation } from '../../../store/actions/serviceAction';

const useStyle = makeStyles((theme) => ({
  appBarArea: {
    '& .MuiBox-root': {
      //padding: '0px'
    },
    '& .MuiTab-root': {
      '@media(max-width: 600px)': {
        fontSize: '1.5rem',
        padding: '0'
      }
    },
    '& .MuiAppBar-root':{
      backgroundColor:'transparent', 
      color: '#000', 
      boxShadow: 'none',
      marginBottom: theme.spacing(4),
      '& button:focus':{
        outline: 'none',
        '& span':{
          fontWeight:'bold'
        }
      }
    }
  },
  tariffArea: {
    '& h5': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    '& h6': {
      color: '#000',
      fontWeight: 'bold'
    },
    '& .MuiInputBase-input': {
      backgroundColor: '#c7c6c6'
    },
    label: {
      fontSize: '1.4rem'
    }
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TariffTester = () => {
  const classes = useStyle()

  const { auth: { access_token }, services: { tariff, estimation } } = useSelector(
    state => ({
      auth: state.auth,
      services: state.services
    })
  );
  const dispatch = useDispatch()
  const formData = {
    country: 'Canada',
    city: 'Montréal',
    service: 'Ménage'
  }

  const { country, city, service } = formData;

  const [value, setValue] = useState(0);
  const [dataTH, setDataTH] = useState(null)
  const [dataHS, setDataHS] = useState(null)
  const [dataTester, setDataTester] = useState({
    houseCategoryId: 2,
    houseSizeId: 0,
    floors: 0,
    bedroom: 0,
    bathroom: 0,
    washbasin: 0,
    kitchens: 0,
    salon: 0,
    diningRoom: 0,
    optionBeds: 0,
    optionBathtub: 0,
    optionFridge: 0,
    optionFour: 0,
    shower: 0,
    product: false,
    ecoProduct: false
  })

  const { cityId } = useParams()

  useEffect(() => {
    if (tariff !== null) {

      const housing_category = tariff['housing_category']
      let category = []
      for (let index in housing_category) {
        category[index] = {
          id: housing_category[index].id,
          name: housing_category[index].fr,
          color: '#000'
        }
      }
      const housing_size = tariff['housing_size']

      let size = [{
        id: '',
        name: 'NA',
        color: '#000'
      }]
      for (let index in housing_size) {
        size[index + 1] = {
          id: housing_size[index].id,
          name: housing_size[index].fr,
          color: '#000'
        }
      }

      setDataHS(size)
      setDataTH(category)
    }
  }, [tariff])

  const handleChangeTester = (e) => {

    if(e.target.type === 'checkbox'){
      setDataTester({ ...dataTester, [e.target.name]: e.target.checked })
    } else {
      e.preventDefault()
      setDataTester({ ...dataTester, [e.target.name]: e.target.value })
    }   
  }

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      dispatch(getEstimation(dataTester))
    }
  }

  const onClick = (e) => {
    dispatch(getEstimation(dataTester))
  }

  return (
    <DashboardLayout>
      
      <Box className={classes.tariffArea}>
        {/*<Grid container >
           <Grid item sm={7}>
            <PaperLayout>
              <Box display="flex" flexDirection="row" >
                <Typography variant="h5" noWrap>GRILLES TARIFAIRES</Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="column" mt={2} mr={2}>
                  <Box>
                    <Typography variant="h6">COUNTRY</Typography>
                  </Box>
                  <Box>
                    <Input
                      id="country"
                      placeholder=""
                      label=""
                      size="small"
                      width={'100%'}
                      defaultValue={country}
                      onBlur={handleChange}
                      variant="outlined"
                      error=''
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" mt={2} mr={2}>
                  <Box>
                    <Typography variant="h6">VILLE</Typography>
                  </Box>
                  <Box>
                    <Input
                      id="city"
                      placeholder=""
                      label=""
                      size="small"
                      width={'100%'}
                      defaultValue={city}
                      onBlur={handleChange}
                      variant="outlined"
                      error=''
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" mt={2}>
                  <Box>
                    <Typography variant="h6">SERVICE</Typography>
                  </Box>
                  <Box>
                    <Input
                      id="service"
                      placeholder=""
                      label=""
                      size="small"
                      width={'100%'}
                      defaultValue={service}
                      onBlur={handleChange}
                      variant="outlined"
                      error=''
                    />
                  </Box>
                </Box>
              </Box>
            </PaperLayout>
          </Grid>
        </Grid> */}

        <Box className={classes.appBarArea}>
          <PaperLayout>
            <Box mb={3}>
              <Typography variant="h5" noWrap>{`${country} - ${city} - ${service}`}</Typography>
            </Box>

            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Grilles tarifaires" {...a11yProps(0)} />
                <Tab label="Testeur" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <Box>
                <Tariff cityId={cityId} access_token={access_token} tariff={tariff} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box>
                <Tester
                  dataTH={dataTH}
                  dataHS={dataHS}
                  handleChangeTester={handleChangeTester}
                  dataTester={dataTester}
                  keyPress={keyPress}
                  onClick={onClick}
                  estimation={estimation}
                />
              </Box>
            </TabPanel>
          </PaperLayout>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default TariffTester