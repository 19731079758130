import { GETTING_ALL_CONTACTS, PROCESSING, GETTING_A_CONTACT  } from '../actions/typesAction'

const initState = {
    processing: true,
    contact: null,
    list: null,
    authError: null,
    status: null
};

const contactReducer = (state = initState, action) => {
    switch (action.type) {
        case PROCESSING:
            return initState;
        case GETTING_A_CONTACT:
            return {
                ...state,
                contact: action.payload,
                list: null,
                processing: false
            };        
        case GETTING_ALL_CONTACTS:
            return {
                ...state,
                contact: null,
                list: action.payload,
                processing: false
            };    
        default:
            return initState;
    }
}

export default contactReducer;