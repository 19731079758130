import axios from 'axios';
import { setAlert } from './alertAction';
import { GET_SERVICES, CREATE_A_NEW_SERVICE, ADMIN_FAIL, PROCESSING, GET_SERVICES_BY_CITY, GET_TARIFF_BY_CITY, GET_ESTIMATION } from './typesAction'
import config from '../../config.json';

export const getAllServices = (token, name) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/services/all`, headers)
            .then((res) => {

                dispatch({
                    type: GET_SERVICES,
                    payload: res.data
                })
            }).catch((error) => {
                console.log(error);
            })


    } catch (error) {
        console.log(error)
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const createService = (token, nameEn, nameFr) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            en: nameEn,
            fr: nameFr
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/service/create`, data, headers)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_A_NEW_SERVICE,
                        payload: res.data
                    })
                    dispatch(setAlert('Le service a été créé avec succès', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}


export const deleteService = (token, id) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/service/delete`, data, headers)
            .then(res => {
                if (res.status === 200) {
                    // dispatch({
                    //     type: CREATE_A_NEW_SERVICE,
                    //     payload: res.data
                    // })
                    dispatch(setAlert('Le service a été créé supprimé', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}

export const updateService = (token, id, name) => async dispatch => {
    try {
        dispatch({
            type: PROCESSING
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            name: name
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/service/update`, data, headers)
            .then(res => {
                if (res.status === 200) {
                    // dispatch({
                    //     type: CREATE_A_NEW_SERVICE,
                    //     payload: res.data
                    // })
                    dispatch(setAlert('Le service a été créé supprimé', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}

export const getServicesByCity = (token) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        await axios.get(`${config.API_SERVER}/api/v1/admin/price/availability`, headers)
            .then((res) => {

                dispatch({
                    type: GET_SERVICES_BY_CITY,
                    payload: res.data
                })
            }).catch((error) => {
                console.log(error)
                dispatch({
                    type: GET_SERVICES_BY_CITY,
                    payload: []
                })
            })


    } catch (error) {
        console.log(error)
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const getTariff = (token, cityId) => async dispatch => {
    try {

        dispatch({
            type: PROCESSING,
        })

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: cityId
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/price/all`, data, headers)
            .then((res) => {

                if (res.status === 200) {
                    dispatch({
                        type: GET_TARIFF_BY_CITY,
                        payload: res.data.data
                    })
                }

            }).catch((error) => {
                console.log(error);
            })


    } catch (error) {
        console.log(error)
        dispatch({
            type: ADMIN_FAIL,
            error,
        })
    }
}

export const updateTariffPriceAndTime = (token, formData, tariff) => async dispatch => {
    try {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            tableName: formData.table,
            id: formData.id,
            price: formData.price,
            duration: formData.time
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/price/update`, data, headers)
            .then((res) => {

                if (res.status === 200) {

                    for (let index in tariff[formData.table]) {
                        if (tariff[formData.table][index].id === parseInt(formData.id)) {
                            tariff[formData.table][index].price = res.data.update[0].price
                            tariff[formData.table][index].duration = res.data.update[0].duration
                        }
                    }
                    dispatch({
                        type: GET_TARIFF_BY_CITY,
                        payload: tariff
                    })
                    dispatch(setAlert(`Le taux a été modifié avec succès le prix ${res.data.update[0].price} et la durée ${res.data.update[0].duration}`, 'success'))
                }

            })
            .catch((error) => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}


export const updateBasePrice = (token, formData, tariff) => async dispatch => {
    try {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            tableName: formData.table,
            id: formData.id,
            basePrice: formData.price,
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/price/updateBasePrice`, data, headers)
            .then((res) => {

                if (res.status === 200) {
                    for (let index in tariff[formData.table]) {
                        if (tariff[formData.table][index].id === parseInt(formData.id)) {
                            tariff[formData.table][index].price = formData.price
                        }
                    }
                    dispatch({
                        type: GET_TARIFF_BY_CITY,
                        payload: tariff
                    })
                    dispatch(setAlert(`Le taux a été modifié avec succès le prix`, 'success'))
                }

            })
            .catch((error) => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}


export const updateProPersonalization = (token, formData, tariff) => async dispatch => {
    try {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            tableName: formData.table,
            id: formData.id,
            price: formData.price,
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/price/updateProPersonalization`, data, headers)
            .then((res) => {

                if (res.status === 200) {
                    for (let index in tariff[formData.table]) {
                        if (tariff[formData.table][index].id === parseInt(formData.id)) {
                            tariff[formData.table][index].price = formData.price
                        }
                    }
                    dispatch({
                        type: GET_TARIFF_BY_CITY,
                        payload: tariff
                    })
                    dispatch(setAlert(`Le taux a été modifié avec succès le prix`, 'success'))
                }

            })
            .catch((error) => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
}

export const getEstimation = (formData) => async dispatch => {

    try {

        const data = {
            "housingCategoryId": parseInt(formData.houseCategoryId), //3
            "housingSizeId": parseInt(formData.houseSizeId), //null
            "housingSpecificity": {
                "floor": parseInt(formData.floors), //0
                "bedroom": parseInt(formData.bedroom), //4
                "bathroom": parseInt(formData.bathroom), //1
                "washbasin": parseInt(formData.washbasin), //0
                "kitchen": parseInt(formData.kitchens), //1
                "salon": parseInt(formData.salon), //1
                "dining_room": parseInt(formData.diningRoom), //0
                "shower": parseInt(formData.shower), //0
                "bathtub": 0
            },
            // "housingPet": {
            //     "cat": false,
            //     "dog": false
            // },
            "houseworkFrequencyId": null,
            "houseworkPersonalization": {
                "oven": parseInt(formData.optionFour), //1,
                "fridge": parseInt(formData.optionFridge), //2
                "bed": parseInt(formData.optionBeds), //3
                "vacuum": false,
                "product_ecological": formData.ecoProduct,
                "product_standard": formData.product
            }
        }

        await axios.post(`${config.API_SERVER}/api/housing/estimation`, data)
            .then((res) => {
                
                if (res.status === 200) {
                    dispatch({
                        type: GET_ESTIMATION,
                        payload: res.data.estimation.calculation
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
} 

export const linkTariffAndService = (token, id, documentId) => async dispatch => {

    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id,
            sheetId: documentId
        }

        await axios.post(`${config.API_SERVER}/api/v1/admin/price/create`, data, headers)
            .then((res) => {
                
                if (res.status === 200) {
                    dispatch(setAlert('Les frais ont été liés avec succès au service', 'success'))
                }
            })
            .catch(error => {
                console.log(error)
            })

    } catch (error) {
        console.log(error)
    }
} 