import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { cleanCandidateAction, getAllCandidates, unlockCandidateAction } from "../../../store/actions/candidateAction";
import { formateDate, formateDateT } from '../../share/librery/librery'
import config from '../../../config.json';
import axios from "axios";

export const LogicCandidateList = () => {

    const [rows, setRows] = useState(null);
    const auth = useSelector(state => { return state.auth })
    const { access_token: token, userAdmin } = auth
    const candidate = useSelector(state => { return state.candidate })
    const { candidates } = candidate

    const { recruitment_delete, recruitment_unlock, recruitment_update } = userAdmin.authority;
    const permit = { recruitment_delete, recruitment_unlock, recruitment_update };

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(cleanCandidateAction())
    }, [dispatch])

    useEffect(() => {
        let ignore = false;

        const initialFilter = (fils) => {

            let filterRows = fils.map((row) => {
                row.created_at = formateDateT(row.credential.created_at);
                row.time_interview = (row.interview_status === 0) ? '-' : formateDate(row.time_interview);
                row.firstName = row.credential.firstName;
                row.lastName = row.credential.lastName;
                row.city = row.address.city;

                return row;
            })
            setRows(filterRows);
        }

        const getCandidateList = () => {
            if (candidates === null) {
                dispatch(getAllCandidates(token))
            } else {
                let arrayCandidate = candidates.filter(can => can.status_id <= 2);
                initialFilter(arrayCandidate);

            }
        }

        if (!ignore) {
            getCandidateList()
        }

        return () => {
            ignore = true;
        }

    }, [dispatch, candidates, token])


    const deleteCandidate = (id) => {
        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }
        const data = {
            id: id
        }

        try {
            axios.post(`${config.API_SERVER}/api/v1/candidate/remove`, data, headers)
                .then((res) => {
                    dispatch(getAllCandidates(token))
                }).catch((error) => {
                    console.error(error)
                })

        } catch (error) {
            console.error(error)
        }
    }

    const unlockCandidate = (e, id, firstName, lastName) => {
        e.preventDefault();
        console.log('Desbloquear: ', id, firstName, lastName)
        dispatch(unlockCandidateAction(id, token, firstName, lastName))
            .then(() => {
                dispatch(getAllCandidates(token))
            })
        // let changeStatusItem = rows.filter(item => {
        //     if (item.id === id) {
        //         item.lock_status = 0;
        //     }
        //     return item;
        // });
        // setFilterrows(changeStatusItem);
        // setRows(changeStatusItem);

    }

    return {
        rows,
        permit,
        deleteCandidate,
        unlockCandidate
    }
}