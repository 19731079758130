import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@material-ui/core'
import Pro from './Pro'
import MapPro from './MapPro'
import { getDefaultFilterPros } from '../../../../store/actions/reservationAction'
//import Processing from '../../Processing'


function Response({client, proChosen, handleChosenPro, access_token, reservationId, startDate}) {

    const { defaultProfs } = useSelector(
        state => ({            
            defaultProfs: state.reservations.prosFilter
        })
    )
    const dispatch = useDispatch()

    const [profs, setProfs] = useState([])
    const [dPro, setDPro]  = useState([])

    useEffect(() => {
        if(defaultProfs === null){
            dispatch(getDefaultFilterPros(access_token, reservationId))
        }else{
            setProfs(defaultProfs)
        }

    }, [access_token, defaultProfs, reservationId, dispatch])
    
    return (
        <Box>
            {
                profs.length === 0 
                ? 
                    (
                        <Box textAlign="center" py={5} >
                            <Typography variant="4">Il n'y a pas de professionnels liés au filtre</Typography>
                        </Box>
                    ) 
                :
                    (
                        <Box>
                            <Grid container >
                                <Grid item md={6} lg={6}>                                    
                                    {
                                        profs.map((row, index) => {
                                            return(
                                                <Pro key={index} pro={row} proChosen={proChosen} handleChosenPro={handleChosenPro} access_token={access_token} startDate={startDate} dPro={dPro} setDPro={setDPro} />
                                            )
                                        })
                                    }                                    
                                </Grid>
                                <Grid item md={6} lg={6}>
                                    <Box display="flex" justifyContent="center" mt={3}>
                                        <MapPro profs={profs} address={client.address} pin = {false}/>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
            }
        </Box>
    )
}

export default Response
