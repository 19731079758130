import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsAccepter from "./RequestsAccepter";

const DashboardRequestAccepter = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsAccepter />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestAccepter;
