import { GET_LIST_PROMOCODES } from "./typesAction";
import config from '../../config.json'
import axios from 'axios'

export const createPromoAction = (token, value) => async dispatch => {
    try {
        const header = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            value: value
        }

        axios.post(`${config.API_SERVER}/api/v1/admin/promotion/create`, data, header)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getPromosAction(token))
                }
            })

    } catch (error) {
        console.log("Error: ", error)
    }
}

export const updatePromoAction = (token, id, code) => async dispatch => {
    try {
        const header = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id,
            code
        }

        axios.post(`${config.API_SERVER}/api/v1/admin/promotion/update`, data, header)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getPromosAction(token))
                }
            })

    } catch (error) {
        console.log("Error: ", error)
    }
}

export const deletePromoAction = (token, id) => async dispatch => {
    try {
        const header = {
            headers: { 'Authorization': `Bearer ${token}` }
        }

        const data = {
            id: id
        }

        axios.post(`${config.API_SERVER}/api/v1/admin/promotion/delete`, data, header)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getPromosAction(token))
                }
            })


    } catch (error) {
        console.log("Error: ", error)
    }
}

export const getPromosAction = (token) => async dispatch => {
    try {

        const headers = {
            headers: { 'Authorization': `Bearer ${token}` }
        }


        await axios.get(`${config.API_SERVER}/api/v1/admin/promotion/all`, headers)
            .then((res) => {
                if (res.status === 200) {
                    dispatch({
                        type: GET_LIST_PROMOCODES,
                        payload: res.data
                    })
                }
            })

    } catch (error) {
        console.log("Error: ", error)
    }
}