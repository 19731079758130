import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import DashboardLayout from '../../layout/Layout';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux'
import { sendEmail, getTemplates, updateTemplate, createTemplate, getAllEmails } from '../../../store/actions/emailTemplateAction';
import Input from '../../share/inputs/Input';
import SelectInput from '../../share/inputs/SelectInput';
import { useNavigate } from 'react-router';
import { Grid } from '@material-ui/core';

const SendMail = () => {

    const { auth:{ access_token }, templates: { templates, emails } } = useSelector(
        state => ({
            auth: state.auth,
            templates: state.templates
        })
    );

    const dispatch = useDispatch();
    const history = useNavigate();

    const emailEditorRef = useRef(null);
    const [dataTem, setDataTem] = useState();
    const [validateName, setValidateName] = useState(false);
    const [searchEmail, setSearchEmail] = useState([]);
    const [searchEmailStatus, setSearchEmailStatus] = useState(false);
    const [searchCCStatus, setSearchCCStatus] = useState(false);

    const [formData, setFormData] = useState({
        to: '',
        title: '',
        cc: '',
        subject: '',
        name: '',
        templateId: 0,
        templateExistes: false
    })

    const { to, title, cc, subject, name, templateId, templateExistes } = formData;

    useEffect(() => {
        if(emails === null){
            dispatch(getAllEmails(access_token))
        }else{
            
            setSearchEmail(emails)
        }
    }, [dispatch, access_token, emails])

    useEffect(() => {

        if(templates === null){
          dispatch(getTemplates(access_token))
        }else{
            const arrayTem = [];
            let adminObj = [];
            for(let index in templates){
                adminObj[templates[index].name] = templates[index].id;
                arrayTem[templates[index].id] = {
                    id: templates[index].id,
                    name: templates[index].name,
                    json: templates[index].json,
                    
                }
            }
            setDataTem(arrayTem);
        }

    },[dispatch, access_token, templates])
        
    const handleChange = (e) => {
        if(e.target.name === 'templateExistes'){
            setFormData({...formData, [e.target.name]: e.target.checked})
        }else{

            if(e.target.name === 'name'){
                if(e.target.value.length === 0){
                    setValidateName(true)
                }else{
                    setValidateName(false)
                }  
            }

            if(e.target.name === 'to'){
                const res = emails.filter(row => row.email.toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1)
                setSearchEmail(res)
                setSearchEmailStatus(true)
            }

            if(e.target.name === 'cc'){
                const ccCopy = cc.split(';')
                const currentMail = ccCopy[ccCopy.length-1]
                const res = emails.filter(row => row.email.toString().toLowerCase().indexOf(currentMail.trim()) > -1)
                setSearchEmail(res)
                setSearchCCStatus(true)
            }

            setFormData({...formData, [e.target.name]: e.target.value})

        }
    }

    const exportHtml = (e) => {
        e.preventDefault()
        
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            const jsondata = JSON.stringify(data.design);
            saveTemplate(html, jsondata)
        });
    };    

    const saveTemplate = (html, jsondata) => {

        if(name.length > 0 && to.length > 0 && subject.length > 0){
            const blob = new File([html], name +'.html', {type : 'text/html;charset=utf-8'});

            if(templateExistes){
                dispatch(updateTemplate(access_token, templateId, blob, jsondata))
                .then(res => {
                    dispatch(sendEmail(access_token, to, cc, title, subject, name));
                })
            }else{
                dispatch(createTemplate(access_token, name, blob, jsondata, history))
                .then(res => {
                    dispatch(sendEmail(access_token, to,  cc, title, subject, name));
                })
            }

        }else{
            setValidateName(true)
        }
    }

    const loadTemplate = (e) => {
        e.preventDefault()
        setFormData({...formData, name: dataTem[templateId].name})
        emailEditorRef.current.editor.loadDesign(JSON.parse(dataTem[templateId].json));
    }

    const selectEmail = (e, email) => {
        e.preventDefault();
        setFormData({...formData, to: email})
        setSearchEmailStatus(false)
    }

    const selectEmailCC = (e, email) => {
        e.preventDefault();
        setFormData({...formData, cc: cc + email})
        setSearchCCStatus(false)
    }

    const getEmails = (emails) => {
        const html = [];
        for (const index in emails) {            
            html.push(
                <Box 
                    key={index} 
                    value={emails[index].email}
                >
                    <Box>
                        <a 
                            href="/#" 
                            onClick={e => selectEmail(e, emails[index].email)} 
                            style={{width: '100%', display: 'flex', flexDirection:"row"}} 
                            title="Email" 
                            rel="noreferrer"
                        >
                            <Box mr={1}>
                                <Typography variant="h6">
                                    {emails[index].email}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6" style={{color: '#2880f9'}}>
                                    { ` - <${emails[index].name}>` }
                                </Typography>
                            </Box>
                        </a>
                    </Box>
                </Box>
            );
        }
        return html;
    }

    const getEmailsCC = (emails) => {
        const html = [];
        for (const index in emails) {            
            html.push(
                <Box 
                    key={index} 
                    value={emails[index].email}
                >
                    <Box>
                        <a 
                            href="/#" onClick={e => selectEmailCC(e, emails[index].email)} 
                            style={{width: '100%', display: 'flex', flexDirection:"row"}} 
                            title="Email" 
                            rel="noreferrer"
                        >
                            <Box mr={1}>
                                <Typography variant="h6">
                                    {emails[index].email}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6" style={{color: '#2880f9'}}>
                                    { ` - <${emails[index].name}>` }
                                </Typography>
                            </Box>
                        </a>
                    </Box>
                </Box>
            );
        }
        return html;
    }
    
    return(
        <DashboardLayout>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <Input
                                    id="to"
                                    placeholder=""
                                    label="to"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={to}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error={(to.length === 0 && validateName)  ? 'Requerido' : ''}
                                />
                            </Box>
                            <Box display = {searchEmailStatus ? 'block' : 'none'}>
                                <Box style={{position: 'absolute', zIndex: 999, backgroundColor: '#ffffff', maxWidth: '35%'}}>
                                    <Box p={2}>
                                        { getEmails(searchEmail) }
                                    </Box> 
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Input
                                    id="cc"
                                    placeholder=""
                                    label="cc"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={cc}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error={(cc.length === 0 && validateName)  ? 'Requerido' : ''}
                                />
                            </Box>
                            <Box display = {searchCCStatus ? 'block' : 'none'}>
                                <Box style={{position: 'absolute', zIndex: 999, backgroundColor: '#ffffff', maxWidth: '35%'}}>
                                    <Box p={2}>
                                        { getEmailsCC(searchEmail) }
                                    </Box> 
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <Input
                                    id="title"
                                    placeholder=""
                                    label="title"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={title}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error={(title.length === 0 && validateName)  ? 'Requerido' : ''}
                                />
                            </Box>      
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box>
                                <Input
                                    id="subject"
                                    placeholder=""
                                    label="Subject"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={subject}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error={(subject.length === 0 && validateName)  ? 'Requerido' : ''}
                                />
                            </Box>       
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Input
                                    id="name"
                                    placeholder=""
                                    label="Template Name"
                                    size="small"
                                    width={'100%'}
                                    defaultValue={name}
                                    onBlur={e => handleChange(e)}
                                    variant="outlined"
                                    error={(name.length === 0 && validateName)  ? 'Requerido' : ''}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box>
                                <FormControlLabel                        
                                    control={
                                        <Checkbox
                                            name="templateExistes"
                                            onClick={(e) => handleChange(e)}
                                            color="primary" checked={templateExistes
                                            }
                                        />
                                    }
                                    label="Le modèle existe déjà"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>     

                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        {(templateExistes) && (
                            <Grid item xs={12}>
                                    <Box>
                                        <SelectInput
                                            id="templateId"
                                            name="templateId"
                                            data={dataTem}
                                            onChange={(e) => handleChange(e)}
                                            defaultValue={templateId}
                                        />        
                                    </Box>
                            </Grid>
                        )}
                        {(templateExistes) && (
                            <Grid item xs={12}>
                                <Box>
                                    {(templateExistes) && (
                                        <Box>
                                            <Button variant="contained" color="primary" onClick={e => loadTemplate(e)}>Télécharger le template</Button>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box>
                                <Button variant="contained" color="secondary" onClick={e => exportHtml(e)}>Envoyer</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>                
            </Grid>
            <Box>
                <EmailEditor
                    ref={emailEditorRef}                        
                    minHeight={'625px'}
                />
            </Box>
        </DashboardLayout>
  );
}

export default SendMail;