import React from 'react';
import { Box } from '@material-ui/core'
import DashboardLayout from '../../layout/Layout';
import RequestsListAccepter from './RequestsAnnuler';


const DashboardRequestAnnuler = () => {
  return(
    <DashboardLayout>
      <Box>
        <RequestsListAccepter />
      </Box>
    </DashboardLayout>
  );
}

export default DashboardRequestAnnuler;