import React, { useState } from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { Typography, IconButton } from '@material-ui/core'
import { Box } from '@material-ui/core'
import CheckBoxCustom from '../../../share/inputs/CheckBoxCustom'
import DateRangeIcon from '@material-ui/icons/DateRange';
import axios from 'axios'
import config from '../../../../config.json'
import { functionActivitiesHour } from '../../../share/librery/librery'
import moment from 'moment'

const useStyle = makeStyles((theme) => ({
    areaPro: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '7px',
        margin: '20px 0',
        maxWidth: '600px',
        borderRadius: '20px',
        boxShadow: '0px 10px 10px #00000055',
        '@media(max-width: 1366px)': {
            maxWidth: '550px',
        },
        '& .MuiAvatar-root': {
            '@media(max-width: 1366px)': {
                width: '36px',
                height: '36px',
            }
        },
        '& .MuiFormControlLabel-root': {
            marginRight: '0px',
            '& .PrivateSwitchBase-root-239': {
                padding: '0px'
            }
        }
    },
    proSelected: {
        backgroundColor: '#c4e4fa',
    },
    styleText: {
        margin: '0 7px',
        '& h6': {
            fontSize: '10px',
            fontWeight: 'bold',
            '@media(max-width: 1366px)': {
                fontSize: '9px',
            }
        },
        '& h4': {
            fontSize: '14px',
            fontColor: '#000 !important',
            fontWeight: 'bold',
            '@media(max-width: 1366px)': {
                fontSize: '10px',
            }
        },
        '& h4.idStyle': {
            fontSize: '9px',
            '@media(max-width: 1366px)': {
                fontSize: '9px',
            }
        }
    },
    labelCalendar: {
        fontSize: '10px',
        width: '31px',
        height: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #000',
        margin: '0.5px'
    },
    labelCalendarAmPm: {
        fontSize: '10px',
        width: '15px',
        fontWeight: 'bold',
        height: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px'
    },
    labelCalendarGreen: {
        backgroundColor: '#28cc8b',
        color: '#fff'
    },
    recommendedProColor: {
        position: 'absolute',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        width: "23px",
        height: "23px",
        fontWeight: "bold",
        borderRadius: "15px",
        backgroundColor: "#28a745",
        color: "#fff",
        zIndex: 1,
        left: -6,
        top: -3
    },
    recommendedProColorTransparent:{
        backgroundColor: "transparent",
        zIndex: -99
    },
    proRecommended: {
        backgroundColor: '#d7ffeb'
    }
}))



const Pro = ({ pro, proChosen, handleChosenPro, access_token, startDate, dPro, setDPro }) => {
    const classes = useStyle()

    const [schedules, setSchedules] = useState({
        proSchedules: []
    })

    const { proSchedules } = schedules

    let classCus = classes.areaPro
    if (proChosen.indexOf(pro.id) > -1) {
        classCus = classCus + ' ' + classes.proSelected
    }

    const runApi = async (proId) => {

        try {
            const headers = {
                headers: { 'Authorization': `Bearer ${access_token}` }
            }

            const data = {
                proId: proId,
                date: startDate
            }

            let arr = []
            await axios.post(`${config.API_SERVER}/api/v1/admin/reservation/pro/available`, data, headers)
                .then(res => {
                    arr = res.data.timeSheet

                })
                .catch((error) => {
                    console.log(error)
                })
            return arr
        } catch (error) {
            console.log('Error: ', error)
        }

    }

    const getCalendarPro = (e, proId) => {

        e.preventDefault()

        const index = dPro.indexOf(proId)
        if (index > -1) {
            const getDPro = dPro.filter(row => row !== proId)
            setDPro(getDPro)
            return true
        }

        runApi(proId)
            .then(res => {
                const getD = functionActivitiesHour(res, startDate)

                setSchedules(schedules => {
                    let proArray = []
                    proArray.push({
                        proId: proId,
                        schedule: getD
                    })

                    return {
                        proSchedules: proArray
                    }

                })
                setDPro([
                    ...dPro,
                    proId
                ])
            })

    }

    const printChedule = () => {
        let htmlAm = []
        const dayDispo = pro[moment(startDate + 'T00:00:00').format('dd')]
        console.log('Este es el profesional: ', dayDispo)

        let activities = null
        if (proSchedules.length > 0) {
            const pexist = proSchedules.filter(proSchedule => proSchedule.proId === pro.proId)
            if (pexist.length > 0) {
                activities = pexist[0].schedule
            }
        }

        if (dayDispo === 1 || dayDispo === 3) {
            for (let i = 8; i <= 13; i++) {
                let h = i
                if (i < 10) {
                    h = `0${i}`
                }
                let class0 = classes.labelCalendar
                if (i <= 13) {
                    let class3 = classes.labelCalendar
                    if (activities !== null) {
                        let obj = activities.filter(row => row.id === `${h}:00:00`)
                        if (obj.length > 0) {
                            class0 = class0 + ' ' + classes.labelCalendarGreen
                        }

                        obj = activities.filter(row => row.id === `${h}:30:00`)
                        if (obj.length > 0) {
                            class3 = class3 + ' ' + classes.labelCalendarGreen
                        }
                    }
                    if (i <= 12) {
                        htmlAm.push(<Box key={`${h}:00`} className={class0}>{`${h}:00`}</Box>)
                        htmlAm.push(<Box key={`${h}:30`} className={class3}>{`${h}:30`}</Box>)
                    }else{
                        if (dayDispo === 1) {
                            htmlAm.push(<Box key={`${h}:00`} className={class0}>{`${h}:00`}</Box>)
                        }
                    }

                }
            }
        }

        let htmlPm = []
        if (dayDispo === 2 || dayDispo === 3) {
            for (let i = 13; i <= 20; i++) {
                let class0 = classes.labelCalendar
                if (i < 20) {

                    let class3 = classes.labelCalendar
                    if (activities !== null) {
                        let obj = activities.filter(row => row.id === `${i}:00:00`)
                        if (obj.length > 0) {
                            class0 = class0 + ' ' + classes.labelCalendarGreen
                        }
                        obj = activities.filter(row => row.id === `${i}:30:00`)
                        if (obj.length > 0) {
                            class3 = class3 + ' ' + classes.labelCalendarGreen
                        }
                    }

                    htmlPm.push(<Box key={`${i}:00`} className={class0}>{`${i}:00`}</Box>)
                    htmlPm.push(<Box key={`${i}:30`} className={class3}>{`${i}:30`}</Box>)

                } else {
                    let class3 = classes.labelCalendar
                    if (activities !== null) {
                        let obj = activities.filter(row => row.id === `${i}:00:00`)
                        if (obj.length > 0) {
                            class0 = class0 + ' ' + classes.labelCalendarGreen
                        }
                        obj = activities.filter(row => row.id === `${i}:30:00`)
                        if (obj.length > 0) {
                            class3 = class3 + ' ' + classes.labelCalendarGreen
                        }
                    }
                    htmlPm.push(<Box className={class0}>{`${i}:00`}</Box>)
                }
            }
        }

        return (
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    {(dayDispo === 1 || dayDispo === 3) && (
                        <Box className={classes.labelCalendarAmPm}>AM</Box>
                    )}
                    <Box display="flex" flexDirection="row">
                        {htmlAm}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    {(dayDispo === 2 || dayDispo === 3) && (

                        <Box className={classes.labelCalendarAmPm}>PM</Box>
                    )}
                    <Box display="flex" flexDirection="row">
                        {htmlPm}
                    </Box>
                </Box>
            </Box>
        )
    }
    let displayR = classes.recommendedProColor
    if(!pro.rebook === true){
        displayR = displayR + ' ' + classes.recommendedProColorTransparent

    }else{
        classCus = classCus + ' ' + classes.proRecommended
    }

    return (
        <Box>
            <Box className={classCus}>
                <Box className={displayR}>R</Box>
                <Box>
                    <CheckBoxCustom
                        name={pro.proId}
                        label=""
                        value={(proChosen.indexOf(pro.proId) > -1) ? true : false}
                        handleChange={e => handleChosenPro(e, pro.push_token, pro.new_request)}
                    />
                </Box>
                <Box style={{ width: '80%' }}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box>
                            <Avatar alt={`${pro.firstName} ${pro.lastName}`} src={pro.url} />
                        </Box>
                        <Box className={classes.styleText}>
                            <Typography variant="h6">ID</Typography>
                            <Typography className={classes.idStyle} variant="h4">{pro.uuid}</Typography>
                        </Box>
                        <Box className={classes.styleText}>
                            <Typography variant="h6">NOM</Typography>
                            <Typography variant="h4">{`${pro.firstName} ${pro.lastName}`}</Typography>
                        </Box>
                        <Box className={classes.styleText}>
                            <Typography variant="h6">DISTANCE</Typography>
                            <Typography variant="h4">{pro.dist}</Typography>
                        </Box>
                        <Box className={classes.styleText}>
                            <Typography variant="h6">PRESTATIONS</Typography>
                            <Typography variant="h4">{pro.prestation}</Typography>
                        </Box>
                        <IconButton color="primary" aria-label="add to shopping cart"
                            onClick={e => getCalendarPro(e, pro.proId)}
                        >
                            <DateRangeIcon />
                        </IconButton>
                    </Box>

                    <Box style={{ marginLeft: '-65px' }}>
                        {
                            dPro.indexOf(pro.proId) > -1
                                ?
                                printChedule()
                                :
                                null
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Pro
