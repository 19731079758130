import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modalPaper: {
      position: 'absolute',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }
}))

function CustomModal(props) {

    const classes = useStyles();
    const { openModal, closePopup, content, runFunction } = props;
    
    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [modalStyle] = useState(getModalStyle);
    
    // const [open, setOpen] = useState(openModal);
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const body = (
        <div style={modalStyle} className={classes.modalPaper}>
            <h2 style={{ fontSize: "22px", marginBottom: '30px' }} >{content}</h2>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.deleteBtn}
                    onClick={e => runFunction(e)}
                >
                    Oui
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.deleteBtn}
                    onClick={e => closePopup(e)}
                >
                    Non
                </Button>
            </Box>
        </div>
    );

    return (
        <div>
            <Modal
                open={openModal}
                onClose={(e) => closePopup(e)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>      
        </div>
    );
}

export default CustomModal;
