import { Box, makeStyles, TextareaAutosize } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
    getARequest,
    proChosenByAdmin,
    offerRemoveByAdmin,
    cleanReservation,
} from "../../../store/actions/reservationAction";
import { updateHouseHoldListReservation } from "../../../store/actions/contactAction";
import DashboardLayout from "../../layout/Layout";
import PaperLayout from "../../share/layout/PaperLayout";
import ClientInfo from "./Components/ClientInfo";
import FilterAndReponse from "./Components/FilterAndReponse";
import ReqHeader from "./Components/ReqHeader";
import PendingPro from "./Components/PendingPro";
import {
    changeAlertStatus,
    setAlert,
} from "../../../store/actions/alertAction";
import ChangeHouseHoldList from "./Components/ContactClient/ChangeHouseHoldList";
import MethodToEnterAtHome from "./Components/ContactClient/MethodToEnterAtHome";
import Processing from "../Processing";
import Rating from '@material-ui/lab/Rating';

const useStyle = makeStyles((theme) => ({
    workArea: {
        "& .MuiPaper-root": {
            padding: theme.spacing(4, 0),
        },
    },
    backImage: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& img': {
            width: '30%',
            padding: '20px',
            "@media(max-width: 960px)": {
                width: '50%',
                padding: '10px',
            },
        }
    },
    complement: {
        display: 'flex',
        // justifyContent: 'space-between',
        // width: '50%',
        position: "relative",
        marginTop: '30px',
        '& p': {
            fontSize: '10px',
            color: '#111216',
            '@media(max-width: 600px)': {
                fontSize: '10px',
            }
        },
        '@media(max-width: 600px)': {
            marginLeft: '25px',
        }
    },
    nombre: {
        color: '#fff',
        padding: '2px 5px 0px 5px',
        position: 'absolute',
        marginLeft: '38px',
        fontSize: '10px',
        borderRadius: '8px',
        backgroundColor: '#1D1D1E',
    },
    button_complement_Opacity: {
        width: '50px',
        height: '50px',
        opacity: '1',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        marginBottom: '5px',
        '& #image': {
            width: '100%',
            height: '100%',

        },
        "&:focus": {

            outline: "none",
        }
    },
    iconMText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 15px',
        '@media(max-width: 700px)': {
            margin: '0 10px',
        }
    },
    enabledOptionArea: {
        border: '0px',
        background: '#c1c1c1c1',
        width: '100%',
        height: '150px !important',
        padding: '15px'
    },
}));

const Request = () => {
    const classes = useStyle();

    const {
        auth: { access_token },
        request,
        houseHoldList,
        alertActive,
        msgAlert,
    } = useSelector((state) => ({
        auth: state.auth,
        request: state.reservations.request,
        houseHoldList: state.tasks.active,
        alertActive: state.alert.active,
        msgAlert: state.alert.msg,
    }));

    const dispatch = useDispatch();
    const { id, reservationStatusId, updateHouseHoldList, requestStatus } =
        useParams();

    useEffect(() => {
        const unLock = () => {
            dispatch(cleanReservation());
        };
        return () => {
            unLock();
            window.addEventListener("beforeunload", unLock());
        };
    }, [dispatch]);

    useEffect(() => {
        if (alertActive) {
            dispatch(changeAlertStatus());
            dispatch(getARequest(access_token, id, updateHouseHoldList));
        }
    }, [access_token, id, dispatch, updateHouseHoldList, alertActive]);

    useEffect(() => {
        if (request === null) {
            dispatch(getARequest(access_token, id, updateHouseHoldList));
        }
    }, [access_token, dispatch, id, request, updateHouseHoldList]);

    const sendSelectPro = (prId, comment) => {
        if (comment !== undefined) {
            if (comment.trim() !== "") {
                dispatch(proChosenByAdmin(access_token, prId, comment.trim()));
            } else {
                dispatch(setAlert("Écrire un commentaire", "error"));
            }
        } else {
            dispatch(setAlert("Écrire un commentaire", "error"));
        }
    };

    const removeProByAdmin = (prId) => {
        dispatch(offerRemoveByAdmin(access_token, prId));
    };

    const updateHouseHoldListFunction = async (newHouseHoldList, method) => {
        dispatch(
            updateHouseHoldListReservation(
                access_token,
                newHouseHoldList,
                method,
                request.client.id
            )
        );
    };
    if (request === null) {
        return false
    }

    let displayPhotos = false
    if(request.profile_reservation.length > 0){
        if (request.profile_reservation[0].photo.length > 0) {
            displayPhotos = true
        }
    }

    return (
        <Box>
            <DashboardLayout>
                {request === null ? (
                    <Box>
                        <Processing />
                    </Box>
                ) : (
                    <Box className={classes.workArea}>
                        <PaperLayout>
                            <Box px={5} py={4} borderBottom={1}>
                                <ReqHeader
                                    uuid={request.uuid}
                                    service={request.service.fr}
                                    dates={request.housework.time}
                                    optionDate={request.housework.option_date}
                                    duration={request.total_duration}
                                    reservationStatusId={reservationStatusId}
                                    formule={request.housework.frequency.fr}
                                    requestStatus={requestStatus}
                                    isFinish={false}
                                />
                            </Box>
                            <Box px={5} py={4} borderBottom={1}>
                                <Box>
                                    <ClientInfo
                                        housing={request.housing}
                                        client={request.client}
                                        totalPrice={request.total_price}
                                        personalization={
                                            request.housework.personalization
                                        }
                                    />
                                    {reservationStatusId === "4" &&
                                        updateHouseHoldList === "true" ? (
                                        houseHoldList !== null ? (
                                            msgAlert.length > 0 ? (
                                                <Box>
                                                    <Processing />
                                                </Box>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box width="50%">
                                                        <MethodToEnterAtHome
                                                            method={
                                                                request
                                                                    .profile_reservation[0]
                                                            }
                                                        />
                                                    </Box>
                                                    <Box width="43%">
                                                        <ChangeHouseHoldList
                                                            selectedList={
                                                                request.client
                                                                    .household_list
                                                            }
                                                            houseHoldList={
                                                                houseHoldList
                                                            }
                                                            updateHouseHoldListFunction={
                                                                updateHouseHoldListFunction
                                                            }
                                                            method={
                                                                request
                                                                    .profile_reservation[0]
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            )
                                        ) : (
                                            <Box>
                                                <Processing />
                                            </Box>
                                        )
                                    ) : null}
                                </Box>
                            </Box>

                            {(reservationStatusId !== "4" && requestStatus !== "3" && request.deleted_at === null) && (
                                    <Box>
                                        <FilterAndReponse
                                            reservationId={id}
                                            address={request.client.address}
                                            personalization={
                                                request.housework
                                                    .personalization
                                            }
                                            reservationStatusId={
                                                reservationStatusId
                                            }
                                            duration={request.total_duration}
                                            startDate={
                                                request.housework.start_date
                                            }
                                            optionDate={
                                                request.housework.option_date
                                            }
                                        />
                                    </Box>
                                )}

                            {(reservationStatusId === "1" ||
                                reservationStatusId === "2" ||
                                reservationStatusId === "4" ||
                                requestStatus === "3" ||
                                requestStatus === "5" ||
                                requestStatus === "7") && (
                                    <Box px={5} pb={4} borderBottom={1}>
                                        <PendingPro
                                            pendingPros={request.pendingPros}
                                            base_price={request.total_price}
                                            reservationStatusId={
                                                reservationStatusId
                                            }
                                            sendSelectPro={sendSelectPro}
                                            removeProByAdmin={removeProByAdmin}
                                            reservationId={request.id}
                                        />
                                    </Box>
                                )}
                                
                                {(displayPhotos) && (
                                    <Box>
                                        <Box px={5} py={2} borderBottom={1}>
                                            {
                                                (request.profile_reservation[0].photo.length > 0) && (
                                                    <Box style={{ width: '100%' }}>
                                                        <Box>
                                                            IMAGES
                                                        </Box>
                                                        <Box className={classes.backImage}>
                                                            {
                                                                request.profile_reservation[0].photo.map((row, index) => {

                                                                    return (
                                                                        <img
                                                                            key={index}
                                                                            src={row.url}
                                                                            className={classes.image}
                                                                            alt=""
                                                                        />
                                                                    )

                                                                })
                                                            }
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        </Box>

                                         
                                        <Box px={5} py={2} borderBottom={1}>
                                            <Box>
                                                NOTATION GENERAL
                                            </Box>
                                            
                                            <Box className={classes.Box2}>
                                                <Box className={classes.prestation}>
                                                    <Box display={'flex'}>
                                                        <Rating name="half-rating"
                                                            defaultValue={2}
                                                            value={request.clientComment.length === 0 ? 0 : request.clientComment[0].rating}
                                                            precision={1}
                                                            className={classes.rating}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box className={classes.complement}>
                                                <Box className={classes.iconMText}>
                                                    <div className={classes.nombre}>{request.clientComment.length === 0 ? 0 : request.clientComment[0].punctual }</div>
                                                    <Box
                                                        type="button"
                                                        className={classes.button_complement_Opacity}
                                                    >
                                                        <img id='image' alt='' src='/images/hourglass.png' />
                                                    </Box>
                                                    <p>Punctual</p>
                                                </Box>

                                                <Box className={classes.iconMText}>
                                                    <div className={classes.nombre}>{request.clientComment.length === 0 ? 0 : request.clientComment[0].professional }</div>
                                                    <Box
                                                        type="button"
                                                        className={classes.button_complement_Opacity}
                                                    >
                                                        <img id='image' alt='' src='/images/Ok.png' />
                                                    </Box>
                                                    <p>Professionnel</p>
                                                </Box>

                                                <Box className={classes.iconMText}>
                                                    <div className={classes.nombre}>{request.clientComment.length === 0 ? 0 : request.clientComment[0].sociable }</div>
                                                    <Box
                                                        type="button"
                                                        className={classes.button_complement_Opacity}
                                                    >
                                                        <img id='image' alt='' src='/images/sociable.png' />
                                                    </Box>
                                                    <p>Sociable</p>
                                                </Box>

                                                <Box className={classes.iconMText}>
                                                    <div className={classes.nombre}>{request.clientComment.length === 0 ? 0 : request.clientComment[0].super_clean }</div>
                                                    <Box
                                                        type="button"
                                                        className={classes.button_complement_Opacity}
                                                    >
                                                        <img id='image' alt='' src='/images/sponge.png' />
                                                    </Box>
                                                    <p>Super clean</p>
                                                </Box>
                                            </Box>

                                            <Box mt={3}>
                                                <TextareaAutosize
                                                    name="enterComment"
                                                    aria-label="minimum height"
                                                    minRows={2}
                                                    placeholder=""
                                                    value={request.clientComment.length === 0 ? '' : request.clientComment[0].content }
                                                    className={classes.enabledOptionArea}
                                                />
                                            </Box>
                                        </Box> 
                                    </Box>
                                )}
                        </PaperLayout>
                    </Box>
                )}
            </DashboardLayout>
        </Box>
    );
};

export default Request;
