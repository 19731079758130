import React, { useEffect, useState } from "react";
import { Box, Button, makeStyles, Typography, Fab } from "@material-ui/core";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import { Link } from "react-router-dom";
import Input from "../../../../share/inputs/Input";

const useStyle = makeStyles((theme) => ({
    activitiesArea: {
        "& h6": {
            fontWeight: "bold",
        },
    },
    date: {
        backgroundColor: theme.palette.secondary.main,
        height: "82px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "20px",
        borderRadius: "15px 0 0 15px",
        "& h5": {
            color: "#fff",
        },
    },
    reservation: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 10px 10px #00000055",
        borderRadius: "15px",
        width: "100%",
        "&>div": {
            margin: "0 16px 0 0",
            "@media(max-width: 1366px)": {
                margin: "0 8px 0 0",
            },
        },
        "& h5": {
            fontSize: "16px",
        },
        "& h6": {
            fontSize: "12px",
        },
    },
    circle: {
        width: "35px",
        height: "35px",
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
        "@media(max-width: 600px)": {
            width: "30px",
            minHeight: "30px",
            height: "30px",
        },
    },
    filter: {
        "&>div": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            "&:nth-child(1)": {
                "& h5": {
                    fontSize: "15px",
                    width: "254px",
                    padding: "8px",
                },
            },
            "&:nth-child(2)": {
                height: "65px",
                backgroundColor: "#dddddd",
            },
        },
    },
}));

const Activities = ({ reservations }) => {
    const classes = useStyle();

    const [formData, setFormData] = useState({
        date: "",
        idReservation: "",
        service: "",
        reservationLocal: [],
    });

    useEffect(() => {
        setFormData((formData) => ({
            ...formData,
            reservationLocal: reservations,
        }));
    }, [reservations]);

    const { date, idReservation, service, reservationLocal } = formData;

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const filterRes = (e) => {
        e.preventDefault();
        let rows = reservations;
        if (date.trim() !== "") {
            rows = rows.filter(
                (row) => row.week_date.toString().indexOf(date) > -1
            );
        }
        if (idReservation.trim() !== "") {
            rows = rows.filter(
                (row) =>
                    row.reservationId.toString().indexOf(idReservation) > -1
            );
        }
        if (service.trim() !== "") {
            rows = rows.filter(
                (row) => row.service.toString().indexOf(service) > -1
            );
        }
        setFormData({ ...formData, reservationLocal: rows });
    };

    return (
        <Box className={classes.activitiesArea}>
            <Box className={classes.filter}>
                <Box>
                    <Box>
                        <Typography color="primary" variant="h5">
                            Date de ménage
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color="primary" variant="h5">
                            ID Réservation
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color="primary" variant="h5">
                            Service
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box p={1}>
                        <Input
                            id="date"
                            placeholder="Date de ménage"
                            size="small"
                            width={"100%"}
                            defaultValue={date}
                            onBlur={handleChange}
                            variant="outlined"
                            error=""
                            readOnly={false}
                        />
                    </Box>
                    <Box p={1}>
                        <Input
                            id="idReservation"
                            placeholder="ID Réservation"
                            size="small"
                            width={"100%"}
                            defaultValue={idReservation}
                            onBlur={handleChange}
                            variant="outlined"
                            error=""
                            readOnly={false}
                        />
                    </Box>
                    <Box p={1}>
                        <Input
                            id="service"
                            placeholder="Service"
                            size="small"
                            width={"100%"}
                            defaultValue={service}
                            onBlur={handleChange}
                            variant="outlined"
                            error=""
                            readOnly={false}
                        />
                    </Box>
                    <Box p={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => filterRes(e)}
                        >
                            RECHERCHER
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box mb={2} mt={2}>
                <Typography variant="h6">
                    Prochaine(s) prestation(s){" "}
                </Typography>
            </Box>
            <Box>
                {reservationLocal.map((row, index) => {
                    return (
                        <Box key={index} className={classes.reservation} mb={3}>
                            <Box className={classes.date}>
                                <Typography variant="h5">
                                    {row.week_date}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    ID RESERVATION
                                </Typography>
                                <Typography variant="h5">
                                    {row.reservationId}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    SERVICE
                                </Typography>
                                <Typography variant="h5">
                                    {row.service}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    HEURE
                                </Typography>
                                <Typography variant="h5">
                                    {row.pro_start_time}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    TEMPS ESTIME
                                </Typography>
                                <Typography variant="h5">
                                    {row.pro_duration}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    FORMULE
                                </Typography>
                                <Typography variant="h5">
                                    {row.frequency}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography color="primary" variant="h6">
                                    TARIFE DE L'OFFRE
                                </Typography>
                                <Typography variant="h5">{`${row.total_price} $`}</Typography>
                            </Box>
                            <Box>
                                <Link
                                    // to={`/contact/client/finish/${row.reservationId}`}
                                    to={`/reservation/request/${row.reservationId}/4/false/4`}
                                >
                                    <Fab
                                        className={classes.circle}
                                        aria-label="add"
                                    >
                                        <ArrowForwardRoundedIcon
                                            className={classes.arrow}
                                        />
                                    </Fab>
                                </Link>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Activities;

// PETRO ANUNCIA
