import { Box, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTaskActive, updateTask } from '../../../store/actions/taskAction'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { Link } from 'react-router-dom'

const ActiveTask = ({access_token}) => {

    const { activeTasks } = useSelector(
        state =>({
            activeTasks: state.tasks.active
    }))

    const dispatch = useDispatch()

    const [activeT, setActiveT] = useState([])

    useEffect(() => {
        if(activeTasks === null){
            dispatch(getTaskActive(access_token))
        }else{
            setActiveT(activeTasks)
        }
    }, [access_token, activeTasks, dispatch])

    const changeStatus = (e, id) =>{
        e.preventDefault()
        console.log('Fuente anonima: ', id)
        dispatch(updateTask(access_token, id))
    }

    return (
        <Box width="50%">
            <Box>Active</Box>
                {
                    activeT.map((row, index) => {
                        return(
                            <Box key={index} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width="100%">
                                <Box width="15%">
                                    <IconButton
                                        onClick = { e => changeStatus(e, row.id)}
                                    >
                                        <RemoveCircleIcon style={{color: 'red'}} />
                                    </IconButton>
                                </Box>
                                <Box width="50%">{row.fr}</Box>
                                
                            </Box>
                        )
                    })
                }

        </Box>
    )
}

export default ActiveTask
