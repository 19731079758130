import { Box, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTaskInactive, removeTask, updateTask } from '../../../store/actions/taskAction'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link } from 'react-router-dom';

const InactiveTask = ({access_token}) => {

    const { inactiveTasks } = useSelector(
        state =>({
            inactiveTasks: state.tasks.inactive
    }))

    const dispatch = useDispatch()

    const [inactiveT, setInactiveT] = useState([])

    useEffect(() => {
        if(inactiveTasks === null){
            dispatch(getTaskInactive(access_token))
        }else{
            setInactiveT(inactiveTasks)
        }
    }, [access_token, inactiveTasks, dispatch])

    const changeStatus = (e, id) =>{
        e.preventDefault()
        
        dispatch(updateTask(access_token, id))
    }

    const deleteTask = (e, id) => {
        e.preventDefault()
        dispatch(removeTask(access_token, id))
    }

    return (
        <Box width="50%">
            <Box>Inactive</Box>
                {
                    inactiveT.map((row, index) => {

                        return(
                            <Box key={index} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width="100%">
                                <Box width="15%">
                                    <IconButton
                                        onClick = { e => changeStatus(e, row.id)}
                                    >
                                        <AddCircleOutlineIcon style={{color: '#2880fb'}} />
                                    </IconButton>
                                </Box>
                                <Box width="50%">{row.fr}</Box>
                                <Box>
                                    <Link to="?#" 
                                        onClick={e => deleteTask(e, row.id)}
                                    >
                                        Suprimer
                                    </Link>
                                </Box>
                            </Box>
                        )
                    })
                }

        </Box>
    )
}

export default InactiveTask
