import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../share/inputs/Input';
import { createTax, updateTaxOfList } from '../../../store/actions/taxAction';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const useStyle = makeStyles((theme) => ({
    groupSelect:{
        marginRight: theme.spacing(3),
        display: 'flex',
        '& select':{
            width: '243px',
            height: '41px',
            backgroundColor: '#fff !important',
            border: '1px solid #c4c4c4',
            borderRadius: '4px',
            marginRight: theme.spacing(2),
            boxShadow: '-1px 4px 6px 3px #80808047'
        }
    },
    selectError:{
        '& select':{
            border: '1px solid #dc3545',
        }
    },
    inputArea:{
        display: 'flex',
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
            transform: 'translate(14px, -6px) scale(0.75)',
            backgroundColor: '#ffff',
            padding: '0 5px'
        }
    },
    error:{
        color: '#dc3545',
        fontSize: '1.6rem',
        padding: '5px 0'
    }
})) 

const Tax = ({formData, setFormData}) => {
    const classes = useStyle()

    const { auth:{ access_token } } = useSelector(
        state => ({
            auth: state.auth
        })
    );

    const dispatch = useDispatch();
    const [validateName, setValidateName] = useState(false);

    const { id, country, province, tps, tvq } = formData;

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const saveTax = (e, IsNew) => {
        e.preventDefault()
        if(IsNew){
            console.log('La nariz: ', country)
            
            if(country === "" || province === "" || tps === 0){
                setValidateName(true)
            }else{
                console.log('Residencia', formData)
                dispatch(createTax(access_token, formData))
                .then(() => {
                    setFormData({
                        id: '',
                        country: '',
                        province: '',
                        tps: 0,
                        tvq: 0
                    })
                    setValidateName(false)
                })
            }
        }else{
            dispatch(updateTaxOfList(access_token, formData))
        }
    }
    console.log('Esta es la data: ', validateName)

    const selectCountry = (val) => {
        setFormData({ 
            ...formData,
            country: val 
        });
    }

    const selectRegion = (val) => {
        console.log(val)
        setFormData({ 
            ...formData,
            province: val 
        });
    }
    
    return(
        <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column">
                <Box className={classes.groupSelect} >
                    <Box mb={1}>
                        <Box className={(formData.country === "" && validateName) ? classes.selectError : null} display="flex" flexDirection="column">
                            <CountryDropdown
                                value={country}
                                //labelType="short"
                                valueType="short"
                                onChange={(val) => selectCountry(val)} 
                            />
                            {
                                (formData.country === "" && validateName)
                                ?
                                    (<span className={classes.error}>Obligatoire</span>)
                                :
                                    null
                            }
                            
                        </Box>
                    </Box>
                    <Box mb={1}>
                        <Box className={(formData.province === "" && validateName) ? classes.selectError : null} display="flex" flexDirection="column">
                            <RegionDropdown
                                country={country}
                                countryValueType="short"
                                value={province}
                                onChange={(val) => selectRegion(val)} 
                            />
                            {
                                (formData.province === "" && validateName)
                                ?
                                    (<span className={classes.error}>Obligatoire</span>)
                                :
                                    null
                            }
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.inputArea}>
                    <Box mr={2}>
                        <Box style={{position: 'relative'}}>
                            <Input
                                id="tps"
                                placeholder=""
                                label="TPS"
                                size="small"
                                width={'100%'}
                                defaultValue={tps}
                                onBlur={e => handleChange(e)}
                                variant="outlined"
                                error={(formData.tps === 0 && validateName)  ? 'Obligatoire' : ''}
                            />
                            <Box style={{position: 'absolute', top: '6px', right: '10px'}}>%</Box>
                        </Box>
                    </Box>  
                    <Box>
                        <Box style={{position: 'relative'}}>
                            <Input
                                id="tvq"
                                placeholder=""
                                label="TVQ"
                                size="small"
                                width={'100%'}
                                defaultValue={tvq}
                                onBlur={e => handleChange(e)}
                                variant="outlined"
                                error=""
                            />
                            <Box style={{position: 'absolute', top: '6px', right: '10px'}}>%</Box>
                        </Box>
                    </Box>         
                </Box>                
            </Box>
            <Box mb={2}>
                {
                    id===''
                    ?
                        (
                            <Button variant="contained" color="primary" onClick={e => saveTax(e, true)}>Enregistrer la Tax</Button>
                        )
                    :
                        (
                            <Button variant="contained" color="primary" onClick={e => saveTax(e, false)}>Update la Tax</Button>
                        )
                }
            </Box>
        </Box>
  );
}

export default Tax;