import { Box, Checkbox, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import InputCustomPhone from '../../share/inputs/InputCustomPhone';
import { useDispatch, useSelector } from 'react-redux';
import { updateReferenceCandidateAction } from '../../../store/actions/candidateAction';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popup from './Popup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    
        groupPerInfo: {
            '& .MuiInputBase-input': {
                // height: '6.5rem',
                // marginTop: '5px',
                paddingTop: '10px',
                backgroundColor: '#d9d7d7'
            },
            '& .MuiFormControl-root': {
                marginRight: '1.5rem',
            },
            '& .MuiTextField-root': {
                width: '100%'
            }
        },
        img: {
            '& img': {
                width: '16rem',
                height: '17.7rem'
            }
        },
        classCheckBox: {
            '& .MuiSvgIcon-root': {
                fontSize: '3rem'
            },
            '& .MuiTypography-root': {
                fontSize: '2rem'
            }
        },
        requi: {
            color: 'red',
            '& svg': {
                color: 'red'
            }
        }
    }
))

const CandidateRef = ({ candidate }) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const token = useSelector(state => { return state.auth.access_token })

    const [open, setOpen] = useState(false);
    const [dataref, setDataref] = useState({
        referContactStatus1: candidate.reference[0]?.contact_status ?? 0 === 1 ? true : false,
        referContactStatus2: candidate.reference[1]?.contact_status ?? 0 === 1 ? true : false,
        errorType: '',
        errorMsg: ''
    })

    const { referContactStatus1, referContactStatus2, errorType, errorMsg } = dataref

    let arrServices = []
    let ma = '';

    for (let i = 0; i < candidate.services.length; i++) {
        ma = `${candidate.services[i].id}-${candidate.services[i].fr}`
        arrServices.push(ma)
    }

    const hadleReferences = (e) => {

        if (candidate.reference.length === 2) {
            if ((referContactStatus1 || referContactStatus2) && e.target.checked) {
                setOpen(!open)
            }
        } else {
            if (e.target.checked) {
                setOpen(!open)
            }

        }

        setDataref({
            ...dataref,
            [e.target.name]: e.target.checked,
            errorType: '',
            errorMsg: ''
        })
    }

    const handleApi = () => {
        dispatch(updateReferenceCandidateAction(token, referContactStatus1, referContactStatus2, candidate))
            .then(() => {
                setOpen(false)
                setDataref({
                    ...dataref,
                    errorMsg: 'Les références ont été validées',
                    errorType: 'success'
                })
            })
    }

    const handleClose = () => {
        setOpen(false);
        setDataref({
            referContactStatus1: false,
            referContactStatus2: false,
            errorType: '',
            errorMsg: ''
        })
    };

    const handleCloseMessage = (e) => {
        e.preventDefault()
        setDataref({
            ...dataref,
            errorMsg: '',
            errorType: 'success'
        })
    }
    return (
        <Box mb={2} display="flex" flexDirection="column" justifyContent="space-between" >

            <Popup
                open={open}
                handleClose={handleClose}
                handleApi={handleApi}
                title={`voulez-vous mettre à jour le statut de vos contacts?`}
            />

            {
                (errorMsg.length > 0) && (
                    <Box style={{textAlign: 'center', marginBottom: '30px'}}>
                        <Box style={{backgroundColor: errorType === 'error' ? 'red' : '#28cc8b', padding: '20px 10px', margin: '10px 20%', color: '#fff' , position: 'relative'}}>
                            <Box style={{position: 'absolute', top: '0', right: '0'}}>
                                <IconButton color="secondary" aria-label="add an alarm" onClick={ (e) => handleCloseMessage(e) }>
                                    <HighlightOffIcon style={{color: 'white'}} />
                                </IconButton>
                            </Box>
                            {errorMsg}
                        </Box>
                    </Box>
                )
            }
            
            <Box className={classes.groupPerInfo}>
                <Box mb={1}><Typography variant="h5">Expérience en tant que préposé au ménage</Typography></Box>
                <RadioGroup className={classes.classCheckBox} row aria-label="gender" name="experience_status"
                    value={(candidate.experience_status === 1) ? "1" : "0"}
                >
                    <FormControlLabel labelPlacement="end" value="1" control={<Radio color="primary" />} label="Oui" />
                    <FormControlLabel labelPlacement="end" value="0" control={<Radio color="primary" />} label="Non" />
                </RadioGroup>
                <Grid container>
                    <Grid item md={2} lg={2}>
                        <Box mt={2} mb={2}>
                            <TextField
                                id="servicesChosen"
                                placeholder=""
                                size="small"
                                defaultValue={arrServices}
                                variant="filled"
                                readOnly={true}
                                disabled
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Box>
                    <Typography variant="h5">Personnes référentes</Typography>
                    <Box mt={2} mb={1}><Typography variant="h5">Premier contact</Typography></Box>
                    <Grid container>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <TextField
                                    id="referFirstName1"
                                    placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.reference[0]?.first_name ?? ''}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <TextField
                                    id="referLastName1"
                                    placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.reference[0]?.last_name ?? ''}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <TextField
                                    id="referEmail1"
                                    placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.reference[0]?.email ?? ''}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <InputCustomPhone
                                    name='referTelephone1'
                                    country={'ca'}
                                    label="Cellulaire"
                                    value={candidate.reference[0]?.telephone ?? ''}
                                    disableCountryCode={false}
                                    disableAreaCodes={true}
                                    disableDropdown={true}
                                    onChange={() => { }}
                                    variant="filled"
                                    size="small"
                                    width={'100%'}
                                    error=''
                                    readOnly={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <TextField
                                    id="referCompany1"
                                    placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.reference[0]?.company ?? ''}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3}>
                            <Box mr={1} mb={1}>
                                <TextField
                                    id="referPosition1"
                                    placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.reference[0]?.position ?? ''}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6}>
                            <Box display='flex' flexDirection='row'>

                                <RadioGroup className={classes.classCheckBox} aria-label="gender" name="experience_status"
                                    value={(candidate.reference[0]?.still_employed ?? 0 === 1) ? "1" : "0"}
                                >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="1"
                                        control={<Radio color="primary" />} label="Je travaille actuellement "
                                    />
                                    <FormControlLabel
                                        labelPlacement="end"
                                        value="0"
                                        control={<Radio color="primary" />} label="J’ai quitté l’entreprise"
                                    />
                                </RadioGroup>

                                <Box display="flex" flexDirection="column" className={candidate.reference[0]?.contact_status ?? 0 === 1 ? true : classes.requi}>
                                    <FormControlLabel
                                        disabled={candidate.reference[0]?.contact_status ?? 0 === 1 ? true : false}
                                        control={
                                            <Checkbox
                                                onClick={(e) => hadleReferences(e)}
                                                name="referContactStatus1"
                                                color="primary"
                                                checked={referContactStatus1}
                                            // className={classes.root}
                                            />
                                        }
                                        label="Contact"
                                    />
                                    <Box className={[candidate.reference[0]?.contact_status ?? 0 === 1 ? true : classes.requi, {display: candidate.reference[0]?.contact_status ?? 0 === 1 ? 'none' : 'block'}]}>
                                        Requi
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={{ display: candidate.reference.length === 1 ? 'none' : 'block' }}>
                        <Box mt={2} mb={1}><Typography variant="h5">Deuxième contact</Typography></Box>
                        <Grid container >
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <TextField
                                        id="referFirstName1"
                                        placeholder="Nom"
                                        size="small"
                                        defaultValue={candidate.reference[1]?.first_name ?? ''}
                                        variant="outlined"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <TextField
                                        id="referLastName1"
                                        placeholder="Nom"
                                        size="small"
                                        defaultValue={candidate.reference[1]?.last_name ?? ''}
                                        variant="outlined"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <TextField
                                        id="referEmail1"
                                        placeholder="Nom"
                                        size="small"
                                        defaultValue={candidate.reference[1]?.email ?? ''}
                                        variant="outlined"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <InputCustomPhone
                                        name='referTelephone1'
                                        country={'ca'}
                                        label="Cellulaire"
                                        value={candidate.reference[1]?.telephone ?? ''}
                                        disableCountryCode={false}
                                        disableAreaCodes={true}
                                        disableDropdown={true}
                                        onChange={() => { }}
                                        variant="filled"
                                        size="small"
                                        width={'100%'}
                                        error=''
                                        readOnly={true}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <TextField
                                        id="referCompany1"
                                        placeholder="Nom"
                                        size="small"
                                        defaultValue={candidate.reference[1]?.company ?? ''}
                                        variant="outlined"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={3} lg={3}>
                                <Box mr={1} mb={1}>
                                    <TextField
                                        id="referPosition1"
                                        placeholder="Nom"
                                        size="small"
                                        defaultValue={candidate.reference[1]?.position ?? ''}
                                        variant="outlined"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <Box display='flex' flexDirection='row'>

                                    <RadioGroup className={classes.classCheckBox} aria-label="gender" name="experience_status"
                                        value={(candidate.reference[1]?.still_employed ?? 0 === 1) ? "1" : "0"}
                                    >
                                        <FormControlLabel
                                            labelPlacement="end"
                                            value="1"
                                            control={<Radio color="primary" />} label="Je travaille actuellement "
                                        />
                                        <FormControlLabel
                                            labelPlacement="end"
                                            value="0"
                                            control={<Radio color="primary" />} label="J’ai quitté l’entreprise"
                                        />
                                    </RadioGroup>

                                    <Box display="flex" flexDirection="column" className={candidate.reference[1]?.contact_status ?? 0 === 1 ? true : classes.requi}>
                                        {/* <Box className={(validateCandidate && !initialState.referContactStatus2 && initialState.referFirstName2.length > 0) ? checkStyle : classes.classCheckBox}> */}

                                        <FormControlLabel
                                            disabled={candidate.reference[1]?.contact_status ?? 0 === 1 ? true : false}
                                            control={
                                                <Checkbox
                                                    onClick={(e) => hadleReferences(e)}
                                                    name="referContactStatus2"
                                                    color="primary"
                                                    checked={referContactStatus2}
                                                // className={classes.root}
                                                />
                                            }
                                            label="Contact"
                                        />
                                        <Box className={[candidate.reference[1]?.contact_status ?? 0 === 1 ? true : classes.requi, {display: candidate.reference[1]?.contact_status ?? 0 === 1 ? 'none' : 'block'}]}>
                                            Requi
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box mt={2}><Typography variant="h5">Pourquoi veut-il intégrer Tiggidoo ?</Typography></Box>
                        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="workRegurary" color="primary" checked={candidate.motivation.work_regularly ? true : false} className={classes.root} />}
                                    label="Travail régulier"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="workExtra" color="primary" checked={candidate.motivation.work_extra ? true : false} className={classes.root} />}
                                    label="Travail d'appoint"
                                />
                            </Box>

                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="visibility" color="primary" checked={candidate.motivation.visibility ? true : false} className={classes.root} />}
                                    label="Visibilité"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="concept" color="primary" checked={candidate.motivation.concept ? true : false} className={classes.root} />}
                                    label="Concept"
                                />
                            </Box>
                            <Box className={classes.classCheckBox}>
                                <FormControlLabel
                                    control={<Checkbox name="extraIncome" color="primary" checked={candidate.motivation.extra_income ? true : false} className={classes.root} />}
                                    label="Revenus supplémentaires"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.groupInfo} mt={3}>
                        <Box><Typography variant="h5">Comment nous as-t-il connu ?</Typography></Box>
                        <Grid container>
                            <Grid items md={2}>
                                <TextField
                                    id="how_know_us"
                                    //placeholder="Nom"
                                    size="small"
                                    defaultValue={candidate.how_know_us.fr}
                                    variant="outlined"
                                    readOnly={true}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

        </Box >
    )
}

export default CandidateRef