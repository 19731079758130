import React, { useState } from "react";
import { AppBar, Box, Tabs, Tab, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import InfoPro from "./InfoPro";
import Criteria from "./Criteria";
import Activities from "./Activities";
import { getDateFormat } from "../../../../share/librery/librery";
import MyAvailabilities from "./availabilities/MyAvailabilities";

const useStyle = makeStyles((theme) => ({
    headerTab: {
        color: "#000",
        backgroundColor: "transparent",
        boxShadow: "none",
        "& button:focus": {
            outline: "0px auto -webkit-focus-ring-color",
        },
        "& .MuiTab-root": {
            textTransform: "none",
            fontFamily: "sans-serif",
            fontSize: "2.1rem",
            color: "#7f7f7f",
        },
    },
    title: {
        fontSize: "3.8rem",
        fontWeight: "bold",
        marginLeft: "1.4rem",
    },
    titleHeader: {
        "& h5": {
            fontSize: "20px",
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const TabsForm = ({ token, pro }) => {
    const classes = useStyle();

    const [value, setValue] = useState(0);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className={classes.contactArea}>
            <Box className={classes.titleHeader}>
                <Box mb={6}>
                    <Typography variant="h1" className={classes.title}>
                        Pro
                    </Typography>
                </Box>
                <Box display="flex">
                    <Box mr={6} mb={6}>
                        <Typography variant="h5" className={classes.title}>
                            ID PRO
                        </Typography>

                        <Typography variant="h5" className={classes.title}>
                            {pro.info.credential.uuid}
                        </Typography>
                    </Box>
                    <Box mr={6} mb={6}>
                        <Typography variant="h5" className={classes.title}>
                            DATE CREATION
                        </Typography>
                        <Typography variant="h5" className={classes.title}>
                            {getDateFormat(pro.info.credential.created_at)}
                        </Typography>
                    </Box>
                    <Box mr={6} mb={6}>
                        <Typography variant="h5" className={classes.title}>
                            NOMBRE PRESTATIONS
                        </Typography>
                        <Typography variant="h5" className={classes.title}>
                            {pro.actives.length}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <AppBar position="static" className={classes.headerTab}>
                    <Tabs
                        value={value}
                        onChange={handleChangeTabs}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Infos Pro" {...a11yProps(0)} />
                        <Tab label="Activite" {...a11yProps(1)} />
                        <Tab label="Critères" {...a11yProps(2)} />
                        <Tab label="Disponibilité" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <InfoPro info={pro.info} token={token} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Activities reservations={pro.actives} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Criteria
                        address={pro.info.address}
                        criterion={pro.info.criterion}
                        tax={pro.info.tax}
                    />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <MyAvailabilities
                        token={token}
                        availability={pro.info.availability}
                        activities={pro.info.unavailability}
                        proId={pro.info.id}
                    />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default TabsForm;
