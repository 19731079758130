import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
    areaStyle: {      
        borderRadius:'21px',   
        boxShadow: '5px 10px 10px #00000055',
        width: '100%',
        marginBottom: theme.spacing(3)
    },
}))

const PaperUser = ({children, backgroundColor}) =>{
    const classes = useStyle()
    return(
        <Box className={classes.areaStyle} style={{backgroundColor: backgroundColor}}>
            {children}
        </Box>
    )
}

export default PaperUser