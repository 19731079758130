import React from "react";
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import Input from "../../../../share/inputs/Input";
import MapPro from "../../../request/Components/MapPro";

const useStyle = makeStyles(() => ({
    housingArea: {
        display: "flex",
        justifyContent: "space-between",
        width: "1400px",
        "@media(max-width: 1850px)": {
            width: "1000px",
        },
        "@media(max-width: 1400px)": {
            width: "870px",
        },
        "@media(max-width: 1200px)": {
            width: "850px",
        },
    },
    mapStyle: {
        "& .leaflet-container": {
            width: "20vw",
            height: "21.2vh",
        },
    },
}));

const Housing = ({ housing, address, reservation }) => {
    const classes = useStyle();

    const client = {
        address: address,
    };
    
    return (
        <Box className={classes.housingArea}>
            <Box>
                <Box mb={4} className={classes.mapStyle}>
                    <Box mb={1}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                            {address.rue === null
                                ? address.postcode
                                : `${address.rue}, ${address.city}, ${address.province}, ${address.country}, ${address.postcode} `}
                        </Typography>
                    </Box>
                    <MapPro profs={[]} address={client.address} pin={false} />
                </Box>
                <Box>
                    <Box mb={3}>
                        <Box mb={1}>
                            <Typography variant="h6">
                                Type de votre logement
                            </Typography>
                        </Box>
                        <Box>
                            <Input
                                id="category"
                                placeholder=""
                                size="small"
                                width={"100%"}
                                defaultValue={housing.category.fr}
                                //onBlur={handleChange}
                                variant="outlined"
                                error=""
                                readOnly={true}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box mb={3}>
                        <Box mb={1}>
                            <Typography variant="h6">
                                Surface du logement
                            </Typography>
                        </Box>
                        <Box>
                            <Input
                                id="size"
                                placeholder="Nom"
                                size="small"
                                width={"100%"}
                                defaultValue={
                                    housing.size === null ? 0 : housing.size.fr
                                }
                                //onBlur={handleChange}
                                variant="outlined"
                                error=""
                                readOnly={true}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {housing.category.id !== 1 && (
                <Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Nombre d'étage
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.floor}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Chambre à coucher
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.bedroom}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Salle de bain
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.bathroom}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">Cuisine</Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.kitchen}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Salle d'eau
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.washbasin}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {housing.category.id !== 1 && (
                <Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">Douche(s)</Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.shower}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Baignoire(s)
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.bathtub}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Salle à manger
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={
                                        housing.specificity.dining_room
                                    }
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Box mb={1}>
                                <Typography variant="h6">
                                    Nombre de salon
                                </Typography>
                            </Box>
                            <Box>
                                <Input
                                    id="size"
                                    placeholder="Nom"
                                    size="small"
                                    width={"100%"}
                                    defaultValue={housing.specificity.salon}
                                    //onBlur={handleChange}
                                    variant="outlined"
                                    error=""
                                    readOnly={true}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Typography variant="h6">Chien</Typography>
                            <RadioGroup row aria-label="gender" name="authorization_status"
                                value={(reservation[0].housework.personalization.dog === 1) ? "1" : "0"} >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>

                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Typography variant="h6">Chat</Typography>
                            <RadioGroup row aria-label="gender" name="authorization_status"
                                value={(reservation[0].housework.personalization.cat === 1) ? "1" : "0"} >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Housing;
