import React from 'react'
import { useDispatch, useStore } from 'react-redux'
import { Navigate } from 'react-router-dom'
import jwt_decode from "jwt-decode"
//import { Route, Redirect } from 'react-router-dom'

import { logOutAction } from '../../store/actions/authAction';
import { setAlert } from '../../store/actions/alertAction';

export const PrivateRoute = ({Component}) => {
    const store = useStore()
    const dispatch = useDispatch()
    
    const auth = store.getState().auth
    const { isLoggedIn, access_token } = auth //loading
    let expiredToken = false

    if(isLoggedIn){
        
        const decoded = jwt_decode(access_token);
        const exp = (decoded.exp*1000) //- 86400000
        const rightNow = new Date().getTime()
                
        if(exp <= rightNow){
            dispatch(logOutAction())
            dispatch(setAlert('La session a expiré', 'success'))
            expiredToken = true
        }

    }    
    if(!isLoggedIn || expiredToken){
        //return (<Navigate to={`/${path.split('/')[1]}/login`} />)
        return (<Navigate to={`/login`} />)
    }

    return  Component
}
