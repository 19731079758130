import { Typography } from "@material-ui/core";
import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import MapPro from "./MapPro";

const useStyle = makeStyles((theme) => ({
    clientArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        //padding: theme.spacing(4, 0)
    },
    boxClient: {
        //width: '33%',
        paddingTop: theme.spacing(1),
        "& h4": {
            fontWeight: "bold",
        },
    },
    areaClientMap: {
        "& .leaflet-container": {
            width: "25vw",
            height: "25vh",
        },
    },
}));

const ClientInfo = ({ housing, client, totalPrice, personalization }) => {
    const classes = useStyle();
    return (
        <Box className={classes.clientArea}>
            <Box className={classes.boxClient}>
                <Typography variant="h4">DESCRIPTION DU LOGEMENT</Typography>
                <Box display="flex">
                    <Box mt={3} mr={3}>
                        <Box>
                            <Typography variant="h6">
                                {housing.category.fr}
                            </Typography>
                        </Box>
                        {housing.specificity !== null ? (
                            <Box>
                                {housing.specificity.floor > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.floor} etage(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.kitchen > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.kitchen} cuisine(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.salon > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.salon} salon(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.dining_room > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.dining_room} salle(s) a manger`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.bedroom > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.bedroom} chambre(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.bathroom > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.bathroom} salle(s) de bain`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.shower > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.shower} douche(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.bathtub > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.bathtub} baignoire(s)`}</Typography>
                                    </Box>
                                )}
                                {housing.specificity.washbasin > 0 && (
                                    <Box>
                                        <Typography variant="h6">{`${housing.specificity.washbasin} salle(s) d'eau`}</Typography>
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <Box></Box>
                        )}

                        <Box>
                            <Box mt={3}>
                                <Typography variant="h6">
                                    OPTION(S) ET/OU EXTRA(S)
                                </Typography>
                            </Box>
                            {personalization.vacuum !== 0 && (
                                <Typography variant="h6">{`Aspirateur - ${
                                    personalization.vacuum !== 0
                                        ? "Oui"
                                        : "None"
                                }`}</Typography>
                            )}
                            {personalization.fridge !== 0 && (
                                <Typography variant="h6">
                                        {`${personalization.fridge} Fridge(s)}`}
                                </Typography>
                            )}
                            {personalization.oven !== 0 && (
                                <Typography variant="h6">
                                        {`${personalization.oven} Four(s)`}
                                </Typography>
                            )}
                            {personalization.bed !== 0 && (
                                <Typography variant="h6">
                                    {`${personalization.bed} Lit(s)}`}
                                </Typography>
                            )}

                            {personalization.product_standard !== 0 && (
                                <Typography variant="h6">{`Product standard - ${
                                    personalization.product_standard !== 0
                                        ? "Oui"
                                        : "None"
                                }`}</Typography>
                            )}
                            {personalization.product_ecological !== 0 && (
                                <Typography variant="h6">{`Product ecological - ${
                                    personalization.product_ecological !== 0
                                        ? "Oui"
                                        : "None"
                                }`}</Typography>
                            )}
                            {personalization.cat !== 0 && (
                                <Typography variant="h6">{`Chats - ${
                                    personalization.cat !== 0 ? "Oui" : "None"
                                }`}</Typography>
                            )}
                            {personalization.dog !== 0 && (
                                <Typography variant="h6">{`Chiens - ${
                                    personalization.dog !== 0 ? "Oui" : "None"
                                }`}</Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.boxClient}>
                <Typography variant="h4">LOCALISATION</Typography>
                <Box
                    mt={3}
                    className={classes.areaClientMap}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                >
                    <MapPro profs={[]} address={client.address} pin={false} />
                </Box>
            </Box>
            <Box className={classes.boxClient}>
                <Typography variant="h4">INFORMATIONS CLIENT</Typography>
                <Box
                    mt={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    style={{ height: "80%" }}
                >
                    <Box>
                        <Box>
                            <Typography variant="h5" color="primary">
                                {client.uuid === null ? "Null" : client.uuid}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5">{`${client.first_name} ${client.last_name}`}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Box>
                            <Typography variant="h5" color="primary">
                                {client.telephone}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h5" color="primary">
                                {client.email}
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Box>
                            <Box>
                                <Typography variant="h4">
                                    ESTIMATION TIGGIDOO
                                </Typography>
                                <Typography variant="h4">{`${totalPrice} $`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ClientInfo;
