export const validateTelephone = (text) =>{
    let expression = RegExp('[0-9()]+[0-9a-zA-Z-/*+_^%~!@#$&_=:;"\'|<>,?.\\s]{6,10}')  //[a-z-/*+_^%~!@#$&_=:;"\'|<>,.\\s]+
    
    if(expression.test(text)){
        return true
    }

    return false
}

export const validateEmail = (text) =>{
    let expression = RegExp('[\\w._%+-]+@[\\w.-]+[a-zA-Z.]{2,4}')
    if(expression.test(text)){
        return true
    }

    return false
}

export const getTelephoneFormat = (text) => {
    let x = text.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = !x[2] ? x[1] : '+' + x[1] +' (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '');
    return x
}