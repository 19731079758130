import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Modal from '@material-ui/core/Modal';
import DashboardLayout from '../layout/Layout';
import PaperLayout from '../share/layout/PaperLayout'
import CarouselAdmin from './carousel/CarouselAdmin';
import { UnavailabilityForm } from './admin/UnavailabilityForm';
import { getTimeToBlockPlannningVisio } from '../share/librery/librery';
import { getCandidatesPlanningVisio, cleanPlanningVisio } from '../../store/actions/planningvisioAction';
import { cleanCandidateAction } from '../../store/actions/candidateAction';
import { createActivityAction, getAllUsers, deleteActivityAction } from '../../store/actions/adminAction';
import { setAlert } from '../../store/actions/alertAction';
import Processing from './Processing';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiGrid-container': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  paper: {
    width: '100%',
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    borderRadius: '25px',
    fontSize: "2.1rem",
    marginBottom: '10px',
    '& label': {
      fontSize: '2rem',
      color: '#7f7f7f',
    },
    '& .MuiButton-outlinedPrimary': {
      border: '2px solid #2880fb',
    },
    '& .MuiButton-outlinedPrimary:not(:last-child)': {
      borderRightColor: 'transparent',
    },
    '& .MuiButton-outlinedPrimary:hover': {
      border: '2px solid #2880fb',
    }
  },
  paperTitle: {
    padding: theme.spacing(2),
    backgroundColor: '#2880fb',
    textAlign: 'center',
    color: '#fff',
    height: '3.5rem'
  },
  paperContent: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '3.25rem', //'5.5rem',
    //backgroundColor: '#c0e2e5'
  },
  title: {
    textAlign: 'left',
    width: '100%',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  itemTitle: {
    width: '13%',
    padding: '1px',
    fontSize: '14px',
    '& .MuiPaper-root': {
      padding: '5px 3px'
    }
  },
  itemContent: {
    padding: '1px',
    fontSize: '14px',
    width: '6%',
    '& .MuiPaper-root': {
      padding: '5px'
    }
  },
  itemTitleTime: {
    width: '6%'
  },
  conten: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '0.75vw',
    color: '#fff',
    fontWeight: '500'
  },
  centerTimeContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: '#fff',
  },
  greenBackground: {
    backgroundColor: '#28cc8b',
  },
  gridHeader: {
    width: '97%'
  },
  groupBtn: {
    '& label': {
      fontSize: '2rem',
      color: '#7f7f7f',
    },
    '& .MuiButton-outlinedPrimary': {
      border: '2px solid #2880fb',
    },
    '& .MuiButton-outlinedPrimary:not(:last-child)': {
      borderRightColor: 'transparent',
    },
    '& .MuiButton-outlinedPrimary:hover': {
      border: '2px solid #2880fb',
    }
  },
  linkVideo: {
    marginRight: '5px',
    '& a': {
      color: 'blue'
    }
  },
  linkCandidature: {
    '& a': {
      color: 'black'
    }
  },
  rootModal: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    width: '992px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Planningvisio = () => {

  const { auth, auth: { access_token, userAdmin }, candidates, admins, processing, diff_hours } = useSelector(
    state => ({
      auth: state.auth,
      admins: state.admin.users,
      candidates: state.planningAdmin.adminCandidates,
      diff_hours: state.planningAdmin.adminCandidates.diff_hours,
      processing: state.planningAdmin.processing
    })
  )
  const dispatch = useDispatch();

  let diff_hours_var = 0;
  if (diff_hours !== undefined) {
    diff_hours_var = diff_hours;
  }

  const classes = useStyles();
  const history = useNavigate();

  const [eCalendar, setECalendar] = useState([]);
  const [schedule, setSchedule] = useState([]);
  let [goNextWeek, setGoNextWeek] = useState(new Date());
  const [listAdmin, setListAdmin] = useState([]);
  const [adminId, setAdminId] = useState(userAdmin.id);
  const [openModal, setOpenModal] = useState(false);
  const [dateIni, setDateIni] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [activities, setActivities] = useState([]);
  const [timeNotAvailable, setTimeNotAvailable] = useState([]);

  const rootRef = useRef(null);

  let titleX = [];
  let eventsInfo = [];

  useEffect(() => {
    return () => {
      dispatch(cleanPlanningVisio());
    };
  }, [dispatch])

  useEffect(() => {
    if (userAdmin.authority.access === 1) {
      if (admins === null) {
        dispatch(getAllUsers(access_token));
      } else {

        let adminsArray = [
          {
            id: userAdmin.id,
            firstName: userAdmin.first_name,
            lastName: userAdmin.last_name,
            urlImg: userAdmin.avatar !== null ? userAdmin.avatar.url : 'images/default_man.png'
          }
        ];

        for (let index in admins) {
          if (admins[index].deleted_at === null) {
            adminsArray.push({
              id: admins[index].id,
              firstName: admins[index].first_name,
              lastName: admins[index].last_name,
              urlImg: admins[index].avatar !== null ? admins[index].avatar.url : 'images/default_man.png'
            })
          }
        }
        setListAdmin(adminsArray);
      }
    }
  }, [access_token, admins, userAdmin, dispatch])

  useEffect(() => {

    if (candidates.length === 0) {
      if (userAdmin.authority.access === 1) {
        dispatch(getCandidatesPlanningVisio(access_token, adminId, 0));
      } else {
        dispatch(getCandidatesPlanningVisio(access_token, userAdmin.id, 1));
      }
    } else {

      setECalendar(candidates.candidates);

      const getTimeNotAvailable = getTimeToBlockPlannningVisio(candidates.unavailability);

      setTimeNotAvailable(getTimeNotAvailable);

      const agenda = candidates.availability;

      let start = 23;
      let finish = 0;

      for (let index in agenda) {
        if (index !== 'id') {
          const arrayAgenda = agenda[index].split('-');
          let hourInit = parseInt(arrayAgenda[0]);
          let hourEnd = parseInt(arrayAgenda[1]);
          if (hourEnd > 0) {
            if (hourInit <= start) {
              start = hourInit - (diff_hours_var);
            }
          }
          if (hourEnd > 0) {
            if (hourEnd >= finish) {
              finish = hourEnd - (diff_hours_var);
            }
          }
        }
      }

      let hourAndMinutes = [];
      for (let h = start; h < finish; h++) {
        hourAndMinutes.push((h < 10 ? '0' + h.toString() : h.toString()) + ':00')
        hourAndMinutes.push((h < 10 ? '0' + h.toString() : h.toString()) + ':30')
      }

      setSchedule(hourAndMinutes);
      if (candidates.unavailability.length > 0) {
        setActivities(candidates.unavailability);
      } else {
        setActivities([]);
      }

    }

  }, [access_token, adminId, candidates, userAdmin, diff_hours_var, dispatch])

  const getAdminId = (adminId) => {
    dispatch(cleanPlanningVisio());
    setECalendar([]);
    setSchedule([]);
    setActivities([]);
    setDateIni(null);
    setDateEnd(null);
    setAdminId(adminId);
  }

  const startOfWeek = (date) => {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  const isDesableTime = (day, hour) => {
    if (candidates.length === 0) {
      return false;
    }

    const range = candidates.availability[day];
    const start = parseInt(range.split('-')[0]) - (diff_hours_var);
    const end = parseInt(range.split('-')[1]) - (diff_hours_var);

    if (hour >= start && hour < end) {
      return false;
    }
    return true;
  }

  const getCurrentWeek = (date) => {

    const dt = new Date(date);
    const startWeek = startOfWeek(dt);
    let unixFormatDate = new Date(startWeek).getTime() / 1000;

    let array = [];
    for (let day = 0; day <= 6; day++) {
      const nextDay = new Date((unixFormatDate + (86400 * day)) * 1000).toString().split(' ');
      let indexA = nextDay[1] + nextDay[2] + nextDay[3];

      array[indexA] = [];
      titleX[indexA] = nextDay[0] + ', ' + nextDay[1] + ' ' + nextDay[2] + ' ' + nextDay[3];

      for (let index in schedule) {
        let indexB = schedule[index];

        let trueOrFalse = isDesableTime(nextDay[0].slice(0, -1), schedule[index].split(':')[0]);
        let color = trueOrFalse ? '#bdbdbd' : '#fff';
        array[indexA][indexB] = [{ color, }];

        if (timeNotAvailable[1] !== undefined) {
          if (timeNotAvailable[1][indexA] !== undefined) {
            if (timeNotAvailable[1][indexA][indexB] !== undefined) {
              array[indexA][indexB] = [{ color: '#bdbdbd', description: timeNotAvailable[1][indexA][indexB] }];
            }
          }
        }

        if (!trueOrFalse) {
          if (timeNotAvailable[2] !== undefined) {
            if (timeNotAvailable[2] !== undefined) {
              if (timeNotAvailable[2][indexB] !== undefined) {
                array[indexA][indexB] = [{ color: '#bdbdbd', description: timeNotAvailable[2][indexB] }];
              }
            }
          }

          if (timeNotAvailable[3] !== undefined) {
            if (timeNotAvailable[3] !== undefined) {
              const dayBlocked = day + 1;
              if (timeNotAvailable[3][dayBlocked] !== undefined) {
                if (timeNotAvailable[3][dayBlocked][indexB] !== undefined) {
                  array[indexA][indexB] = [{ color: '#bdbdbd', description: timeNotAvailable[3][dayBlocked][indexB] }];
                }
              }
            }
          }
          if (timeNotAvailable[4] !== undefined) {
            if (timeNotAvailable[4] !== undefined) {
              const dayBlocked = parseInt(nextDay[2]);
              if (timeNotAvailable[4][dayBlocked] !== undefined) {
                if (timeNotAvailable[4][dayBlocked][indexB] !== undefined) {
                  array[indexA][indexB] = [{ color: '#bdbdbd', description: timeNotAvailable[4][dayBlocked][indexB] }];
                }
              }
            }
          }
        }

      }
    }
    return array;
  }

  const getThisWeek = (e) => {
    e.preventDefault();
    setGoNextWeek(new Date());
  }

  const getNextWeek = (e) => {
    e.preventDefault();
    const dt = new Date(goNextWeek);
    const startWeek = startOfWeek(dt);
    let unixFormatDate = new Date(startWeek).getTime() / 1000;
    const nextDay = new Date((unixFormatDate + (86400 * 7)) * 1000);
    setGoNextWeek(nextDay);
  }

  const getLastWeek = (e) => {
    e.preventDefault();
    const dt = new Date(goNextWeek);
    const startWeek = startOfWeek(dt);
    let unixFormatDate = new Date(startWeek).getTime() / 1000;
    const nextDay = new Date((unixFormatDate - (86400 * 7)) * 1000);
    setGoNextWeek(nextDay);

  }

  const goToCandidate = (e, id) => {
    e.preventDefault();
    dispatch(cleanCandidateAction());
    history(`/recruitment/candidatelist/${id}/2`);
  }

  const openPopup = (e, value) => {
    e.preventDefault();
    let d = new Date();
    d = d.toString().split(' ')

    if (dateIni === null) {
      let di = value.replace(',', '') + ':00 ' + d[5] + ' ' + d[6];
      setDateIni(di)
    } else if (dateEnd === null) {
      let de = value.replace(',', '') + ':00 ' + d[5] + ' ' + d[6];
      setDateEnd(de)
      setOpenModal(true);
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setDateIni(null);
    setDateEnd(null);
  };

  const displayContent = (fecha) => {
    eventsInfo = getCurrentWeek(fecha);
    const events = eCalendar;

    if (events.length > 0) {

      for (let i in events) {
        if (events[i].interviewTime !== null) {
          let intDate = events[i].interviewTime.replace(' ', 'T');
          //intDate = new Date(intDate);
          intDate = moment(intDate.replace(' ', 'T'))._d

          const DateArray = intDate.toString().split(' ');
          const indexA = DateArray[1] + DateArray[2] + DateArray[3];
          const indexB = DateArray[4].slice(0, -3);

          if (eventsInfo[indexA] !== undefined) {
            if (eventsInfo[indexA][indexB] !== undefined) {
              eventsInfo[indexA][indexB][0].color = "#28cc8b";
              eventsInfo[indexA][indexB].push({
                time: DateArray[4].slice(0, -3),
                name: `${events[i].firstName} ${events[i].lastName}`,
                link: events[i].start_url,
                id: events[i].id
              });

            }
          }
        }
      }

    }

    const html = []
    const styleTime = classes.itemTitle + ' ' + classes.itemTitleTime
    html.push(<Box key={'t0'} className={styleTime}><Paper className={classes.paperTitle}>Time</Paper></Box>);
    for (let index in titleX) {
      html.push(<Box key={'t' + index} className={classes.itemTitle}><Paper className={classes.paperTitle}>{titleX[index]}</Paper></Box>);
    }

    const stylteTimeContent = classes.paperContent + ' ' + classes.centerTimeContent;
    const styleContent = classes.itemContent;

    for (let indice in schedule) {
      let time = schedule[indice];
      html.push(<Box key={'c0' + time} className={styleContent}><Paper className={stylteTimeContent}>{time + 'H'}</Paper></Box>);

      for (let index in titleX) {

        let htmlArray = [];
        let dateTime = titleX[index] + ' ' + schedule[indice];
        let papaerContentStyle = classes.paperContent;

        if (eventsInfo[index][time].length === 2) {

          for (let i = 1; i < eventsInfo[index][time].length; i++) {

            let contArray = eventsInfo[index][time][i];
            htmlArray.push(
              <Box key={'d' + index + time + i} className={classes.conten}>
                <Box className={classes.linkVideo}>
                  <a href={contArray.link} target="_blank" title={`Link Video at ${contArray.time}`} rel="noopener noreferrer">
                    <VideoCallIcon />
                  </a>
                </Box>
                <Box display="flex" alignItems="center">
                  <a href="/#" onClick={e => goToCandidate(e, contArray.id)} title="Link Candidature">
                    {contArray.name}
                  </a>
                </Box>
              </Box>
            );
          }

        }

        let text = null;
        if (eventsInfo[index][time][0].description !== undefined) {
          text = eventsInfo[index][time][0].description;
        }

        html.push(
          <Box
            onDoubleClick={e => openPopup(e, dateTime)}
            key={'c' + index + time}
            className={classes.itemTitle}
          >
            <Paper style={{ backgroundColor: eventsInfo[index][time][0].color, color: '#000' }} className={papaerContentStyle}>
              {text === null ? htmlArray : text}
            </Paper>
          </Box>);

      }

    }

    return html;
  }

  return (
    <DashboardLayout>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openModal}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
        onClose={handleClose}
      >
        <Box className={classes.paperModal}>
          {(dateIni !== null && dateEnd !== null) && (
            <UnavailabilityForm
              auth={auth}
              activities={activities}
              setAlert={setAlert}
              createActivityAction={createActivityAction}
              deleteActivityAction={deleteActivityAction}
              dateStart={dateIni}
              dateEnd={dateEnd}
              dispatch={dispatch}
            />
          )}
        </Box>
      </Modal>

      <Box className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" textAlign="center">
              <Box className={classes.gridHeader}>
                <Typography component="h1" variant="h3" className={classes.title}>Planning Visio</Typography>
                <PaperLayout>

                  <Box mb={3}>
                    {(listAdmin.length > 0) && (
                      <CarouselAdmin listAdmin={listAdmin} getAdminId={getAdminId} adminId={adminId} />
                    )}
                  </Box>

                  <Box className={classes.groupBtn}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={e => getLastWeek(e)}>Back</Button>
                      <Button onClick={e => getThisWeek(e)}>Today</Button>
                      <Button onClick={e => getNextWeek(e)}>Next</Button>
                      {/* <Button onClick={e => logGoogleCalendar(e)}>Login Google Calendar</Button> */}
                    </ButtonGroup>
                  </Box>
                </PaperLayout>
              </Box>
            </Box>
          </Grid>

          {(processing)
            ?
            (<Processing />)
            :
            userAdmin.authority.access === 0
              ?
              //if user is not superadmin the planningvisio will be diplay directly 
              //and user will be able to add the time when he wont be enabled
                displayContent(goNextWeek)
              :
                (eCalendar.length > 0) 
                ? 
                  displayContent(goNextWeek) 
                : 
                  `Pas d'entretiens`
          }
        </Grid>
      </Box>
    </DashboardLayout >
  );
}
export default Planningvisio;