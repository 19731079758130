import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsNonAffecter from "./RequestsNonAffecter";

const DashboardRequestAffecter = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsNonAffecter />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestAffecter;
