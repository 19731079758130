import React from 'react'
import Calendar from '../../layout/calendar/Calendar';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { getAMPMFrom24Hrs } from '../../share/librery/librery';

const useStyle = makeStyles((theme) => ({
    calendarArea: {
        display: "flex",
        justifyContent: "center"
    },
    timeArea: {
        height: '325px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: '8px',
        //marginRight: '8px',   
        '@media (max-width:992px)': {
            flexDirection: 'row',
            alignContent: 'start',
            height: 'auto'
        },
    },
    timeStyle: {
        border: '1px solid #9ed7c0',
        backgroundColor: '#dddddd',
        fontSize: '13px',
        margin: '2px 1px',
        width: '45px',
        textAlign: 'center',
        paddingTop: '2px',
    },
    timeSelectedBorder: {
        border: '2px solid #2880fb',
        color: '#2880fb',
        fontWeight: 'bold',
        backgroundColor: '#fff'
    },
    calendarHour: {
        padding: '26px 10px',
        backgroundColor: '#45f3aa38'
    },
    boxTime: {
        //display: 'flex',
        flexDirection: 'row',
        marginTop: '35px',
        '@media (max-width:440px)': {
            flexDirection: 'column',
        },
    },
    borderError: {
        border: '2px solid #dc3545',
    },
    errorMessage: {
        color: '#dc3545',
        fontSize: '1.6rem',
        marginBottom: '-24px',
    }
}))

const CalendarForm = ({ timeNotAvailable, dayAgenda, daysBlocked, selectedDate, onChangeDate, selectedTime, setSelectedTime, validateTime, setValidateTime, diff_hours }) => {
    const classes = useStyle();
    let timeStyle = classes.timeStyle;
    const date = new Date(selectedDate);

    const constDateUnixSelected = date.getTime();
    const rightUnixDateSelected = (new Date()).getTime();
    const timeChosen = date.toISOString().split('T')[0];

    let start = 0;
    let end = 0;
    let arrayAM = [];
    let arrayPM = [];
    const functionTime = (e, row) => {
        e.preventDefault();
        setSelectedTime(row);
        setValidateTime(false);
    }

    for (let index in dayAgenda) {
        start = parseInt(dayAgenda[index].split('-')[0]) + diff_hours;
        end = parseInt(dayAgenda[index].split('-')[1]) + diff_hours;
        if (end > 24) {
            end = 24
        }
    }

    for (let i = start; i < end; i++) {
        const timeH00 = getAMPMFrom24Hrs(timeChosen + 'T' + (i < 10 ? '0' + i.toString() : i.toString()) + ':00:00');
        const timeH30 = getAMPMFrom24Hrs(timeChosen + 'T' + (i < 10 ? '0' + i.toString() : i.toString()) + ':30:00');

        if (i < 12) {
            arrayAM.push(timeH00);
            arrayAM.push(timeH30);
        } else {
            arrayPM.push(timeH00);
            arrayPM.push(timeH30);
        }
    }

    const weekday = date.getDay();
    const year = date.getFullYear();
    const month = ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1));
    const day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    const getDate = year + '-' + month + '-' + day;



    let timeToBlock = timeNotAvailable[1][getDate];
    let index;
    if (timeToBlock !== undefined) {

        for (let i = 0; i < timeToBlock.length; i++) {
            const amOrPm = timeToBlock[i].split(' ');
            if (amOrPm[1].localeCompare('AM') === 0) {
                index = arrayAM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayAM.splice(index, 1);
                }
            } else {
                index = arrayPM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayPM.splice(index, 1);
                }
            }
        }
    }

    if (timeNotAvailable[2] !== undefined) {
        timeToBlock = timeNotAvailable[2];
        for (let i = 0; i < timeToBlock.length; i++) {
            index = arrayAM.indexOf(timeToBlock[i]);
            if (index > -1) {
                arrayAM.splice(index, 1);
            }
            index = arrayPM.indexOf(timeToBlock[i]);
            if (index > -1) {
                arrayPM.splice(index, 1);
            }
        }
    }
    if (timeNotAvailable[3] !== undefined) {
        if (timeNotAvailable[3][weekday] !== undefined) {
            timeToBlock = timeNotAvailable[3][weekday];
            for (let i = 0; i < timeToBlock.length; i++) {
                index = arrayAM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayAM.splice(index, 1);
                }
                index = arrayPM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayPM.splice(index, 1);
                }
            }
        }
    }
    if (timeNotAvailable[4] !== undefined) {
        if (timeNotAvailable[4][day] !== undefined) {
            timeToBlock = timeNotAvailable[4][day];
            for (let i = 0; i < timeToBlock.length; i++) {
                index = arrayAM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayAM.splice(index, 1);
                }
                index = arrayPM.indexOf(timeToBlock[i]);
                if (index > -1) {
                    arrayPM.splice(index, 1);
                }
            }
        }
    }

    return (
        <Box>
            <Grid container >
                <Grid item md={8}>
                    <Box className={classes.calendarArea}>
                        <Calendar value={selectedDate} onChange={onChangeDate} daysBlocked={daysBlocked} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>

                    {(validateTime) && (
                        <Box className={classes.errorMessage}>Choisissez une heure</Box>
                    )}
                    <Box className={validateTime ? classes.boxTime + ' ' + classes.borderError : classes.boxTime}>
                        <Box display="flex" flexDirection="row" justifyContent="space-around">
                            <Box>
                                <Box textAlign="center">AM</Box>
                                <Box>
                                    {(arrayAM.length > 0 && (constDateUnixSelected > rightUnixDateSelected)) && (
                                        <Box className={classes.timeArea}>
                                            {
                                                arrayAM.map((row, index) => {

                                                    if (row.localeCompare(selectedTime) === 0) {
                                                        timeStyle = timeStyle + ' ' + classes.timeSelectedBorder;
                                                    } else {
                                                        timeStyle = classes.timeStyle;
                                                    }

                                                    return (
                                                        <Box
                                                            key={`AM-${index}`}
                                                            className={timeStyle}
                                                            onClick={e => functionTime(e, row)}
                                                        >
                                                            {row.replace('AM', '')}
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box>
                                <Box textAlign="center">PM</Box>
                                <Box>
                                    {(arrayPM.length > 0 && (constDateUnixSelected > rightUnixDateSelected)) && (
                                        <Box className={classes.timeArea}>
                                            {
                                                arrayPM.map((row, index) => {

                                                    if (row.localeCompare(selectedTime) === 0) {
                                                        timeStyle = timeStyle + ' ' + classes.timeSelectedBorder;
                                                    } else {
                                                        timeStyle = classes.timeStyle;
                                                    }

                                                    return (
                                                        <Box
                                                            key={`PM-${index}`}
                                                            className={timeStyle}
                                                            onClick={e => functionTime(e, row)}
                                                        >
                                                            {row.replace('PM', '')}
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CalendarForm;