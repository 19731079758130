import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsNonAssigner from "./RequestsNonAssigner";

const DashboardRequestAssigner = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsNonAssigner />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestAssigner;
