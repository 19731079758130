import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import iconReservation from '.././../../assets/img/iconReservation.svg';
import iconRecrutement from '.././../../assets/img/iconRecrutement.svg';
import iconContact from '.././../../assets/img/iconContact.svg';
import iconMailbox from '.././../../assets/img/iconMailbox.svg';
import iconFacturation from '.././../../assets/img/iconPaiement.svg';
import iconPages from '.././../../assets/img/iconPage.svg';
import iconAnalytics from '.././../../assets/img/iconAnalytics.svg';
import { Box, Checkbox, Grid } from '@material-ui/core';

const useTreeItemStyles = makeStyles((theme) => ({
  /*
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      }
    },
  */
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  imagen: {
    margin: '0 11px 0 3px',
    '&>img': {
      width: '18px'
    }
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { hundleCheckbox, name, defaultValue, labelText, imagen, labelIcon: LabelIcon, labelInfo, color, bgColor, width, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} style={{ width: width }}>

          {(LabelIcon !== undefined) && (
            <LabelIcon color="inherit" className={classes.labelIcon} />
          )}
          {(imagen !== undefined) && (
            <Box className={classes.imagen}><img src={imagen} alt="" /></Box>
          )}

          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          <Checkbox name={name} onClick={e => hundleCheckbox(e)} checked={defaultValue === 1 ? true : false} />
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        //expanded: classes.expanded,
        //selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

// const useStyles = makeStyles({
//   root: {
//     height: 264,
//     flexGrow: 1,
//     maxWidth: 400,
//   },
// });

export default function Tree(props) {
  //const classes = useStyles();
  const { value_access, handleCheckbox } = props;

  const hundleCheckbox = (e) => {
    //e.preventDefault();
    handleCheckbox(e.target.name, e.target.checked);
  }

  return (
    <TreeView
      //className={classes.root}
      //defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Box px={4}>
            <StyledTreeItem name="access" nodeId="1" labelText="Création Accès" width="360px" labelIcon={FingerprintIcon} defaultValue={value_access.access} hundleCheckbox={hundleCheckbox}>
              <StyledTreeItem
                name="access_create"
                nodeId="5"
                labelText="Créer un salarié"
                labelInfo=""
                color="#1a73e8"
                bgColor="#e8f0fe"
                width="330px"
                labelIcon={AddCircleOutlineIcon}
                defaultValue={value_access.access_create}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="access_update"
                nodeId="6"
                labelText="Modifier un salarié"
                labelInfo=""
                color="#32cc8c"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={EditIcon}
                defaultValue={value_access.access_update}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="access_delete"
                nodeId="7"
                labelText="Supprimer un salarié"
                labelInfo=""
                color="#f44336"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={HighlightOffIcon}
                defaultValue={value_access.access_delete}
                hundleCheckbox={hundleCheckbox}
              />
            </StyledTreeItem>
            <StyledTreeItem name="contacts" nodeId="3" labelText="Contact" width="360px" imagen={iconContact} defaultValue={value_access.contacts} hundleCheckbox={hundleCheckbox}>
              <StyledTreeItem
                name="contacts_update"
                nodeId="11"
                labelText="Modifier un contact Pro"
                labelInfo=""
                color="#32cc8c"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={EditIcon}
                defaultValue={value_access.contacts_update}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="contacts_delete_pro"
                nodeId="12"
                labelText="Supprimer un contact Pro"
                labelInfo=""
                color="#f44336"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={HighlightOffIcon}
                defaultValue={value_access.contacts_delete_pro}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="contacts_delete_client"
                nodeId="13"
                labelText="Supprimer un contact Client"
                labelInfo=""
                color="#f44336"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={HighlightOffIcon}
                defaultValue={value_access.contacts_delete_client}
                hundleCheckbox={hundleCheckbox}
              />
            </StyledTreeItem>
            <StyledTreeItem name="mailbox" nodeId="17" labelText="Mailbox" width="360px" imagen={iconMailbox} defaultValue={value_access.mailbox} hundleCheckbox={hundleCheckbox} />
            <StyledTreeItem name="pages" nodeId="19" labelText="Pages" width="360px" imagen={iconPages} defaultValue={value_access.pages} hundleCheckbox={hundleCheckbox} />
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Box px={4}>
            <StyledTreeItem name="reservation" nodeId="2" labelText="Réservations" width="360px" imagen={iconReservation} defaultValue={value_access.reservation} hundleCheckbox={hundleCheckbox}>
              <StyledTreeItem
                name="reservation_create"
                nodeId="8"
                labelText="Modifier une réservation "
                labelInfo=""
                color="#32cc8c"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={EditIcon}
                defaultValue={value_access.reservation_create}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="reservation_delete"
                nodeId="9"
                labelText="Annuler une réservation"
                labelInfo=""
                color="#f44336"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={HighlightOffIcon}
                defaultValue={value_access.reservation_delete}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="reservation_billing"
                nodeId="10"
                labelText="ID Facture "
                labelInfo=""
                color="#1a73e8"
                bgColor="#e8f0fe"
                width="330px"
                labelIcon={VpnKeyIcon}
                defaultValue={value_access.reservation_billing}
                hundleCheckbox={hundleCheckbox}
              />
            </StyledTreeItem>
            <StyledTreeItem name="recruitment" nodeId="4" labelText="Recrutement" width="360px" imagen={iconRecrutement} defaultValue={value_access.recruitment} hundleCheckbox={hundleCheckbox}>
              <StyledTreeItem
                name="recruitment_unlock"
                nodeId="14"
                labelText="Déverrouiller une candidature en cours "
                labelInfo=""
                color="#32cc8c"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={LockOpenIcon}
                defaultValue={value_access.recruitment_unlock}
                hundleCheckbox={hundleCheckbox}
              />
              <StyledTreeItem
                name="recruitment_delete"
                nodeId="15"
                labelText="Supprimer une candidature"
                labelInfo=""
                color="#f44336"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={HighlightOffIcon}
                defaultValue={value_access.recruitment_delete}
                hundleCheckbox={hundleCheckbox}
              />

              {/* <StyledTreeItem
                name="recruitment_update"
                nodeId="16"
                labelText="Modifier les informations candidat"
                labelInfo=""
                color="#2880fb"
                bgColor="#fcefe3"
                width="330px"
                labelIcon={EditIcon}
                defaultValue={value_access.recruitment_update}
                hundleCheckbox={hundleCheckbox}
              /> */}

            </StyledTreeItem>
            <StyledTreeItem name="billing" nodeId="18" labelText="Facturation" width="360px" imagen={iconFacturation} defaultValue={value_access.billing} hundleCheckbox={hundleCheckbox} />
            <StyledTreeItem name="analytic" nodeId="20" labelText="Analytics" width="360px" imagen={iconAnalytics} defaultValue={value_access.analytic} hundleCheckbox={hundleCheckbox} />
          </Box>
        </Grid>
      </Grid>

    </TreeView>
  );
}