import { Box, makeStyles, Typography, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PaperLayout from "../../share/layout/PaperLayout";
import { useDispatch, useSelector } from "react-redux";
import Filter from "./Filter";
import { getActivitiesByPro } from "../../../store/actions/reservationAction";
import { getDate } from "../../share/librery/librery";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
    timeArea: {
        fontSize: "13px",
        fontWeight: "bold",
        width: "4%",
        textAlign: "center",
        "@media(max-width: 1366px)": {
            fontSize: "10px",
        },
    },
    timeActivity: {
        backgroundColor: "#aaa9ab",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#fff",
        padding: "5px 8px",
        fontSize: "12px",
        "@media(max-width: 1200px)": {
            fontSize: "10px",
        },
    },
    timeNoActivity: {
        color: "#707070",
        width: "4%",
    },
    proArea: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(3, 0),
        "& .MuiAvatar-root": {
            width: "3.5rem",
            height: "3.5rem",
        },
    },
    txtRes: {
        color: "#000",
        fontSize: "12px",
        fontWeight: "bold",
        "@media(max-width: 1200px)": {
            fontSize: "10px",
        },
    },
    nameArea: {
        width: "13%",
        fontSize: "13px",
        "@media(max-width: 1200px)": {
            fontSize: "11px",
        },
    },
}));

const filterData = (pro, formData) => {
    let filterRows = pro;

    if (formData.service !== "") {
        filterRows = filterRows.filter(
            (row) =>
                row.service
                    .toString()
                    .toLowerCase()
                    .indexOf(formData.service.toLowerCase()) > -1
        );
    }

    if (formData.reservation !== "") {
        filterRows = filterRows.filter(
            (row) => parseInt(row.prId, 10) === parseInt(formData.reservation),
            10
        );
    }
    if (formData.pro !== "") {
        filterRows = filterRows.filter(
            (row) =>
                row.uuid
                    .toString()
                    .toLowerCase()
                    .indexOf(formData.pro.toLowerCase()) > -1
        );
    }

    if (
        formData.service === "" &&
        formData.reservation === "" &&
        formData.pro === ""
    ) {
        filterRows = pro;
    }

    return filterRows;
};

const ServiceSchedule = () => {
    const classes = useStyle();

    const { token, reservations } = useSelector((state) => ({
        token: state.auth.access_token,
        reservations: state.reservations.reservations,
    }));

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        service: "",
        reservation: "",
        pro: "",
        date: getDate(),
    });

    const { date } = formData;

    const [uFinal, setUFinal] = useState(null);

    useEffect(() => {
        const d = getDate();
        dispatch(getActivitiesByPro(token, d));
    }, [token, dispatch]);

    useEffect(() => {
        let user = [];

        const res = filterData(reservations, formData);

        if (res !== null) {
            for (let index in res) {
                let d_start = `${getDate()}T${res[index].pro_start_time}`;
                d_start = new Date(d_start).getTime();

                let t_start = res[index].pro_finish_time;
                if (t_start.toString().length === 5) {
                    t_start = `0${res[index].pro_finish_time
                        .toString()
                        .slice(0, 1)}:${res[index].pro_finish_time
                        .toString()
                        .slice(1, 3)}:${res[index].pro_finish_time
                        .toString()
                        .slice(3, 5)}`;
                } else {
                    t_start = `${res[index].pro_finish_time
                        .toString()
                        .slice(0, 2)}:${res[index].pro_finish_time
                        .toString()
                        .slice(2, 4)}:${res[index].pro_finish_time
                        .toString()
                        .slice(4, 6)}`;
                }

                let d_end = `${getDate()}T${t_start}`;

                //let d_end = `${getDate()}T${res[index].pro_finish_time.toString().slice(0, 2)}:${res[index].pro_finish_time.toString().slice(2, 4)}:${res[index].pro_finish_time.toString().slice(4, 6)}`
                d_end = new Date(d_end).getTime();

                if (!user[res[index].uuid]) {
                    user[res[index].uuid] = {
                        avatar: res[index].avatar,
                        activities: [],
                    };
                }

                user[res[index].uuid]["activities"][
                    res[index].pro_start_time.toString().slice(0, 5)
                ] = {
                    blocks: (d_end - d_start) / 1800000,
                    avatar: res[index].avatar,
                    reservation: res[index].prId,
                    start: res[index].pro_start_time.toString().slice(0, 5),
                    end: t_start.slice(0, 5),
                };
            }

            setUFinal(user);
        }
    }, [reservations, formData]);

    const displayPro = (datos) => {
        let html = [];

        let d_start = `${getDate()}T08:00:00`;
        d_start = moment(d_start)._d;
        d_start = d_start.getTime();

        let d_end = `${getDate()}T20:00:00`;
        d_end = moment(d_end)._d;
        d_end = d_end.getTime();

        let userCollect = [];

        for (let index in datos) {
            let user = [];

            let x = 0;

            for (let i = d_start; i <= d_end; i += 1800000, x++) {
                const t = new Date(i).toLocaleTimeString("en-GB").slice(0, -3);

                if (datos[index]["activities"][t] !== undefined) {
                    let new_t = datos[index]["activities"][t].end;
                    new_t = new_t.replace("H", ":");
                    let d_new = `${getDate()}T${new_t}:00`;

                    d_new = new Date(d_new);
                    i = d_new.getTime() - 1800000;

                    user.push(
                        <Box
                            key={`I${index + i}`}
                            className={classes.timeActivity}
                            style={{
                                width: `${
                                    datos[index]["activities"][t].blocks !==
                                    undefined
                                        ? datos[index]["activities"][t].blocks *
                                          4
                                        : 0
                                }%`,
                            }}
                        >
                            <Box>{datos[index]["activities"][t].start}</Box>
                            <Box className={classes.txtRes}>
                                {datos[index]["activities"][t].reservation}
                            </Box>
                            <Box>{datos[index]["activities"][t].end}</Box>
                        </Box>
                    );
                } else {
                    user.push(
                        <Box
                            key={`I${index + i}`}
                            className={
                                classes.timeArea + " " + classes.timeNoActivity
                            }
                        ></Box>
                    );
                }
            }

            //return false

            userCollect.push(
                <Box key={index} className={classes.proArea}>
                    <Box
                        className={classes.nameArea}
                        display="flex"
                        alignItems="center"
                    >
                        <Box mr={1}>
                            <Avatar
                                alt={`${datos[index].avatar}`}
                                src={datos[index].avatar}
                            />
                        </Box>
                        <Box>{index}</Box>
                    </Box>
                    <Box style={{ width: "86%", display: "flex" }}>{user}</Box>
                </Box>
            );
        }

        //html.push(<Box>{userCollect}</Box>)
        html.push(userCollect);
        return html;
    };

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeDate = (e) => {
        setFormData({ ...formData, date: getDate(e) });
    };

    const filter = (e) => {
        e.preventDefault();
        dispatch(getActivitiesByPro(token, date));
    };

    return (
        <PaperLayout>
            <Box mb={2}>
                <Typography variant="h5">PLANNING DES PRESTATIONS</Typography>
            </Box>
            <Filter
                handleChange={handleChange}
                handleChangeDate={handleChangeDate}
                formData={formData}
                filter={filter}
            />
            <Box
                display="flex"
                style={{
                    backgroundColor: "#dddddd",
                    padding: "8px 0",
                    fontSize: "16px",
                }}
            >
                <Box className={classes.nameArea}></Box>
                <Box
                    style={{ width: "86%", marginLeft: "-25px" }}
                    display="flex"
                    alignItems="center"
                >
                    <Box className={classes.timeArea}>08H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>10H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>12H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>14H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>16H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>18H00</Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}></Box>
                    <Box className={classes.timeArea}>20H00</Box>
                </Box>
            </Box>
            <Box>{displayPro(uFinal)}</Box>
        </PaperLayout>
    );
};

export default ServiceSchedule;
