import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Carousel from 'react-multi-carousel'
import CardPro from './CardPro';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import "react-multi-carousel/lib/styles.css";


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1600 }, //max: 3000, min: 1024
      items: 5,
      slidesToSlide: 3, // optional, default to 1.
    },
    xldesktop: {
      breakpoint: { max: 1599, min: 1024 }, //max: 1024, min: 464
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    lgtablet: {
      breakpoint: { max: 1023, min: 992 }, //max: 1024, min: 464
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mdtablet: {
      breakpoint: { max: 991, min: 768 }, //max: 1024, min: 464
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    smmobile: {
      breakpoint: { max: 767, min: 605 }, //max: 464, min: 0
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    xsmobile: {
      breakpoint: { max: 604, min: 0 }, //max: 464, min: 0
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
};

const useState = makeStyles((theme) => ({
    carouselArea: {
        display:'flex',
        flexDirection: 'column',
        '& .react-multi-carousel-track':{
            display:'flex',
        },
        '& .react-multi-carousel-list': {
            margin: '0 24px',
        }
    },
    customButtonGroup: {
        width: '100%',
        height: '100%',
        display:'flex',
        justifyContent:'space-between',
        '& .btnPrev': {
            border: '2px solid #2880fb',
            borderRadius: '16px',
            width: '30px',
            height: '30px',
            marginTop: '-70px',
            marginLeft: '-10px',
            '& svg': {
                marginTop: '-9px',
                marginLeft: '-2px',
                color: '#2880fb'
            }
        },
        '& .btnNext': {
            border: '2px solid #2880fb',
            borderRadius: '16px',
            width: '30px',
            height: '30px',
            marginTop: '-70px',
            marginRight: '-10px',
            '& svg': {
                marginTop: '-9px',
                marginRight: '-2px',
                color: '#2880fb'
            }
        }
        //marginTop: '-26.8rem'
    }
}))

const CustomButtonGroup = ({ next, previous }) => {
    const classes = useState();
    //"custom-button-group"

    return (
      <div className={classes.customButtonGroup}>
        <button className="btnPrev" onClick={() => previous()}>
          <ChevronLeftIcon />
        </button>
        <button className="btnNext" onClick={() => next()}>
          <ChevronRightIcon />
        </button>
      </div>
    );
};

const CarouselAdmin = ({listAdmin, getAdminId, adminId}) => {

    const classes = useState();
    return (
        <Box className={classes.carouselArea}>
            <Carousel
                responsive={responsive}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<CustomButtonGroup />}
            >
                {listAdmin.map((dato) => {
                    return (
                          <CardPro
                              key={dato.id}
                              id={dato.id}
                              firstName={dato.firstName}
                              lastName={dato.lastName}
                              urlImg={dato.urlImg}
                              getAdminId={getAdminId}
                              selected = { adminId === dato.id ? true : false }
                          />
                    );
                })}
          </Carousel>
        </Box>        
    )
}

export default CarouselAdmin