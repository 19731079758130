import moment from "moment";
import constant from "../../../constant.json";

/**
 *
 * @param {Date} date The format is: Thu Feb 18 2021 20:07:49 GMT+0100
 */
export const getDate = (date) => {
    let d = new Date();

    if (date !== undefined) {
        d = new Date(date);
    }

    const year = d.getFullYear();
    const month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

    const dateChanged = year + "-" + month + "-" + day;
    return dateChanged;
};

/**
 *
 * @param {Date} ra The format is: 2021-08-01 00:00:00 return 01/08/2021
 */
export const formateDate = (date) => {
    let splittedDate = date.split(" ");

    if (splittedDate.length > 1) {
        const d = splittedDate[0].split("-");
        const t = splittedDate[1].split(":");

        //let today = new Date(date);
        let today = new Date(
            d[0],
            parseInt(d[1]) - 1,
            parseInt(d[2]),
            parseInt(t[0]),
            parseInt(t[1]),
            parseInt(t[2])
        );
        let year = today.getFullYear();
        let month =
            today.getMonth() + 1 < 10
                ? "0" + (today.getMonth() + 1)
                : today.getMonth() + 1;
        let day =
            today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let formDate = day + "/" + month + "/" + year;

        return formDate;
    } else {
        return date;
    }
};

export const formateDateT = (date) => {
    let splittedDate = date.split("T");
    const d = splittedDate[0].split("-");
    const t = splittedDate[1].split(":");

    //let today = new Date(date);
    let today = new Date(
        d[0],
        parseInt(d[1]) - 1,
        parseInt(d[2]),
        parseInt(t[0]),
        parseInt(t[1]),
        parseInt(t[2])
    );
    let year = today.getFullYear();
    let month =
        today.getMonth() + 1 < 10
            ? "0" + (today.getMonth() + 1)
            : today.getMonth() + 1;
    let day = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    let formDate = day + "/" + month + "/" + year;

    return formDate;
};

/**
 *
 * @param {Date} date The format is: 2021-03-27T02:18:23.000000Z return: 31/03/2021
 */
export const getDateFormat = (date) => {
    const d = date.split("T")[0].split("-");
    const dSlash = d[2] + "/" + d[1] + "/" + d[0];

    return dSlash;
};

/**
 *
 * @param {Date} ra The format is: 08:00:00 return '08H00'
 */
export const formateTime = (ra) => {
    let hour = ra.split(":");
    hour = (parseInt(hour[0], 10) < 10 ? "0" + hour[0] : hour[0]) + hour[1];

    return hour;
};

/**
 *
 * @param {Date} date The format is: 2021-03-27T02:18:23.000000Z return: 31 May 2021
 */
export const getDateFormatDayMotnYear = (date) => {
    let customeDate = moment(date);
    customeDate = customeDate.toString().split(" ");
    customeDate = `${customeDate[2]} ${customeDate[1]} ${customeDate[3]}`;

    return customeDate;
};

/**
 *
 * @param {Date} date The format is: Thu Feb 18 2021 20:07:49 GMT+0100 return 2021-12-31 23:59
 */
export const getDateAndTime = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    const hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    const minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

    const dateChanged =
        year + "-" + month + "-" + day + " " + hour + ":" + minute;
    return dateChanged;
};
/**
 *
 * @param {String} timeStr The format is: 3:21 pm or 3:21 am
 */
export const get24HrsFromAMPM = (timeStr) => {
    if (timeStr && timeStr.indexOf(" ") !== -1 && timeStr.indexOf(":") !== -1) {
        let hrs = 0;
        const tempAry = timeStr.split(" ");

        const hrsMinAry = tempAry[0].split(":");
        hrs = parseInt(hrsMinAry[0], 10);
        if ((tempAry[1] === "AM" || tempAry[1] === "am") && hrs === 12) {
            hrs = 0;
        } else if ((tempAry[1] === "PM" || tempAry[1] === "pm") && hrs !== 12) {
            hrs += 12;
        }

        return (
            ("0" + hrs).slice(-2) +
            ":" +
            ("0" + parseInt(hrsMinAry[1], 10)).slice(-2)
        );
    } else {
        return null;
    }
};
/**
 *
 * @param {String} daten The format is: "2021-02-22 15:30" return "Thu Feb 25 2021 13:34"
 */
export const getFullDate = (daten) => {
    let parts = daten.split(" ");
    let d;

    if (parts.length === 1) {
        parts = daten.split("T");
        d = new Date().toString().split("GMT")[0].slice(0, -4);
    } else {
        const date = parts[0].split("-");
        const time = (parts[1] ? parts[1] : "00:00:00").split(":");
        time[2] = "00";

        const created_at = new Date(
            date[0],
            date[1] - 1,
            date[2],
            time[0],
            time[1],
            time[2]
        );
        d = created_at.toString().split("GMT");
        d = d[0].slice(0, d[0].length - 4);
    }

    return d;
};
/**
 *
 * @param {String} timeHour24 The format is: "2021-02-22T15:30" return "9:00 AM or 4:30 PM"
 */
export const getAMPMFrom24Hrs = (timeHour24) => {
    const hora = timeHour24.split("T")[1].split(":")[0];
    const minutos = timeHour24.split("T")[1].split(":")[1];

    const t = `${hora}:${minutos}`;
    return constant.TIMEAMPM[t];
};

export const getTimeToBlockPlannningVisio = (activities) => {
    const minutes = 30;
    let dateObject;
    let totalTimeSchedule = [];
    let timeScheduledOnce = [];
    let dailyScheduledTime = [];
    let weeklyScheduledTime = [];
    let monthlyScheduledTime = [];

    for (let index in activities) {
        let start_date =
            moment(activities[index].start_time.replace(" ", "T")).unix() *
            1000;
        let end_date =
            moment(activities[index].end_time.replace(" ", "T")).unix() * 1000;

        if (activities[index].recurrence_id === 1) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                dateObject = dateObject.toString().split(" ");

                let indexDate = dateObject[1] + dateObject[2] + dateObject[3];
                let arrayTime = dateObject[4].slice(0, -3);

                if (timeScheduledOnce[indexDate] === undefined) {
                    timeScheduledOnce[indexDate] = [];
                    timeScheduledOnce[indexDate][arrayTime] =
                        activities[index].description;
                } else {
                    timeScheduledOnce[indexDate][arrayTime] =
                        activities[index].description;
                }
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                timeScheduledOnce;
        }

        if (activities[index].recurrence_id === 2) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                dateObject = dateObject.toString().split(" ");

                let arrayTime = dateObject[4].slice(0, -3);

                dailyScheduledTime[arrayTime] = activities[index].description;
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                dailyScheduledTime;
        }

        if (activities[index].recurrence_id === 3) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                const weekday = dateObject.getDay();
                dateObject = dateObject.toString().split(" ");

                // let indexDate = dateObject[1] + dateObject[2] + dateObject[3];
                let arrayTime = dateObject[4].slice(0, -3);

                //weeklyScheduledTime[weekday][arrayTime] = activities[index].description;

                if (weeklyScheduledTime[weekday] === undefined) {
                    weeklyScheduledTime[weekday] = [];
                    weeklyScheduledTime[weekday][arrayTime] =
                        activities[index].description;
                } else {
                    weeklyScheduledTime[weekday][arrayTime] =
                        activities[index].description;
                }
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                weeklyScheduledTime;
        }

        if (activities[index].recurrence_id === 4) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);

                const day = dateObject.getDate();
                dateObject = dateObject.toString().split(" ");

                // let indexDate = dateObject[1] + dateObject[2] + dateObject[3];
                let arrayTime = dateObject[4].slice(0, -3);

                //weeklyScheduledTime[weekday][arrayTime] = activities[index].description;

                if (monthlyScheduledTime[day] === undefined) {
                    monthlyScheduledTime[day] = [];
                    monthlyScheduledTime[day][arrayTime] =
                        activities[index].description;
                } else {
                    monthlyScheduledTime[day][arrayTime] =
                        activities[index].description;
                }
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                monthlyScheduledTime;
        }
    }

    return totalTimeSchedule;
};

export const getTimeToBlockCalendar = (activities) => {
    const minutes = 30;
    let dateObject;
    let totalTimeSchedule = [];
    let timeScheduledOnce = [];
    let dailyScheduledTime = [];
    let weeklyScheduledTime = [];
    let monthlyScheduledTime = [];

    for (let index in activities) {
        let start_date =
            moment(activities[index].start_time.replace(" ", "T")).unix() *
            1000;
        let end_date =
            moment(activities[index].end_time.replace(" ", "T")).unix() * 1000;

        /* Save sporadic time */
        if (activities[index].recurrence_id === 1) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                //const day = getAMPMFrom24HrsTodo(dateObject.toString());

                let hour = dateObject.getHours();
                hour = hour < 10 ? "0" + hour.toString() : hour.toString();

                let minutes = dateObject.getMinutes();
                minutes =
                    minutes < 10
                        ? "0" + minutes.toString()
                        : minutes.toString();

                const d = getDate(dateObject).toString();
                const t = getAMPMFrom24Hrs(`${d}T${hour}:${minutes}`);

                if (timeScheduledOnce[d] !== undefined) {
                    const indice = timeScheduledOnce[d].indexOf(t);
                    if (indice === -1) {
                        timeScheduledOnce[d].push(t);
                    }
                } else {
                    timeScheduledOnce[d] = [t];
                }
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                timeScheduledOnce;
        }

        /* Save daily time */
        if (activities[index].recurrence_id === 2) {
            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                //const day = getAMPMFrom24HrsTodo(dateObject.toString());

                let hour = dateObject.getHours();
                hour = hour < 10 ? "0" + hour.toString() : hour.toString();

                let minutes = dateObject.getMinutes();
                minutes =
                    minutes < 10
                        ? "0" + minutes.toString()
                        : minutes.toString();

                const d = getDate(dateObject).toString();
                const t = getAMPMFrom24Hrs(`${d}T${hour}:${minutes}`);
                /*
                if(dailyScheduledTime.indexOf(t) < 0){
                  dailyScheduledTime.push(t);
                }
        
        */
                if (!dailyScheduledTime.includes(t)) {
                    dailyScheduledTime.push(t);
                }
            }
            totalTimeSchedule[activities[index].recurrence_id] =
                dailyScheduledTime;
        }

        if (activities[index].recurrence_id === 3) {
            const weekday = moment(
                activities[index].start_time.replace(" ", "T")
            ).weekday();

            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                //const weekday = getAMPMFrom24HrsTodo(dateObject.toString());

                let hour = dateObject.getHours();
                hour = hour < 10 ? "0" + hour.toString() : hour.toString();

                let minutes = dateObject.getMinutes();
                minutes =
                    minutes < 10
                        ? "0" + minutes.toString()
                        : minutes.toString();

                const d = getDate(dateObject).toString();
                const t = getAMPMFrom24Hrs(`${d}T${hour}:${minutes}`);

                if (weeklyScheduledTime[weekday] !== undefined) {
                    // const indice = weeklyScheduledTime[weekday].indexOf(t);
                    // if(indice === -1){
                    //   weeklyScheduledTime[weekday].push(t);
                    // }

                    if (!weeklyScheduledTime[weekday].includes(t)) {
                        weeklyScheduledTime[weekday].push(t);
                    }
                } else {
                    weeklyScheduledTime[weekday] = [t];
                }
            }

            totalTimeSchedule[activities[index].recurrence_id] =
                weeklyScheduledTime;
        }

        if (activities[index].recurrence_id === 4) {
            const day = moment(
                activities[index].start_time.replace(" ", "T")
            ).date();

            for (let i = start_date; i < end_date; i += minutes * 60000) {
                dateObject = new Date(i);
                //const day = getAMPMFrom24HrsTodo(dateObject.toString());

                let hour = dateObject.getHours();
                hour = hour < 10 ? "0" + hour.toString() : hour.toString();

                let minutes = dateObject.getMinutes();
                minutes =
                    minutes < 10
                        ? "0" + minutes.toString()
                        : minutes.toString();

                const d = getDate(dateObject).toString();
                const t = getAMPMFrom24Hrs(`${d}T${hour}:${minutes}`);

                if (monthlyScheduledTime[day] !== undefined) {
                    if (!monthlyScheduledTime[day].includes(t)) {
                        monthlyScheduledTime[day].push(t);
                    }
                } else {
                    monthlyScheduledTime[day] = [t];
                }
            }

            totalTimeSchedule[activities[index].recurrence_id] =
                monthlyScheduledTime;
        }
    }

    return totalTimeSchedule;
};

export const cleanUserSession = (error, history) => {
    //const history = useNavigate();
    const msgError = parseInt(
        error
            .toString()
            .substr(error.toString().length - 3, error.toString().length)
    );

    if (msgError === 401) {
        console.log("Error Mensaje: ", msgError, localStorage);
        localStorage.clear();
        history("/login");
    }
};

/** This portion of code works to convert time from Montreal to the other cities. */
export const convertTimeFromMontrealToCityX = (selectedDate, agenda) => {
    let dayAgenda = [];
    let d = new Date(selectedDate);
    d = d.toString().split(" ");
    const day = d[0].slice(0, -1);

    const dayStart = parseInt(agenda[day].split("-")[0]);
    const dayEnd = parseInt(agenda[day].split("-")[1]);

    if (!(dayStart === 0 && dayEnd === 0)) {
        dayAgenda[day] = `${dayStart} - ${dayEnd}`;
    } else {
        dayAgenda[day] = `0 - 0`;
    }

    return dayAgenda;
};

export const telephoneMask = (string) => {
    return string;
};

/**
 *
 * @param {Boolean, Boolean} Null return null
 * Boolean(am) = Available between 8 a.m. and 1 p.m.
 * Boolean(pm) = Available between 1pm a.m. and 6 p.m.
 *
 * rerunt Null:
 * null: The pro is not available during the day
 * 1 : Available only in the morning
 * 2 : Available only in the afternoon
 * 3 : Available all day
 */

export const getIdAmPmProSchedule = (am, pm) => {
    let id = null;
    if (am && !pm) {
        id = 1;
    }
    if (!am && pm) {
        id = 2;
    }
    if (am && pm) {
        id = 3;
    }
    return id;
};

/**
 * @param {Float, Float, Boolean} Float format is: 165.3, 15 return: {tps: 9.02, tvq: 17.98, total: 207.30}
 */
export const calculateTpsTvqAndTotal = (
    workPrice,
    vacuumPrice,
    ecologicalPrice,
    standardPrice,
    isCalculateTax
) => {
    let tps = 0.0;
    let tvq = 0.0;

    if (isCalculateTax !== null && isCalculateTax !== 0) {
        tps =
            ((workPrice + vacuumPrice + ecologicalPrice + standardPrice) *
                parseFloat(constant.TPS, 10)) /
            100;
        tps = tps.toFixed(2);

        tvq =
            ((workPrice + vacuumPrice + ecologicalPrice + standardPrice) *
                parseFloat(constant.TVQ, 10)) /
            100;
        tvq = tvq.toFixed(2);
    }

    let total =
        parseFloat(workPrice, 10) +
        parseFloat(vacuumPrice, 10) +
        parseFloat(ecologicalPrice, 10) +
        parseFloat(standardPrice, 10) +
        parseFloat(tps, 10) +
        parseFloat(tvq, 10);
    total = total.toFixed(2);

    return {
        ptps: constant.TPS,
        ptvq: constant.TVQ,
        tps: tps,
        tvq: tvq,
        total: total,
    };
};

/**
 * @param {String} Float format is: array('0': 2021-01-01, '1': 2021-01-01)
 * @return: boolean
 */
export const compareDatesWithTodayDateCopia = (time) => {
    const today = moment(`${new Date()}`).unix() * 1000;

    let state = false;
    for (let index in time) {
        let d = moment(`${time[index]}T00:00:00`).unix() * 1000;
        if (d <= today) {
            state = true;
        } else {
            state = false;
        }
    }

    return state;
};

/**
 * @param {String} Float format is: array('0': 2021-01-01, '1': 2021-01-01)
 * @return: boolean
 */
export const compareDatesWithTodayDate = (time) => {
    const today = moment(`${new Date()}`).unix() * 1000;

    let state = false;
    for (let index in time) {
        let d = moment(`${time[index]}T00:00:00`).unix() * 1000;
        if (d <= today) {
            state = true;
        } else {
            return false;
        }
    }

    return state;
};

export const getUnixDate = (date) => {
    return moment(date.replace(" ", "T")).unix() * 1000;
};

/**
 * @param {String} Float format is: 2021-12-31T15:00:00
 * @param {String} Float format is: 01:30:00
 * @return: 16:30:00
 */
export const functionAddMinutesToADate = (date, min) => {
    let splittedDate = date.split("T");
    const d = splittedDate[0].split("-");
    const t = splittedDate[1].split(":");
    const numberOfMlSeconds = new Date(
        d[0],
        parseInt(d[1]) - 1,
        parseInt(d[2]),
        parseInt(t[0]),
        parseInt(t[1]),
        parseInt(t[2])
    ).getTime();

    const addMlSeconds =
        (parseInt(min.split(":")[0], 10) * 60 +
            parseInt(min.split(":")[1], 10)) *
        60000;
    const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    let hours = newDateObj.getHours();
    hours = hours < 10 ? "0" + hours : hours;

    let minutes = newDateObj.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${minutes}:00`;

    return time;
};

/**
 * @param {String} Float format is: 2022-03-28 00:00:00
 * @param {String} Float format is: fr-FR
 * @return: Monday, April 25, 2022
 */

export const datetoLocaleString = (date, language) => {
    const getDay = new Date(date.replace(/-/g, "/"));
    const res = getDay.toLocaleString(language, {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
    });

    let cad = "";
    if (language === "fr") {
        let darray = res.split(" ");

        for (let index in darray) {
            cad =
                cad +
                " " +
                darray[index].slice(0, 1).toUpperCase() +
                darray[index].slice(1, darray[index].length).toLowerCase();
        }
    } else {
        cad = res;
    }

    return cad;
};

export const functionActivitiesHour = (scheduleActivities, chosenDate) => {
    let array = [];
    for (let index in scheduleActivities) {
        const startActivity = scheduleActivities[index].pro_start_time;

        let hourEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(0, 2);
        let minuteEndActivity = scheduleActivities[index].pro_finish_time
            .toString()
            .substring(2, 4);
        if (scheduleActivities[index].pro_finish_time.toString().length === 5) {
            hourEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(0, 1);
            minuteEndActivity = scheduleActivities[index].pro_finish_time
                .toString()
                .substring(1, 3);
        }

        const endActivity =
            hourEndActivity < 10
                ? "0" + hourEndActivity + ":" + minuteEndActivity + ":00"
                : hourEndActivity + ":" + minuteEndActivity + ":00";

        //const endActivity = `${ hourEndActivity < 10 ? `0${hourEndActivity}` : hourEndActivity }:${minuteEndActivity}:00`

        const unixStartActivity =
            moment(`${chosenDate}T${startActivity}`).unix() * 1000;
        const unixEndActivity =
            moment(`${chosenDate}T${endActivity}`).unix() * 1000;
        //let i = unixStartActivity
        //let hour
        //let minutes
        //let t
        for (let i = unixStartActivity; i <= unixEndActivity; i += 30 * 60000) {
            let hour = moment(i).hours();
            hour = hour < 10 ? `0${hour.toString()}` : hour.toString();

            let minutes = moment(i).minutes();
            minutes =
                minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

            let t = `${hour}:${minutes}:00`;
            array.push({
                id: t,
                color: "green",
            });
        }

        // hour = moment(i).hours()
        // hour = hour < 10 ? `0${hour.toString()}`: hour.toString()

        // minutes = moment(i).minutes()
        // minutes = minutes < 10 ? `0${minutes.toString()}`: minutes.toString()

        // t = `${hour}:${minutes}:00`
        // array.push({
        //   id: t,
        //   color: 'orange'
        // })
    }
    return array;
};