import { Container, makeStyles } from '@material-ui/core'
import DashboardLayout  from '../layout/Layout'
import React from 'react'
import PaperLayout from '../share/layout/PaperLayout'
import { Box, Divider, Typography } from '@material-ui/core'
import Task from './task/Task'
import { createTask } from '../../store/actions/taskAction'
import { useDispatch, useSelector } from 'react-redux'
import InactiveTask from './task/InactiveTask'
import ActiveTask from './task/ActiveTask'

const useStyle = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 'bold',
        marginRight: '20px',
        fontSize: "1.8rem"
    },
    gridSize: {
        height: '80%',
        width: '100%'
    }

}))

const TaskList = () => {
    const classes = useStyle()

    const { auth:{ access_token } } = useSelector(
        state => ({ 
            auth: state.auth 
        })
    )
    
    const dispatch = useDispatch()

    const saveTask = (description) => {
        dispatch(createTask(access_token, description))
    }
    
    return (
        <DashboardLayout>
            <Container maxWidth="md">
                <PaperLayout>
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>LIST DES SERVICES</Typography>
                    <Box mt={3} mb={3}>
                        
                        <Task saveTask={saveTask} />

                    </Box>
              
                    <Typography component="h1" variant="h6" noWrap className={classes.title}>
                        List des services
                    </Typography>

                    <Divider className={classes.divider} />
              
                    <Box className={classes.gridSize}>
                        <Box display="flex" justifyContent="space-around" width="1">
                            <InactiveTask access_token={access_token} />
                            <ActiveTask access_token={access_token} />
                        </Box>
                    </Box>
              
                </PaperLayout>

            </Container>
        </DashboardLayout>
    )
}

export default TaskList
