import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Link,
    makeStyles,
    IconButton,
    Typography,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { datetoLocaleString } from "../../../share/librery/librery"
import StarIcon from "@material-ui/icons/Star";

const useStyle = makeStyles((theme) => ({
    info: {
        padding: theme.spacing(3),
        boxShadow: "5px 6px 10px #00000025",
        borderRadius: "23px",
        "@media(max-width: 600px)": {
            //padding: theme.spacing(2,0,0,0),
            boxShadow: "none",
            border: `3px solid ${theme.palette.primary.main}`
        },
    },
    border: {
        border: `3px solid ${theme.palette.primary.main}`,
    },
    borderGreen: {
        border: `3px solid ${theme.palette.secondary.main}`,
    },
    greenColor: {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
    },
    orangeColor: {
        color: theme.palette.fourth.main,
        fontWeight: "bold",
    },
    blueColor: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    commentArea: {
        backgroundColor: "#F7F7F7",
        overflow: "auto",
        marginBottom: "20px",
        width: "100%",
        padding: "8px",
        border: "1px solid #f0f0f0",
        boxShadow: "0px 10px 10px #00000058",
        borderRadius: "6px",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        "@media(max-width: 960px)": {
            width: "100%",
        },
    },
    oneComment: {
        display: "flex",
        flexDirection: "column",
        '& p':{
            fontSize: '14px',
            padding: '10px 0 0 0'
        },
    },
    evaluationStartArea: {
        //width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,
        //marginLeft:10,
        "& h5": {
            color: "#000",
            fontSize: "16px",
            lineHeight: "1",
        },
        "& h6": {
            color: "#000",
            fontSize: "13px",
            lineHeight: "1",
            padding: "8px 0",
        },
        "@media(max-width: 1200px)": {
            width: "100%",
        },
        "@media(max-width: 960px)": {
            width: "35%",
        },
        "@media(max-width: 600px)": {
            width: "100%",
        },
    },
    starArea: {
        fontSize: "15px",
        color: "#2880F9",
        "& svg": {
            fontSize: "17px",
            marginRight: "5px",
            color: theme.palette.primary.main,
        },
    },
    divisionBar: {
        //width: "50%",
        margin: "0 15px",
        // border: '1px solid #d6d5d5',
        outline: "none",
        border: "none",
        textAlign: "left",
        //marginTop: "20px",
    },
    hr: {
        width: " 90%",
        margin: "0px 30px 20px 20px",
    }
}))

const ProComments = ({statusId, display, pro}) => {
    const classes = useStyle()
    
    const [displayComment, setDisplayComment] = useState(false);

    let classInfo = classes.info;
    if (display) {
        classInfo = classInfo + " " + classes.border;
    }

    let fontColorClass = classes.blueColor;
    if (statusId === "2") {
        fontColorClass = classes.orangeColor;
        classInfo = classInfo + " " + classes.borderGreen;
    }
    if (statusId === "4") {
        fontColorClass = classes.greenColor;
        classInfo = classInfo + " " + classes.borderGreen;
    }
    const displayCommments = (e) => {
        e.preventDefault();
        if(pro.comment.length) setDisplayComment(!displayComment);
    };

    const displayStars = (totale) => {
        let html = [];
        for (let i = 0; i < totale; i++) {
            html.push(<StarIcon key={i} />);
        }
        return html;
    };
    return (
        <Box>
            
            <Box
                style={{
                    textAlign: "right",
                    marginLeft: displayComment ? "21px" : "48px",
                    position: 'relative',
                    zIndex: 9
                }}
            >
                <Link
                    href="#"
                    onClick={(e) =>
                        displayCommments(e)
                    }
                    color="inherit"
                    underline="none"
                >
                    {!displayComment ? (
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <Box mr={0.3}>
                                <Typography
                                    className={
                                        fontColorClass
                                    }
                                    variant="h5"
                                >

                                    {pro.statistics === null ? 0 : pro.statistics.comments}

                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    Commentaires
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <HighlightOffIcon
                            style={{
                                fontSize:
                                    "2.5rem",
                                textAlign:
                                    "right",
                            }}
                        />
                    )}
                </Link>
            </Box>
 
            {displayComment ? (
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                >
                    <Box
                        className={
                            classes.commentArea
                        }
                    >
                        {pro.comment.map(
                            (e, index) => {
                                return (
                                    <Box key={index}>
                                        <Box
                                            className={
                                                classes.oneComment
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.evaluationStartArea
                                                }
                                            >
                                                <Box>
                                                    <Typography variant="h5">
                                                        {
                                                            e[
                                                            "first_name"
                                                            ]
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="h6">
                                                        {datetoLocaleString(
                                                            e[
                                                            "created_at"
                                                            ],
                                                            'fr'
                                                        )}
                                                    </Typography>
                                                </Box>

                                                <Box
                                                    className={
                                                        classes.starArea
                                                    }
                                                >
                                                    {displayStars(
                                                        e[
                                                        "rating"
                                                        ]
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box
                                                className={
                                                    classes.divisionBar
                                                }
                                            >
                                                <p>
                                                    {
                                                        e[
                                                        "content"
                                                        ]
                                                    }
                                                </p>
                                            </Box>
                                        </Box>

                                        <hr
                                            className={
                                                classes.hr
                                            }
                                        />
                                    </Box>
                                )
                            }
                        )}
                    </Box>
                </Box>
            ) : null}
 

        </Box>
    )
}

export default ProComments