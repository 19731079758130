import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from './Avatar'
import InputCustomPhone from '../../share/inputs/InputCustomPhone';
import SelectInput from '../../share/inputs/SelectInput';
import Popup from './Popup';
import { updateBgStatusCandidateAction, updateCandidateDateMailVisioAction, updateCandidateVisioStatusProgrammedAction } from '../../../store/actions/candidateAction';
import { getUnixDate } from '../../share/librery/librery';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // const theme = useTheme()

    groupPerInfo: {
        '& .MuiInputBase-input': {
            // height: '6.5rem',
            // marginTop: '5px',
            paddingTop: '5px'
        },
        '& .MuiFormControl-root': {
            marginRight: '1.5rem',
        }
    },
    img: {
        '& img': {
            width: '16rem',
            height: '17.7rem'
        }
    },
    classCheckBox: {
        '& .MuiSvgIcon-root': {
            fontSize: '3rem'
        },
        '& .MuiTypography-root': {
            fontSize: '2rem'
        }
    }
}
))

const CandidateChecked = [{ id: '0', name: 'À vérifier', color: '#3ad640' }, { id: '1', name: 'Vérifié', color: '#3ad640' }];

const CandidateInfo = () => {

    const classes = useStyles();
    const token = useSelector(state => { return state.auth.access_token })
    const candidate = useSelector(state => { return state.candidate.candidate })
    const dispatch = useDispatch()

    //const [open, setOpen] = useState(false);

    const [dataForm, setDataForm] = useState({
        backgroundCheckStatus: candidate.background_check_status,
        disabledbgCheckStauts: false,
        interviewStatus: candidate.interview_status,
        dataPopup: {
            open: false,
            titlePopup: '',
            typeApi: ''
        },
        errorType: '',
        errorMsg: '',
        borderType: ''
    })

    const { backgroundCheckStatus, disabledbgCheckStauts, interviewStatus, errorMsg, borderType, errorType } = dataForm
    const { open, titlePopup, typeApi } = dataForm.dataPopup


    const getTodayDateAndTime = () => {
        let t = new Date();
        let year = t.getFullYear();
        let month = t.getMonth() + 1;
        month = (month < 10) ? "0" + month : month;
        let hours = (t.getHours() < 10) ? "0" + t.getHours() : t.getHours();
        let minutes = (t.getMinutes() < 10) ? "0" + t.getMinutes() : t.getMinutes();
        let day = (t.getDate() < 10) ? "0" + t.getDate() : t.getDate();
        let formDate = year + "-" + month + "-" + day + ' ' + hours + ':' + minutes;

        return formDate;
    }

    const [getFechaEmailSent, setGetDateEmailSent] = useState(getTodayDateAndTime());
    const [clicked, setClicked] = useState(0)
    const [count, setCount] = useState(0)

    const Ref = useRef()
    const [timer, setTimer] = useState('00:00:00');


    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    const timeInterviewMailTem = candidate.time_interview_mail !== null ? candidate.time_interview_mail : getFechaEmailSent

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    //Codigo nuevo
    const clearTimer = (e) => {
        setTimer('00:01:00');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    //Codigo NUevo
    const handleBackgroundStatus = (e) => {
        e.preventDefault()

        if (e.target.name === 'backgroundCheckStatus') {

            //setOpen(true);
            setDataForm({
                ...dataForm,
                errorMsg: '',
                backgroundCheckStatus: e.target.value,
                dataPopup: {
                    open: true,
                    titlePopup: "Vous souhaitez modifier la valeur de l'antécédents juridiques?",
                    typeApi: 'backgroundCheckStatus'
                }
            })
        } else if (e.target.name === 'interviewStatus') {
            if (candidate.email_interview_status === 1) {

                const timeInterviewEmailTem = candidate.time_interview_mail !== null ? candidate.time_interview_mail : getFechaEmailSent
                const timeInterview = candidate.time_interview !== null ? candidate.time_interview : ""

                if (getUnixDate(timeInterviewEmailTem) <= getUnixDate(timeInterview)) {

                    setDataForm({
                        ...dataForm,
                        interviewStatus: 1,
                        errorMsg: '',
                        dataPopup: {
                            open: true,
                            titlePopup: "La visioconférence a été faite?",
                            typeApi: 'interviewStatus'
                        }
                    })

                } else {
                    setDataForm({
                        ...dataForm,
                        errorMsg: `la visioconférence n'a pas été programmée par le candidat`,
                        errorType: 'error'
                    })
                }
            } else {

                setDataForm({
                    ...dataForm,
                    errorMsg: `Aucun e-mail n'a été envoyé pour planifier la Mail Visio`,
                    errorType: 'error'
                })

                // setDataForm({
                //     ...dataForm,
                //     initialState: {
                //         ...dataForm.initialState,
                //         interviewStatus: false
                //     }
                // })
                // dispatch(setAlert(`Aucun e-mail n'a été envoyé pour planifier la Mail Visio`, 'error'));

            }
        }
    }

    const handleClose = () => {
        //setOpen(false);
        setDataForm({
            ...dataForm,
            backgroundCheckStatus: 0,
            interviewStatus: 0,
            dataPopup: {
                open: false,
                titlePopup: "",
                typeApi: ""
            }
        })
    };

    const handleApi = () => {
        //interviewStatus
        if (typeApi === 'backgroundCheckStatus') {
            dispatch(updateBgStatusCandidateAction(token, candidate))
                .then(() => {
                    setDataForm({
                        ...dataForm,
                        errorMsg: `Les antécédents juridiques est passé à Vérifié`,
                        errorType: 'success',
                        disabledbgCheckStauts: true,
                        dataPopup: {
                            open: false,
                            titlePopup: "",
                            typeApi: ""
                        }
                    })
                })
        }
        if (typeApi === 'interviewStatus') {
            dispatch(updateCandidateVisioStatusProgrammedAction(token, candidate))
                .then(() => {
                    setDataForm({
                        ...dataForm,
                        errorMsg: `L'entretien a été réalisé avec succès`,
                        errorType: 'success',
                        dataPopup: {
                            open: false,
                            titlePopup: "",
                            typeApi: ""
                        }
                    })
                });
        }
    }

    const updateMailVisio = (e) => {

        e.preventDefault();

        let referStatus2 = 0;
        if (candidate.reference.length === 2) {
            if (candidate.reference[0].contact_status === 1 && candidate.reference[1].contact_status === 1) {
                referStatus2 = 1
            }
        } else if (candidate.reference.length === 1) {
            if (candidate.reference[0].contact_status === 1) {
                referStatus2 = 1
            }
        } else {
            referStatus2 = 1
        }

        if (candidate.background_check_status === 1 && referStatus2 === 1) {
            const timeInterveiwMail = getTodayDateAndTime()
            //dispatch(updateCandidateDateMailVisioAction(id, token, initialState.timeInterviewMail, 'fr', initialState.firstName, initialState.lastName, initialState.email, initialState.telephone, candidate));

            dispatch(updateCandidateDateMailVisioAction(token, timeInterviewMailTem, 'fr', candidate))
                .then(() => {
                    setDataForm({
                        ...dataForm,
                        errorMsg: `La date ${timeInterviewMailTem} de Mail Visio a été mis à jour avec succès`,
                        errorType: 'success',
                        borderType: 'bkgStatus'
                    })
                })

        } else {

            setDataForm({
                ...dataForm,
                errorMsg: `Les antécédents juridiques et les références d'emploi doivent être vérifiés`,
                errorType: 'error',
                borderType: 'bkgStatus'
            })
        }
    }

    const handleCloseMessage = (e) => {
        e.preventDefault()
        setDataForm({
            ...dataForm,
            errorMsg: ''
        })
    }

    const disableFunction = () => {

        if (clicked === 0 && candidate.email_interview_status === 1 && count >= 1 && candidate.interview_status === 0 && timer === '00:00:00') {
            return false
        } else if (clicked === 0 && candidate.email_interview_status === 1 && count === 0 && candidate.interview_status !== 1) {
            return false
        } else if (clicked === 1 && candidate.email_interview_status === 1 && count === 1) {
            return false
        }
        else if (clicked === 1 && count > 1 && timer === '00:00:00') {
            return false
        }
        else return true
    }

    return (
        <div style={{ padding: 0 }}>
            <Popup
                open={open}
                handleClose={handleClose}
                handleApi={handleApi}
                title={titlePopup}
            />
            {
                (errorMsg.length > 0) && (
                    <Box style={{ textAlign: 'center', marginBottom: '30px' }}>
                        <Box style={{ backgroundColor: errorType === 'error' ? 'red' : '#28cc8b', padding: '20px 10px', margin: '10px 20%', color: '#fff', position: 'relative' }}>
                            <Box style={{ position: 'absolute', top: '0', right: '0' }}>
                                <IconButton color="secondary" aria-label="add an alarm" onClick={(e) => handleCloseMessage(e)}>
                                    <HighlightOffIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Box>
                            {errorMsg}
                        </Box>
                    </Box>
                )
            }
            <Grid container spacing={1}>
                <Grid item sm={12} md={5} lg={5} xl={4}>
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-around">
                        <Avatar />
                        <Box>
                            <label style={{ fontSize: '1.6rem', color: '#7f7f7f' }}>
                                Description Todoo
                            </label>
                            <Box style={{ color: '#3ad640' }}>
                                {candidate.self_description}
                            </Box>
                        </Box>

                        <Box className={classes.groupPerInfo}>

                            <Box display="flex" flexDirection="row" mb={2}>
                                <Box>
                                    <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Prénom</Box>
                                    <TextField
                                        id="firstName"
                                        placeholder=""
                                        size="small"
                                        defaultValue={candidate.credential.firstName}
                                        variant="filled"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                                <Box>
                                    <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Nom</Box>
                                    <TextField
                                        id="firstName"
                                        placeholder=""
                                        size="small"
                                        defaultValue={candidate.credential.lastName}
                                        variant="filled"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.groupPerInfo} mb={2} >
                            <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Courriel</Box>
                            <TextField
                                id="email"
                                placeholder=""
                                size="small"
                                defaultValue={candidate.credential.email}
                                variant="filled"
                                readOnly={true}
                                style={{ width: '96%' }}
                                disabled
                            />
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Téléphone</Box>
                            <InputCustomPhone
                                name='telephone'
                                country={'ca'}
                                label="Cellulaire"
                                value={candidate.credential.telephone}
                                disableCountryCode={false}
                                disableAreaCodes={true}
                                disableDropdown={true}
                                onChange={() => { }}
                                variant="filled"
                                size="small"
                                width={'100%'}
                                error=''
                                readOnly={true}
                            />
                        </Box>

                    </Box>
                </Grid>


                <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Box display="flex" flexDirection="column" justifyContent="space-around" alignContent="center" height="100%">

                        <Box>
                            <Box style={{ color: '#7f7f7f', fontSize: '16px' }}>Adress</Box>
                            <Box display="flex">
                                <Box style={{ fontSize: '16px' }}>{`${candidate.address.rue}, ${candidate.address.city}, ${candidate.address.province}, ${candidate.address.country}, ${candidate.address.postcode}`}</Box>
                            </Box>
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <TextField
                                id="date_of_birth"
                                placeholder=""
                                size="small"
                                defaultValue={candidate.credential.date_of_birth}
                                variant="filled"
                                readOnly={true}
                                disabled
                            />
                        </Box>
                        <Box className={classes.groupPerInfo}>
                            <Box><Typography variant="h5">Autorisation de travail</Typography></Box>
                            <RadioGroup row aria-label="gender" name="authorization_status"
                                value={(candidate.authorization_status === 1) ? "1" : "0"} >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <Box><Typography variant="h5">As-tu un smartphone ?</Typography></Box>
                            <RadioGroup row aria-label="gender" name="smartphone_status"
                                value={(candidate.smartphone_status === 1) ? "1" : "0"}

                            >
                                <FormControlLabel labelPlacement="end" value="1" control={<Radio className={classes.classCheckBox} color="primary" />} label="Oui" />
                                <FormControlLabel labelPlacement="end" value="0" control={<Radio className={classes.classCheckBox} color="primary" />} label="Non" />
                            </RadioGroup>
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <Box><Typography variant="h5">Languages</Typography></Box>
                            <Box display="flex" flexDirection="row">
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="fr" color="primary" checked={candidate.fr ? true : false} />}
                                    label="fr"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="en" color="primary" checked={candidate.en ? true : false} />}
                                    label="en"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="es" color="primary" checked={candidate.es ? true : false} />}
                                    label="es"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="ar" color="primary" checked={candidate.ar ? true : false} />}
                                    label="ar"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="po" color="primary" checked={candidate.po ? true : false} />}
                                    label="po"
                                />
                                <FormControlLabel
                                    control={<Checkbox className={classes.classCheckBox} name="zh" color="primary" checked={candidate.zh ? true : false} />}
                                    label="ma"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12} md={3} lg={3} xl={4}>
                    <Box display="flex" flexDirection="column" justifyContent="space-around" alignContent="center" height="100%">
                        <Box className={classes.groupPerInfo}>
                            <Box><Typography variant="h5">Antécédents juridiques</Typography></Box>
                            <FormControl disabled={disabledbgCheckStauts || candidate.background_check_status === 1} className={{ border: (borderType === 'bkgStatus' && errorMsg.length > 0 && errorType === 'error' && backgroundCheckStatus === 0) ? '1px solid red' : '0px solid white' }}>
                                <SelectInput
                                    id="backgroundCheckStatus"
                                    name="backgroundCheckStatus"
                                    data={CandidateChecked}
                                    colorFont={{ color: '#25c96b', fontWeight: 'bold' }}
                                    onChange={(e) => handleBackgroundStatus(e)}
                                    defaultValue={backgroundCheckStatus}
                                    disabled={disabledbgCheckStauts || candidate.background_check_status === 1}
                                    error=""
                                />
                            </FormControl>
                            <Box style={{ display: (borderType === 'bkgStatus' && errorMsg.length > 0 && errorType === 'error' && backgroundCheckStatus === 0) ? 'block' : 'none' }}>
                                <Box style={{ color: 'red' }}>Requi</Box>
                            </Box>
                        </Box>

                        <Box className={classes.groupPerInfo}>
                            <Box display="flex" flexDirection="row" alignItems="flex-end">
                                <Box display="flex" flexDirection="column">
                                    <Box><Typography variant="h5">Mail Visio</Typography></Box>
                                    <TextField
                                        id="timeInterviewMail"
                                        placeholder=""
                                        size="small"
                                        defaultValue={timeInterviewMailTem}
                                        variant="filled"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        className={classes.btn}
                                        color="primary"
                                        variant="contained"
                                        onClick={(e) => { setClicked(clicked + 1); setCount(count + 1); updateMailVisio(e) }}
                                        disabled={candidate.email_interview_status ? true : false}
                                    >
                                        Envoyer
                                    </Button>

                                </Box>

                                <Box marginLeft="10px" marginRight={"10px"}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.btn}
                                        //className={classes.btnDate}
                                        onClick={(e) => { setCount(count + 1); clearTimer(getDeadTime()); updateMailVisio(e); }}
                                        disabled={disableFunction()}
                                    >
                                        Renvoyer
                                    </Button>

                                </Box>
                                <p hidden={(clicked === 1 && candidate.email_interview_status > 0 && count < 2) ||
                                    (candidate.email_interview_status > 0 && count === 0 && clicked === 0) ||
                                    (candidate.email_interview_status === 0 && count === 0 && clicked === 0)}>{timer}</p>
                            </Box>
                        </Box>


                        <Box className={classes.groupPerInfo}>
                            <Box display="flex" flexDirection="row" alignItems="flex-end">
                                <Box mb={3}>
                                    <Box><Typography variant="h5">Visio programmé</Typography></Box>
                                    <TextField
                                        id="timeInterview"
                                        placeholder=""
                                        size="small"
                                        defaultValue={candidate.time_interview !== null ? candidate.time_interview : ""}
                                        variant="filled"
                                        readOnly={true}
                                        disabled
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box display="flex" flexDirection="column">
                                    <Box><Typography variant="h5">Visio effectuée</Typography></Box>
                                    <FormControlLabel
                                        disabled={candidate.interview_status === 1 ? true : false}
                                        control={
                                            <Checkbox
                                                className={classes.classCheckBox}
                                                name="interviewStatus"
                                                checked={interviewStatus}
                                                color="primary"
                                                onClick={(e) => handleBackgroundStatus(e)}
                                            />
                                        }
                                    />

                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default CandidateInfo