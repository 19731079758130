import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../../layout/Layout";
import RequestsTerminer from "./RequestsTerminer";

const DashboardRequestTerminer = () => {
    return (
        <DashboardLayout>
            <Box>
                <RequestsTerminer />
            </Box>
        </DashboardLayout>
    );
};

export default DashboardRequestTerminer;
