import React from 'react';
import { Box } from '@material-ui/core'
import DashboardLayout from '../../layout/Layout';
import RequestsList from './RequestsList';



const DashboardRequestList = () => {
  return(
    <DashboardLayout>
      <Box>
        <RequestsList />
      </Box>
    </DashboardLayout>
  );
}

export default DashboardRequestList;