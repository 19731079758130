export const PROCESSING = 'PROCESSING';
export const FAILED_PROCESS = 'FAILED_PROCESS';

/* GENERATE ALERT FOR THE ENTERE APPLICATION*/
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const RESET_ALERT = 'RESET_ALERT';
export const CHANGE_STATE_ALERT = 'CHANGE_STATE_ALERT';

/* Type Actions are to ADMIN MODULE*/
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const ADMIN_FAIL = 'ADMIN_FAIL';
export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const GET_AN_ADMIN = 'GET_AN_ADMIN';
export const GENERATE_PASSWORD = 'GENERATE_PASSWORD';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const SAVE_PHOTO_ADMIN = 'SAVE_PHOTO_ADMIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';

/* Type Action are to PLANVISIO  */
export const GET_CANDIDATES_VISIO = 'GET_CANDIDATES_VISIO';
export const INTERVIEW_DATE_EXISTS = 'INTERVIEW_DATE_EXISTS';

/** Type Action are to Activity(unAvailability) */
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';

/** Type Action are to Candidate Module */
export const GETTING_CANDIDATE_SUCCESS = 'GETTING_CANDIDATE_SUCCESS';
export const GETTING_ALL_CANDIDATE_SUCCESS = 'GETTING_ALL_CANDIDATE_SUCCESS';
export const CANDIDATE_SUCCESSFULLY_UPDATED = 'CANDIDATE_SUCCESSFULLY_UPDATED';
export const AVATAR_STATUS_UPDATED_SUCCESSFULLY = 'AVATAR_STATUS_UPDATED_SUCCESSFULLY';
export const AVATAR_UPDATED_SUCCESSFULLY = 'AVATAR_UPDATED_SUCCESSFULLY';
export const AVATAR_VALIDATED_SUCCESSFULLY = 'AVATAR_VALIDATED_SUCCESSFULLY';
export const STATUS_UPDATED_SUCCESSFULLY = 'STATUS_UPDATED_SUCCESSFULLY';
export const DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY = 'DATE_INTERVIEW_MAIL_UPDATED_SUCCESSFULLY';
export const STATUS_INTERVIEW_UPDATED_SUCCESSFULLY = 'STATUS_INTERVIEW_UPDATED_SUCCESSFULLY';
export const USER_SUCCESSFULLY_UNLOCKED = 'USER_SUCCESSFULLY_UNLOCKED';
export const GET_MESSAGES = 'GET_MESSAGES';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const GET_NOTATIONS_CANDIDATE = 'GET_NOTATIONS_CANDIDATE';
export const GET_MESSAGES_CANDIDATE = 'GET_MESSAGES_CANDIDATE';
export const CANCEL_VISIO = 'CANCEL_VISIO';
export const GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED = 'GETTING_ALL_CANDIDATES_ACEPTED_AND_REJETED';

/** Type Action are to change the avatar */
export const SAVE_AVATAR = 'SAVE_AVATAR';

/** Type Action are to change the avatar */
export const EMAIL_TEMPLATE_UPDATED = 'EMAIL_TEMPLATE_UPDATED';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_EMAILS = 'GET_EMAILS';

/** Type Action to get Services */
export const GET_SERVICES = 'GET_SERVICES';
export const GET_A_SERVICE = 'GET_A_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const CREATE_A_NEW_SERVICE = 'CREATE_A_NEW_SERVICE';
export const GET_SERVICES_BY_CITY = 'GET_SERVICES_BY_CITY';
export const GET_TARIFF_BY_CITY = 'GET_TARIFF_BY_CITY';
export const GET_ESTIMATION = 'GET_ESTIMATION';

/** Type Action to get Services CONCIERGE */
export const GET_SERVICESC = 'GET_SERVICESC';
export const GET_A_SERVICEC = 'GET_A_SERVICEC';
export const UPDATE_SERVICEC = 'UPDATE_SERVICEC';
export const CREATE_A_NEW_SERVICEC = 'CREATE_A_NEW_SERVICEC';
export const GET_SERVICES_BY_CITYC = 'GET_SERVICES_BY_CITYC';
export const GET_TARIFF_BY_CITYC = 'GET_TARIFF_BY_CITYC';
export const GET_ESTIMATIONC = 'GET_ESTIMATIONC';
export const PROCESSING_C = 'PROCESSING_C';

/** Type Action to get Reservations */
export const GET_REQUEST = 'GET_REQUEST';
export const GET_A_REQUEST = 'GET_A_REQUEST';
export const GET_FILTER_PRO = 'GET_FILTER_PRO';
export const GET_RESERVATIONS_BY_PRO = 'GET_RESERVATIONS_BY_PRO';

/** Type Action to get Reservations CONCIERGE*/
export const GET_REQUEST_C = 'GET_REQUEST_C';
export const GET_A_REQUEST_C = 'GET_A_REQUEST_C';
export const GET_FILTER_PRO_C = 'GET_FILTER_PRO_C';
export const GET_RESERVATIONS_BY_PRO_C = 'GET_RESERVATIONS_BY_PRO_C';

/** Type Action to get Taks */
export const CREATE_TASK = 'CREATE_TASK';
export const GET_TASKS = 'GET_TASKS'; 
export const GET_TASKS_ACTIVE = 'GET_TASKS_ACTIVE';

/** Type Action to get Contacts */
export const GETTING_ALL_CONTACTS = 'GETTING_ALL_CONTACTS';
export const GETTING_A_CONTACT = 'GETTING_A_CONTACT';

/** Type Action to get Taxes */
export const CREATE_A_NEW_TAX = 'CREATE_A_NEW_TAX';
export const GET_LIST_OF_TAXES = 'GET_LIST_OF_TAXES';
/**Type Action to work with chat */
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_ALL_PRO_CHAT = 'GET_ALL_PRO_CHAT';
export const GET_PRO_INFO_CHAT = 'GET_PRO_INFO_CHAT';
export const GET_ALL_MSG_CHAT = 'GET_ALL_MSG_CHAT';
export const UPDATE_MSG = 'UPDATE_MSG';
export const COUNT_A_ZERO = 'COUNT_A_ZERO';
export const ADD_MSG = 'ADD_MSG';
export const ADD_IMG = 'ADD_IMG';

/** Type Action to work with promocodes */
export const GET_LIST_PROMOCODES =  "GET_LIST_PROMOCODES";