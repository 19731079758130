import React from "react";
import { Box } from "@material-ui/core";
import DashboardLayout from "../layout/Layout";
import ServiceSchedule from "./planning/ServiceSchedule";
import RequestsList from "./request-list/RequestsList";
import CandidateList from "./candidate-list/CandidateList";

const HomePage = () => {

    return (
        <DashboardLayout>
            <Box>
                <ServiceSchedule />
            </Box>
            {
                <Box>
                    <RequestsList />
                </Box>
            }
            <Box>
                <CandidateList />
            </Box>
        </DashboardLayout>
    );
};

export default HomePage;
